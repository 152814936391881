import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { BASE_URL } from "../CommonApi";
import { AES, enc } from "crypto-js";
import swal from "sweetalert";

function TermsAndConditionDetails({ show, onHide, onTermsSelected, jwtToken }) {
  const [termsData, setTermsData] = useState([]);
  const [selectedTerms, setSelectedTerms] = useState([]);
  const decryptedUniqueId = AES.decrypt(
    localStorage.getItem("uniqueId1"),
    "1234567812345678"
  ).toString(enc.Utf8);

  useEffect(() => {
    if (show) {
      fetchTermsData();
    }
  }, [show]);

  const fetchTermsData = async () => {
    try {
      const response = await fetch(BASE_URL + `/getTermsAndCondition`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      });

      const data = await response.json();

      if (data.status === "success") {
        // Initialize selectedTerms based on fetched data's status
        const initialSelectedTerms = data.responseCode
          .filter((term) => term.status === "yes")
          .map((term) => ({
            id: term.id,
            text: term.termsAndCondition,
          }));
        setSelectedTerms(initialSelectedTerms);
        setTermsData(data.responseCode);
      } else {
        console.error("Failed to fetch Terms:", data.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching Terms:", error);
    }
  };

  const handleCheckboxChange = (termId, termText, termStatus) => {
    const termObj = { id: termId, text: termText };
    // we will not check termstatus from database
    if (selectedTerms.some((term) => term.id === termId)) {
      // If the term is currently selected, remove it
      setSelectedTerms(selectedTerms.filter((term) => term.id !== termId));
    } else {
      // Otherwise, add it to the selected terms
      setSelectedTerms([...selectedTerms, termObj]);
    }
  };

  const handleSave = async () => {
    try {
      const selectedTermIds = selectedTerms.map((term) => term.id);
      // Get all term IDs for update
      const allTermIds = termsData.map((term) => term.id);
      const response = await fetch(BASE_URL + `/updateTermsAndConditionAll`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          uniqueId: decryptedUniqueId,
          id: selectedTermIds, // Pass selected terms
        }),
      });

      const data = await response.json();
      if (data.status === "success") {
        swal("Success!", "Selected terms saved.", "success");
        onTermsSelected(selectedTerms);
        onHide();
        // After successful saving, update the status of terms in the local state
        const updatedTerms = termsData.map((term) => {
          if (selectedTermIds.includes(term.id)) {
            return { ...term, status: "yes" };
          } else {
            return { ...term, status: "no" };
          }
        });
        setTermsData(updatedTerms);
      } else {
        swal("Error", "Failed to save selected terms.", "error");
        console.error(data.responseMessage);
      }
    } catch (error) {
      console.error("Error saving selected terms:", error);
      swal("Error", "An error occurred while saving.", "error");
    }
  };

  // Internal CSS
  const modalBodyStyle = {
    maxHeight: "400px",
    overflowY: "auto",
  };

  const termsListStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  };

  const termItemStyle = {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #eee",
    paddingBottom: "10px",
    marginBottom: "10px",
  };

  const termCheckboxStyle = {
    marginRight: "10px",
    cursor: "pointer",
    width: "20px",
    height: "20px",
  };

  const termLabelStyle = {
    cursor: "pointer",
    width: "100%",
  };

  const termTextStyle = {
    wordWrap: "break-word",
  };
  const termTextAfter = {
    content: '""',
    display: "block",
    width: "100%",
    marginTop: "5px",
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" className="terms-modal">
      <Modal.Header closeButton>
        <Modal.Title>Choose Terms & Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalBodyStyle}>
        {termsData.length > 0 ? (
          <div style={termsListStyle}>
            {termsData.map((term, index) => (
              <div key={index} style={termItemStyle}>
                <input
                  type="checkbox"
                  id={`term-${term.id}`}
                  value={term.termsAndCondition}
                  checked={selectedTerms.some(
                    (selectedTerm) => selectedTerm.id === term.id
                  )}
                  onChange={() =>
                    handleCheckboxChange(
                      term.id,
                      term.termsAndCondition,
                      term.status
                    )
                  }
                  style={termCheckboxStyle}
                  className="term-checkbox"
                />
                <label htmlFor={`term-${term.id}`} style={termLabelStyle}>
                  <div
                    className="term-text"
                    style={termTextStyle}
                    dangerouslySetInnerHTML={{
                      __html: term.termsAndCondition,
                    }}
                  ></div>
                  <div style={termTextAfter}></div>
                </label>
              </div>
            ))}
          </div>
        ) : (
          <p>No terms and conditions found.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onHide}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleSave}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default TermsAndConditionDetails;
