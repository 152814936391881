import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Modal } from "react-bootstrap";
import Select from "react-select";
import { addDays, endOfMonth, addMonths } from "date-fns";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function BillsNew({ navigation }) {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [row, setRow] = useState([
    {
      itemDetails: "",
      itemDetails2: "",
      batchId: "",
      rate1: "0.0",
      account: "",
      hsnSac: "",
      per:"1",
      quantity: "1",
      rate: "0.0",
      discount: "0.0",
      discountType: "₹",
      description1: "",
      description2: "",
    },
  ]);

  const itemRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  useEffect(() => {
    if (itemRefs.current.length > 0) {
      itemRefs.current[itemRefs.current.length - 1].focus();
    }
  }, [row]);

  const [manualCheck, setManualCheck] = useState("No");

  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleBillsClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      handleNavigation("bills");
    } else {
      console.log("Cancellation not confirmed");
    }
  };
  const [showing1, setShowing1] = useState(false);
  const [showing2, setShowing2] = useState(false);
  const tds = [
    {
      value: "Commission or Brokerag 5%",
      label: "Commission or Brokerag 5%",
      discount: 5,
    },
    {
      value: "Commission or Brokerag (Reduced) 3.75%",
      label: "Commission or Brokerag (Reduced) 3.75%",
      discount: 3.75,
    },
    { value: "Divident 10%", label: "Divident 10%", discount: 10 },
    {
      value: "Divident (Reduced) 7.5%",
      label: "Divident (Reduced) 7.5%",
      discount: 7.5,
    },
    {
      value: "Other Interest then securities 10%",
      label: "Other Interest then securities 10%",
      discount: 10,
    },
    {
      value: "Other Interest then securities (Reduced) 7.5%",
      label: "Other Interest then securities (Reduced) 7.5%",
      discount: 7.5,
    },
    {
      value: "Payment of Contractors Others 2%",
      label: "Payment of Contractors Others 2%",
      discount: 2,
    },
    {
      value: "Payment of Contractors Others (Reduced) 1.5%",
      label: "Payment of Contractors Others (Reduced) 1.5%",
      discount: 1.5,
    },
    {
      value: "Payment of Contractors Others HUD/Indiv 1%",
      label: "Payment of Contractors Others HUD/Indiv 1%",
      discount: 1,
    },
    {
      value: "Payment of Contractors Others HUD/Indiv (Reduced) 0.75%",
      label: "Payment of Contractors Others HUD/Indiv (Reduced) 0.75%",
      discount: 0.75,
    },
    {
      value: "Professional Fees 10%",
      label: "Professional Fees 10%",
      discount: 10,
    },
    {
      value: "Rent on land or furniture etc 10%",
      label: "Rent on land or furniture etc 10%",
      discount: 10,
    },
  ];

  const discountAccountOptions = [
    {
      value: "[50311] Advertising And Marketing",
      label: "[50311] Advertising And Marketing",
    },
    {
      value: "[14141] Automobile Expense",
      label: "[14141] Automobile Expense",
    },
    { value: "[3193] Bad Debt", label: "[3193] Bad Debt" },
    {
      value: "[26135] Bank Fees and Charges",
      label: "[26135] Bank Fees and Charges",
    },
    {
      value: "[82626] Consultant Expense",
      label: "[82626] Consultant Expense",
    },
    {
      value: "[96192] Depreciation Expense",
      label: "[96192] Depreciation Expense",
    },
    {
      value: "[27448] IT and Internet Expenses",
      label: "[27448] IT and Internet Expenses",
    },
    { value: "[6979] Janitorial Expense", label: "[6979] Janitorial Expense" },
    { value: "[69281] Lodging", label: "[69281] Lodging" },
    {
      value: "[90818] Meals and Entertainment",
      label: "[90818] Meals and Entertainment",
    },
    { value: "[29025] Office Supplies", label: "[29025] Office Supplies" },
    { value: "[29758] Other Expenses", label: "[29758] Other Expenses" },
    { value: "[24403] Postage", label: "[24403] Postage" },
    {
      value: "[84981] Printing and Stationery",
      label: "[84981] Printing and Stationery",
    },
    { value: "[89752] Rent Expense", label: "[89752] Rent Expense" },
    {
      value: "[46854] Repairs and Maintainece",
      label: "[46854] Repairs and Maintainece",
    },
    {
      value: "[73789] Salaries and Employee Wages",
      label: "[73789] Salaries and Employee Wages",
    },
    { value: "[9939] Telephone Expense", label: "[9939] Telephone Expense" },
    { value: "[24350] Travel Expense", label: "[24350] Travel Expense" },
    { value: "[81688] Uncategorized", label: "[81688] Uncategorized" },
    {
      value: "[71978] Depreciation and Amortisation",
      label: "[71978] Depreciation and Amortisation",
    },
    { value: "[54857] Marchandise", label: "[54857] Marchandise" },
    {
      value: "[65773] Raw Materials and Comsumables",
      label: "[65773] Raw Materials and Comsumables",
    },
    {
      value: "[53602] Transportation Expense",
      label: "[53602] Transportation Expense",
    },
    { value: "[7074] Cost of Goods Sold", label: "[7074] Cost of Goods Sold" },
    { value: "[79169] Job Costing", label: "[79169] Job Costing" },
    { value: "[58139] Labor", label: "[58139] Labor" },
    { value: "[38921] Materials", label: "[38921] Materials" },
    { value: "[71810] Subcontractor", label: "[71810] Subcontractor" },
    {
      value: "[48773] Exchange Gain or Loss",
      label: "[48773] Exchange Gain or Loss",
    },
  ];

  const tcs = [
    { value: "5 GST [5%]", label: "5 GST [5%]", discount: 5 },
    { value: "12 GST [12%]", label: "12 GST [12%]", discount: 12 },
    { value: "18 GST [18%]", label: "18 GST [18%]", discount: 18 },
    { value: "28 GST [28%]", label: "28 GST [28%]", discount: 28 },
  ];

  const handleAdd = () => {
    const newItem = {
      itemDetails: "",
      account: "",
      hsnSac: "",
      per:"1",
      quantity: "1",
      rate: "0.0",
      discount: "0.0",
      discountType: "₹",
    };
    const newRow = [...row, newItem];
    setRow(newRow);
  };

  const handleDeleteRow = (i) => {
    const updatedRow = [...row];
    updatedRow.splice(i, 1);
    setRow(updatedRow);
    updateTotal(updatedRow);
  };
  const handleCancleClick = () => {
    handleBillsClick();
  };

  const [vendorNames, setVendorNames] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [salesPersonData, setSalesPersonData] = useState([]);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState("");

  const fetchAllVendors = () => {
    fetch(BASE_URL + `/fetchVendors`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setVendorNames(data.responseMessage);
        } else {
          console.error("Failed to fetch vendor names:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching vendor names:", error);
      });
  };
  useEffect(() => {
    fetchAllVendors();
    fetchTermsData();
  }, []);

  const fetchSalesData = () => {
    fetch(BASE_URL + `/getAllSalesPersonByUniqueId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const filteredData = data.responseMessage.filter(
            (item) => item.name !== "Others"
          );
          setSalesPersonData(filteredData);
        } else {
          console.error("Failed to fetch salesperson:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching salesperson:", error);
      });
  };
  useEffect(() => {
    fetchSalesData();
  }, []);
  const [bussinessRegistered, setBussinessRegistered] = useState("");
  const [gstNumber, setGstNumber] = useState("");

  useEffect(() => {
    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage[0].businessRegistered;
          setBussinessRegistered(Data);
          setGstNumber(data.responseMessage[0].gstin);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const handleSalesPersonChange = (value, id) => {
    const selectedValue = value.value;
    setSelectedSalesPerson(selectedValue);

    const selectedSalesPersonObject = salesPersonData.find(
      (person) => person.name === selectedValue
    );
    if (selectedSalesPersonObject) {
    } else {
      console.log("Selected Sales Person : (Not found)");
    }
  };

  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption.value); // Store the selected vendor's value
    const selectedVendorObject = vendorNames.find(
      (vendor) => vendor.vendorDisplayName === selectedOption.value
    );
    if (selectedVendorObject) {
      const vendorTerms = selectedVendorObject.paymentTerms;
      setSelectedTerms(vendorTerms);
      setSelectedVendorId(selectedVendorObject.id);
      setCustomerState(selectedVendorObject.billingState);
    } else {
      setSelectedTerms("");
    }
  };

  const [show1, setShow1] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const closePopup = () => {
    setShowPopup(false);
    fetchSalesData();
  };

  const [selectedTerms, setSelectedTerms] = useState("Due On Receipt");
  const [dueDate, setDueDate] = useState(null);

  const handleDiscountChange = (e, i) => {
    const { name, value } = e.target;
    const newValues =
      name === "discount" ? { discount: value } : { discountType: value };
    const newRow = [...row];
    newRow[i] = { ...newRow[i], ...newValues };
    setRow(newRow);
    updateTotal(newRow);
  };

  const handleQuantityChange = (e, i) => {
    const newQuantity = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], quantity: newQuantity };
    setRow(newRow);
    updateTotal(row);
  };
  const handleSizeChange = (e, i) => {
    const { value } = e.target;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], size: value };
    setRow(newRow);
    updateTotal(row);
  };
  const handleHsnSacChange = (e, i) => {
    const newHsn = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], hsnSac: newHsn };
    setRow(newRow);
    updateTotal(row);
  };

  const handleRateChange = (e, i) => {
    const newRate = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], rate: newRate };
    setRow(newRow);
    updateTotal(row);
  };
  const handleRateChange1 = (e, i) => {
    const newRate = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], rate1: newRate };
    setRow(newRow);
  };

  const handleBatchIdChange = (e, i) => {
    const { value } = e.target;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], batchId: value };
    setRow(newRow);
  };

  const updateTotal = (rowData) => {
    let calculatedTotal = 0;
    rowData.forEach((data) => {
      const amount = calculateAmount(data);
      calculatedTotal += parseFloat(amount);
    });
  };

  const calculateAmount = (data, tax) => {
    const { quantity, rate, discount, discountType } = data;
    let amount = quantity * rate;
    if (discount) {
      amount -= discountType === "%" ? (amount * discount) / 100 : discount;
    }
    return amount;
  };

  const [selectedTaxRate, setSelectedTaxRate] = useState(0);

  const calculateSubTotal = () => {
    let subTotal = 0;
    row.forEach((data) => {
      const amount = calculateAmount(data);
      subTotal += parseFloat(amount);
    });
    return subTotal.toFixed(2);
  };

  const calculateIGST = () => {
    let totalIGST = 0;
    row.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      if (taxRate > 0) {
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = (parseFloat(amount) * taxRate) / 100;
        totalIGST += increasedTaxAmount;
      }
    });

    return totalIGST.toFixed(2);
  };

  const calculateIGSTForTax = () => {
    const taxResults = [];
    row.forEach((data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate > 0) {
        let existingTaxResult = taxResults.find(
          (result) => result.taxRate === taxRate
        );
        if (!existingTaxResult) {
          existingTaxResult = { taxRate, totalIGST: 0 };
          taxResults.push(existingTaxResult);
        }
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = amount * taxRate;
        existingTaxResult.totalIGST += increasedTaxAmount;
      }
    });
    return taxResults;
  };

  const calculateGroupedSGSTandCGST = () => {
    const taxGroups = row.reduce((acc, data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate === 0) return acc; // Skip 0% GST
      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = amount * taxRate;
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;
      if (!acc[taxRate]) {
        acc[taxRate] = { totalSGST: 0, totalCGST: 0 };
      }
      acc[taxRate].totalSGST += sgst;
      acc[taxRate].totalCGST += cgst;
      return acc;
    }, {});
    return taxGroups;
  };

  const [adjustmentValue, setAdjustmentValue] = useState(""); // Step 1: State for adjustment value
  const [oppositeValue, setOppositeValue] = useState(""); // State for opposite value
  const [discountCheck, setDiscountCheck] = useState("");
  const [batchIdCheck, setBatchIdCheck] = useState("");
  const [quantityCheck,setQuantityCheck] = useState("");
  const [netRateCheck, setNetRateCheck] = useState("");
  const [disAllValue, setDisAllValue] = useState("");
  const [discountType, setDiscountType] = useState("₹");
  const [disValue, setDisValue] = useState("");
  const calculateDiscount = () => {
    const amount = parseFloat(calculateSubTotal()).toFixed(2);
    if (discountType === "₹") {
      setDisValue(disAllValue);
    } else if (discountType === "%") {
      const percentageDiscount = (amount * disAllValue) / 100;
      setDisValue(percentageDiscount.toFixed(2));
    }
  };

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
  };
  const handleDisAllValueChange = (e) => {
    setDisAllValue(e.target.value);
  };

  useEffect(() => {
    calculateDiscount();
  }, [disAllValue, discountType]);

  useEffect(() => {
    fetch(BASE_URL + `/fetchDiscountCheck`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage;
          setDiscountCheck(Data);
          setBatchIdCheck(data.responseMessage.batchIdCheck);
          setNetRateCheck(data.responseMessage.netRateCheck);
          setQuantityCheck(data.responseMessage.quantityCheck);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  useEffect(() => {
    const parsedValue = parseFloat(adjustmentValue);
    if (!isNaN(parsedValue)) {
      const opposite = parsedValue * 1;
      setOppositeValue(opposite.toFixed(2));
    } else {
      setOppositeValue("");
    }
  }, [adjustmentValue]);

  const calculateTotal = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const igst = parseFloat(calculateIGST());
    const adjustment = parseFloat(adjustmentValue) || 0; // Use the adjustment value if it's a valid number, otherwise, default to 0
    const total1 = subTotal + igst + adjustment;
    const total = total1 - disValue;
    const totalFinal = discountAmountTcs + total;
    return totalFinal.toFixed(2);
  };

  const calculateTotal2 = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const igst = parseFloat(calculateIGST());
    const adjustment = parseFloat(adjustmentValue) || 0; // Use the adjustment value if it's a valid number, otherwise, default to 0
    const total1 = subTotal + igst + adjustment;
    const total = total1 - disValue;
    const totalFinal = total - discountAmountTds;
    return totalFinal.toFixed(2);
  };

  const [billsNumber, setBillsNumber] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [lrNumber, setLrNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [adjustmentLabel, setAdjustmentLabel] = useState("");
  const [customerNote, setCustomerNote] = useState("");
  const [termsAndCondition, setTermsAndCondition] = useState("");
  const [selectedTaxType, setSelectedTaxType] = useState(""); // To store the selected tax type (TDS or TCS)
  const [selectedTdsOption, setSelectedTdsOption] = useState(null); // To store the selected TDS option
  const [selectedDiscountAccount, setSelectedDiscountAccount] = useState(null); // To store the selected TDS option
  const [selectedTcsOption, setSelectedTcsOption] = useState(null); // To store the selected TCS option
  const [billNumberPrefix, setBillNumberPrefix] = useState([]);
  const [billNumberNext, setBillNumberNext] = useState([]);
  const [userState, setUserState] = useState([]);
  const [customerState, setCustomerState] = useState("");
  const [discountAmountTds, setDiscountAmountTds] = useState(0.0);

  const applyDiscount = (subtotal, discountPercentage) => {
    const discountAmount = (subtotal * discountPercentage) / 100;
    const tdsDis = (subtotal - discountAmount).toFixed(2);
    setDiscountAmountTds(parseFloat(discountAmount.toFixed(2))); // Set discountAmount in state

    return tdsDis;
  };

  const handleTdsOptionChange = (selectedOption) => {
    setSelectedTdsOption(selectedOption);
    const subtotal = calculateSubTotal();

    if (selectedOption && selectedOption.discount) {
      const discountedTotal = applyDiscount(subtotal, selectedOption.discount);
    }
  };

  const handleDiscountAccountChange = (selectedOption) => {
    setSelectedDiscountAccount(selectedOption);
  };

  const [discountAmountTcs, setDiscountAmountTcs] = useState(0.0);
  const [tType, setTType] = useState("");
  const applyDiscountTcs = (subtotal, discountPercentage) => {
    const discountAmount = (subtotal * discountPercentage) / 100;
    const tcsDis = (subtotal - discountAmount).toFixed(2);
    setDiscountAmountTcs(parseFloat(discountAmount.toFixed(2))); // Set discountAmount in state
    return tcsDis;
  };

  const handleTcsOptionChange = (selectedOption) => {
    setSelectedTcsOption(selectedOption);
    const subtotal = calculateSubTotal();
    if (selectedOption && selectedOption.discount) {
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedOption.discount
      );
    }
  };

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");

  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userState = data.responseMessage.state;
          setUserState(userState);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [dateValue, setDateValue] = useState("");
  const [dateValue1, setDateValue1] = useState("");
  const [showDueDate, setShowDueDate] = useState(true);
  const [dueDateChangedManually, setDueDateChangedManually] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return date;
  };

  const itemDetails = row.map((data) => {
    return {
      itemId: data.itemId,
      itemDetails: data.itemDetails || "",
      itemDetails2: data.itemDetails || "",
      hsnSac: data.hsnSac,
      account: data.account,
      per:data.per,
      quantity: data.quantity,
      size: data.size,
      rate: data.rate,
      discount: data.discount,
      discountType: data.discountType,
      tax: data.tax || 0,
      amount: calculateAmount(data, selectedTaxRate),
      description1: data.description1 || "",
      description2: data.description2 || "",
      customerTaxType:
        userState.toString() === customerState.toString() ? 1 : 2,
      cgst:
        userState.toString() === customerState.toString()
          ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100 / 2
          : 0,
      sgst:
        userState.toString() === customerState.toString()
          ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100 / 2
          : 0,
      igst:
        userState.toString() !== customerState.toString()
          ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100
          : 0,
    };
  });

  const formData = {
    userId: userId,
    uniqueId: decryptedUniqueId,
    vendorName: selectedVendor,
    billsNumber: billsNumber,
    orderNumber: orderNumber,
    lrNumber: lrNumber,
    billsDate: dateValue || normalDateFormat(startDate),
    dueDate: showDueDate === true ? dueDate : dateValue1,
    vendorId: selectedVendorId,
    items: itemDetails,
    salesPerson: selectedSalesPerson,
    terms: selectedTerms,
    subject: subject,
    customerNote: customerNote,
    subtotal: calculateSubTotal(),
    adjustment: parseFloat(adjustmentValue) || 0,
    adjustmentLabel: adjustmentLabel,
    total: calculateTotal(),
    receivables: calculateTotal(),
    termsAndCondition: termsAndCondition,
    discountCheck: discountCheck.discountCheck || 0,
    batchIdCheck: batchIdCheck,
    netRateCheck: netRateCheck,
    quantityCheck:quantityCheck,
    overallDiscount: disValue || 0,
    taxCheck: bussinessRegistered || 0,
    totalDiscount: disAllValue || 0,
    totalDiscountType: discountType || 0,
    tdsTax: selectedTdsOption?.value,
    tcsTax: selectedTcsOption?.value,
    taxType: selectedTaxType,
    type: tType,
    value: showing1 ? discountAmountTds : discountAmountTcs,
    discountAccount: selectedDiscountAccount?.value || 0,
    manualCheck: manualCheck,
    totalTax: "",
    state: customerState,
    gstNumber: gstNumber,
  };

  const [validationMessage1, setValidationMessage1] = useState("");

  const insertDataIntoDatabase = async () => {
    let newValidationMessage = "";
    if (!formData.vendorName) {
      newValidationMessage += "Please select a vendor \n";
    } else if (!formData.billsNumber) {
      newValidationMessage += "Please enter bill number.\n";
    } else if (!formData.billsDate) {
      newValidationMessage += "Please select a bill date \n";
    } else if (!formData.dueDate) {
      newValidationMessage += "Please select a due date\n";
    }

    row.forEach((item, index) => {
      if (item.itemDetails.trim() === "") {
        newValidationMessage += `Item name is required.\n`;
      }
      if (item.rate.trim() === "") {
        newValidationMessage += `Rate is required.\n`;
      } else if (item.rate.trim() === "0.0") {
        newValidationMessage += `Rate cannot be 0.0.\n`;
      }
      if (item.quantity.trim() === "") {
        newValidationMessage += `Quantity is required.\n`;
      }
      if (item.account === "") {
        newValidationMessage += `A valid account must be selected.\n`;
      }
    });

    setValidationMessage1(newValidationMessage);
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }

    setIsLoading(true);
    const response = await fetch(BASE_URL + "/insertBill", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ ...formData, status: "Sent" }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Bill Created",
          });
          handleNavigation("bills");
          setIsLoading(false);
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        setIsLoading(false);
        console.error(error);
      });
  };

  const insertDataIntoDatabaseAsDraft = async () => {
    let newValidationMessage = "";
    if (!formData.vendorName) {
      newValidationMessage += "Please select a vendor \n";
    } else if (!formData.billsNumber) {
      newValidationMessage += "Please enter bill number.\n";
    } else if (!formData.billsDate) {
      newValidationMessage += "Please select a bill date \n";
    } else if (!formData.dueDate) {
      newValidationMessage += "Please select a due date\n";
    }
    row.forEach((item, index) => {
      if (item.itemDetails.trim() === "") {
        newValidationMessage += `Item name is required.\n`;
      }
      if (item.rate.trim() === "") {
        newValidationMessage += `Rate is required.\n`;
      } else if (item.rate.trim() === "0.0") {
        newValidationMessage += `Rate cannot be 0.0.\n`;
      }
      if (item.quantity.trim() === "") {
        newValidationMessage += `Quantity is required.\n`;
      }
      if (item.account === "") {
        newValidationMessage += `A valid account must be selected.\n`;
      }
    });
    setValidationMessage1(newValidationMessage);
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    setIsLoading2(true);
    const response = await fetch(BASE_URL + "/insertBill", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ ...formData, status: "Draft" }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Bill Created",
          });
          handleNavigation("bills");
          setIsLoading2(false);
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading2(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        setIsLoading2(false);
        console.error(error);
      });
  };

  const handleAccoutChange = (e, i) => {
    const { value } = e.target;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], account: value };
    setRow(newRow);
  };

  const handleRadioClick = (taxType) => {
    setSelectedTaxType(taxType);

    if (taxType === "TDS") {
      setSelectedTcsOption(null);
      setDiscountAmountTds(0);
      setDiscountAmountTcs(0);
      setTType("TDS");
    } else if (taxType === "TCS") {
      setSelectedTdsOption(null);
      setDiscountAmountTds(0);
      setDiscountAmountTcs(0);
      setTType("TCS");
    }

    setShowing1(taxType === "TDS");
    setShowing2(taxType === "TCS");
  };

  const copyAdd = () => {
    if (show1) {
      setBillsNumber(billNumberPrefix + billNumberNext);
      setManualCheck("No");
    } else {
      setBillsNumber("");
      setManualCheck("Yes");
    }
  };

  const handleBillsChangeInpute = (e) => {
    setBillsNumber(e.target.value);
    setManualCheck("Yes");
  };

  const setBill = () => {
    setShow1(true);
    fetchBill();
  };

  const fetchBill = () => {
    const requestData = {
      uniqueId: decryptedUniqueId,
      type: "bill",
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(requestData),
    };
    fetch(BASE_URL + "/getCurruntNumberList2", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setBillNumberPrefix(data.responseMessage.prefix);
        setBillNumberNext(data.responseMessage.numbers);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }; // Empty dependency array to fetch data once when the component mounts

  useEffect(() => {
    fetch(BASE_URL + `/getCurruntNumberList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ type: "bill", uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setBillsNumber(data.responseMessage);
        } else {
          console.error("Failed to fetch numberList:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Number List:", error);
      });
  }, []);

  const handleTaxChange = (e, index) => {
    setSelectedTaxRate(e.target.value);
    const newTaxRate = parseFloat(e.target.value);
    const updatedRow = [...row];
    updatedRow[index].tax = newTaxRate;
    setRow(updatedRow);
  };

  const [salesData, setSalesData] = useState({
    uniqueId: decryptedUniqueId,
    name: "",
    email: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSalesData({ ...salesData, [name]: value });
  };

  const [itemData, setItemData] = useState({
    uniqueId: decryptedUniqueId,
    description: "",
  });

  const handleItemChange = (event) => {
    const { name, value } = event.target;
    setItemData({ ...itemData, [name]: value });
  };

  const [dropdownData, setDropdownData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const fetchItemData = () => {
    const postApiUrl = BASE_URL + `/getAllItemData`;
    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, vendorId: "" }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseMessage;
        setDropdownData(responseData.map((item) => item));
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  const [searchIndex, setSearchIndex] = useState(0);

  useEffect(() => {
    const results = dropdownData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, dropdownData]);

  const handleSearchChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...row];
    newRow[index].itemDetails = value;
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setRow(newRow);
    setSearchIndex(index);
  };

  const handleItemDetailsChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...row];
    newRow[index].itemDetails = value;
    setRow(newRow);
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setSearchIndex(index);
    if (value) {
      fetchItemRate(value, index);
    } else {
      handleSearchChange(value, index);
    }
  };

  const handleItemSelect = (selectedItem, selectedItemId, index) => {
    setSearchResults([]);
    const newRow = [...row];
    newRow[index].itemDetails = selectedItem;
    setRow(newRow);
    fetchItemRate(selectedItemId, index);
  };

  const fetchItemRate = (itemId, index) => {
    const getApiUrl = BASE_URL + `/getItemData/${itemId}`;

    fetch(getApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const rate = data.data.purchasePrice;
        const description1 = data.data.description;
        const hsnSac = data.data.hsnCode;
        const itemName = data.data.name;
        const tax = data.data.intraStateTaxRate;
        const itemId = data.data.id;
        const updatedRow = [...row];
        updatedRow[index].rate = rate;
        updatedRow[index].description1 = description1;
        updatedRow[index].hsnSac = hsnSac;
        updatedRow[index].itemDetails = itemName;
        updatedRow[index].tax = tax;
        updatedRow[index].itemId = itemId;
        setRow(updatedRow);
        setSearchResults([]);
        handleAdd();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    if (currentDate && selectedTerms && !dueDateChangedManually) {
      updateDueDate(new Date(currentDate), selectedTerms);
    }
  }, [currentDate, selectedTerms, dueDateChangedManually]);

  const updateDueDate = (date, terms) => {
    setDueDateChangedManually(false);
    if (terms.startsWith("Net ")) {
      const daysToAdd = parseInt(terms.replace("Net ", ""));
      setDueDate(normalDateFormat(addDays(startDate, daysToAdd)));
      setShowDueDate(true);
    } else if (terms === "Due End Of The Month") {
      setDueDate(normalDateFormat(endOfMonth(startDate)));
      setShowDueDate(true);
    } else if (terms === "Due End Of Next Month") {
      setDueDate(normalDateFormat(endOfMonth(addMonths(startDate, 1))));
      setShowDueDate(true);
    } else if (terms === "Due On Receipt") {
      setDueDate(normalDateFormat(startDate));
      setShowDueDate(true);
    } else if (terms === "Custom") {
      setShowDueDate(false);
    } else {
      setDueDate("");
    }
  };

  const updateDueDate1 = (date, terms) => {
    setDueDateChangedManually(false);
    if (terms.startsWith("Net ")) {
      const daysToAdd = parseInt(terms.replace("Net ", ""));
      setDueDate(normalDateFormat(addDays(date, daysToAdd)));
      setShowDueDate(true);
    } else if (terms === "Due End Of The Month") {
      setDueDate(normalDateFormat(endOfMonth(date)));
      setShowDueDate(true);
    } else if (terms === "Due End Of Next Month") {
      setDueDate(normalDateFormat(endOfMonth(addMonths(date, 1))));
      setShowDueDate(true);
    } else if (terms === "Due On Receipt") {
      setDueDate(normalDateFormat(date));
      setShowDueDate(true);
    } else if (terms === "Custom") {
      setShowDueDate(false);
    } else {
      setDueDate("");
    }
  };

  useEffect(() => {
    setStartDate(new Date());
    setStartDate1(new Date());
  }, []);

  const handleDate = (date) => {
    setStartDate(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue(normalFormat);
    updateDueDate1(date, selectedTerms);
  };

  const handleDate1 = (date) => {
    setStartDate1(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue1(normalFormat);
  };

  const [formDataVendor, setFormDataVendor] = useState({
    uniqueId: decryptedUniqueId,
    primaryContact: "Mr.",
    companyName: "",
    vendorDisplayName: "",
    vendorEmail: "",
    vendorPhone1: "",
    vendorPhone2: "",
    designation: "",
    department: "",
    website: "",
    currency: "INR- Indian Rupee",
    openingBalance: "",
    paymentTerms: "Net 15",
    facebook: "",
    twitter: "",
    billingAttention: "",
    billingCountry: "",
    billingAddress1: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingZipcode: "",
    billingPhone: "",
    shipAttention: "",
    shipCountry: "",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "",
    shipZipcode: "",
    shipPhone: "",
    sourceOfSupply: "[MH] - Maharashtra",
    gstTreatment: "",
    contactPerson: [
      {
        other: "",
        firstName: "",
        lastName: "",
        email: "",
        workphone: "",
        mobile: "",
      },
    ],
  });

  const vendorOptions = vendorNames.map((names) => ({
    label: names.vendorDisplayName,
    value: names.vendorDisplayName,
  }));

  const [showNewVendor, setShowNewVendor] = useState(false);

  const handleNewVendorClose = () => setShowNewVendor(false);
  const handleNewVendorShow = () => setShowNewVendor(true);
  const [validationMessage, setValidationMessage] = useState("");

  const handleAddVendor = () => {
    let newValidationMessage = "";

    if (!formDataVendor.vendorDisplayName) {
      newValidationMessage += "Please enter a vendor display name.\n";
    } else if (
      formDataVendor.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formDataVendor.email)
    ) {
      newValidationMessage += "Please enter a valid email address.\n";
    }
    setValidationMessage(newValidationMessage);
    if (newValidationMessage) {
      return; // Exit function if validation fails
    }

    fetch(BASE_URL + "/insertVendor", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formDataVendor),
    })
      .then((response) => {
        if (response.ok) {
          swal({
            icon: "success",
            title: "Vendor Created",
            text: "",
          });
          setShowNewVendor(false);
          fetchAllVendors();
        } else {
          swal({
            icon: "error",
            title: "Failed to Create Vendor!",
            text: "",
          });
          setShowNewVendor(true);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        setShowNewVendor(true);
      });
  };
  const handleCreateVendor = (event) => {
    const { name, value } = event.target;
    setFormDataVendor((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const optionPaymentTerms = [
    { value: "Net 15", label: "Net 15" },
    { value: "Net 30", label: "Net 30" },
    { value: "Net 45", label: "Net 45" },
    { value: "Net 60", label: "Net 60" },
    { value: "Due End Of The Month", label: "Due End Of The Month" },
    { value: "Due End Of Next Month", label: "Due End Of Next Month" },
    { value: "Due On Receipt", label: "Due On Receipt" },
    { value: "Custom", label: "Custom" },
  ];

  const handleChange = (selectedOption) => {
    setSelectedTerms(selectedOption.value);
    updateDueDate(dateValue, selectedOption.value);
  };

  const optionsSalesPerson = salesPersonData.map((sales) => ({
    value: sales.name,
    label: sales.name,
  }));

  const [showSalesModal, setShowSalesModal] = useState(false);
  const [showItemModal, setShowItemModal] = useState(false);
  const [itemName, setItemName] = useState("");

  const handleSalesClose = () => setShowSalesModal(false);

  const handleSalesShow = (e) => setShowSalesModal(true);

  const handleItemClose = () => {
    setShowItemModal(false);
    setItemData({
      ...itemData,
      description: "",
    });
  };

  const [validationMessage2, setValidationMessage2] = useState("");

  const handleSalesPerson = (event) => {
    let newValidationMessage = "";

    if (!salesData.name) {
      newValidationMessage += "Please enter a name.\n";
    }

    setValidationMessage2(newValidationMessage);
    if (newValidationMessage) {
      window.scrollTo(0, 0);
      return; // Exit function if validation fails
    }
    event.preventDefault();

    fetch(BASE_URL + "/createSalesperson", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(salesData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Sales Person Created",
          });
          setShowSalesModal(false);
          fetchSalesData();
          closePopup();
        } else {
          swal({
            icon: "error",
            title: "Failed To Create Sales Person!",
          });
          setShowSalesModal(true);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "An Error Occured While Creating Sales Person",
        });
        setShowSalesModal(true);
      });
  };

  const [itemDescriptions, setItemDescriptions] = useState([]);

  const handleItem = (event) => {
    event.preventDefault();

    const newItemDescription = {
      itemDetails: itemName,
      description: itemData.description,
    };

    setItemDescriptions((prevDescriptions) => {
      const existingIndex = prevDescriptions.findIndex(
        (desc) => desc.itemDetails === itemName
      );

      if (existingIndex !== -1) {
        const updatedDescriptions = [...prevDescriptions];
        updatedDescriptions[existingIndex] = newItemDescription;

        return updatedDescriptions;
      } else {
        return [...prevDescriptions, newItemDescription];
      }
    });

    setRow((prevRows) => {
      return prevRows.map((row) =>
        row.itemDetails === itemName
          ? { ...row, description2: itemData.description }
          : row
      );
    });

    setShowItemModal(false);
    closePopup();
  };

  const handleItemShow = (itemDetails) => {
    const itemDescription = itemDescriptions.find(
      (desc) => desc.itemDetails === itemDetails
    );
    if (itemDescription) {
      setItemData({ description: itemDescription.description });
    } else {
      setItemData({ description: "" });
    }
    setItemName(itemDetails);
    setShowItemModal(true);
  };

  const customStyles3 = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      background: "#F6F6F6",
      borderRadius: "10px",
      height: "20px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginLeft: "8px",
      height: "20px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
  };
  const generateNamePairs = () => {
    const { primaryContact, firstName, lastName, companyName } = formDataVendor;
    const pairs = [];
    if (firstName && lastName) {
      pairs.push(`${primaryContact} ${firstName} ${lastName}`);
      pairs.push(`${firstName} ${lastName}`);
      pairs.push(`${lastName} , ${firstName}`);
      pairs.push(`${companyName}`);
    }
    return pairs;
  };
  useEffect(() => {
    if (
      selectedTaxType === "TDS" &&
      selectedTdsOption &&
      selectedTdsOption.discount
    ) {
      const subtotal = calculateSubTotal();
      const discountedTotal = applyDiscount(
        subtotal,
        selectedTdsOption.discount
      );
    } else if (
      selectedTaxType === "TCS" &&
      selectedTcsOption &&
      selectedTcsOption.discount
    ) {
      const subtotal = calculateSubTotal();
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedTcsOption.discount
      );
    }
  }, [row]);

  const handleEditorChange = (value) => {
    setTermsAndCondition(value); // Update the state with the content of the editor
  };

  const fetchTermsData = () => {
    const postApiUrl = BASE_URL + `/getCheckedTermsAndCondition`;
    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseCode;
        setTermsAndCondition(responseData.join("") || "");
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };
  return (
    <>
      <div className="my-3">
        <div className="d-flex align-items-center ml-1 mt-4">
          <div
            className="cursor-pointer d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              height: "35px",
              width: "38px",
            }}
          >
            <ArrowBackIosIcon
              width={2}
              onClick={handleCancleClick}
              className="arrow-child"
            />
          </div>
          <div className="newcustomer-head ml-2">
            <h5
              className="ml-1 mb-0"
              style={{ letterSpacing: "1px", color: "#086bd5de" }}
            >
              Create New Bill
            </h5>
          </div>
        </div>
      </div>
      {validationMessage1 && (
        <Alert severity="error" className="mt-0">
          {validationMessage1.split("\n").map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </Alert>
      )}{" "}
      <div className="card border-0 my-3">
        <div className="pl-4 pr-4">
          <div className="row pt-2">
            <div className="salespersonbox">
              <div className="text-style">
                Vendor Name <span className="valid"> *</span>
              </div>
              <Select
                options={vendorOptions}
                value={vendorOptions.find(
                  (option) => option.value === selectedVendor
                )}
                onChange={handleVendorChange}
                placeholder="Select a Vendor"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    marginTop: "10px",
                    width: "250px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#F6F6F6",
                    borderRadius: "7px",
                    height: "46px",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    height: "19px",
                  }),
                }}
              />
              <button
                type="button"
                class="btn p-0"
                onClick={handleNewVendorShow}
              >
                <span
                  class="text-primary"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  New Vendor
                </span>
              </button>
              <Modal
                size="lg"
                show={showNewVendor}
                onHide={handleNewVendorClose}
              >
                <div class="modal-header d-flex align-items-center pb-2 pt-2">
                  <h5 class="modal-title fs-5" id="newcustomerPopup">
                    New Vendor
                  </h5>
                  <button
                    type="button"
                    className="btn pr-0"
                    onClick={handleNewVendorClose}
                  >
                    <ion-icon
                      name="close-circle-outline"
                      size="large"
                      style={{ width: "28px" }}
                    ></ion-icon>
                  </button>
                </div>
                <Modal.Body className="p-0 pb-3">
                  {validationMessage && (
                    <Alert severity="error" className="mt-0">
                      {validationMessage.split("\n").map((message, index) => (
                        <div key={index}>{message}</div>
                      ))}
                    </Alert>
                  )}{" "}
                  <div className="container">
                    <div className="row d-inline-flex">
                      <div className="modalinputchild">
                        <div className="text-style"> Salutation </div>
                        <select
                          className="form-control"
                          name="primaryContact"
                          value={formDataVendor.primaryContact}
                          onChange={handleCreateVendor}
                          style={{
                            borderRadius: "7px",
                            backgroundColor: "#f5f5f5",
                            width: "200px",
                            height: "35px",
                            fontSize: "13px",
                            marginTop: "10px",
                          }}
                        >
                          <option value="Mr.">Mr.</option>
                          <option value="Mrs.">Mrs.</option>
                          <option value="Ms.">Ms.</option>
                          <option value="Miss.">Miss.</option>
                          <option value="Dr.">Dr.</option>
                        </select>
                      </div>
                      <div className="modalinputchild">
                        <div className="text-style">First Name</div>
                        <input
                          className="form-control"
                          placeholder="First Name"
                          name="firstName"
                          value={formDataVendor.firstName}
                          onChange={handleCreateVendor}
                          id="firstName"
                          type="text"
                        />
                      </div>
                      <div className="modalinputchild">
                        <div className="text-style">Last Name</div>
                        <input
                          className="form-control"
                          placeholder="Last Name"
                          id="lastName"
                          name="lastName"
                          value={formDataVendor.lastName}
                          onChange={handleCreateVendor}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="row d-inline-flex pt-2">
                      <div className="modalinputchild">
                        <div className="text-style"> Vendor Email</div>
                        <input
                          className="form-control"
                          placeholder="Customer Email"
                          name="vendorEmail"
                          type="text"
                          value={formDataVendor.vendorEmail}
                          onChange={handleCreateVendor}
                        />
                      </div>
                      <div className="modalinputchild">
                        <div className="text-style">
                          {" "}
                          Vendor Display Name <span className="valid">
                            *
                          </span>{" "}
                        </div>
                        <input
                          className="form-control"
                          placeholder="Vendor Display Name"
                          list="vendorDisplayName"
                          name="vendorDisplayName"
                          value={formDataVendor.vendorDisplayName}
                          onChange={handleCreateVendor}
                          style={{
                            borderRadius: "7px",
                            backgroundColor: "#f5f5f5",
                            width: "200px",
                            height: "40px",
                            fontSize: "13px",
                            marginTop: "10px",
                          }}
                        />
                        <datalist id="vendorDisplayName">
                          {generateNamePairs().map((pair, index) => (
                            <option key={index} value={pair} />
                          ))}
                        </datalist>{" "}
                      </div>

                      <div className="modalinputchild">
                        <div className="text-style">Company Name</div>
                        <input
                          className="form-control"
                          placeholder="Add company name"
                          name="companyName"
                          type="text"
                          value={formDataVendor.companyName}
                          onChange={handleCreateVendor}
                        />
                      </div>
                    </div>
                    <div className="row d-inline-flex pt-2">
                      <div className="modalinputchild">
                        <div className="text-style">Vendor Phone</div>
                        <input
                          className="form-control"
                          placeholder="Work Phone"
                          name="vendorPhone1"
                          type="text"
                          value={formDataVendor.vendorPhone1}
                          onChange={handleCreateVendor}
                        />
                      </div>
                      <div className="modalinputchild">
                        <div className="text-style">Mobile</div>
                        <input
                          className="form-control"
                          placeholder="Mobile"
                          name="vendorPhone2"
                          type="text"
                          value={formDataVendor.vendorPhone2}
                          onChange={handleCreateVendor}
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="p-2">
                  <div className="modal-bottombtns">
                    <button
                      type="button"
                      class="modalbtn-1 btn mt-0"
                      onClick={handleNewVendorClose}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="modalbtn-3 btn btn-primary"
                      onClick={handleAddVendor}
                    >
                      Create Vendor
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
          </div>

          <div className="row d-inline-flex pt-3">
            <div className="inputchild">
              <div className="text-style">
                Bill <span className="valid"> *</span>
              </div>
              <input
                className="form-control"
                placeholder="Bills Number"
                name="billsNumber"
                value={billsNumber}
                onChange={handleBillsChangeInpute}
                type="text"
                style={{
                  padding: "12px",
                  backgroundColor: "#F6F6F6",
                  borderRadius: "7px",
                  color: "black",
                  height: "40px",
                  "::placeholder": {
                    color: "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                  },
                }}
              />

              <button
                type="button"
                className="btn p-0"
                data-bs-toggle="modal"
                data-bs-target="#generatePop"
              >
                <span
                  className="text-primary"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  Generate
                </span>
              </button>
              <div
                className="modal fade"
                id="generatePop"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header d-flex align-items-center pb-2 pt-2">
                      <h5 className="modal-title fs-5" id="generateinvoiceHead">
                        Bill Number
                      </h5>
                      <button
                        type="button"
                        className="btn pr-0"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <ion-icon
                          name="close-circle-outline"
                          size="large"
                          style={{ width: "28px" }}
                        ></ion-icon>
                      </button>
                    </div>
                    <div className="modal-body pt-1 pb-1">
                      <div className="popupinvoice-container ml-2 mt-2 mr-2 mb-0">
                        <div className="row headline">
                          <div className="customer-headline-label">
                            <label className="form-label">
                              <span className="headline">
                                Your bill numbers are set on auto-generate mode
                                to save your time.
                                <br /> Are you sure about changing this setting?
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <label className="customer-rdiobox-label d-flex align-items-center">
                            <input
                              className="mr-2"
                              type="radio"
                              name="cus_type"
                              id="cus_bus"
                              value="Business"
                              onClick={setBill}
                            />
                            <span className="customer-option">
                              Continue auto-generating bill numbers
                            </span>
                          </label>
                        </div>
                        {show1 && (
                          <div className="row ml-3 mb-3">
                            <div className="customer-generate-inputfield">
                              <span>Prefix</span>
                              <input
                                className="form-control mt-1"
                                placeholder="Prefix"
                                value={billNumberPrefix}
                                onChange={(e) =>
                                  setBillNumberPrefix(e.target.value)
                                }
                              />
                            </div>
                            <div className="customer-generate-inputfield2">
                              <span>Next Number</span>
                              <input
                                className="form-control mt-1"
                                placeholder="Next Number"
                                value={billNumberNext}
                                onChange={(e) =>
                                  setBillNumberNext(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="row mt-1">
                          <label className="customer-rdiobox-label d-flex align-items-center">
                            <input
                              className="mr-2"
                              type="radio"
                              name="cus_type"
                              id="cus_bus"
                              value="Business"
                              onClick={() => {
                                setShow1(false);
                              }}
                            />
                            <span className="customer-option">
                              I will add them manually each time
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="modal-bottombtns">
                        <button
                          type="button"
                          class="modalbtn-1 btn mt-0"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="modalbtn-3 btn btn-primary"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            copyAdd();
                          }}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="inputchild">
              <div className="text-style">Order Number</div>
              <input
                className="form-control"
                placeholder="Order Number"
                name="orderNumber"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
                type="text"
                style={{
                  padding: "12px",
                  backgroundColor: "#F6F6F6",
                  borderRadius: "7px",
                  color: "black",
                  height: "40px",
                  "::placeholder": {
                    color: "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                  },
                }}
              />
            </div>

            <div className="inputchild">
              <div className="text-style">LR Number</div>
              <input
                className="form-control"
                placeholder="LR Number"
                name="lrNumber"
                value={lrNumber}
                onChange={(e) => setLrNumber(e.target.value)}
                type="text"
                style={{
                  padding: "12px",
                  backgroundColor: "#F6F6F6",
                  borderRadius: "7px",
                  color: "black",
                  height: "40px",
                  "::placeholder": {
                    color: "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                  },
                }}
              />
            </div>
          </div>

          <div className="row pt-3">
            <div className="inputchild">
              <div className="text-style">Bill Date</div>
              <div className="customDatePickerWidth">
                <DatePicker
                  className="form-control"
                  selected={startDate}
                  dateFormat="dd-MM-yyyy"
                  onChange={(date) => handleDate(date)}
                />
              </div>
            </div>

            <div className="salespersonbox">
              <div className="text-style">Terms</div>
              <Select
                options={optionPaymentTerms}
                value={optionPaymentTerms.find(
                  (option) => option.value === selectedTerms
                )}
                onChange={handleChange}
                placeholder="Select Terms"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    marginTop: "10px",
                    width: "250px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#F6F6F6",
                    borderRadius: "7px",
                    height: "46px",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    height: "19px",
                  }),
                }}
              />
            </div>
            <div className="inputchild">
              <div className="text-style">
                Due Date <span className="valid"> *</span>
              </div>
              {showDueDate ? (
                <input
                  className="form-control"
                  type="text"
                  value={dueDate}
                  onClick={() => {
                    setShowDueDate(false);
                    setSelectedTerms("Custom");
                  }}
                  style={{
                    padding: "12px",
                    backgroundColor: "#F6F6F6",
                    borderRadius: "7px",
                    color: "black",
                    height: "40px",
                    "::placeholder": {
                      color: "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                    },
                  }}
                  readOnly
                />
              ) : (
                <div className="customDatePickerWidth">
                  <DatePicker
                    className="form-control"
                    selected={startDate1}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => handleDate1(date)}
                    placeholderText="Select due date"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="row pt-3">
            <div className="salespersonbox">
              <div className="text-style">Salesperson</div>
              <Select
                options={optionsSalesPerson}
                value={optionsSalesPerson.find(
                  (option) => option.value === selectedSalesPerson
                )}
                onChange={handleSalesPersonChange}
                placeholder="Select a SalesPerson"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    marginTop: "10px",
                    width: "250px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#F6F6F6",
                    borderRadius: "7px",
                    height: "46px",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    height: "19px",
                  }),
                }}
              />
              <button type="button" class="btn p-0" onClick={handleSalesShow}>
                <span
                  class="text-primary"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  New Sales Person
                </span>
              </button>
              <Modal show={showSalesModal} onHide={handleSalesClose}>
                <div class="modal-header d-flex align-items-center pb-2 pt-2">
                  <h5 class="modal-title fs-5" id="salespersonHead">
                    Add Sales Person
                  </h5>
                  <button
                    type="button"
                    className="btn pr-0"
                    onClick={handleSalesClose}
                  >
                    <ion-icon
                      name="close-circle-outline"
                      size="large"
                      style={{ width: "28px" }}
                    ></ion-icon>
                  </button>
                </div>
                <Modal.Body>
                  {validationMessage2 && (
                    <Alert severity="error" className="mt-0">
                      {validationMessage2.split("\n").map((message, index) => (
                        <div key={index}>{message}</div>
                      ))}
                    </Alert>
                  )}{" "}
                  <div className="row pt-2">
                    <div className="modalsalesinput">
                      <label className="form-label">
                        <span style={{ color: "red" }}>Name *</span>{" "}
                      </label>
                      <input
                        className="form-control"
                        placeholder="Name"
                        id="name"
                        name="name"
                        type="text"
                        value={salesData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row pt-4">
                    <div className="modalsalesinput">
                      <label className="form-label">
                        <span style={{ color: "#0b0a0ab5" }}>Email</span>
                      </label>
                      <input
                        className="form-control"
                        placeholder="Email"
                        id="email"
                        name="email"
                        type="text"
                        value={salesData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="p-2">
                  <div className="modal-bottombtns">
                    <button
                      type="button"
                      class="modalbtn-1 btn mt-0"
                      onClick={handleSalesClose}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="modalbtn-3 btn btn-primary"
                      onClick={handleSalesPerson}
                      data-bs-dismiss="modal"
                    >
                      Create Sales Person
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="subjectbox ml-3">
              <div className="text-style">Subject</div>
              <input
                type="text"
                className="form-control subject-textarea"
                placeholder="Subject..."
                name="subject"
                value={formData.subject}
                onChange={(e) => setSubject(e.target.value)}
                style={{
                  width: "34.5vw",
                  padding: "12px",
                  backgroundColor: "#F6F6F6",
                  borderRadius: "7px",
                  color: "black",
                  height: "40px",
                  "::placeholder": {
                    color: "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                  },
                }}
              />
            </div>
          </div>
          <>
            <Row className="mt-4">
              <Col md="3">
                <label
                  className="text-primary"
                  style={{ fontSize: 14, fontWeight: "600" }}
                >
                  ITEM INFO
                </label>
              </Col>
            </Row>
            <div className="">
              <div>
                <Row className="">
                  <Col>
                    <Card className="mb-3">
                      <div style={{ overflowX: "auto" }}>
                        <table
                          className="table table-bordered mb-0"
                          style={{
                            width: "100%",
                          }}
                        >
                          <thead className="text-center">
                            <tr>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "350px",
                                }}
                              >
                                {" "}
                                ITEM DETAILS
                              </th>
                              {batchIdCheck === "YES" && (
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  {" "}
                                  BATCH ID
                                </th>
                              )}
                              {discountCheck.hsnCheck === "YES" && (
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  {" "}
                                  HSN/SAC
                                </th>
                              )}
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "100px",
                                }}
                              >
                                {" "}
                                ACCOUNT
                              </th>
                              {discountCheck.sizeCheck === "YES" && (
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  {" "}
                                  SIZE
                                </th>
                              )}
                               <th
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "100px",
                                }}
                              >
                                {" "}
                                PER
                              </th>
                              {quantityCheck === "YES" && (
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "100px",
                                }}
                              >
                                {" "}
                                QUANTITY
                              </th>
                              )}
                              {netRateCheck === "YES" && (
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  {" "}
                                  RATE
                                </th>
                              )}
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "100px",
                                }}
                              >
                                {" "}
                                {netRateCheck === "YES" ? "NET RATE" : "RATE"}
                              </th>

                              {discountCheck.discountCheck === "YES" && (
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  {" "}
                                  DISCOUNT
                                </th>
                              )}
                              {bussinessRegistered === "YES" && (
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  {" "}
                                  TAX
                                </th>
                              )}
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "50px",
                                }}
                              >
                                {" "}
                                AMOUNT
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "50px",
                                }}
                              >
                                {" "}
                                ACTION
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {row.map((data, i) => {
                              const amount = calculateAmount(data, data.tax);
                              const totalAmountWithTax = parseFloat(amount);
                              const itemDescription = itemDescriptions.find(
                                (desc) => desc.itemDetails === data.itemDetails
                              );
                              return (
                                <tr key={i}>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        className="form-control noBorder"
                                        placeholder="Search items..."
                                        value={data.itemDetails}
                                        onChange={(e) =>
                                          handleItemDetailsChange(e, i)
                                        }
                                        style={{ flex: 1 }}
                                      />
                                      {data.itemDetails && (
                                        <button
                                          type="button"
                                          className="btn p-0"
                                          onClick={() =>
                                            handleItemShow(data.itemDetails)
                                          }
                                          style={{ marginLeft: "10px" }}
                                        >
                                          <span
                                            className="text-primary"
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            Edit
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                    {itemDescription && (
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          color: "#6c757d",
                                          whiteSpace: "pre-line",
                                        }}
                                      >
                                        {itemDescription.description}
                                      </div>
                                    )}
                                    {searchPerformed &&
                                      data.itemDetails &&
                                      i === searchIndex && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            zIndex: 9999, // Higher than scrollbar
                                            top: "100%", // Position below input
                                            left: 0,
                                            width: "30%",
                                          }}
                                        >
                                          {searchResults.length === 0 && ""}
                                          {searchResults.length > 0 && (
                                            <ul
                                              style={{
                                                listStyleType: "none",
                                                padding: 0,
                                                position: "absolute",
                                                top: "calc(100% + 10px)",
                                                left: 0,
                                                zIndex: 1000,
                                                backgroundColor: "#FFF",
                                                boxShadow:
                                                  "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                                borderRadius: "5px",
                                                width: "100%",
                                                maxHeight: "200px", // Set a max height for the list
                                                overflowY: "auto", // Enable vertical scrolling
                                              }}
                                            >
                                              {searchResults.map(
                                                (result, index) => (
                                                  <li
                                                    key={index}
                                                    onClick={() =>
                                                      handleItemSelect(
                                                        result.name,
                                                        result.id,
                                                        i
                                                      )
                                                    }
                                                    onMouseEnter={(e) => {
                                                      e.target.style.backgroundColor =
                                                        "#408dfb";
                                                      e.target.style.color =
                                                        "#FFF";
                                                      e.target.style.borderRadius =
                                                        "5px"; // Apply border radius on hover
                                                    }}
                                                    onMouseLeave={(e) => {
                                                      e.target.style.backgroundColor =
                                                        "";
                                                      e.target.style.color =
                                                        "#000";
                                                      e.target.style.borderRadius =
                                                        ""; // Reset border radius
                                                    }}
                                                    style={{
                                                      padding: "12px",
                                                      cursor: "pointer",
                                                      whiteSpace: "nowrap", // Prevent text from overflowing
                                                      overflow: "hidden", // Hide overflow text
                                                      textOverflow: "ellipsis", // Add ellipsis for overflowing text
                                                    }}
                                                  >
                                                    {result.name}
                                                    <br />
                                                    Rate : ₹
                                                    {result.purchasePrice}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          )}
                                        </div>
                                      )}
                                  </td>
                                  {batchIdCheck === "YES" && (
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.batchId || ""}
                                        name="batchId"
                                        type=""
                                        placeholder="Batch Id"
                                        onChange={(e) =>
                                          handleBatchIdChange(e, i)
                                        }
                                      />
                                    </td>
                                  )}
                                  {discountCheck.hsnCheck === "YES" && (
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <input
                                        className="form-control noBorder"
                                        value={data.hsnSac || ""}
                                        type="number"
                                        onChange={(e) =>
                                          handleHsnSacChange(e, i)
                                        }
                                      />
                                    </td>
                                  )}
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <select
                                      className="form-control"
                                      style={{ width: "100px", padding: "0px" }}
                                      name="account"
                                      value={data.account}
                                      onChange={(e) => handleAccoutChange(e, i)}
                                    >
                                      <option selected={true} value="">
                                        Select Account
                                      </option>
                                      <option
                                        disabled="true"
                                        style={{ color: "black" }}
                                      >
                                        Other Current Assets
                                      </option>
                                      <option value="Advance Tax">
                                        Advance Tax
                                      </option>
                                      <option value="Employee Advance">
                                        Employee Advance
                                      </option>
                                      <option value="Input Tax Credits">
                                        Input Tax Credits
                                      </option>
                                      <option value="Input CGST">
                                        {" "}
                                        - Input CGST
                                      </option>
                                      <option value="Input IGST">
                                        {" "}
                                        - Input IGST
                                      </option>
                                      <option value="Input SGST">
                                        {" "}
                                        - Input SGST
                                      </option>
                                      <option value="Prepaid Expenses">
                                        Prepaid Expenses
                                      </option>
                                      <option value="Reverse Charge Tax Input but not due">
                                        Reverse Charge Tax Input but not due
                                      </option>
                                      <option value="TDS Receivable">
                                        TDS Receivable
                                      </option>
                                      <option
                                        disabled="true"
                                        style={{ color: "black" }}
                                      >
                                        Fixed Assets
                                      </option>
                                      <option value="Furniture and Equipment">
                                        Furniture and Equipment
                                      </option>
                                      <option
                                        disabled="true"
                                        style={{ color: "black" }}
                                      >
                                        Other Current Liability
                                      </option>
                                      <option value="Employee Reimbursements">
                                        Employee Reimbursements
                                      </option>
                                      <option value="GST Payable">
                                        GST Payable
                                      </option>
                                      <option value="Output CGST">
                                        {" "}
                                        - Output CGST
                                      </option>
                                      <option value="Output IGST">
                                        {" "}
                                        - Output IGST
                                      </option>
                                      <option value="Output SGST">
                                        {" "}
                                        - Output SGST
                                      </option>
                                      <option value="Opening Balance Adjustments">
                                        Opening Balance Adjustments
                                      </option>
                                      <option value="Tax Payable">
                                        Tax Payable
                                      </option>
                                      <option value="TDS Payable">
                                        TDS Payable
                                      </option>
                                      <option value="Unearned Revenue">
                                        Unearned Revenue
                                      </option>
                                      <option
                                        disabled="true"
                                        style={{ color: "black" }}
                                      >
                                        Expense
                                      </option>
                                      <option value="Advertising And Marketing">
                                        Advertising And Marketing
                                      </option>
                                      <option value="Automobile Expense">
                                        Automobile Expense
                                      </option>
                                      <option value="Bad Debt">Bad Debt</option>
                                      <option value="Bank Fees and Charges">
                                        Bank Fees and Charges
                                      </option>
                                      <option value="Consultant Expense">
                                        Consultant Expense
                                      </option>
                                      <option value="Contract Assets">
                                        Contract Assets
                                      </option>
                                      <option value="Credit Card Charges">
                                        Credit Card Charges
                                      </option>
                                      <option value="Depreciation And Amortisation">
                                        Depreciation And Amortisation
                                      </option>
                                      <option value="Depreciation Expense">
                                        Depreciation Expense
                                      </option>
                                      <option value="IT and Internet Expenses">
                                        IT and Internet Expenses
                                      </option>
                                      <option value="Janitorial Expense">
                                        Janitorial Expense
                                      </option>
                                      <option value="Lodging">Lodging</option>
                                      <option value="Meals and Entertainment">
                                        Meals and Entertainment
                                      </option>
                                      <option value="Merchandise">
                                        Merchandise
                                      </option>
                                      <option value="Office Supplies">
                                        Office Supplies
                                      </option>
                                      <option value="Other Expenses">
                                        Other Expenses
                                      </option>
                                      <option value="Postage">Postage</option>
                                      <option value="Printing and Stationery">
                                        Printing and Stationery
                                      </option>
                                      <option value="Raw Materials And Consumables">
                                        Raw Materials And Consumables
                                      </option>
                                      <option value="Rent Expense">
                                        Rent Expense
                                      </option>
                                      <option value="Repairs and Maintenance">
                                        Repairs and Maintenance
                                      </option>
                                      <option value="Salaries and Employee Wages">
                                        Salaries and Employee Wages
                                      </option>
                                      <option value="Telephone Expense">
                                        Telephone Expense
                                      </option>
                                      <option value="Transportation Expense">
                                        Transportation Expense
                                      </option>
                                      <option value="Travel Expense">
                                        Travel Expense
                                      </option>
                                      <option value="Uncategorized">
                                        Uncategorized
                                      </option>
                                      <option
                                        value=""
                                        disabled="true"
                                        style={{ color: "black" }}
                                      >
                                        Cost Of Good Sold
                                      </option>
                                      <option value="Cost of Goods Sold">
                                        Cost of Goods Sold
                                      </option>
                                      <option value="Job Costing">
                                        Job Costing
                                      </option>
                                      <option value="Labor">Labor</option>
                                      <option value="Materials">
                                        Materials
                                      </option>
                                      <option value="Subcontractor">
                                        Subcontractor
                                      </option>
                                    </select>
                                  </td>
                                  {discountCheck.sizeCheck === "YES" && (
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.size || ""}
                                        name="size"
                                        type="number"
                                        placeholder="size"
                                        onChange={(e) => handleSizeChange(e, i)}
                                      />
                                    </td>
                                  )}
                                   <td style={{ whiteSpace: "nowrap" }}>
                                    <input
                                      className="form-control noBorder"
                                      value={data.per || ""}
                                      type="number"
                                      readOnly
                                      // onChange={(e) =>
                                      //   handleQuantityChange(e, i)
                                      // }
                                    />
                                  </td>
                                  {quantityCheck === "YES" && (
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <input
                                      className="form-control noBorder"
                                      value={data.quantity || ""}
                                      type="number"
                                      onChange={(e) =>
                                        handleQuantityChange(e, i)
                                      }
                                    />
                                  </td>
                                  )}
                                  {netRateCheck === "YES" && (
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.rate1}
                                        type="number"
                                        onChange={(e) =>
                                          handleRateChange1(e, i)
                                        }
                                      />
                                    </td>
                                  )}
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <input
                                      className="form-control noBorder"
                                      value={data.rate}
                                      type="number"
                                      onChange={(e) => handleRateChange(e, i)}
                                    />
                                  </td>
                                  {discountCheck.discountCheck === "YES" && (
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <div className="d-flex justify-content-center">
                                        <input
                                          className="form-control col-md-6 noBorder"
                                          value={data.discount || ""}
                                          type="number"
                                          onChange={(e) =>
                                            handleDiscountChange(e, i)
                                          }
                                          name="discount"
                                          style={{
                                            textAlign: "center",
                                            padding: "0px",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <select
                                          className="border-0"
                                          value={data.discountType}
                                          onChange={(e) =>
                                            handleDiscountChange(e, i)
                                          }
                                          name="discountType"
                                          style={{
                                            textAlign: "center",
                                            padding: "0px",
                                            marginRight: "5px",
                                          }}
                                        >
                                          <option value="₹">₹</option>
                                          <option value="%">%</option>
                                        </select>
                                      </div>
                                    </td>
                                  )}
                                  {bussinessRegistered === "YES" && (
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <select
                                        className="form-control"
                                        value={data.tax}
                                        onChange={(e) => handleTaxChange(e, i)}
                                        style={{ padding: "0px" }}
                                      >
                                        <option value="0">N/A</option>
                                        <option value="5">5 GST [5%]</option>
                                        <option value="12">12 GST [12%]</option>
                                        <option value="18">18 GST [18%]</option>
                                        <option value="28">28 GST [28%]</option>
                                      </select>
                                    </td>
                                  )}
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <span>
                                      {parseFloat(totalAmountWithTax).toFixed(
                                        2
                                      )}
                                    </span>
                                  </td>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <button
                                      className="btn px-2 btn-light icon-border btn-sm"
                                      onClick={() => handleDeleteRow(i)}
                                    >
                                      <i className="fa fa-trash text-danger"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                        {/*item description Popup*/}
                        <Modal show={showItemModal} onHide={handleItemClose}>
                          <div class="modal-header d-flex align-items-center pb-2 pt-2">
                            <h5 class="modal-title fs-5" id="salespersonHead">
                              Add Item Description
                            </h5>
                            <button
                              type="button"
                              className="btn pr-0"
                              onClick={handleItemClose}
                            >
                              <ion-icon
                                name="close-circle-outline"
                                size="large"
                                style={{ width: "28px" }}
                              ></ion-icon>
                            </button>
                          </div>
                          <Modal.Body>
                            <Row>
                              <Col md="12">
                                <div className="row pt-2">
                                  <div className="col-lg-6">
                                    <label
                                      className="form-label"
                                      style={{
                                        fontSize: "14px",
                                        color: "grey",
                                        borderRadius: "7px",
                                      }}
                                    >
                                      Item
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 pl-0 pr-0">
                                  <input
                                    className="form-control"
                                    placeholder="Name"
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={itemName}
                                    readOnly
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <div className="row pt-2">
                                  <div className="col-lg-6">
                                    <label
                                      className="form-label"
                                      style={{
                                        fontSize: "14px",
                                        color: "grey",
                                        borderRadius: "7px",
                                      }}
                                    >
                                      Description
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 pl-0 pr-0">
                                  <textarea
                                    className="cn-textarea"
                                    type="text"
                                    placeholder="Item Description"
                                    name="description"
                                    value={itemData.description}
                                    onChange={handleItemChange}
                                    rows={2}
                                    style={{
                                      backgroundColor: "#F6F6F6",
                                      borderRadius: "9px",
                                      padding: "12px",
                                      width: "100%",
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Modal.Body>
                          <Modal.Footer className="p-2">
                            <div className="modal-bottombtns">
                              <button
                                type="button"
                                class="modalbtn-1 btn mt-0"
                                onClick={handleItemClose}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                class="modalbtn-3 btn btn-primary"
                                onClick={handleItem}
                                data-bs-dismiss="modal"
                              >
                                Add Description
                              </button>
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <button className="btn addline-btn" onClick={handleAdd}>
                  + Add Another Line
                </button>
              </div>

              <div className="grid-container">
                <div className="flex-item p-0 d-flex flex-column-reverse">
                  <div className="row pt-3">
                    <div className="col-lg-6">
                      <label
                        className="form-label"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Customer Notes
                      </label>
                    </div>
                    <div className="col-lg-12">
                      <textarea
                        className="cn-textarea"
                        placeholder="Customer Notes..."
                        name="customerNote"
                        value={customerNote}
                        onChange={(e) => setCustomerNote(e.target.value)}
                        type="text"
                        rows={1}
                        style={{
                          backgroundColor: "#F6F6F6",
                          borderRadius: "7px",
                          padding: "12px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="subtotal-box p-2">
                  <div className="flex-item pb-1">
                    <div className="row">
                      <div className="col-lg-8">
                        <label className="form-label">Sub Total</label>
                      </div>
                      <div className="col-lg-4 text-end pl-0">
                        <label className="form-label">
                          {calculateSubTotal()}
                        </label>
                      </div>
                    </div>

                    {discountCheck.discountCheck === "YES" && (
                      <div className="row d-flex align-items-baseline mt-1">
                        <div className="col-lg-5 pr-0">
                          <label>Discount</label>
                        </div>
                        <div className="col-lg-3 pl-0 d-flex">
                          <input
                            className="form-control"
                            value={disAllValue || "0"}
                            type="number"
                            name="discount"
                            onChange={handleDisAllValueChange}
                            style={{
                              height: "36px",
                              padding: "3px 8px",
                              backgroundColor: "#F6F6F6",
                            }}
                          />
                          <select
                            className="form-control ml-1 pl-0 rounded"
                            name="discountType"
                            value={discountType}
                            onChange={handleDiscountTypeChange}
                            style={{
                              height: "36px",
                              width: "40px",
                              padding: "3px 8px",
                              backgroundColor: "#F6F6F6",
                            }}
                          >
                            <option disabled>Select</option>
                            <option value="₹">₹</option>
                            <option value="%">%</option>
                          </select>
                        </div>
                        <div className="col-lg-4 text-end pl-0">
                          <label className="form-label textColor-red">
                            {" "}
                            {isNaN(parseFloat(disValue).toFixed(2))
                              ? "0.00"
                              : -parseFloat(disValue).toFixed(2)}
                          </label>
                        </div>
                      </div>
                    )}
                    <div className="row d-flex align-items-baseline mt-2">
                      <div className="col-lg-5 pr-0">
                        <label>Discount Account</label>
                      </div>
                      <div className="col-lg-3 pl-0 d-flex">
                        <Select
                          options={discountAccountOptions}
                          value={selectedDiscountAccount}
                          onChange={handleDiscountAccountChange}
                          placeholder="Select..."
                          styles={customStyles3}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      {userState.toString() !== customerState.toString() && (
                        <>
                          {calculateIGSTForTax().map((taxResult, index) => (
                            <React.Fragment key={index}>
                              <div className="col-lg-8">
                                <label className="form-label required">
                                  IGST ({(taxResult.taxRate * 100).toFixed(0)}%)
                                </label>
                              </div>
                              <div className="col-lg-4 text-end pl-0">
                                <label className="form-label required">
                                  {taxResult.totalIGST.toFixed(2)}
                                </label>
                              </div>
                            </React.Fragment>
                          ))}
                        </>
                      )}
                      {userState.toString() === customerState.toString() && (
                        <>
                          {Object.entries(calculateGroupedSGSTandCGST()).map(
                            ([taxRate, taxData], index) => (
                              <React.Fragment key={index}>
                                <div className="col-lg-8">
                                  <label className="form-label required">
                                    CGST (
                                    {((parseFloat(taxRate) * 100) / 2)
                                      .toFixed(1)
                                      .replace(/\.0$/, "")}
                                    %)
                                  </label>
                                </div>
                                <div className="col-lg-4 text-end pl-0">
                                  <label className="form-label required">
                                    {taxData.totalCGST.toFixed(2)}
                                  </label>
                                </div>
                                <div className="col-lg-8 mt-2">
                                  <label className="form-label required">
                                    SGST (
                                    {((parseFloat(taxRate) * 100) / 2)
                                      .toFixed(1)
                                      .replace(/\.0$/, "")}
                                    %)
                                  </label>
                                </div>
                                <div className="col-lg-4 text-end pl-0 mt-2">
                                  <label className="form-label required">
                                    {taxData.totalSGST.toFixed(2)}
                                  </label>
                                </div>
                              </React.Fragment>
                            )
                          )}
                        </>
                      )}
                    </div>
                    <div className="my-4 d-flex justify-content-between">
                      <div
                        className="d-flex justify-content-between"
                        style={{ width: "70%" }}
                      >
                        <div className="col-lg-7 pl-0">
                          <label className="rdiobox">
                            <input
                              className="mx-1"
                              type="radio"
                              name="cus_type"
                              id="cus_bus"
                              value="TDS"
                              onClick={() => handleRadioClick("TDS")}
                            />
                            <span>TDS</span>
                          </label>
                          <label className="rdiobox ml-3">
                            <input
                              className="mx-1"
                              type="radio"
                              name="cus_type"
                              id="cus_indivi"
                              value="TCS"
                              onClick={() => handleRadioClick("TCS")}
                            />
                            <span>TCS</span>
                          </label>
                        </div>
                        <div
                          className="col-lg-6 p-0"
                          style={{ width: "190px" }}
                        >
                          {showing1 && (
                            <Select
                              options={tds}
                              value={selectedTdsOption}
                              onChange={handleTdsOptionChange}
                              placeholder="Select TDS..."
                              styles={customStyles3}
                            />
                          )}
                          {showing2 && (
                            <Select
                              options={tcs}
                              value={selectedTcsOption}
                              onChange={handleTcsOptionChange}
                              placeholder="Select TCS..."
                              styles={customStyles3}
                            />
                          )}
                        </div>
                      </div>
                      {showing1 && (
                        <label className="form-label">
                          {discountAmountTds}
                        </label>
                      )}
                      {showing2 && (
                        <label className="form-label">
                          {discountAmountTcs}
                        </label>
                      )}
                    </div>
                    <div className="row mt-3 mb-3">
                      <div className="col-lg-5 pr-0">
                        <input
                          className="form-control col-md-10"
                          type="text"
                          placeholder="Adjustment Label" // Provide a placeholder for user input
                          value={adjustmentLabel}
                          onChange={(e) => setAdjustmentLabel(e.target.value)}
                          name="adjustment"
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "10px",
                            color: "black",
                            "::placeholder": {
                              color:
                                "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                            },
                          }}
                        />
                      </div>
                      <div className="col-lg-5 pl-0">
                        <input
                          className="form-control col-md-10"
                          type="text"
                          placeholder="Adjustment Value"
                          value={adjustmentValue}
                          onChange={(e) => setAdjustmentValue(e.target.value)}
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "10px",
                            color: "black",
                            "::placeholder": {
                              color:
                                "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                            },
                          }}
                        />
                      </div>
                      <div className="col-lg-2 text-end pl-0">
                        <label className="form-label mt-2">
                          {oppositeValue}
                        </label>
                      </div>
                    </div>

                    <hr className="mt-0" />
                    <div className="row mt-3">
                      <div className="col-lg-8">
                        <label className="form-label required ">
                          Total (₹) :
                        </label>
                      </div>
                      <div className="col-lg-4 text-end pl-0">
                        <label className="form-label required">
                          {showing2 ? calculateTotal() : calculateTotal2()}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-4" />
              <div className="row pt-2 align-items-center">
                <div className="col-lg-6">
                  <label
                    className="form-label"
                    style={{ fontSize: "14px", color: "grey" }}
                  >
                    Terms & Condition
                  </label>
                </div>
                <div className="col-lg-12">
                  <ReactQuill
                    value={termsAndCondition}
                    onChange={handleEditorChange}
                    theme="snow" // Use "bubble" for a minimal UI
                    style={{ height: "300px", marginBottom: "50px" }}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
                        [{ font: [] }], // Font options
                        [{ size: [] }], // Font size
                        [{ color: [] }, { background: [] }], // Text color & background
                        ["bold", "italic", "underline", "strike"], // Basic text styles
                        [{ script: "sub" }, { script: "super" }], // Subscript & superscript
                        [{ align: [] }], // Text alignment
                        [{ list: "ordered" }, { list: "bullet" }], // Lists
                        [{ indent: "-1" }, { indent: "+1" }], // Indentation
                        ["blockquote", "code-block"], // Blockquote & code block
                        ["link", "image", "video"], // Media links
                        ["clean"], // Remove formatting
                      ],
                    }}
                    formats={[
                      "header",
                      "font",
                      "size",
                      "color",
                      "background",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "script",
                      "align",
                      "list",
                      "bullet",
                      "indent",
                      "blockquote",
                      "code-block",
                      "link",
                      "image",
                      "video",
                    ]}
                  />
                  <i className="text-muted">
                    This Terms and Condition will be displayed on the bill
                  </i>
                </div>
              </div>
            </div>
          </>
        </div>
        <div className="text-right cn-bottom-buttons">
          <button
            type="button"
            className="btn-1 btn mt-0"
            onClick={handleCancleClick}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn-1 btn mt-0"
            onClick={insertDataIntoDatabaseAsDraft}
          >
            {isLoading2 ? "Saving..." : "Save as Draft"}
          </button>
          <button
            type="button"
            className="btn-3 btn btn-primary"
            onClick={insertDataIntoDatabase}
          >
            {isLoading ? "Saving..." : "Create Bill"}
          </button>
        </div>
      </div>
    </>
  );
}
export default BillsNew;
