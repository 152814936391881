import React from "react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const PaySlip = ({ navigation, onCancel }) => {
  const handleCancleClick = () => {
    onCancel();
  };
  return (
    <>
      <div className="my-3">
        <div className="px-2 d-flex">
          <div className="cursor-pointer d-flex justify-content-center align-items-center back-button">
            <ArrowBackIosIcon
              width={2}
              className="arrow-child"
              onClick={handleCancleClick}
            />
          </div>
          <h5
            className="mt-1 mx-2 text-primary"
            style={{ letterSpacing: "1px" }}
          >PaySlip</h5>
        </div>
      </div>
      <div style={styles.container}>
        <h2 style={styles.payslipMonth}>PAYSLIP FEB 2025</h2>
        <h1 style={styles.company}>JPS CHEMICALS PRIVATE LIMITED</h1>
        <p style={styles.address}>
          2/10 Shivaji Nagar, Big Bazar Road,
          <br />
          Sat Rasta Solapur, Maharashtra - 413001, India
        </p>

        <div style={styles.section}>
          <h3 style={styles.employeeName}>SAKSHI UPASE</h3>
          <table style={styles.table}>
            <tbody>
              <tr>
                <td style={styles.td1}>Employee Number</td>
                <td style={styles.td}>JPS213</td>
                <td style={styles.td1}>Date Joined</td>
                <td style={styles.td}>01 Dec 2024</td>
              </tr>
              <tr>
                <td style={styles.td1}>Department</td>
                <td style={styles.td}>SE Unit</td>
                <td style={styles.td1}>Designation</td>
                <td style={styles.td}>System Administrator</td>
              </tr>
              <tr>
                <td style={styles.td1}>Payment Mode</td>
                <td style={styles.td}>Bank Transfer</td>
                <td style={styles.td1}>Bank</td>
                <td style={styles.td}>DCB BANK</td>
              </tr>
              <tr>
                <td style={styles.td1}>Bank IFSC</td>
                <td style={styles.td}>DCBL0000323</td>
                <td style={styles.td1}>Bank Account</td>
                <td style={styles.td}>32311100003681</td>
              </tr>
              <tr>
                <td style={styles.td1}>PAN</td>
                <td style={styles.td}>ANYPU9492K</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>SALARY DETAILS</h3>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th}>Actual Payable Days</th>
                <th style={styles.th}>Total Working Days</th>
                <th style={styles.th}>Loss of Pay Days</th>
                <th style={styles.th}>Days Payable</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={styles.td}>28.0</td>
                <td style={styles.td}>28.0</td>
                <td style={styles.td}>0.0</td>
                <td style={styles.td}>28</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>EARNINGS & DEDUCTIONS</h3>
          <div style={styles.salaryContainer}>
            {/* Earnings Table */}
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.th1} colSpan="2">
                    EARNINGS
                  </th>
                </tr>
              </thead>
              <tbody>
                {renderRow("Basic", "41,666.67")}
                {renderRow("Education Allowance", "200.00")}
                {renderRow("HRA", "16,666.67")}
                {renderRow("Monthly Reimbursement", "3,000.00")}
                {renderRow("Travel Reimbursement (LTA)", "3,470.83")}
                {renderRow("Statutory Bonus", "1,749.25")}
                {renderRow("Special Allowance", "12,993.92")}
                {renderRow("Total Earnings (A)", "79,747.34", true)}
              </tbody>
            </table>

            {/* Deductions Table */}
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.th1} colSpan="2">
                    TAXES & DEDUCTIONS
                  </th>
                </tr>
              </thead>
              <tbody>
                {renderRow("Professional Tax", "200.00")}
                {renderRow("Total Income Tax", "3,000.00")}
                {renderRow("Total Deductions (B)", "3,200.00", true)}
              </tbody>
            </table>
          </div>

          {/* Net Salary */}
          <h3 style={styles.netSalary}>Net Salary Payable (A - B): 76,547</h3>
          <h3 style={styles.netSalaryWords}>
            Seventy Six Thousand Five Hundred and Forty Seven
          </h3>
          <h1 style={styles.Words}>
            **NOTE:All amounts displayed in the payslip are in INR
          </h1>
          <h1 style={styles.stamp}>Authorized Signature and Stamp</h1>
        </div>
      </div>
    </>
  );
};
const renderRow = (label, value, isBold = false) => (
  <tr>
    <td style={isBold ? styles.td1 : styles.td}>{label}</td>
    <td style={isBold ? styles.td1 : styles.td}>{value}</td>
  </tr>
);

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
    borderRadius: "8px",
    backgroundColor: "#f5faff",
    color: "#333",
  },
  payslipMonth: {
    textAlign: "left",
    color: "#0056b3",
  },
  company: {
    textAlign: "left",
    fontSize: "20px",
    fontWeight: "bold",
  },
  address: {
    textAlign: "left",
    fontSize: "17px",
  },
  section: {
    marginTop: "20px",
    padding: "10px",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    boxShadow: "0 0 10px rgba(0, 86, 179, 0.2)",
  },
  sectionTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#0056b3",
    borderBottom: "2px solid #0056b3",
    paddingBottom: "5px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
  },
  th: {
    borderBottom: "2px solid #0056b3",
    padding: "8px",
    textAlign: "left",
    color: "#0056b3",
  },
  th1: {
    borderBottom: "2px solid #0056b3",
    padding: "8px",
    fontSize: "20px",
    textAlign: "left",
    color: "#0056b3",
  },
  td: {
    padding: "8px",
    borderBottom: "1px solid #ddd",
    color: "#000000",
  },
  td1: {
    padding: "8px",
    borderBottom: "1px solid #ddd",
    color: "#0056b3",
    fontWeight: "bold",
  },
  salaryContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  earnings: {
    width: "48%",
    padding: "10px",
    fontSize: "16px",
  },
  deductions: {
    width: "48%",
    padding: "10px",
    fontSize: "16px",
  },
  subTitle: {
    fontWeight: "bold",
    color: "#0056b3",
    fontSize: "18px",
    borderBottom: "2px solid #0056b3",
    paddingBottom: "5px",
  },
  total: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  netSalary: {
    fontSize: "18px",
    // fontWeight: 'bold',
    color: "#0056b3",
    textAlign: "left",
    marginTop: "20px",
  },
  netSalaryWords: {
    // fontWeight: 'bold',
    fontSize: "18px",
    textAlign: "left",
  },
  Words: {
    fontSize: "15px",
    textAlign: "left",
  },
  stamp: {
    fontSize: "15px",
    textAlign: "left",
  },
  stamp: {
    fontWeight: "bold", // Corrected this
    marginTop: "85px",
    fontSize: "15px",
    textAlign: "right",
  },
};

export default PaySlip;
