import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";

const UpdateCategory = ({ handleCancel, categoryId }) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    uniqueId: decryptedUniqueId || "",
    category: "",
    description: "",
  });

  useEffect(() => {
    fetch(`${BASE_URL}/getCategoryData/${categoryId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Category Data fetched:", data);
        if (data.data) {
          const categoryData = data.data;
          setFormData({
            category: categoryData.category || "",
            description: categoryData.description || "",
          });
        } else {
          console.error("Error fetching Category data:", data.message);
          swal({
            icon: "error",
            title: "Failed to fetch Category details",
            text: data.message || "An error occurred",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        swal({
          icon: "error",
          title: "An Error Occurred",
          text: "Failed to fetch Category details",
        });
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateCategory = async (e) => {
    e.preventDefault();

    const updatedCategoryData = {
      id:categoryId,
      uniqueId: decryptedUniqueId,
      category: formData.category,
      description: formData.description,
    };
    setLoading(true);

    try {
      const response = await fetch(`${BASE_URL}/updateCategory`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(updatedCategoryData),
      });

      const data = await response.json();

      if (data.responseMessage === "Category Updated Successfully") {
        swal({
          icon: "success",
          title: "Success",
          text: "Category updated successfully",
        }).then(() => {
          handleCancel();
        });
      } else {
        throw new Error(data.message || "Failed to update Category");
      }
    } catch (error) {
      console.error("Error updating Category:", error);
      swal({
        icon: "error",
        title: "Error",
        text: error.message || "An error occurred while updating the Category",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    const confirmCancel = window.confirm(
      "Are you sure you want to leave this page? Any unsaved changes will be lost."
    );
    if (confirmCancel) {
      handleCancel();
    }
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="my-3">
        <div className="d-flex align-items-center ml-1 mt-4">
          <div
            className="cursor-pointer d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              height: "35px",
              width: "38px",
            }}
          >
            <ArrowBackIosIcon
              className="arrow-child"
              onClick={handleCancelClick}
            />
          </div>
          <div className="newcustomer-head ml-2">
            <h5 className="ml-1 mb-0" style={{ color: "#086bd5de" }}>
              Update Category
            </h5>
          </div>
        </div>
      </div>

      <Container fluid>
        <Card className="card-style p-4">
          <Row>
            <Col md="3">
              <Form.Group>
                <label className="text-primary" style={{ fontWeight: "600" }}>
                  Category Details
                </label>
              </Form.Group>
            </Col>
          </Row>

          <Form onSubmit={handleUpdateCategory}>
            <Row>
              {/* From Name */}
              <Col md="6">
                <Form.Group>
                  <label style={{ color: "grey" }}>Category Name</label>
                  <Form.Control
                    placeholder="Enter Category Name"
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                    style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                  />
                </Form.Group>
              </Col>

              {/* Tracking Number */}
              <Col md="6">
                <Form.Group>
                  <label style={{ color: "grey" }}>Category Description</label>
                  <Form.Control
                    placeholder="Enter Category Description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <hr className="mt-4" />

            <div className="text-right invoice-bottombtns">
              <Button
                variant="secondary"
                className="mt-0 mr-2"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Update Category
              </Button>
            </div>
          </Form>
        </Card>
      </Container>
    </div>
  );
};

export default UpdateCategory;
