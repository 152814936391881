import React, { useState, useEffect } from "react";
import Select from "react-select";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import { Card, Form, Container, Row, Col } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DatePicker from "react-datepicker";
import Alert from "@mui/material/Alert";

function ExpensesNew({ navigation }) {
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      handleNavigation("expenses");
    } else {
    }
  };

  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const expenseAccount = [
    { value: "Cost of Goods Sold", label: "Cost of Goods Sold" },
    { value: "Job Costing", label: "Job Costing" },
    { value: "Labor", label: "Labor" },
    { value: "Materials", label: "Materials" },
    { value: "Subcontractor", label: "Subcontractor" },
    { value: "Addance Tax", label: "Addance Tax" },
    { value: "Employee Advance", label: "Employee Advance" },
    { value: "Input Tax Credit", label: "Input Tax Credit" },
    { value: "Input CSGT", label: "- Input CSGT" },
    { value: "Input IGST", label: "- Input IGST" },
    { value: "Input SGST", label: "- Input SGST" },
    { value: "Prepaid Expenses", label: "Prepaid Expenses" },
    {
      value: "Reverse charge tax input but not due",
      label: "Reverse charge tax input but not due",
    },
    { value: "TDS receivable", label: "TDS receivable" },
    { value: "Furniture and quipment", label: "Furniture and quipment" },
    { value: "Employee Reimbursement", label: "Employee Reimbursement" },
    { value: "GST payable", label: "GST payable" },
    { value: "Output CGST", label: "- Output CGST" },
    { value: "Output IGST", label: "- Output IGST" },
    { value: "Output SGST", label: "- Output SGST" },
    { value: "Opening Balance Adjusment", label: "GOpening Balance Adjusment" },
    { value: "Tax payable", label: "Tax payable" },
    { value: "TDS payable", label: "TDS payable" },
    { value: "Unearned revenue", label: "Unearned revenue" },
    { value: "Advertising and marketing", label: "Advertising and marketing" },
    { value: "Automobile expense", label: "Automobile expense" },
    { value: "Bad debt", label: "Bad debt" },
    { value: "Bank fees and charges", label: "Bank fees and charges" },
    { value: "Consultant Expense", label: "Consultant Expense" },
    { value: "Contract assets", label: "Contract assets" },
    { value: "Credit card charges", label: "Credit card charges" },
    {
      value: "Depreciation and amortisation",
      label: "Depreciation and amortisation",
    },
    { value: "Depreciation expense", label: "Depreciation expense" },
    { value: "IT and Internet expenses", label: "IT and Internet expenses" },
    { value: "Janitorial expense", label: "Janitorial expense" },
    { value: "Lodging", label: "Lodging" },
    { value: "Meals and entertainment", label: "Meals and entertainment" },
    { value: "Merchandise", label: "Merchandise" },
    { value: "Office Supplies", label: "Office Supplies" },
    { value: "Other expenses", label: "Other expenses" },
    { value: "Postage", label: "Postage" },
    { value: "Printing Stationery", label: "Printing Stationery" },
    {
      value: "Raw materials and consumables",
      label: "Raw materials and consumables",
    },
    { value: "Rent expense", label: "Rent expense" },
    { value: "Repairs and maintainance", label: "Repairs and maintainance" },
    {
      value: "Salareis and employee wages",
      label: "Salareis and employee wages",
    },
    { value: "Telephone expense", label: "Telephone expense" },
    { value: "Transportation expense", label: "Transportation expense" },
    { value: "Travel expense", label: "Travel expense" },
    { value: "Uncategorized", label: "Uncategorized" },
  ];

  const paidThrough = [
    { value: "Bank account", label: "Bank account" },
    { value: "Invoice", label: "Invoice" },
    { value: "Petty Cash", label: "Petty Cash" },
    { value: "Undeposited funds", label: "Undeposited funds" },
    { value: "Advance Tax", label: "Advance Tax" },
    { value: "Employee Advance", label: "Employee Advance" },
    { value: "Prepaid Expenses", label: "Prepaid Expenses" },
    { value: "TDS Receivables", label: "TDS Receivables" },
    { value: "Employee reimbursements", label: "Employee reimbursements" },
    {
      value: "Opening balance adjustment",
      label: "Opening balance adjustment",
    },
    { value: "TDS payable", label: "TDS payable" },
  ];

  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().substring(0, 10)
  );

  const [custNames, setCustNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(""); // State to store the selected customer's ID
  const [vendorNames, setVendorNames] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [selectedExpenseAccount, setSelectedExpenseAccount] = useState("");
  const [selectedPaidThrough, setSelectedPaidThrough] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const [dateValue, setDateValue] = useState("");

  useEffect(() => {
    setStartDate(new Date());
  }, []);

  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return date;
  };
  const handleDate = (date) => {
    setStartDate(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue(normalFormat);
  };

  const [serverData, setServerData] = useState({
    uniqueId: decryptedUniqueId,
    date: "",
    expenseAccount: selectedExpenseAccount, // Include selected expense account
    amount: "",
    paidThrough: "", // Initialize to an empty string
    vendor: "",
    invoice: "",
    notes: "",
    customerName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServerData({
      ...serverData,
      [name]: value,
    });
  };

  const handleExpenseAccountChange = (selectedOption) => {
    setSelectedExpenseAccount(selectedOption);
  };

  const handlePaidThroughChange = (selectedOption) => {
    setSelectedPaidThrough(selectedOption);
  };

  const [validationMessage1, setValidationMessage1] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const handleSaveClick = () => {
    const dataToSend = {
      date: dateValue || normalDateFormat(startDate),
      uniqueId: decryptedUniqueId,
      expenseAccount: selectedExpenseAccount.value,
      amount: serverData.amount,
      paidThrough: selectedPaidThrough.value,
      vendor: selectedVendor,
      invoice: serverData.invoice,
      notes: serverData.notes,
      customerName: selectedCustomer,
      status: "Sent",
      image: image,
    };
    let newValidationMessage = "";
    if (!dataToSend.date) {
      newValidationMessage += "Please insert date \n";
    } else if (!dataToSend.expenseAccount) {
      newValidationMessage += "Please select the expense account\n";
    } else if (!dataToSend.amount) {
      newValidationMessage += "Please insert the amount \n";
    } else if (!dataToSend.paidThrough) {
      newValidationMessage += "Please select paid through\n";
    } else if (!dataToSend.invoice) {
      newValidationMessage += "Please insert the invoice\n";
    }
    setValidationMessage1(newValidationMessage);

    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    setIsLoading(true);

    if (image) {
      fetch(image)
        .then((res) => res.blob())
        .then((blob) => {
          const formData = new FormData();
          formData.append("image", blob);
          Object.keys(dataToSend).forEach((key) => {
            formData.append(key, dataToSend[key]);
          });

          const apiUrl1 = BASE_URL + "/insertExpense";
          const requestOptions1 = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
            body: formData,
          };

          fetch(apiUrl1, requestOptions1)
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else {
                throw new Error("Response was non-200");
              }
            })
            .then((data) => {
              if (data.status === "success") {
                handleNavigation("expenses");
                swal({
                  icon: "success",
                  title: "Expense Created",
                });
                setIsLoading(false);
              } else {
                swal({
                  icon: "error",
                  title: "Failed to Create Expense!",
                });
                setIsLoading(false);
              }
            })
            .catch((error) => {
              swal({
                icon: "error",
                title: "Error during API call",
              });
              setIsLoading(false);
            });
        })
        .catch((error) => {
          console.error("Error converting image to blob:", error);
        });
    } else {
      const formData = new FormData();
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });
      const apiUrl1 = BASE_URL + "/insertExpense";
      const requestOptions1 = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      };

      fetch(apiUrl1, requestOptions1)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Response was non-200");
          }
        })
        .then((data) => {
          const id = data.responseMessage;

          if (data.status === "success") {
            handleCancleClick();
            swal({
              icon: "success",
              title: "Expense Created",
            });
            setIsLoading(false);
          } else {
            swal({
              icon: "error",
              title: "Failed to Create Expense!",
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          swal({
            icon: "error",
            title: "Error during API call",
          });
          setIsLoading(false);
        });
    }
  };

  const handleSaveClickAsDraft = () => {
    const dataToSend = {
      date: dateValue || normalDateFormat(startDate),
      uniqueId: decryptedUniqueId,
      expenseAccount: selectedExpenseAccount.value,
      amount: serverData.amount,
      paidThrough: selectedPaidThrough.value,
      vendor: selectedVendor,
      invoice: serverData.invoice,
      notes: serverData.notes,
      customerName: selectedCustomer,
      status: "Draft",
      image: image,
    };
    let newValidationMessage = "";
    if (!dataToSend.date) {
      newValidationMessage += "Please insert date \n";
    } else if (!dataToSend.expenseAccount) {
      newValidationMessage += "Please select the expense account\n";
    } else if (!dataToSend.amount) {
      newValidationMessage += "Please insert the amount \n";
    } else if (!dataToSend.paidThrough) {
      newValidationMessage += "Please select paid through\n";
    } else if (!dataToSend.invoice) {
      newValidationMessage += "Please insert the invoice\n";
    }
    setValidationMessage1(newValidationMessage);

    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    setIsLoading2(true);
    if (image) {
      fetch(image)
        .then((res) => res.blob())
        .then((blob) => {
          const formData = new FormData();
          formData.append("image", blob);
          Object.keys(dataToSend).forEach((key) => {
            formData.append(key, dataToSend[key]);
          });

          const apiUrl1 = BASE_URL + "/insertExpense";
          const requestOptions1 = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
            body: formData,
          };

          fetch(apiUrl1, requestOptions1)
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else {
                throw new Error("Response was non-200");
              }
            })
            .then((data) => {
              if (data.status === "success") {
                handleNavigation("expenses");
                swal({
                  icon: "success",
                  title: "Expense Created",
                });
                setIsLoading2(false);
              } else {
                swal({
                  icon: "error",
                  title: "Failed to Create Expense",
                });
                setIsLoading2(false);
              }
            })
            .catch((error) => {
              swal({
                icon: "error",
                title: "Error during API call",
              });
              setIsLoading2(false);
            });
        })
        .catch((error) => {
          console.error("Error converting image to blob:", error);
        });
    } else {
      const formData = new FormData();
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });
      // Proceed with your fetch request without the image
      const apiUrl1 = BASE_URL + "/insertExpense";
      const requestOptions1 = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      };

      fetch(apiUrl1, requestOptions1)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Response was non-200");
          }
        })
        .then((data) => {
          const id = data.responseMessage;

          if (data.status === "success") {
            handleCancleClick();
            swal({
              icon: "success",
              title: "Expense Created",
            });
            setIsLoading2(false);
          } else {
            swal({
              icon: "error",
              title: "Failed to Create Expense!",
            });
            setIsLoading2(false);
          }
        })
        .catch((error) => {
          swal({
            icon: "error",
            title: "Error during API call",
          });
          setIsLoading2(false);
        });
    }
  };

  // Fetch customer data
  useEffect(() => {
    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  // Handle customer selection
  const handleCustomerChange = (event) => {
    const selectedValue = event.value;
    setSelectedCustomer(selectedValue);

    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );

    if (selectedCustomerObject) {
      setSelectedCustomerId(selectedCustomerObject.id);
    }
  };

  useEffect(() => {
    fetch(BASE_URL + `/fetchVendors`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setVendorNames(data.responseMessage);
        } else {
          console.error("Failed to fetch vendor names:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching vendor names:", error);
      });
  }, []);

  const handleVendorChange = (event) => {
    const selectedValue = event.value;
    setSelectedVendor(selectedValue); // Store the selected vendor's display name

    const selectedVendorObject = vendorNames.find(
      (vendor) => vendor.vendorDisplayName === selectedValue
    );

    if (selectedVendorObject) {
      setSelectedVendorId(selectedVendorObject.id);
    }
  };

  const options = custNames.map((customer) => ({
    value: customer.customerName,
    label: customer.customerName,
  }));

  const vendorOptions = vendorNames.map((names) => ({
    label: names.vendorDisplayName,
    value: names.vendorDisplayName,
  }));

  const [image, setImage] = useState(null);

  const handleDrop1 = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleImage(file);
  };

  const handleImageInputChange = (e) => {
    const file = e.target.files[0];
    handleImage(file);
  };

  const handleImage = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
  };

  return (
    <>
      <Container fluid className="">
        <Row>
          <Col>
            <div className="my-3 mt-4">
              <div className="px-2 d-flex">
                <div
                  className="cursor-pointer d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    height: "35px",
                    width: "38px",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIosIcon
                    width={2}
                    onClick={handleCancleClick}
                    className="arrow-child"
                  />
                </div>
                <h5
                  className="mt-1 mx-2 text-primary"
                  style={{ letterSpacing: "1px" }}
                >
                  Add New Expense
                </h5>
              </div>
            </div>
            {validationMessage1 && (
              <Alert severity="error" className="mt-0">
                {validationMessage1.split("\n").map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </Alert>
            )}{" "}
            <Card className="card-style">
              <Card.Body>
                <Row>
                  <Col md="2" className="mt-2">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span style={{ color: "red" }}>Date</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <div className="customDatePickerWidth">
                        <DatePicker
                          className="form-control"
                          selected={startDate}
                          dateFormat="dd-MM-yyyy"
                          onChange={(date) => handleDate(date)}
                          showIcon
                          toggleCalendarOnIconClick
                          style={{
                            width: "80px",
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="2" className="">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span style={{ color: "red" }}>Expense Account</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <Select
                        options={expenseAccount}
                        value={selectedExpenseAccount}
                        onChange={handleExpenseAccountChange}
                        placeholder="Select..."
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="2" className="">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span style={{ color: "red" }}>Amount</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <div>
                        <div className="wrapper" style={{ borderRight: "5px" }}>
                          <Form.Control
                            className=""
                            placeholder="Amount"
                            value={serverData.amount}
                            onChange={handleInputChange}
                            name="amount"
                            type="text"
                            style={{
                              backgroundColor: "#F6F6F6",
                              padding: "12px",
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="2" className="">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span style={{ color: "red" }}>Paid Through</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <Select
                        options={paidThrough}
                        onChange={handlePaidThroughChange}
                        value={selectedPaidThrough}
                        placeholder="Select..."
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="2" className="mt-2">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span>Vendor</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <Select
                        value={vendorOptions.find(
                          (option) => option.value === selectedVendor
                        )}
                        onChange={handleVendorChange}
                        options={vendorOptions}
                        placeholder="Select a Vendor"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="2" className="mt-2">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span style={{ color: "red" }}>Invoice</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <Form.Control
                        className=""
                        placeholder="Invoice"
                        name="invoice"
                        type="text"
                        value={serverData.invoice}
                        onChange={handleInputChange}
                        style={{
                          backgroundColor: "#F6F6F6",
                          padding: "12px",
                          borderRadius: "5px",
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="2" className="mt-2">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span>Notes</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <Form.Control
                        className=""
                        placeholder="Notes..."
                        name="notes"
                        value={serverData.notes}
                        onChange={handleInputChange}
                        type="text"
                        style={{
                          backgroundColor: "#F6F6F6",
                          padding: "12px",
                          borderRadius: "5px",
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="2" className="mt-2">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span>Customer Name</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <Select
                        className=""
                        options={options}
                        value={options.find(
                          (option) => option.value === selectedCustomer
                        )}
                        onChange={handleCustomerChange}
                        placeholder="Select Customer"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "6px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="2" className="mt-2">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span>Attach Receipt</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <Col md="3">
                        <div
                          onDrop={handleDrop1}
                          onDragOver={(e) => e.preventDefault()}
                          style={{
                            border: "2px dashed #ccc",
                            borderRadius: "5px",
                            padding: "10px",
                            textAlign: "center",
                            width: "200px",
                            height: "200px",
                            position: "relative",
                            marginRight: "20px",
                          }}
                        >
                          {image ? (
                            <>
                              <img
                                src={image}
                                alt="Uploaded"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  borderRadius: "5px",
                                }}
                              />
                              <button
                                onClick={handleRemoveImage}
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                  padding: "5px 10px",
                                  backgroundColor: "red",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                Remove
                              </button>
                            </>
                          ) : (
                            <>
                              <p>Drag & Drop or Click to Browse Image</p>
                              <input
                                type="file"
                                onChange={handleImageInputChange}
                                accept="image/*"
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                  opacity: "0",
                                  cursor: "pointer",
                                }}
                              />
                            </>
                          )}
                        </div>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-right">
                  <button className="btn-1 mt-0" onClick={handleCancleClick}>
                    Cancel{" "}
                  </button>
                  <button
                    className="btn-1 mt-0"
                    onClick={handleSaveClickAsDraft}
                  >
                    {isLoading2 ? "Saving..." : "Save as Draft"}
                  </button>
                  <button
                    className="btn-3 btn-primary "
                    onClick={handleSaveClick}
                  >
                    {isLoading ? "Saving..." : "Create Expenses"}
                  </button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ExpensesNew;
