import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Table,
  Container,
} from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NoDataImage from "../../../asset/images/nodata.jpg";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import UpdateOutWard from "./UpdateOutWard";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import $ from "jquery";

function OutWard({ navigation }) {
  const [outwardData, setOutwardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [showCard, setShowCard] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [outwardNum, setOutwardNum] = useState();

  const jwtToken = localStorage.getItem("jwtToken");

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const fetchData = () => {
    $("#DataTables_Table_0").DataTable();
    setLoading(true);
    fetch(`${BASE_URL}/getOutwardByUniqueId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Outward Data:", data);
        setOutwardData(data.data);
        console.log("Outward Data:", outwardData);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching outward data:", error);
        $("#DataTables_Table_0").DataTable();
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = (trackingNo) => {
    console.log("Outward Number:", trackingNo);
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this item!"
    );

    setLoading(true);
    if (userConfirmed) {
      fetch(`${BASE_URL}/deleteOutward`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          trackingNo: trackingNo,
          uniqueId: decryptedUniqueId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.message === "Outward entry deleted successfully") {
            setLoading(false);
            swal({
              icon: "success",
              title: "Outward Deleted Successfully",
            });
            fetchData();
          } else {
            setLoading(false);
            swal({
              icon: "error",
              title: "Failed to Delete Outward",
              text: data.message || "An error occurred",
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
          swal({
            icon: "error",
            title: "An Error Occurred While Deleting Outward",
          });
        });
    }
  };

  const handleUpdate = (outwardNum) => {
    setOutwardNum(outwardNum);
    setShowUpdate(true);
    setShowCard(false);
  };

  const handleCancelUpdate = () => {
    setShowCard(true);
    setShowUpdate(false);
    fetchData();
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      {!showUpdate && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Outward
            </div>
            <div className="button-container d-flex">
              <Button
                className="btn btn-primary px-1 py-2 me-2 mr-1"
                onClick={() => navigation.navigate("NewOutWard")}
              >
                + New Outward
              </Button>
            </div>
          </div>
        </div>
      )}

      {showCard && (
        <div
          className="container-fluid"
          style={{ border: "none", borderRadius: "7px" }}
        >
          <div className="page-content ">
            <Container fluid>
              {/* <Card className="card-style p-3"> */}
              <Row>
                <Col>
                  <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    {outwardData && outwardData.length > 0 ? (
                      <div className="body">
                        <div
                          className="table-responsive1 dataTables_wrapper"
                          id="DataTables_Table_0_wrapper"
                        >
                          <Table
                            className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                            id="DataTables_Table_0"
                          >
                            <thead className="heading-text">
                              <tr role="row">
                                <th
                                  className="sorting_asc"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-sort="ascending"
                                  // aria-label="Date: activate to sort column descending"
                                >
                                  #
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  // aria-label="Type: activate to sort column ascending"
                                >
                                  Tracking No
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  // aria-label="Type: activate to sort column ascending"
                                >
                                  Invoice/Document No
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Packaged By
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Status
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Place
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Date
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text1">
                              {outwardData.map((item, index) => (
                                <tr role="row" className="odd" key={item.id}>
                                  <td>{index + 1}</td>
                                  <td className="sorting_1">
                                    {item.trackingNo || "N/A"}
                                  </td>
                                  <td>{item.invoiceNumber || "N/A"}</td>
                                  <td>{item.toName || "N/A"}</td>
                                  <td>{item.packedBy || "N/A"}</td>
                                  <td>{item.status || "N/A"}</td>
                                  <td>{item.placeLocation || "N/A"}</td>
                                  <td>{item.date}</td>
                                  <td>
                                    <button
                                      variant="warning"
                                      size="sm"
                                      className="btn icon-border btn-sm mx-1"
                                      onClick={() =>
                                        handleUpdate(item.trackingNo)
                                      }
                                    >
                                      <i className="fa fa-edit"></i> 
                                    </button>
                                    <button
                                      variant="danger"
                                      size="sm"
                                      className="btn icon-border btn-sm mx-1"
                                      onClick={() =>
                                        handleDelete(item.trackingNo)
                                      }
                                    >
                                      <i className="fa fa-trash"></i> 
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    ) : (
                      <div className="body">
                      <div className="table-responsive1">
                        <div
                          id="DataTables_Table_0_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 no-footer"
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                id="DataTables_Table_0"
                                role="grid"
                                aria-describedby="DataTables_Table_0_info"
                              >
                                <thead className="heading-text">
                                <tr role="row">
                                <th
                                  className="sorting_asc"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-sort="ascending"
                                  // aria-label="Date: activate to sort column descending"
                                >
                                  #
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  // aria-label="Type: activate to sort column ascending"
                                >
                                  Tracking No
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  // aria-label="Type: activate to sort column ascending"
                                >
                                  Invoice/Document No
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Packaged By
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Status
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Place
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Date
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Actions
                                </th>
                              </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                              <h6 className="text-center">
                                <img
                                  src={NoDataImage}
                                  style={{ width: "50%", height: "50%" }}
                                  alt="No Data Logo"
                                />{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    )}
                  </Card>
                </Col>
              </Row>
              {/* </Card> */}
            </Container>
          </div>
        </div>
      )}

      {showUpdate && (
        <UpdateOutWard
          handleCancel={handleCancelUpdate}
          trackingNo={outwardNum}
        />
      )}
    </div>
  );
}

export default OutWard;
