import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import { Description, InsertLink, Lock, Person, Visibility } from "@mui/icons-material";

const UpdatePassword = ({ navigation, handleCancel, passwordId }) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    uniqueId: decryptedUniqueId,
    link: "",
    password: "",
    userId: "",
    description: "",
    makeVisible: "",
  });

  useEffect(() => {
    // Fetch password data by ID
    if (passwordId) {
      fetch(`${BASE_URL}/findPassManagerById`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          id: passwordId,
          uniqueId: decryptedUniqueId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Password retrieved successfully" && data.data) {
            const passwordData = data.data;
            setFormData({
              ...formData,
              link: passwordData.link || "",
              password: passwordData.password || "",
              userId: passwordData.userId || "",
              description: passwordData.description || "",
              makeVisible: passwordData.makeVisible || "",
            });
          } else {
            console.error("Error fetching password data");
            swal({
              icon: "error",
              title: "Failed to fetch password details",
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
          swal({
            icon: "error",
            title: "An Error Occurred While Fetching Password Details",
          });
        });
    } else {
      setLoading(false);
    }
  }, [passwordId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRadioChange = (e) => {
    setFormData({
      ...formData,
      makeVisible: e.target.value,
    });
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm(
      "Are you sure you want to leave this page?"
    );
    if (confirmCancel) {
      handleCancel();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    const requestBody = {
      id: passwordId,
      uniqueId: formData.uniqueId,
      link: formData.link,
      description: formData.description,
      makeVisible: formData.makeVisible,
      password: formData.password,
      userId: formData.userId,
    };

    setLoading(true);

    fetch(`${BASE_URL}/updatePassManager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.message === "Password updated successfully") {
          swal({
            icon: "success",
            title: "Password Updated Successfully",
          }).then(() => {
            handleCancel();
          });
        } else {
          swal({
            icon: "error",
            title: "Failed to Update Password",
            text: data.message || "An error occurred",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occurred While Updating Password",
        });
      });
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col>
          <div className="my-3">
            <div className="d-flex align-items-center ml-1 mt-4">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                }}
              >
                <ArrowBackIosIcon
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5 className="ml-1 mb-0" style={{ color: "#086bd5de" }}>
                  Update Password
                </h5>
              </div>
            </div>
          </div>

          <Card className="card-style p-4">
            <Row>
              <Col md="3">
                <Form.Group>
                  <label className="text-primary" style={{ fontWeight: "600" }}>
                    Password Details
                  </label>
                </Form.Group>
              </Col>
            </Row>

            <Form>
              <Row>
                <Col md="6">
                  <Form.Group>
                 
                    <label style={{ color: "grey" }}>Link</label>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <InsertLink sx={{fill:"#9A9A9A"}}/>
                    <Form.Control
                      placeholder="Enter Link"
                      name="link"
                      value={formData.link}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>User Name/Id</label>
                    <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
                    <Person sx={{fill:"#9A9A9A"}}/>
                    <Form.Control
                      placeholder="Enter User Name/Id"
                      name="userId"
                      value={formData.userId}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                    </div>
                  </Form.Group>
                </Col>

                
              </Row>

              <Row>
                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Description</label>
                    <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
                    <Description sx={{fill:"#9A9A9A"}}/>
                    
                    <Form.Control
                      name="description"
                      placeholder="Enter Description"
                      value={formData.description}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                    </div>
                  </Form.Group>
                  
                </Col>
                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Password</label>
                    <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
                    <Lock sx={{fill:"#9A9A9A"}}/>
                    <Form.Control
                      placeholder="Enter Password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                    </div>
                  </Form.Group>
                </Col>
                </Row>
                <Row>
                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Visibility{" "}<span><Visibility sx={{fill:"#9A9A9A"}}/></span></label>
                    <div style={{display:"flex",alignItems:"center",gap:"40px"}} className="py-3 px-3">
                      <Form.Check
                        type="radio"
                        label="Admin"
                        className="mx-3 cursor-pointer"
                        name="makeVisible"
                        value="Admin"
                        checked={formData.makeVisible === "Admin"}
                        onChange={handleRadioChange}
                        inline
                      />
                      <Form.Check
                        type="radio"
                        label="General"
                        className="mx-3 cursor-pointer"
                        name="makeVisible"
                        value="General"
                        checked={formData.makeVisible === "General"}
                        onChange={handleRadioChange}
                        inline
                      />
                    </div>
                  </Form.Group>
                </Col>
                </Row>
              
            </Form>

            <hr className="mt-4" />

            <div className="text-right invoice-bottombtns">
              <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
                Cancel
              </button>
              <button
                className="btn-3 btn btn-primary"
                onClick={handleUpdatePassword}
              >
                Update Password
              </button>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UpdatePassword;