import React from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import swal from "sweetalert";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";

const AddNonInventory = ({ navigation }) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [loading, setLoading] = useState(false);


  const [formData, setFormData] = useState({
    uniqueId: decryptedUniqueId || "",
    type:"nonInventory",
    name: "",
    description: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleCancel = () => {
    handleNavigation("NonInventory");
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleCancel();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const handleCreateNonInventory = (e) => {
    e.preventDefault();
    const requestBody = {
      uniqueId: formData.uniqueId,
      type:formData.type,
      name: formData.name,
      description: formData.description,
    };
    console.log("Request Body: ", requestBody);
    setLoading(true);
    fetch(`${BASE_URL}/insertInformation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Non Inventory Created Successfully",
          });
        } else {
          swal({
            icon: "error",
            title: "Failed to Create Non Inventory ",
          });
        }
        handleCancel();
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occurred While Creating Non Inventory ",
        });
      });
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col>
          <div className="my-3">
            <div className="d-flex align-items-center ml-1 mt-4">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                }}
              >
                <ArrowBackIosIcon
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5 className="ml-1 mb-0" style={{ color: "#086bd5de" }}>
                  Add Non - Inventory
                </h5>
              </div>
            </div>
          </div>

          <Card className="card-style p-4">
            <Row>
              <Col md="3">
                <Form.Group>
                  <label className="text-primary" style={{ fontWeight: "600" }}>
                  Non - Inventory Details
                  </label>
                </Form.Group>
              </Col>
            </Row>

            <Form>
              <Row>
                {/* From Name */}
                <Col md="4">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Name</label>
                    <Form.Control
                      placeholder="Enter Name"
                      name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>

                {/* Transport or Courier */}
                <Col md="8">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Description</label>
                    <Form.Control
                      placeholder="Enter Description"
                      name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>

            <hr className="mt-4" />

            <div className="text-right invoice-bottombtns">
              <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
                Cancel
              </button>
              {/* <button className="btn-1 btn mt-0">Save as Draft</button> */}
              <button
                className="btn-3 btn btn-primary"
                onClick={handleCreateNonInventory}
              >
                Create Non Inventory
              </button>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddNonInventory;
