import React from "react";
import Header from "../../../Components/Common/Header";
import RazorpayCard from "../../../Components/Apps/RazorpayCard";

class Razorpay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompose: false,
      show: false,
    };
  }

  handleNewButtonClick = () => {
    this.setState({ show: true });
  };

  handleCancelNew = () => {
    this.setState({ show: false });
  };

  render() {
    return (
      <div className="container-fluid">
        {/* {!this.state.show && <Header headerText="Razorpay " mainNavigate="Razorpay Integration" currentPage="" childNav={this.state.isCompose ? "Compose" : ""} />} */}
        <div className="row clearfix ">
          {!this.state.show && (
            <div className="container mx-2 mb-2">
              <div className="d-flex justify-content-between">
                <div>
                  <h4>RAZORPAY INTEGRATION</h4>
                </div>
                <hr />

                <div className="d-flex">
                  {/* <form id="navbar-search" className="navbar-form search-form">
                  <input className="form-control" placeholder="Search here..." type="text" />
                  <button type="button" className="btn btn-default"><i className="icon-magnifier"></i></button>
                </form> */}
                  {/* <div className="">
                  {!this.state.show && <button className="btn btn-secondary" onClick={this.handleNewButtonClick}>+ NEW</button>}
                </div> */}
                </div>
              </div>
            </div>
          )}
          {/* {/ {this.state.show ? <CreditNoteNew handleCancle={this.handleCancelNew} /> : <CreditNotesCard />} /} */}
          <RazorpayCard />
        </div>
        <div className="col-xl-9 col-lg-7 col-md-12">
          <div className="card">
            <div className="card-body">
              <h6 className="card-title pb-2">
                Steps to Add Razorpay Payment Integration
              </h6>
              <div
                className="accordion accordion-color  panel-group1"
                id="accordion2"
                role="tablist"
              >
                <ol style={{ fontweight: "bold", size: "16px" }}>
                  <li>
                    Register on RazorPay if you do not have account{" "}
                    <a
                      href="https://easy.razorpay.com/onboarding/l1/signup?field=MobileNumber"
                      style={{ color: "blue" }}
                    >
                      click here{" "}
                    </a>
                  </li>
                  <li>
                    Then Sign In to your RazorPay Account{" "}
                    <a
                      href="https://dashboard.razorpay.com/signin?screen=sign_in"
                      style={{ color: "blue" }}
                    >
                      click here{" "}
                    </a>
                  </li>
                  <li>
                    Go to Dashboard and Click on Settings{" "}
                    <a
                      href="https://dashboard.razorpay.com/?screen=sign_in&next=app%2Fconfig"
                      style={{ color: "blue" }}
                    >
                      click here{" "}
                    </a>
                  </li>

                  <li>
                    Select API Keys tab{" "}
                    <a
                      href="https://dashboard.razorpay.com/?screen=sign_in&next=app%2Fkeys"
                      style={{ color: "blue" }}
                    >
                      click here{" "}
                    </a>
                  </li>

                  <li>
                    Click on Generate New Key, Copy Key Id &amp; Key Secret from
                    there
                  </li>
                  <li>
                    Paste Key Id &amp; Key Secret in Above Fields and Click on
                    Save
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Razorpay;
