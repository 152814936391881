import React, { useState } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";

function NewOutWard({ navigation }) {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());

  const [formData, setFormData] = useState({
    trackingNo: "",
    uniqueId: decryptedUniqueId || "",
    toName: "",
    transportOrCourier: "",
    materialDescription: "",
    packedBy: "",
    checkedBy: "",
    placeLocation: "",
    invoiceNumber: "",
    date: new Date().toISOString().split("T")[0],
    time: new Date().toLocaleTimeString(),
    status: "",
  });

  const [files, setFiles] = useState([]);
  const [descriptions, setDescriptions] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCreateOutward = async (e) => {
    e.preventDefault();

    console.log("formData", formData);
    const requestBody = {
      trackingNo: formData.trackingNo,
      uniqueId: formData.uniqueId,
      toName: formData.toName,
      transportOrCourier: formData.transportOrCourier,
      materialDescription: formData.materialDescription,
      packedBy: formData.packedBy,
      checkedBy: formData.checkedBy,
      placeLocation: formData.placeLocation,
      invoiceNumber: formData.invoiceNumber,
      date: formData.date,
      time: formData.time,
      status: formData.status,
    };

    // Create FormData object
    const formDataToSend = new FormData();
    formDataToSend.append("request", JSON.stringify(requestBody));

    setLoading(true);

    fetch(`${BASE_URL}/createOutward`, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: formDataToSend,
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.message === "Outward entry created successfully") {
          swal({
            icon: "success",
            title: "Outward Created Successfully",
          });
        } else {
          swal({
            icon: "error",
            title: "Failed to Create Outward",
          });
        }
        handleCancel();
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occurred While Creating Outward",
        });
      });
  };
  const handleCancel = () => {
    navigation.navigate("OutWard");
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      handleCancel();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col>
          <div className="my-3">
            <div className="d-flex align-items-center ml-1 mt-4">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                }}
              >
                <ArrowBackIosIcon
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5 className="ml-1 mb-0" style={{ color: "#086bd5de" }}>
                  New OutWard
                </h5>
              </div>
            </div>
          </div>

          <Card className="card-style p-4">
            <Row>
              <Col md="3">
                <Form.Group>
                  <label className="text-primary" style={{ fontWeight: "600" }}>
                    OUTWARD DETAILS
                  </label>
                </Form.Group>
              </Col>
            </Row>

            <Form>
              <Row>
                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>To Name</label>
                    <Form.Control
                      name="toName"
                      placeholder="Enter Name"
                      value={formData.toName}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>

                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>
                      Transport or Courier
                    </label>
                    <Form.Control
                      name="transportOrCourier"
                      placeholder="Enter Transport/Courier"
                      value={formData.transportOrCourier}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>

                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Tracking Number</label>
                    <Form.Control
                      name="trackingNo"
                      placeholder="Enter Tracking Number"
                      value={formData.trackingNo}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>
                      Material Description
                    </label>
                    <Form.Control
                      name="materialDescription"
                      placeholder="Enter Material Description"
                      value={formData.materialDescription}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>

                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Packed by</label>
                    <Form.Control
                      name="packedBy"
                      placeholder="Enter Packager's Name"
                      value={formData.packedBy}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>

                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Checked by</label>
                    <Form.Control
                      name="checkedBy"
                      placeholder="Enter Checker's Name"
                      value={formData.checkedBy}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Place / Location</label>
                    <Form.Control
                      name="placeLocation"
                      placeholder="Enter Location"
                      value={formData.placeLocation}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>

                <Col md="3">
                  <Form.Group className="d-flex flex-column new-campaign-time w-100">
                    <label style={{ color: "grey" }}>Date</label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date);
                        setFormData({
                          ...formData,
                          date: date.toISOString().split("T")[0],
                        });
                      }}
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select date"
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group className="d-flex flex-column new-campaign-time w-100">
                    <label style={{ color: "grey" }}>Time</label>
                    <DatePicker
                      selected={selectedTime}
                      onChange={(time) => {
                        setSelectedTime(time);
                        setFormData({
                          ...formData,
                          time: time.toLocaleTimeString(),
                        });
                      }}
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="Select time"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Status</label>
                    <Form.Control
                      as="select"
                      className="form-control"
                      name="status"
                      value={formData.status}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Status</option>
                      <option value="Dispatch">Dispatch</option>
                      <option value="Transit">Transit</option>
                      <option value="Deliver">Deliver</option>
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>
                      Invoice / Document Number
                    </label>
                    <Form.Control
                      name="invoiceNumber"
                      placeholder="Enter Invoice / Document Number"
                      value={formData.invoiceNumber}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <hr className="mt-4" />

              <div className="text-right invoice-bottombtns">
                <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
                  Cancel
                </button>
                {/* <button className="btn-1 btn mt-0">Save as Draft</button> */}
                <button
                  className="btn-3 btn btn-primary"
                  onClick={handleCreateOutward}
                >
                  Create OutWard
                </button>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default NewOutWard;
