import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import "../../../asset/css/inbox.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import NoDataImage from "../../../asset/images/nodata.jpg";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import VendorsUpdate from "./VendorsUpdate";
import VendorDetails from "../SalesNew/VendorDetails";

function Vendors({ navigation }) {
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleVendorClick = () => {
    handleNavigation("VendorsNew");
  };

  const [itemId, setItemID] = useState();
  const [loading, setLoading] = useState(true);
  const [showCard, setShowCard] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const handleEdit = (id) => {
    setItemID(id);
    setShowCard(false);
    setShowUpdate(true);
  };

  const handleCancelUpdate = () => {
    fetchVendorDataFromApi();
    setShowUpdate(false);
    setShowCard(true);
  };

  const handleCancelDetails = () => {
    setShowDetails(false);
    setShowCard(true);
    fetchVendorDataFromApi();
    setShowUpdate(false);
    setVendortable(false);
  };

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this vendor!"
    );

    if (userConfirmed) {
      fetch(BASE_URL + `/deleteVendor`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ id }),
      })
        .then((response) => {
          if (response.status === 200) {
            swal({
              icon: "success",
              title: "Vendor Deleted Successfully",
            });
            setLoading(true); // Set loading to true to indicate data fetching
            setVendorsData((prevVendors) =>
              prevVendors.filter((vendor) => vendor.id !== id)
            );
            fetchVendorDataFromApi();
          } else {
            console.error("Failed to delete Vendor");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error deleting Vendor:", error);
          setLoading(false);
        });
    } else {
      // If the user clicks "Cancel", show a cancellation message
      alert("Vendor deletion canceled!");
    }
  };

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const [postData, setPostData] = useState({
    uniqueId: decryptedUniqueId,
  });
  const [vendorData, setVendorsData] = useState([]);
  const jwtToken = localStorage.getItem("jwtToken");
  const [vendorTable, setVendortable] = useState(false);
  const [newVendorId, setNewVendorId] = useState([]);
  const [fetchedPaymentTerms, setFetchedPaymentTerms] = useState("");
  const fetchVendorDataFromApi = async () => {
    $("#DataTables_Table_0").DataTable();
    const apiUrl = BASE_URL + `/fetchVendors`;
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setVendorsData(data.responseMessage);
          setLoading(false);
        } else {
          setLoading(false);
        }
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      });
  };

  useEffect(() => {
    fetchVendorDataFromApi();
  }, []);

  const handleVendor = (id, terms) => {
    setNewVendorId(id);
    setFetchedPaymentTerms(terms);
    setShowCard(false);
    setVendortable(true);
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }
  let i = 1;
  return (
    <div className="container-fluid">
      {!showUpdate && !vendorTable && !showDetails && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Vendors
            </div>
            <div className="d-flex">
              <div className="">
                <button
                  className="btn btn-primary px-1 py-2 new-purchase"
                  onClick={handleVendorClick}
                >
                  + New Vendor
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showCard && (
        <div className="container-fluid">
          <div className="page-content ">
            <Container fluid={true} className="">
              <Row>
                <Col>
                  <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    {vendorData && vendorData.length > 0 ? (
                      <>
                        <div className="body">
                          <div className="table-responsive1">
                            <div
                              id="DataTables_Table_0_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                    id="DataTables_Table_0"
                                    role="grid"
                                    aria-describedby="DataTables_Table_0_info"
                                  >
                                    <thead className="heading-text">
                                      <tr role="row">
                                        <th
                                          className="sorting_asc"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Date: activate to sort column descending"
                                        >
                                          Serial no.
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                        >
                                          Name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Amount BTC: activate to sort column ascending"
                                        >
                                          Company name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="BTC Remaining: activate to sort column ascending"
                                        >
                                          Email
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Status
                                        </th>

                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Payment terms
                                        </th>

                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        ></th>
                                      </tr>
                                    </thead>
                                    <tbody className="text1">
                                      {vendorData.map((data) => (
                                        <tr
                                          role="row"
                                          className="odd"
                                          key={data.id}
                                        >
                                          <td>{i++}</td>
                                          <td className="sorting_1">
                                            {" "}
                                            <a
                                              href="#"
                                              onClick={() =>
                                                handleVendor(
                                                  data.id,
                                                  data.paymentTerms
                                                )
                                              }
                                            >
                                              {data.vendorDisplayName}
                                            </a>
                                          </td>
                                          <td>{data.companyName}</td>
                                          <td>{data.vendorEmail}</td>
                                          <td style={{ color: "green" }}>
                                            {data.status}
                                          </td>
                                          <td>{data.paymentTerms}</td>

                                          <td>
                                            <button
                                              className="btn icon-border btn-sm mr-2"
                                              onClick={() =>
                                                handleEdit(data.id)
                                              }
                                            >
                                              <i className="fa fa-edit  text1"></i>
                                            </button>
                                            <button
                                              className="btn icon-border btn-sm"
                                              onClick={() =>
                                                handleDelete(data.id)
                                              }
                                            >
                                              <i className="fa fa-trash text1"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Serial no.
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Company name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        Email
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Work phone
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Payment terms
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                                <h6 className="text-center">
                                  <img
                                    src={NoDataImage}
                                    style={{ width: "50%", height: "50%" }}
                                    alt="No Data Logo"
                                  />{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}

      {showUpdate && (
        <VendorsUpdate id={itemId} handleCancle={handleCancelUpdate} />
      )}

      {vendorTable && (
        <VendorDetails
          newVendorId={newVendorId}
          fetchedPaymentTerms={fetchedPaymentTerms}
          handleCancel={handleCancelDetails}
        />
      )}
    </div>
  );
}

export default Vendors;
