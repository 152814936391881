import React, { useState, useEffect } from "react";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import "./Expenses.css";
import ExpensesUpdate from "../SalesNew/ExpensesUpdate";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CiEdit } from "react-icons/ci";
import { MdOutlinePrint } from "react-icons/md";

function ExpenseDetails(props) {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [invoiceNumber, setInvoiceId] = useState(null);
  const [invoiceEdit, setInvoiceEdit] = useState(false);
  const [detailsPage, setDetailsPage] = useState(true);
  const [showDetails, setShowDetails] = useState(true);
  const jwtToken = localStorage.getItem("jwtToken");
  const [image, setImage] = useState(null);
  const handleCancleClick = () => {
    props.handleCancel();
    setShowDetails(true);
  };
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const { expId } = props;
  useEffect(() => {
    fetch(BASE_URL + `/fetchExpensesById/${expId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setInvoices(data.responseMessage);
          setImage(
            `https://documents.zarp.software/zarp_images/${data.responseMessage.attachReceipt}`
          );
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, [expId]);

  const handleExpenseClick = (id) => {
    fetch(BASE_URL + `/fetchExpensesById/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setInvoices(data.responseMessage);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  const handleCancel = () => {
    setInvoiceEdit(false);
    setDetailsPage(true);
    // setopenNew(false);
  };

  const [SalesOrder, setSalesOrder] = useState([]);
  useEffect(() => {
    fetch(BASE_URL + `/fetchExpenses`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setSalesOrder(data.responseMessage);
        } else {
          console.error("Failed to fetch SalesOrder:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching SalesOrder:", error);
        setLoading(false);
      });
  }, []);

  const handleEditClick = (id) => {
    setInvoiceId(id);
    setInvoiceEdit(true);
    setDetailsPage(false);
    // setopenNew(false);
  };
  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div class="center-div">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  const handlePrint = () => {
    const printableContent = document.getElementById("printable-content");
    if (printableContent) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Expense</title>
    <style>
        * {
            margin: 0px;
            padding: 0px;
            box-sizing: border-box;
        }
        body {
            font-family: Arial, sans-serif;
            width: 100vw;
        }
        .exp-inside {
            
            padding-top: 22px;
            font-size: 25px;
        }
        .for-inside {
            padding: 10px;
        }
        .font-bold {
            font-weight: 500;
            color: black;
            font-size: 20px;
        }
        .font-normal {
            font-size: 15px;
            color: #555;
        }
        .main-box {
            display: flex;
            width: 100%;
            border: 1px solid #757272;
            border-right: 1px solid #757272;
        }
        .left-main {          
            width: 50%;
        }
        hr
        {
            /* border: none; */
            
        }
        .right-main {
            width: 50%;
        }    
        .date-time-currnet {
            margin: 8px 0px;
        }
        .exp-cre-for {
            margin: 16px 0px;
        }
        .exp-amount {
            margin: 6px 5px;
        }
        .ml {
            margin-left: 5px;
        }
        .ml-tb {
            margin-top: 8px;
            margin-bottom: 5px;
        }
        .padi-th-trail {
            font-size: 12;
            margin-top: 35px;
        }
        .exp-detail {
            font-size: 30px;
            padding-left: 15px;
        }
        img {
            width: 150px;
            height: 150px;        
            position: absolute;
            right: 20px;
            top: 130px;
        }    
    </style>
</head>
<body>
    <div class="main-box">
        <div class="left-main">           
            <div class="date-time-currnet ml">
                <span> ${invoices.date} ${invoices.createdTime}</span>
            </div>
            <hr>
            <div class="exp-cre-for ml">
                <span class="font-bold"> Expense Created for
                </span>
                <span class="font-normal">₹ ${invoices.amount}.00</span>
            </div>
            <hr style="width: 200%;">
            <div class="exp-amount ml ml-tb">
                <span class="font-normal">
                    <span class="font-bold"> Expense Amount </span><br />
                    ₹ ${invoices.amount}.00  on ${invoices.date}<br />
                    <span class="font-light-bold"> NON-BILLABLE</span>
                </span>
            </div>
            <div class="paid-thro ml ml-tb">
                <p class="font-bold">
                    Paid Through
                </p>
                <span class="font-normal">
                    ${invoices.paidThrough}
                </span>
            </div>
            <div class="ref ml ml-tb">
                <p class="font-bold">
                    Ref#
                </p>
                <p class="font-normal">
                ${invoices.invoice}
                </p>
            </div>
            <div class="customer-name ml ml-tb">
                <p class="font-bold">
                    Customer
                </p>
                <p class="font-normal">
                ${invoices.customerName}
                </p>
            </div>
            <div class="padi-th-trail ml ml-tb">
                <b> Paid through Trail</b>
            </div>
        </div>
        <div class="right-main">
            <div class="exp-detail">
                <div class="exp-inside size-big">
                    EXPENSE DETAILS
                </div>
            </div>
            <div class="img">
                <img src=${image} alt="not found" width="100" height="100">
            </div>
        </div>
    </div>
</body>
</html>
`
      );
      printWindow.document.close();
      printWindow.onafterprint = () => {
        printWindow.close();
        printWindow.onafterprint = null;
      };
      printWindow.print();
    }
  };
  return (
    <>
      {detailsPage && (
        <>
          <div
            className="d-flex justify-content-between mt-3"
            style={{ padding: "5px 5px 0px 5px" }}
          >
            <div className="d-flex align-items-center ">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancleClick}
                  className="arrow-child"
                />
              </div>
              <div className="headinvoice ml-2">
                <h5
                  className="ml-1 mb-0 text-primary"
                  style={{ letterSpacing: "1px" }}
                >
                  All Details
                </h5>
                <p className="ml-1 mb-0 text-muted">
                  {SalesOrder.length} Payments
                </p>
              </div>
            </div>
            <div className="add-invoice pr-2 d-flex align-items-baseline"></div>
          </div>
          <div
            className="card border-2 invoice-border mt-3"
            style={{
              borderRadius: "20px",
              height: "auto",
              marginBottom: "0px",
            }}
          >
            <div className="custom-templates-container">
              <div className="left-invoice custom-scrollbar">
                <div>
                  <div
                    className="text-left custom-scrollbar"
                    style={{ cursor: "pointer" }}
                  >
                    {SalesOrder.map((data, index) => (
                      <React.Fragment key={index}>
                        <div
                          className="invoice-row"
                          onClick={() => handleExpenseClick(data.id)}
                        >
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              marginLeft: "10px",
                              marginBottom: "1px",
                            }}
                          >
                            {data.expenseAccount}
                          </p>

                          <p
                            className="text-muted"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              marginLeft: "10px",
                              marginBottom: "0px",
                            }}
                          >
                            {data.invoice} | {data.customerName}
                          </p>
                          <div className="d-flex justify-content-between mt-1">
                            <p
                              className=""
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                marginLeft: "10px",
                                marginBottom: "0px",
                              }}
                            >
                              {data.date}
                            </p>
                            <span className="fs-1 text-muted">
                              {`₹ ${data.amount}`}
                            </span>
                          </div>
                        </div>
                        <div className="horizontal-line"></div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>

              <div className="middle-line"></div>
              {showDetails && (
                <div className="right-invoice" id="printable-content">
                  <ul
                    className="nav nav-tabs-new d-flex"
                    style={{ padding: "0px 0px 10px 0px" }}
                  >
                    <h5
                      className="flex-grow-1 mb-0 ml-1"
                      style={{ color: "#3C7BD4" }}
                    >
                      {invoices.expenseAccount}
                    </h5>

                    <li className="nav-item mr-1 cursor-pointer">
                      <a
                        className="nav-link"
                        href="#"
                        role="button"
                        onClick={() => handleEditClick(invoices.id)}
                        style={{
                          borderRadius: "10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <CiEdit size={18} /> {/* Set the size to 24 */}
                        <span>Edit</span>{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link "
                        onClick={() => handlePrint()}
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <MdOutlinePrint size={18} />
                        <span className="ml-2">Print</span>
                      </a>
                    </li>
                  </ul>

                  <div className="horizontal-line"></div>
                  <div
                    className="container custom-scrollbar"
                    style={{ overflowY: "auto", maxHeight: "500px" }}
                  >
                    <div className="section1 pt-2 hello">
                      <div className="container my-3 border border-2">
                        <div className="row">
                          <div className="col-md-6">
                            {invoices.date} {invoices.createdTime}
                            <br />
                            <hr />
                            <span
                              className="label-title"
                              style={{ fontSize: "18px", color: "black" }}
                            >
                              {" "}
                              Expense Created for
                            </span>{" "}
                            <span
                              className="label-title"
                              style={{
                                fontSize: "14px",
                                color: "gray",
                              }}
                            >
                              ₹{invoices.amount}.00
                            </span>
                          </div>

                          <div className="col-md-6 text-left mt-2">
                            <h4>EXPENSE DETAILS</h4>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-8">
                                <span
                                  className="label-title"
                                  style={{
                                    fontSize: "18px",
                                    color: "black",
                                    marginBottom: "1px",
                                  }}
                                >
                                  {" "}
                                  Expense Amount{" "}
                                </span>{" "}
                                <br />{" "}
                                <span
                                  className="label-title"
                                  style={{
                                    fontSize: "14px",
                                    color: "gray",
                                    marginBottom: "1px",
                                  }}
                                >
                                  ₹ {invoices.amount}.00 on {invoices.date}
                                </span>
                                <br />
                                NON-BILLABLE
                                <br />
                                <br />
                                <span
                                  className="label-title"
                                  style={{
                                    fontSize: "18px",
                                    color: "black",
                                    marginBottom: "1px",
                                  }}
                                >
                                  Paid Through
                                </span>{" "}
                                <br />
                                <span
                                  className="label-title"
                                  style={{
                                    fontSize: "14px",
                                    color: "gray",
                                    marginBottom: "1px",
                                  }}
                                >
                                  {invoices.paidThrough}
                                </span>
                                <br />
                                <br />
                                <span
                                  className="label-title"
                                  style={{
                                    fontSize: "18px",
                                    color: "black",
                                    marginBottom: "1px",
                                  }}
                                >
                                  Ref#
                                </span>
                                <br />
                                <span
                                  className="label-title"
                                  style={{
                                    fontSize: "14px",
                                    color: "gray",
                                    marginBottom: "1px",
                                  }}
                                >
                                  {invoices.invoice}
                                </span>
                                <br />
                                <br />
                                <span
                                  className="label-title"
                                  style={{
                                    fontSize: "18px",
                                    color: "black",
                                    marginBottom: "1px",
                                  }}
                                >
                                  Customer
                                </span>{" "}
                                <br />
                                <span
                                  className="label-title"
                                  style={{
                                    fontSize: "14px",
                                    color: "gray",
                                    marginBottom: "1px",
                                  }}
                                >
                                  {invoices.customerName}
                                </span>
                                <br />
                                <br />
                                <br />
                                <br />
                                <b>Paid Through trail</b>
                              </div>
                              <div className="col-md-6"></div>
                            </div>
                            {/* </div> */}
                          </div>

                          <div className="col-md-6 text-right">
                            <img
                              src={image}
                              alt="Description of the image"
                              style={{ maxWidth: "60%", maxHeight: "60%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {invoiceEdit && (
        <ExpensesUpdate expenseId={invoiceNumber} handleCancel={handleCancel} />
      )}
    </>
  );
}

export default ExpenseDetails;
