import React, { useEffect, useState } from "react";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Carousel,
} from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  faFilePdf,
  faFileWord,
  faImage,
  faFileExcel,
  faFile,
  faDownload,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EnquiryDetails = (props) => {
  const { Itemid } = props;
  const jwtToken = localStorage.getItem("jwtToken");
  const [inquiryData, setInquiryData] = useState("");
  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      props.handleCancle();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const apiUrl = BASE_URL + `/getInquiryByInquiryId`;
  const fetchData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, inquiryId: Itemid }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          console.log(data.data);
          setInquiryData(data.data);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [enquiryData] = useState({
    id: "ENQ001",
    date: "2024-01-23",
    customerName: "John Doe",
    description: `
        <h4>Comprehensive Product Information</h4>
        <p>This chemical compound is widely used across industries for its unique properties and high efficiency.</p>
        <ul>
          <li><strong>Purity:</strong> 99.8%</li>
          <li><strong>Appearance:</strong> White crystalline powder</li>
          <li><strong>Applications:</strong> Pharmaceutical formulations, water treatment, and industrial processing</li>
        </ul>
        <p>For further details or bulk inquiries, please contact our sales team.</p><hr>
        <p>This chemical compound is widely used across industries for its unique properties and high efficiency.</p>
        <ul>
          <li><strong>Purity:</strong> 99.8%</li>
          <li><strong>Appearance:</strong> White crystalline powder</li>
          <li><strong>Applications:</strong> Pharmaceutical formulations, water treatment, and industrial processing</li>
        </ul>
      `,
    images: [
      {
        url: "https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072821_640.jpg",
        name: "Project Concept 1",
      },
      {
        url: "https://cdn.pixabay.com/photo/2023/02/01/10/37/sunset-7760143_640.jpg",
        name: "Project Concept 2",
      },
      {
        url: "https://cdn.pixabay.com/photo/2018/11/17/22/15/trees-3822149_640.jpg",
        name: "Project Mockup",
      },
      {
        url: "https://cdn.pixabay.com/photo/2023/11/17/22/10/road-8395119_640.jpg",
        name: "Project Concept 3",
      },
    ],
    documents: [
      { name: "project_overview.pdf", type: "pdf", size: "2.5 MB", file: null },
      {
        name: "requirement_doc.docx",
        type: "word",
        size: "1.2 MB",
        file: null,
      },
      {
        name: "logo.png",
        type: "image",
        size: "0.5 MB",
        file: "https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072821_640.jpg",
      },
      {
        name: "additional_specs.xlsx",
        type: "excel",
        size: "3.1 MB",
        file: null,
      },
    ],
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageIndex, setImageIndex] = useState(0);

  const [selectedFile, setSelectedFile] = useState(null);

  // const renderFileContent = (file) => {
  //   if (file.type === "image") {
  //     if (file.file) {
  //       // Check if file.file is a Blob or a URL string
  //       if (file.file instanceof Blob) {
  //         return (
  //           <>
  //             <p className="mb-1">{file.name}</p>
  //             <img
  //               src={URL.createObjectURL(file.file)}
  //               alt={file.name}
  //               style={{
  //                 maxWidth: "100%",
  //                 maxHeight: "300px",
  //                 objectFit: "contain",
  //                 borderRadius: "5px",
  //               }}
  //             />
  //           </>
  //         );
  //       } else if (typeof file.file === "string") {
  //         // If file.file is a string, treat it as a URL
  //         return (
  //           <>
  //             <p className="mb-1">{file.name}</p>
  //             <img
  //               src={file.file}
  //               alt={file.name}
  //               style={{
  //                 maxWidth: "100%",
  //                 maxHeight: "300px",
  //                 objectFit: "contain",
  //                 borderRadius: "5px",
  //               }}
  //             />
  //           </>
  //         );
  //       } else {
  //         console.error("Invalid file object for image:", file.file);
  //       }
  //     } else if (file.url) {
  //       return (
  //         <>
  //           <p className="mb-1">{file.name}</p>
  //           <img
  //             src={file.url}
  //             alt={file.name}
  //             style={{
  //               maxWidth: "100%",
  //               maxHeight: "300px",
  //               objectFit: "contain",
  //               borderRadius: "5px",
  //             }}
  //           />
  //         </>
  //       );
  //     }
  //   } else if (["pdf", "word", "excel"].includes(file.type)) {
  //     return (
  //       <div className="d-flex align-items-center">
  //         {renderFileIcon(file.type)}
  //         <div className="ml-2">
  //           <p className="mb-0">{file.name}</p>
  //           <small className="text-muted">{file.size}</small>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return <p>{file.name}</p>;
  //   }
  // };

  const getFileType = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return "pdf";
      case "doc":
      case "docx":
        return "word";
      case "xls":
      case "xlsx":
        return "excel";
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return "image";
      default:
        return "other";
    }
  };

  const getFileUrl = (fileName) => {
    return `https://qabooks.zarp.software/uploads/enquiryFiles/${fileName}`;
  };

  const renderFileContent = (fileName) => {
    const fileType = getFileType(fileName);
    const fileUrl = getFileUrl(fileName);

    if (fileType === "image") {
      return (
        <>
          <p className="mb-1">{fileName}</p>
          <img
            src={fileUrl}
            alt={fileName}
            style={{
              maxWidth: "100%",
              maxHeight: "300px",
              objectFit: "contain",
              borderRadius: "5px",
            }}
          />
        </>
      );
    } else {
      return (
        <div className="d-flex align-items-center w-100">
          {renderFileIcon(fileType)}
          <div className="ml-2 overflow-hidden" style={{ flex: 1 }}>
            <p className="mb-0 text-truncate" title={fileName}>
              {fileName}
            </p>
          </div>
        </div>
      );
    }
  };

  const handleFileView = (fileName) => {
    const fileType = getFileType(fileName);
    const fileUrl = getFileUrl(fileName);

    if (fileType === "image") {
      setSelectedFile({ name: fileName, type: fileType, url: fileUrl });
    } else {
      // For non-image files, trigger download
      window.open(fileUrl, "_blank");
    }
  };

  const renderFileIcon = (fileType) => {
    const iconStyle = {
      pdf: {
        icon: faFilePdf,
        color: "text-danger",
        size: "2x",
      },
      word: {
        icon: faFileWord,
        color: "text-primary",
        size: "2x",
      },
      excel: {
        icon: faFileExcel,
        color: "text-success",
        size: "2x",
      },
    };

    const fileConfig = iconStyle[fileType] || {
      icon: faFile,
      color: "",
      size: "",
    };

    return (
      <FontAwesomeIcon
        icon={fileConfig.icon}
        className={`${fileConfig.color} mx-2`}
        size={fileConfig.size}
      />
    );
  };

  const handleImageView = (index) => {
    setSelectedImage(enquiryData.images[index]);
    setImageIndex(index);
  };

  return (
    <Container fluid className="py-4">
      <Row>
        <Col>
          <div className="my-3">
            <div className="px-2 d-flex">
              <div className="cursor-pointer d-flex justify-content-center align-items-center back-button">
                <ArrowBackIosIcon
                  width={2}
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <h5
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                Enquiry Details
              </h5>
            </div>
          </div>

          <Card className="shadow-sm card-style">
            <Card.Body>
              <Row>
                <Col md={6}>
                  <div
                    className="bg-light p-3 rounded mb-3"
                    style={{
                      maxHeight: "370px",
                      height: "100%",
                    }}
                  >
                    <h5 className="text-primary mb-3">Enquiry Information</h5>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Enquiry ID:</strong> {inquiryData.inquiryId}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Date:</strong> {inquiryData.createdDate}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Customer Name:</strong> {inquiryData.customerName}
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="bg-light p-3 rounded" style={{}}>
                    <h5 className="text-primary mb-3">Description</h5>
                    <div
                      className=""
                      style={{
                        overflow: "scroll",
                        scrollbarWidth: "thin",
                        scrollBehavior: "smooth",
                        maxHeight: "300px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: inquiryData.description,
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <hr />

              {/* Image Gallery */}
              <h5 className="text-primary mb-4">Attached Documents</h5>
              <Row>
                {inquiryData.fileNames &&
                  inquiryData.fileNames.map((fileName, index) => (
                    <Col key={index} md={3} className="mb-3">
                      <Card className="h-100 align-content-center border border-4 rounded-5">
                        <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                          {renderFileContent(fileName)}
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="mt-2"
                            onClick={() => handleFileView(fileName)}
                          >
                            <FontAwesomeIcon
                              icon={
                                getFileType(fileName) === "image"
                                  ? faEye
                                  : faDownload
                              }
                            />
                          </Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <Modal
        show={!!selectedFile}
        onHide={() => setSelectedFile(null)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedFile?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {selectedFile && renderFileContent(selectedFile)}
        </Modal.Body>
      </Modal> */}

      <Modal
        show={!!selectedFile}
        onHide={() => setSelectedFile(null)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedFile?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {selectedFile && selectedFile.type === "image" && (
            <img
              src={selectedFile.url}
              alt={selectedFile.name}
              style={{
                maxWidth: "100%",
                maxHeight: "500px",
                objectFit: "contain",
              }}
            />
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default EnquiryDetails;
