import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import {
  AccountBalance,
  CalendarMonth,
  CorporateFare,
  CreditCard,
  Person,
  ReceiptLong,
} from "@mui/icons-material";

const UpdateCheque = ({ handleCancel, chequeId }) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [formData, setFormData] = useState({
    partyName: "",
    chequeNo: "",
    amount: "",
    partyBank: "",
    firmNameBank: "",
  });

  useEffect(() => {
    if (chequeId) {
      fetch(`${BASE_URL}/findChequeById`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          id: chequeId,
          uniqueId: decryptedUniqueId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Cheque Data fetched:", data);
          if (data.message === "Cheques retrieved successfully" && data.data) {
            const chequeData = data.data;
            setFormData({
              partyName: chequeData.partyName || "",
              chequeNo: chequeData.chequeNo || "",
              amount: chequeData.amount || "",
              partyBank: chequeData.partyBank || "",
              firmNameBank: chequeData.firmNameBank || "",
            });

            if (chequeData.date) {
              setSelectedDate(new Date(chequeData.date));
            }
          } else {
            console.error("Error fetching cheque data:", data.message);
            swal({
              icon: "error",
              title: "Failed to fetch cheque details",
              text: data.message || "An error occurred",
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
          swal({
            icon: "error",
            title: "An Error Occurred",
            text: "Failed to fetch cheque details",
          });
        });
    } else {
      setLoading(false);
    }
  }, [chequeId, jwtToken, decryptedUniqueId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateCheque = async (e) => {
    e.preventDefault();

    if (!formData.chequeNo || !formData.partyName || !formData.amount) {
      swal({
        icon: "warning",
        title: "Required Fields Missing",
        text: "Please fill in all required fields",
      });
      return;
    }

    const updatedChequeData = {
      id: chequeId,
      uniqueId: decryptedUniqueId,
      chequeNo: formData.chequeNo,
      partyBank: formData.partyBank,
      date: selectedDate.toISOString().split("T")[0],
      amount: formData.amount,
      partyName: formData.partyName,
      firmNameBank: formData.firmNameBank,
    };

    setLoading(true);

    try {
      const response = await fetch(`${BASE_URL}/updateCheque`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(updatedChequeData),
      });

      const data = await response.json();

      if (data.message === "Cheque entry updated successfully") {
        swal({
          icon: "success",
          title: "Success",
          text: "Cheque updated successfully",
        }).then(() => {
          handleCancel();
        });
      } else {
        throw new Error(data.message || "Failed to update cheque");
      }
    } catch (error) {
      console.error("Error updating cheque:", error);
      swal({
        icon: "error",
        title: "Error",
        text: error.message || "An error occurred while updating the cheque",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    const confirmCancel = window.confirm(
      "Are you sure you want to leave this page? Any unsaved changes will be lost."
    );
    if (confirmCancel) {
      handleCancel();
    }
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="my-3">
        <div className="d-flex align-items-center ml-1 mt-4">
          <div
            className="cursor-pointer d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              height: "35px",
              width: "38px",
            }}
          >
            <ArrowBackIosIcon
              className="arrow-child"
              onClick={handleCancelClick}
            />
          </div>
          <div className="newcustomer-head ml-2">
            <h5 className="ml-1 mb-0" style={{ color: "#086bd5de" }}>
              Update Cheque
            </h5>
          </div>
        </div>
      </div>

      <Container fluid>
        <Card className="card-style p-4">
          <Row>
            <Col md="3">
              <Form.Group>
                <label className="text-primary" style={{ fontWeight: "600" }}>
                  Cheque Details
                </label>
              </Form.Group>
            </Col>
          </Row>

          <Form onSubmit={handleUpdateCheque}>
            <Row>
              <Col md="6">
                <Form.Group>
                  <label style={{ color: "grey" }}>
                    Party Name <span className="text-danger">*</span>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Person sx={{ fill: "#9A9A9A" }} />
                    <Form.Control
                      required
                      placeholder="Enter Party Name"
                      name="partyName"
                      value={formData.partyName}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group>
                  <label style={{ color: "grey" }}>
                    Cheque Number <span className="text-danger">*</span>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <ReceiptLong sx={{ fill: "#9A9A9A" }} />
                    <Form.Control
                      required
                      placeholder="Enter Cheque Number"
                      name="chequeNo"
                      value={formData.chequeNo}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Group>
                  <label style={{ color: "grey" }}>
                    Amount <span className="text-danger">*</span>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <CreditCard sx={{ fill: "#9A9A9A" }} />
                    <Form.Control
                      required
                      type="number"
                      placeholder="Enter Amount"
                      name="amount"
                      value={formData.amount}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <label style={{ color: "grey" }}>Party Bank Name</label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <AccountBalance sx={{ fill: "#9A9A9A" }} />
                    <Form.Control
                      placeholder="Enter Party Bank Name"
                      name="partyBank"
                      value={formData.partyBank}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <Form.Group>
                  <label style={{ color: "grey" }}>Firm Name</label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <CorporateFare sx={{ fill: "#9A9A9A" }} />
                    <Form.Control
                      placeholder="Enter Firm Name"
                      name="firmNameBank"
                      value={formData.firmNameBank}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group className="d-flex flex-column new-campaign-time w-100">
                  <label style={{ color: "grey" }}>Date</label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <CalendarMonth sx={{ fill: "#9A9A9A" }} />
                    <div className="customDatePickerWidth">
                      <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        className="form-control date-control"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Select date"
                        style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                      />
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <hr className="mt-4" />

            <div className="text-right invoice-bottombtns">
              <Button
                variant="secondary"
                className="mt-0 mr-2"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Update Cheque
              </Button>
            </div>
          </Form>
        </Card>
      </Container>
    </div>
  );
};

export default UpdateCheque;
