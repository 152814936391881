import React, { useState, useEffect } from "react";
import { CaretLeft } from "phosphor-react";
import {
  Row,
  Container,
  Col,
  Button,
  Form,
  Card,
  Modal,
} from "react-bootstrap";
import "./popup.css";
import { addDays, endOfMonth, addMonths } from "date-fns";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import { differenceInDays } from "date-fns";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import TinyMceEditor from "../Settings/TinyMceEditor";
import { tr } from "date-fns/locale";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function SalesOrderNew({
  customerId,
  customerName,
  enqNo,
  handleCancel,
  navigation,
}) {
  const [row, setRow] = useState([
    {
      itemDetails: "",
      itemCode: "",
      per: "1",
      quantity: "1",
      rate1: "0.0",
      rate: "0.0",
      pack: "",
      make: "",
      discount: "0.0",
      discountType: "₹",
      description1: "",
      description2: "",
      alternateUnit: "", // Add this
      alternateUnitValue: "", // Add this
      calculatedAltUnitValue: "", // Add this
    },
  ]);

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");

  const handleAdd = () => {
    const newItem = {
      itemDetails: "",
      per: "1",
      quantity: "1",
      rate: "0.0",
      discount: "0.0",
      discountType: "₹",
      alternateUnit: "", // Add this
      alternateUnitValue: "", // Add this
      calculatedAltUnitValue: "", // Add this
    }; // Replace with your desired default values
    const newRow = [...row, newItem];
    setRow(newRow);
  };

  const handleTaxChange = (e, index) => {
    setSelectedTaxRate(e.target.value);
    const newTaxRate = parseFloat(e.target.value);
    const updatedRow = [...row];
    updatedRow[index].tax = newTaxRate;
    setRow(updatedRow);
  };

  const handleDeleteRow = (i) => {
    const updatedRow = [...row];
    updatedRow.splice(i, 1);
    setRow(updatedRow);
    updateTotal(updatedRow);
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      handleInvoicesClick();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  const [show1, setShow1] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [manualCheck, setManualCheck] = useState("No");
  const [batchNumbers, setBatchNumbers] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleInvoicesClick = () => {
    // handleNavigation("Order");
    handleCancel();
  };
  const openPopup = () => {
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
    fetchSalesData();
  };

  const [showInvoice, setShowInvoice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const openInvoice = () => {
    setShowInvoice(true);
  };

  const closeInvoice = () => {
    setShowInvoice(false);
  };

  const handleDiscountChange = (e, i) => {
    const { name, value } = e.target;
    const newValues =
      name === "discount" ? { discount: value } : { discountType: value };
    const newRow = [...row];
    newRow[i] = { ...newRow[i], ...newValues };
    setRow(newRow);
    updateTotal(newRow);
  };

  const handleQuantityChange = (e, i) => {
    const newQuantity = e.target.value;
    const quantityValue = parseFloat(newQuantity);
    if (!isNaN(quantityValue) && quantityValue >= 0) {
      const updatedRow = [...row];
      updatedRow[i] = {
        ...updatedRow[i],
        quantity: newQuantity,
        calculatedAltUnitValue:
          quantityValue * updatedRow[i].alternateUnitValue,
      };
      setRow(updatedRow);
    }
    updateTotal(row);
  };
  const handleHsnSacChange = (e, i) => {
    const { value } = e.target;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], hsnSac: value };
    setRow(newRow);
    updateTotal(row);
  };

  const handleSizeChange = (e, i) => {
    const { value } = e.target;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], size: value };
    setRow(newRow);
    updateTotal(row);
  };

  const handleRateChange = (e, i) => {
    const newRate = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], rate: newRate };
    setRow(newRow);
    updateTotal(row);
  };
  const handleRateChange1 = (e, i) => {
    const newRate = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], rate1: newRate };
    setRow(newRow);
  };

  const handlePackChange = (e, i) => {
    const newRate = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], pack: newRate };
    setRow(newRow);
    updateTotal(row);
  };
  const handleItemCodeChange = (e, i) => {
    const newRate = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], itemCode: newRate };
    setRow(newRow);
    updateTotal(row);
  };
  const handleMakeChange = (e, i) => {
    const newRate = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], make: newRate };
    setRow(newRow);
    updateTotal(row);
  };

  const updateTotal = (rowData) => {
    let calculatedTotal = 0;
    rowData.forEach((data) => {
      const amount = calculateAmount(data);
      calculatedTotal += parseFloat(amount);
    });
    setTotal(calculatedTotal.toFixed(2));
  };
  const [total, setTotal] = useState(0);
  const calculateAmount = (data, tax) => {
    const { quantity, rate, discount, discountType } = data;
    let amount = quantity * rate;

    if (discount) {
      amount -= discountType === "%" ? (amount * discount) / 100 : discount;
    }

    return amount;
  };

  const [selectedTaxRate, setSelectedTaxRate] = useState(0);

  const calculateTaxAmount = (amount, taxRate) => {
    if (taxRate > 0) {
      let taxIncrease = 0;
      switch (taxRate) {
        case 0.05:
          taxIncrease = 0.5;
          break;
        case 0.12:
          taxIncrease = 1.0;
          break;
        case 0.18:
          taxIncrease = 1.5;
          break;
        case 0.28:
          taxIncrease = 2.0;
          break;
        default:
          break;
      }
      return amount * (taxRate + taxIncrease);
    }
    return 0;
  };

  const calculateSubTotal = () => {
    let subTotal = 0;
    row.forEach((data) => {
      const amount = calculateAmount(data);
      subTotal += parseFloat(amount);
    });
    return subTotal.toFixed(2);
  };

  const [dropdownData, setDropdownData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [assignedField, setAssignedField] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [selectedItems, setSelectedItems] = useState(
    Array(row.length).fill("")
  );

  const fetchItemData = () => {
    const postApiUrl = BASE_URL + `/getAllItemData`;
    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, vendorId: "" }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseMessage;
        setDropdownData(responseData.map((item) => item));
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };
  const fetchBatchNumbers = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/getAllPendingSalesOrderStatus`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ uniqueId: decryptedUniqueId }),
        }
      );
      const data = await response.json();
      if (data.responseCode === "200") {
        const formattedBatches = data.data.map((batch) => ({
          value: batch.inquiryId,
          label: batch.inquiryId,
        }));
        setBatchNumbers(formattedBatches);
      } else {
        console.error("Failed to fetch batch numbers:", data.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching batch numbers:", error);
    }
  };

  useEffect(() => {
    fetchItemData();
    fetchTermsData();
    fetchBatchNumbers();
  }, []);

  const [searchIndex, setSearchIndex] = useState(0);

  useEffect(() => {
    const results = dropdownData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, dropdownData]);

  const handleSearchChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...row];
    newRow[index].itemDetails = value;
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setRow(newRow);
    setSearchIndex(index);
  };

  const handleItemDetailsChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...row];
    newRow[index].itemDetails = value;
    setRow(newRow);
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setSearchIndex(index);
    if (value) {
      fetchItemRate(value, index);
    } else {
      handleSearchChange(value, index);
    }
  };

  const handleItemSelect = (selectedItem, selectedItemId, index) => {
    setSearchResults([]);
    const newRow = [...row];
    newRow[index].itemDetails = selectedItem;
    setRow(newRow);
    fetchItemRate(selectedItemId, index);
  };

  const fetchItemRate = (itemId, index) => {
    const getApiUrl = BASE_URL + `/getItemData/${itemId}`;

    fetch(getApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const rate = data.data.taxableAmount;
        const hsnSac = data.data.hsnCode;
        const description1 = data.data.description;
        const itemName = data.data.name;
        const pack = data.data.pack;
        const make = data.data.make;
        const tax = data.data.intraStateTaxRate;
        const alternateUnitValue = data.data.alternateUnitValue;
        const alternateUnit = data.data.alternateUnit;
        const updatedRow = [...row];
        updatedRow[index].rate = rate;
        updatedRow[index].hsnSac = hsnSac;
        updatedRow[index].description1 = description1;
        updatedRow[index].itemDetails = itemName;
        updatedRow[index].pack = pack;
        updatedRow[index].make = make;
        updatedRow[index].tax = tax;
        updatedRow[index].alternateUnitValue = alternateUnitValue;
        updatedRow[index].alternateUnit = alternateUnit;
        updatedRow[index].calculatedAltUnitValue = alternateUnitValue;
        setRow(updatedRow);
        setSearchResults([]);
        handleAdd();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  // => IGST - 1
  const calculateIGST = () => {
    let totalIGST = 0;

    row.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      if (taxRate > 0) {
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = (parseFloat(amount) * taxRate) / 100;
        totalIGST += increasedTaxAmount;
      }
    });

    return totalIGST.toFixed(2);
  };

  //calculate SGST and CGSt
  const calculateSGSTandCGST = () => {
    let totalSGST = 0;
    let totalCGST = 0;

    row.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = parseFloat(amount) - amount / (1 + taxRate);
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;
      totalSGST += sgst;
      totalCGST += cgst;
    });
    return {
      totalSGST: totalSGST.toFixed(2),
      totalCGST: totalCGST.toFixed(2),
    };
  };

  const calculateIGSTForTax = () => {
    const taxResults = [];

    row.forEach((data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate > 0) {
        let existingTaxResult = taxResults.find(
          (result) => result.taxRate === taxRate
        );
        if (!existingTaxResult) {
          existingTaxResult = { taxRate, totalIGST: 0 };
          taxResults.push(existingTaxResult);
        }
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = amount * taxRate;
        existingTaxResult.totalIGST += increasedTaxAmount;
      }
    });
    return taxResults;
  };

  const calculateGroupedSGSTandCGST = () => {
    const taxGroups = row.reduce((acc, data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate === 0) return acc; // Skip 0% GST

      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = amount * taxRate;
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;

      if (!acc[taxRate]) {
        acc[taxRate] = { totalSGST: 0, totalCGST: 0 };
      }

      acc[taxRate].totalSGST += sgst;
      acc[taxRate].totalCGST += cgst;

      return acc;
    }, {});

    return taxGroups;
  };

  const [selectedTcsOption, setSelectedTcsOption] = useState(null);
  const tcs = [
    // { value: "0 GST [0%]", label: "0 GST [0%]",discount:0 },
    { value: "5 GST [5%]", label: "5 GST [5%]", discount: 5 },
    { value: "12 GST [12%]", label: "12 GST [12%]", discount: 12 },
    { value: "18 GST [18%]", label: "18 GST [18%]", discount: 18 },
    { value: "28 GST [28%]", label: "28 GST [28%]", discount: 28 },
  ];

  const [discountAmountTcs, setDiscountAmountTcs] = useState(null);

  const applyDiscountTcs = (subtotal, discountPercentage) => {
    const discountAmount = (subtotal * discountPercentage) / 100;
    const tcsDis = (subtotal - discountAmount).toFixed(2);
    setDiscountAmountTcs(parseFloat(discountAmount.toFixed(2))); // Set discountAmount in state

    return tcsDis;
  };

  const handleTcsOptionChange = (selectedOption) => {
    setSelectedTcsOption(selectedOption);
    const subtotal = calculateSubTotal();

    // Check if a TDS option is selected and has a discount
    if (selectedOption && selectedOption.discount) {
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedOption.discount
      );
    }
  };

  const [adjustmentValue, setAdjustmentValue] = useState(""); // Step 1: State for adjustment value
  const [oppositeValue, setOppositeValue] = useState(""); // State for opposite value
  const [bussinessRegistered, setBussinessRegistered] = useState("");

  useEffect(() => {
    const parsedValue = parseFloat(adjustmentValue);
    if (!isNaN(parsedValue)) {
      // Calculate the opposite value only if the adjustmentValue is a valid number
      const opposite = parsedValue * 1;
      setOppositeValue(opposite.toFixed(2));
    } else {
      // If adjustmentValue is not a valid number, set the opposite value to an empty string
      setOppositeValue("");
    }
  }, [adjustmentValue]);

  useEffect(() => {
    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage[0].businessRegistered;
          setGstNumber(data.responseMessage[0].gstin);
          setBussinessRegistered(Data);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const totalTax =
    calculateIGST() ||
    calculateSGSTandCGST().totalSGST + calculateSGSTandCGST().totalCGST;

  const calculateDisRupees = (data, taxRate) => {
    const quantity = parseFloat(data.quantity || 0);
    const rate = parseFloat(data.rate || 0);
    const discount = parseFloat(data.discount || 0);
    const amount = quantity * rate;
    let discountedAmount = 0;
    if (data.discountType === "%") {
      discountedAmount = (amount * discount) / 100;
    } else if (data.discountType === "₹") {
      discountedAmount = discount;
    }

    return discountedAmount.toFixed(2);
  };

  const calculateTotal = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const igst = parseFloat(calculateIGST());
    const adjustment = parseFloat(adjustmentValue) || 0;
    const totalDis = parseFloat(disValue) || 0;
    const total1 = subTotal + igst + adjustment - totalDis;
    const total = total1 + discountAmountTcs;
    return total.toFixed(2);
  };

  const [salesOrderNumber, setSalesOrderNumber] = useState("");

  const [reference, setReference] = useState("");
  const [subject, setSubject] = useState("");
  const [adjustmentLabel, setAdjustmentLabel] = useState("");
  const [customerNote, setCustomerNote] = useState("");
  const [termsAndCondition, setTermsAndCondition] = useState("");
  const [salesPersonData, setSalesPersonData] = useState([]);
  const [batchNo, setBatchNo] = useState([]);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState("");
  const [salesorderNumberPrefix, setSalesorderNumberPrefix] = useState([]);
  const [salesorderNumberNext, setsalesorderNumberNext] = useState([]);
  const [showPurchasePage, setShowPurchasePage] = useState(true);
  const [showMailPage, setShowMailPage] = useState(false);
  const [userDetail, setUserDetail] = useState("");
  const [userEmail, setUserEmail] = useState([]);
  const [custEmail, setCustomerEmail] = useState([]);
  const [subjectMail, setSubjectMail] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const handleSubjectChange = (event) => {
    setSubjectMail(event.target.value);
  };

  const handleUserEmail = (event) => {
    setUserEmail(event.target.value);
  };

  const handleCustomerEmail = (event) => {
    setCustomerEmail(event.target.value);
  };
  const handleMailClick = () => {
    setShowPurchasePage(false);
    setShowMailPage(true);
  };
  const [validationMessage2, setValidationMessage2] = useState("");

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    row.forEach((data) => {
      const amount = calculateAmount(data);
      totalAmount += parseFloat(amount);
    });
    return totalAmount.toFixed(2);
  };

  const insertDataIntoDatabase = async () => {
    const itemDetails = row.map((data) => {
      return {
        itemDetails: data.itemDetails || "",
        hsnSac: data.hsnSac,
        size: data.size,
        per: data.per,
        quantity: data.quantity,
        rateOne: data.rate1,
        rate: data.rate,
        pack: data.pack,
        make: data.make,
        discount: data.discount,
        discountType: data.discountType,
        tax: data.tax || 0,
        amount: calculateAmount(data, selectedTaxRate),
        rowDiscount: calculateDisRupees(data),
        description1: data.description1 || "",
        description2: data.description2 || "",
        customerTaxType: userState.state === customerState ? 1 : 2,
        cgst:
          userState.state === customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100 / 2
            : 0,
        sgst:
          userState.state === customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100 / 2
            : 0,
        igst:
          userState.state !== customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100
            : 0,
        alternateUnit: data.alternateUnit || "", // Add this
        alternateUnitValue: data.calculatedAltUnitValue || "", // Add this
      };
    });

    const formData = {
      uniqueId: decryptedUniqueId,
      customerName: selectedCustomer,
      inquiryId: enqNo,
      customerId: selectedCustomerId,
      salesOrderNumber: salesOrderNumber,
      salesOrderDate: dateValue || normalDateFormat(startDate),
      paymentTerms: selectedTerms,
      expectedShipmentDate: showDueDate === true ? dueDate : dateValue1,
      salesPerson: selectedSalesPerson,
      reference: reference,
      // subject: subject,
      items: itemDetails,
      subtotal: calculateSubTotal(),
      adjustment: parseFloat(adjustmentValue) || 0,
      adjustmentLabel: adjustmentLabel,
      total: calculateTotal(),
      customerNote: customerNote || "Thank You For Business.",
      termsAndCondition: termsAndCondition,
      totalAmount: calculateTotalAmount(), // Assuming you have a function to calculate the total amount
      igst: calculateIGST(),
      sgst: calculateSGSTandCGST().totalSGST,
      cgst: calculateSGSTandCGST().totalCGST,
      overallDiscount: disAllValue || 0,
      taxCheck: bussinessRegistered || 0,
      totalDiscount: disValue || 0,
      overallDiscountType: discountType || 0,
      taxInAmount: totalTax,
      type: "TCS",
      value: discountAmountTcs,
      tcsTax: selectedTcsOption?.value,
      status: "Sent",
      manualCheck: manualCheck,
      state: customerState,
      hsnCheck: discountCheck.hsnCheck,
      sizeCheck: discountCheck.sizeCheck,
      discountCheck: discountCheck.discountCheck,
      netRateCheck: discountCheck.netRateCheck,
      gstNumber: gstNumber,
      packCheck: discountCheck.packCheck,
      quantityCheck:discountCheck.quantityCheck,
    };
    let newValidationMessage = "";
    if (!formData.customerName) {
      newValidationMessage += "Please select a customer \n";
    } else if (!formData.salesOrderNumber) {
      newValidationMessage += "Please enter sales order number.\n";
    } else if (!formData.salesOrderDate) {
      newValidationMessage += "Please select a sales date \n";
    } else if (!formData.expectedShipmentDate) {
      newValidationMessage += "Please select a due date\n";
    }
    row.forEach((item, index) => {
      if (
        item.itemDetails.trim() === "" ||
        item.rate.trim() === "" ||
        item.rate.trim() === "0.0" ||
        item.quantity.trim() === ""
      ) {
        newValidationMessage +=
          "Enter the valid item name, rate and quantity.\n";
      }
    });

    setValidationMessage2(newValidationMessage);

    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    setIsLoading(true);
    const response = await fetch(BASE_URL + `/insertSalesOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Sales Order Created",
          });
          handleMailClick();
          setIsLoading(false);
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        setIsLoading(false);
      });
  };

  const insertDataIntoDatabaseAsDraft = async () => {
    const itemDetails = row.map((data) => {
      return {
        itemDetails: data.itemDetails || "",
        hsnSac: data.hsnSac,
        size: data.size,
        per: data.per,
        quantity: data.quantity,
        rate: data.rate,
        pack: data.pack,
        discount: data.discount,
        discountType: data.discountType,
        tax: data.tax || 0,
        amount: calculateAmount(data, selectedTaxRate),
        rowDiscount: calculateDisRupees(data),
        description1: data.description1 || "",
        description2: data.description2 || "",
        customerTaxType: userState.state === customerState ? 1 : 2,
        cgst:
          userState.state === customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100 / 2
            : 0,
        sgst:
          userState.state === customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100 / 2
            : 0,
        igst:
          userState.state !== customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100
            : 0,
        alternateUnit: data.alternateUnit || "", // Add this
        alternateUnitValue: data.calculatedAltUnitValue || "", // Add this
      };
    });

    const formData = {
      uniqueId: decryptedUniqueId,
      customerName: selectedCustomer,
      inquiryId: enqNo,
      customerId: selectedCustomerId,
      salesOrderNumber: salesOrderNumber,
      salesOrderDate: dateValue || normalDateFormat(startDate),
      paymentTerms: selectedTerms,
      expectedShipmentDate: showDueDate === true ? dueDate : dateValue1,
      salesPerson: selectedSalesPerson,
      reference: reference,
      // subject: subject,
      items: itemDetails,
      subtotal: calculateSubTotal(),
      adjustment: parseFloat(adjustmentValue) || 0,
      adjustmentLabel: adjustmentLabel,
      total: calculateTotal(),
      customerNote: customerNote || "Thank You For Business.",
      termsAndCondition: termsAndCondition,
      totalAmount: calculateTotalAmount(), // Assuming you have a function to calculate the total amount
      igst: calculateIGST(),
      sgst: calculateSGSTandCGST().totalSGST,
      cgst: calculateSGSTandCGST().totalCGST,
      overallDiscount: disAllValue || 0,
      taxCheck: bussinessRegistered || 0,
      totalDiscount: disValue || 0,
      overallDiscountType: discountType || 0,
      taxInAmount: totalTax,
      type: "TCS",
      value: discountAmountTcs,
      tcsTax: selectedTcsOption?.value,
      status: "Draft",
      manualCheck: manualCheck,
      state: customerState,
      hsnCheck: discountCheck.hsnCheck,
      sizeCheck: discountCheck.sizeCheck,
      discountCheck: discountCheck.discountCheck,
      gstNumber: gstNumber,
      packCheck: discountCheck.packCheck,
      quantityCheck:discountCheck.quantityCheck,
    };
    let newValidationMessage = "";
    if (!formData.customerName) {
      newValidationMessage += "Please select a customer \n";
    } else if (!formData.salesOrderNumber) {
      newValidationMessage += "Please enter sales order number.\n";
    } else if (!formData.salesOrderDate) {
      newValidationMessage += "Please select a sales date \n";
    } else if (!formData.expectedShipmentDate) {
      newValidationMessage += "Please select a due date\n";
    }
    row.forEach((item, index) => {
      if (
        item.itemDetails.trim() === "" ||
        item.rate.trim() === "" ||
        item.rate.trim() === "0.0" ||
        item.quantity.trim() === ""
      ) {
        newValidationMessage +=
          "Enter the valid item name, rate and quantity.\n";
      }
    });

    setValidationMessage2(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    setIsLoading(true);
    const response = await fetch(BASE_URL + `/insertSalesOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Sales Order Created",
          });
          handleMailClick();
          setIsLoading(false);
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        setIsLoading(false);
      });
  };

  const fetchSalesData = () => {
    fetch(BASE_URL + `/getAllSalesPersonByUniqueId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const filteredData = data.responseMessage.filter(
            (item) => item.name !== "Others"
          );
          setSalesPersonData(filteredData);
        } else {
          console.error("Failed to fetch salesperson:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching salesperson:", error);
      });
  };
  useEffect(() => {
    fetchSalesData();
  }, []);

  const handleSalesPersonChange = (value, id) => {
    const selectedValue = value.value;
    setSelectedSalesPerson(selectedValue);
  };

  useEffect(() => {
    fetch(BASE_URL + `/getCurruntNumberList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ type: "salesorder", uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setSalesOrderNumber(data.responseMessage);
        } else {
          console.error("Failed to fetch numberList:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Number List:", error);
      });
  }, []);

  const copyAdd = () => {
    if (show1) {
      // If auto-generating is selected, set the generatedInvoiceNumber
      setSalesOrderNumber(salesorderNumberPrefix + salesorderNumberNext);
      setManualCheck("No");
    } else {
      // If manually adding is selected, set it to null
      setSalesOrderNumber("");
      setManualCheck("Yes");
    }

    // Close the popup
    closeInvoice();
  };
  const handleSalesNumberChange = (e) => {
    setSalesOrderNumber(e.target.value);
    setManualCheck("Yes");
  };
  const setBill = () => {
    setShow1(true);
    fetchBill();
  };

  const fetchBill = () => {
    const requestData = {
      // Include any other data you need to send in the request
      uniqueId: decryptedUniqueId,
      type: "salesorder",
    };

    // Define the POST request options
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
        // Add any other headers if required
      },
      body: JSON.stringify(requestData), // Convert the data to JSON format
    };

    // Make the POST request to your API endpoint
    fetch(BASE_URL + `/getCurruntNumberList2`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // Assuming your API returns an object with prefix and next number properties
        setSalesorderNumberPrefix(data.responseMessage.prefix);
        setsalesorderNumberNext(data.responseMessage.numbers);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }; // Empty dependency array to fetch data once when the component mounts

  const [custNames, setCustNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(customerName);
  const [selectedCustomerId, setSelectedCustomerId] = useState(customerId); // State to store the selected customer's ID
  const [discountCheck, setDiscountCheck] = useState("");
  const [disAllValue, setDisAllValue] = useState("");
  const [discountType, setDiscountType] = useState("₹");
  const [disValue, setDisValue] = useState("");

  const calculateDiscount = () => {
    const amount = parseFloat(calculateSubTotal()).toFixed(2);

    if (discountType === "₹") {
      setDisValue(disAllValue);
    } else if (discountType === "%") {
      const percentageDiscount = (amount * disAllValue) / 100;
      setDisValue(percentageDiscount.toFixed(2));
    }
  };

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
  };

  const handleDisAllValueChange = (e) => {
    setDisAllValue(e.target.value);
  };

  const options = custNames.map((customer) => ({
    value: customer.id,
    label: customer.customerName,
  }));

  useEffect(() => {
    calculateDiscount();
  }, [disAllValue, discountType]);

  // Fetch customer data
  const fetchAllCustomers = () => {
    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };
  useEffect(() => {
    fetchAllCustomers();
  }, []);

  // Handle customer selection
  const handleCustomerChange = (value, id) => {
    const selectedValue = value;
    const selectedValueId = id;
    setSelectedCustomer(selectedValue);
    setSelectedCustomerId(selectedValueId);

    // Find the selected customer object in the fetched data
    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );

    if (selectedCustomerObject) {
      const customerState = selectedCustomerObject.billState;
      const customerTerms = selectedCustomerObject.paymentTerms;
      const custEmail = selectedCustomerObject.email;
      setCustomerEmail(custEmail);
      setCustomerState(customerState);
      setSelectedCustomerId(selectedCustomerObject.id);
      setSelectedTerms(customerTerms);
    } else {
      setSelectedTerms("");
    }
  };

  const [userState, setUserState] = useState([]);
  const [customerState, setCustomerState] = useState("");
  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");

  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userState = data.responseMessage;
          setUserDetail(data.responseMessage);
          setUserState(userState);
          setUserEmail(data.responseMessage.email);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [salesData, setSalesData] = useState({
    uniqueId: decryptedUniqueId,
    name: "",
    email: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSalesData({ ...salesData, [name]: value });
  };

  const handleEditorChange = (value) => {
    setTermsAndCondition(value); // Update the state with the content of the editor
  };
  const [validationMessage1, setValidationMessage1] = useState("");

  const handleSalesPerson = (event) => {
    let newValidationMessage = "";

    if (!salesData.name) {
      newValidationMessage += "Please enter a name.\n";
    }

    setValidationMessage1(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      // alert(newValidationMessage.trim()); // Trim trailing newline for better formatting
      return; // Exit function if validation fails
    }
    event.preventDefault();

    fetch(BASE_URL + "/createSalesperson", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(salesData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Sales Person Created ",
          });
          setShowSalesModal(false);
          fetchSalesData();
          closePopup();
        } else {
          swal({
            icon: "error",
            title: "Failed To Create Sales Person!",
          });
          setShowSalesModal(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occured While Creating",
        });
        setShowSalesModal(true);
      });
  };

  const handleSalesPersonAsDraft = (event) => {
    let newValidationMessage = "";

    if (!salesData.name) {
      newValidationMessage += "Please enter a name.\n";
    }

    if (!salesData.email) {
      newValidationMessage += "Please enter an email address.\n";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(salesData.email)) {
      newValidationMessage += "Please enter a valid email address.\n";
    }

    setValidationMessage1(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      // alert(newValidationMessage.trim()); // Trim trailing newline for better formatting
      return; // Exit function if validation fails
    }
    event.preventDefault();

    fetch(BASE_URL + "/createSalesperson", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(salesData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Sales Person Created",
          });
          setShowSalesModal(false);
          fetchSalesData();
          closePopup();
        } else {
          swal({
            icon: "error",
            title: "Failed To Create Sales Person!",
          });
          setShowSalesModal(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occured While Creating Sales Person",
        });
        setShowSalesModal(true);
      });
  };

  useEffect(() => {
    fetch(BASE_URL + `/fetchDiscountCheck`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage;
          setDiscountCheck(Data);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [selectedTerms, setSelectedTerms] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [dueDateChangedManually, setDueDateChangedManually] = useState(false);

  const handleSalesOrderDateChange = (e) => {
    const newDate = e.target.value;
    setCurrentDate(newDate);

    if (selectedTerms) {
      updateDueDate(new Date(newDate), selectedTerms);
    }
  };

  const optionPaymentTerms = [
    { value: "Net 15", label: "Net 15" },
    { value: "Net 30", label: "Net 30" },
    { value: "Net 45", label: "Net 45" },
    { value: "Net 60", label: "Net 60" },
    { value: "Due End Of The Month", label: "Due End Of The Month" },
    { value: "Due End Of Next Month", label: "Due End Of Next Month" },
    { value: "Due On Receipt", label: "Due On Receipt" },
    { value: "Custom", label: "Custom" },
  ];

  const handlePaymentTerms = (selectedOption) => {
    setSelectedTerms(selectedOption.value);
    updateDueDate(dateValue, selectedOption.value);
  };

  const handleChange = (event) => {
    // setSelectedOption(event.target.value);
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePaymentTermsChange = (e) => {
    const terms = e.target.value;
    setSelectedTerms(terms);

    if (currentDate && terms) {
      updateDueDate(new Date(currentDate), terms);
    }
  };

  const handleExpectedShipmentDateChange = (e) => {
    setDueDateChangedManually(true);
    setDueDate(new Date(e.target.value));
    updatePaymentTerms(new Date(e.target.value));
    // Add any validation or error handling for the expected shipment date here if needed
  };

  const [showDueDate, setShowDueDate] = useState(true);

  const updateDueDate = (date, terms) => {
    setDueDateChangedManually(false);
    if (terms.startsWith("Net ")) {
      const daysToAdd = parseInt(terms.replace("Net ", ""));
      setDueDate(normalDateFormat(addDays(startDate, daysToAdd)));
      setShowDueDate(true);
    } else if (terms === "Due End Of The Month") {
      setDueDate(normalDateFormat(endOfMonth(startDate)));
      setShowDueDate(true);
    } else if (terms === "Due End Of Next Month") {
      setDueDate(normalDateFormat(endOfMonth(addMonths(startDate, 1))));
      setShowDueDate(true);
    } else if (terms === "Due On Receipt") {
      setDueDate(normalDateFormat(startDate));
      setShowDueDate(true);
    } else if (terms === "Custom") {
      setShowDueDate(false);
    } else {
      setDueDate("");
    }
  };

  const updateDueDate1 = (date, terms) => {
    setDueDateChangedManually(false);
    if (terms.startsWith("Net ")) {
      const daysToAdd = parseInt(terms.replace("Net ", ""));
      setDueDate(normalDateFormat(addDays(date, daysToAdd)));
      setShowDueDate(true);
    } else if (terms === "Due End Of The Month") {
      setDueDate(normalDateFormat(endOfMonth(date)));
      setShowDueDate(true);
    } else if (terms === "Due End Of Next Month") {
      setDueDate(normalDateFormat(endOfMonth(addMonths(date, 1))));
      setShowDueDate(true);
    } else if (terms === "Due On Receipt") {
      setDueDate(normalDateFormat(date));
      setShowDueDate(true);
    } else if (terms === "Custom") {
      setShowDueDate(false);
    } else {
      setDueDate("");
    }
  };

  const [formData, setFormData] = useState({
    uniqueId: decryptedUniqueId,
    customerType: "Business",
    status: "Active",
    someName: "Mr.",
    firstName: "",
    lastName: "",
    companyName: "",
    customerName: "",
    email: "",
    workphone: "",
    mobile: "",
    designation: "",
    department: "",
    website: "",
    currency: "INR- Indian Rupee",
    openingBalance: "0",
    paymentTerms: "",
    facebook: "",
    twitter: "",
    billAttention: "",
    billCountry: "India",
    billAddress1: "",
    billAddress2: "",
    billCity: "",
    billState: "Maharashtra",
    billZipcode: "",
    billPhone: "",
    billFax: "",
    shipAttention: "",
    shipCountry: "India",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "Maharashtra",
    shipZipcode: "",
    shipPhone: "",
    shipFax: "",
    gstin: "",
    pan: "",
    placeOfSupply: "[MH] - Maharashtra",
    taxable: "",
    reason: "N/A",
    activeStatus: "Active",
    gstTreatment: "",
    contactPerson: [
      {
        other: "",
        otherFirstname: "",
        otherLastname: "",
        otherEmail: "",
        otherWorkphone: "",
        otherMobile: "",
      },
    ],
  });

  const updatePaymentTerms = (newDueDate) => {
    const daysDifference = differenceInDays(newDueDate, new Date());

    if (daysDifference === 15) {
      setSelectedTerms("Net 15");
    } else if (daysDifference === 30) {
      setSelectedTerms("Net 30");
    } else if (daysDifference === 45) {
      setSelectedTerms("Net 45");
    } else if (daysDifference === 60) {
      setSelectedTerms("Net 60");
    } else {
      setSelectedTerms("Custom");
    }
  };

  useEffect(() => {
    if (currentDate && selectedTerms && !dueDateChangedManually) {
      updateDueDate(new Date(currentDate), selectedTerms);
    }
  }, [currentDate, selectedTerms, dueDateChangedManually]);

  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());

  useEffect(() => {
    setStartDate(new Date());
    setStartDate1(new Date());
  }, []);

  const [dateValue, setDateValue] = useState("");
  const [dateValue1, setDateValue1] = useState("");

  const handleDate = (date) => {
    setStartDate(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue(normalFormat);
    updateDueDate1(date, selectedTerms);
  };

  const handleDate1 = (date) => {
    setStartDate1(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue1(normalFormat);
  };

  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return date;
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setFormData({});
  };
  const handleShow = () => setShow(true);
  const [validationMessage, setValidationMessage] = useState("");

  const handleSaveClick = () => {
    let newValidationMessage = "";

    if (!formData.customerName) {
      newValidationMessage += "Please enter a customer display name.\n";
    } else if (
      formData.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
    ) {
      newValidationMessage += "Please enter a valid email address.\n";
    }

    setValidationMessage(newValidationMessage);

    if (newValidationMessage) {
      return; // Exit function if validation fails
    }

    fetch(BASE_URL + "/createCustomer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          swal({
            icon: "success",
            title: "Customer Created",
            text: "",
          });
          setShow(false);
          fetchAllCustomers();
        } else if (data.responseCode === "400") {
          swal({
            icon: "error",
            title: "This email already exists, please insert another email.",
            text: "",
          });
          setShow(true);
        } else {
          swal({
            icon: "error",
            title: "Failed to Create Customer!",
            text: "",
          });
          setShow(true);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        setShow(true);
      });
  };

  const optionsSalesPerson = salesPersonData.map((person) => ({
    value: person.id,
    label: person.name,
  }));

  const [showSalesModal, setShowSalesModal] = useState(false);
  const handleSalesClose = () => setShowSalesModal(false);
  const handleSalesShow = () => setShowSalesModal(true);

  const [showItemModal, setShowItemModal] = useState(false);
  const [itemName, setItemName] = useState("");

  const [itemData, setItemData] = useState({
    uniqueId: decryptedUniqueId,
    description: "",
  });

  const handleItemChange = (event) => {
    const { name, value } = event.target;
    setItemData({ ...itemData, [name]: value });
  };

  const handleItemClose = () => {
    setShowItemModal(false);
    setItemData({
      ...itemData,
      description: "",
    });
  };

  const [itemDescriptions, setItemDescriptions] = useState([]);

  const handleItem = (event) => {
    event.preventDefault();

    const newItemDescription = {
      itemDetails: itemName,
      description: itemData.description,
    };

    setItemDescriptions((prevDescriptions) => {
      const existingIndex = prevDescriptions.findIndex(
        (desc) => desc.itemDetails === itemName
      );

      if (existingIndex !== -1) {
        const updatedDescriptions = [...prevDescriptions];
        updatedDescriptions[existingIndex] = newItemDescription;
        return updatedDescriptions;
      } else {
        return [...prevDescriptions, newItemDescription];
      }
    });

    setRow((prevRows) => {
      return prevRows.map((row) =>
        row.itemDetails === itemName
          ? { ...row, description2: itemData.description }
          : row
      );
    });

    setShowItemModal(false);
  };

  const handleItemShow = (itemDetails) => {
    const itemDescription = itemDescriptions.find(
      (desc) => desc.itemDetails === itemDetails
    );
    if (itemDescription) {
      setItemData({ description: itemDescription.description });
    } else {
      setItemData({ description: "" });
    }
    setItemName(itemDetails);
    setShowItemModal(true);
  };

  const customStyles3 = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      background: "#F6F6F6",
      borderRadius: "10px",
      // minHeight: "40px",
      height: "20px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginLeft: "8px",
      height: "20px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      // height: "40px",
    }),
  };
  const generateNamePairs = () => {
    const { someName, firstName, lastName, companyName } = formData;
    const pairs = [];

    if (firstName && lastName) {
      pairs.push(`${someName} ${firstName} ${lastName}`);
      pairs.push(`${firstName} ${lastName}`);
      pairs.push(`${lastName} , ${firstName}`);
      pairs.push(`${companyName}`);
    }

    return pairs;
  };

  useEffect(() => {
    if (selectedTcsOption && selectedTcsOption.discount) {
      const subtotal = calculateSubTotal();
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedTcsOption.discount
      );
    }
  }, [row]);

  const initialContent = `
  <div class="main-container" style="margin: 10px; padding: 10px; text-align: center; background-color: #4190f2;">
  <h2 style="margin: 10px; padding: 10px; text-align: center;">${salesOrderNumber}</h2>
</div>

<div class="content-container">
<h4 style="margin: 20px; padding: 20px;"> Dear  ${selectedCustomer} ,</h4>
<p style="margin: 20px; padding: 20px;" class="my-custom-paragraph">Thank you for your business. Your sales order can be viewed, printed and downloaded as PDF from the link below. You can also choose to pay it online.</p>
  <div class="card" style="border: 1px solid #e8deb5; border-radius: 3px; padding: 26px; margin: 26px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); width: 70%; margin: 0 auto;  background-color: #fefff1;">
      <h4 style="margin: 5px; padding: 5px; text-align: center;">SALES ORDER AMOUNT</h4>
      <h4 style=" text-align: center;">${calculateTotal()}</h4>
      <table style="width: 100%; text-align: center;">
      <tr>
          <td>Sales Order No</td>
          <td>${salesOrderNumber}</td>
      </tr>
      <tr>
          <td>Sales Order Date</td>
          <td>${dateValue || normalDateFormat(startDate)}</td>
      </tr>
      <tr>
          <td>Expected Shipment Date</td>
          <td>${showDueDate === true ? dueDate : dateValue1}</td>
      </tr>

     
  
  </table>
  <div style="text-align: center; padding-top:5px;">
  </div>

  </div>

    <div style=" padding: 10px; width: 80%; margin: 0 auto;">
      <h4>Regards</h3>
      <p>${userDetail.companyName}</p>
    </div>
</div>`;
  const handleMailSubmit = async () => {
    const itemDetails = row.map((data) => {
      return {
        item: data.itemDetails || "",
        quantity: data.quantity,
        rate: data.rate,
        discount: data.discount,
        discountType: data.discountType,
        tax: data.tax, // Assuming the tax rate is the same for all items
        amount: calculateAmount(data, selectedTaxRate),
        // rowDiscount: calculateDisRupees(data),
      };
    });
    // You can access the content of the editor here
    const postData = {
      to: custEmail,
      from: userEmail,
      subject: subject,
      body: editorContent,
      total: calculateTotal(),
      salesOrderNumber: salesOrderNumber,
      salesOrderDate: dateValue || normalDateFormat(startDate),
      dueDate: showDueDate === true ? dueDate : dateValue1,
      payButtonNow: "Pay Now",
      organizationName: userDetail.companyName,
      customerName: selectedCustomer,
      companyName: userDetail.companyName,
      terms: termsAndCondition,
      items: itemDetails,
      // name: bankData.name,
      // bank: bankData.bankName,
      // accountNo: bankData.accountNumber,
      // ifscCode: bankData.ifsc,
      // type: bankData.accountType,
      // totalInWords: "One Thousand Dollars",
      subTotal: calculateSubTotal(),
      adjustment: parseFloat(adjustmentValue) || 0,
      totalDue: calculateTotal(),
      // notes: invoiceDetails.customerNote,
      // state: state,
      address: userDetail.address1,
      imageName: "kunal.PNG",
      uniqueId: decryptedUniqueId,
    };
    setIsLoading3(true);
    const response = await fetch(BASE_URL + "/send-sales-email-with-pdf", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Mail Send Successfully",
          });
          handleInvoicesClick();
          setIsLoading3(false);
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading3(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        setIsLoading3(false);
      });
  };
  const fetchTermsData = () => {
    const postApiUrl = BASE_URL + `/getCheckedTermsAndCondition`;
    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseCode;
        // setDropdownData(responseData.map((item) => item));
        setTermsAndCondition(responseData.join("") || "");
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  return (
    <>
      {showPurchasePage && (
        <Row>
          <Col>
            {" "}
            <div className="my-3">
              <div className="d-flex align-items-center ml-1 mt-4">
                <div
                  className="cursor-pointer d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    height: "35px",
                    width: "38px",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIosIcon
                    width={2}
                    onClick={handleCancleClick}
                    className="arrow-child"
                  />
                </div>
                <div className="headitems ml-2">
                  <h5
                    className="ml-1 mb-0"
                    style={{ letterSpacing: "1px", color: "#086bd5de" }}
                  >
                    Create New Sales Order
                  </h5>
                </div>
              </div>
            </div>
            {validationMessage2 && (
              <Alert severity="error" className="mt-0">
                {validationMessage2.split("\n").map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </Alert>
            )}{" "}
            <div className="card border-2 mt-3">
              <div className="pl-4 pr-4">
                {/* <Card className="card-style p-4"> */}
                {/* <Card.Body> */}
                <div className="row pt-3">
                  <div className="inputchild">
                    <label
                      className="text-primary m-0"
                      style={{ fontSize: 16, fontWeight: "600" }}
                    >
                      CUSTOMER INFO
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="salespersonbox">
                    <div className="text-style">
                      Customer Name <span className="valid"> *</span>
                    </div>

                    <input
                      className="form-control mt-2"
                      placeholder="Customer Name"
                      type="text"
                      name="customerName"
                      value={customerName}
                      readOnly
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",
                        width: "250px",
                        borderRadius: "7px",
                        color: "black",
                        height: "38px",
                      }}
                    />
                  </div>

                  <div className="inputchild">
                    <div className="text-style">
                      Sales Order <span className="valid"> *</span>
                    </div>
                    <input
                      className="form-control"
                      placeholder="Sales Order Number"
                      type="text"
                      name="salesOrderNumber"
                      value={salesOrderNumber}
                      onChange={handleSalesNumberChange}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        color: "black",
                        height: "38px",
                      }}
                    />
                    <button
                      type="button"
                      className="btn p-0"
                      data-bs-toggle="modal"
                      data-bs-target="#generatePop"
                    >
                      <span
                        className="text-primary"
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        Generate
                      </span>
                    </button>

                    {/* Invoice generate popup*/}
                    <div
                      className="modal fade"
                      id="generatePop"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header d-flex align-items-center pb-2 pt-2">
                            <h5
                              className="modal-title fs-5"
                              id="generateinvoiceHead"
                            >
                              Configure Sales Order# Preferences
                            </h5>
                            <button
                              type="button"
                              className="btn pr-0"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <ion-icon
                                name="close-circle-outline"
                                size="large"
                                style={{ width: "28px" }}
                              ></ion-icon>
                            </button>
                          </div>
                          <div className="modal-body pt-1 pb-1">
                            <div className="popupinvoice-container ml-2 mt-2 mr-2 mb-0">
                              <div className="row headline">
                                <div className="customer-headline-label">
                                  <label className="form-label">
                                    <span className="headline">
                                      Your sales order numbers are set on
                                      auto-generate mode to save your time.
                                      <br /> Are you sure about changing this
                                      setting?
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <label className="customer-rdiobox-label d-flex align-items-center">
                                  <input
                                    className="mr-2"
                                    type="radio"
                                    name="cus_type"
                                    id="cus_bus"
                                    value="Business"
                                    onClick={setBill}
                                  />
                                  <span className="customer-option">
                                    Continue auto-generating sales order numbers
                                  </span>
                                </label>
                              </div>
                              {show1 && (
                                <div className="row ml-3 mb-3">
                                  <div className="customer-generate-inputfield">
                                    <span>Prefix</span>
                                    <input
                                      className="form-control mt-1"
                                      placeholder="Prefix"
                                      value={salesorderNumberPrefix}
                                      onChange={(e) =>
                                        setSalesorderNumberPrefix(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="customer-generate-inputfield2">
                                    <span>Next Number</span>
                                    <input
                                      className="form-control mt-1"
                                      placeholder="Next Number"
                                      value={salesorderNumberNext}
                                      onChange={(e) =>
                                        setsalesorderNumberNext(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="row mt-1">
                                <label className="customer-rdiobox-label d-flex align-items-center">
                                  <input
                                    className="mr-2"
                                    type="radio"
                                    name="cus_type"
                                    id="cus_bus"
                                    value="Business"
                                    onClick={() => {
                                      setShow1(false);
                                    }}
                                  />
                                  <span className="customer-option">
                                    Enter sales order numbers manually
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => {
                                copyAdd();
                              }}
                              data-bs-dismiss="modal"
                            >
                              Save changes
                            </button> */}
                            <div className="modal-bottombtns">
                              <button
                                type="button"
                                class="modalbtn-1 btn mt-0"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                class="modalbtn-3 btn btn-primary"
                                onClick={() => {
                                  copyAdd();
                                }}
                                data-bs-dismiss="modal"
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="inputchild">
                    <div className="text-style">Reference</div>
                    <input
                      className="form-control"
                      placeholder="Reference Number"
                      name="reference"
                      value={reference}
                      onChange={(e) => setReference(e.target.value)}
                      type="text"
                      style={{
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        padding: "12px",
                        color: "black",
                        height: "38px",
                        "::placeholder": {
                          color: "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                        },
                      }}
                    ></input>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="inputchild">
                    <div className="text-style">
                      Expected Shipment Date <span className="valid"> *</span>
                    </div>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        dateFormat="dd-MM-yyyy"
                        onChange={(date) => handleDate(date)}
                        style={{
                          padding: "12px",
                          backgroundColor: "#F6F6F6",
                          borderRadius: "10px",
                          color: "black",
                        }}
                      />
                    </div>
                  </div>
                  <div className="salespersonbox">
                    <div className="text-style">Payment Terms</div>
                    <Select
                      className="mt-0"
                      options={optionPaymentTerms}
                      value={optionPaymentTerms.find(
                        (option) => option.value === selectedTerms
                      )}
                      onChange={handlePaymentTerms}
                      placeholder="Select Terms"
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: "250px",
                        }),
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",
                          borderRadius: "7px",
                          marginTop: "10px",
                          height: "46px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          height: "19px",
                        }),
                      }}
                    />
                  </div>

                  <div className="inputchild">
                    <div className="text-style">
                      Due Date <span className="valid"> *</span>
                    </div>
                    <div>
                      {showDueDate ? (
                        <input
                          className="form-control"
                          type="text"
                          value={dueDate}
                          onClick={() => {
                            setShowDueDate(false);
                            setSelectedTerms("Custom");
                          }}
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            padding: "12px",
                            height: "40px",
                            width: "260.8px",
                          }}
                          readOnly
                        />
                      ) : (
                        <div className="customDatePickerWidth">
                          <DatePicker
                            className="form-control"
                            selected={startDate1}
                            dateFormat="dd-MM-yyyy"
                            onChange={(date) => handleDate1(date)}
                            placeholderText="Select due date"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="salespersonbox">
                    <div className="text-style">Salesperson</div>
                    <Select
                      options={optionsSalesPerson}
                      value={optionsSalesPerson.find(
                        (option) => option.value === selectedSalesPerson
                      )}
                      onChange={(selectedOption) =>
                        handleSalesPersonChange(
                          selectedOption.label,
                          selectedOption.value
                        )
                      }
                      placeholder="Select a SalesPerson"
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          marginTop: "10px",
                          width: "250px",
                        }),
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",
                          borderRadius: "9px",
                          height: "46px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          height: "19px",
                        }),
                      }}
                    />
                    <button
                      type="button"
                      class="btn p-0"
                      onClick={handleSalesShow}
                    >
                      <span
                        class="text-primary"
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        New Sales Person
                      </span>
                    </button>

                    <Modal show={showSalesModal} onHide={handleSalesClose}>
                      <div class="modal-header d-flex align-items-center pb-2 pt-2">
                        <h5 class="modal-title fs-5" id="salespersonHead">
                          Add Sales Person
                        </h5>
                        <button
                          type="button"
                          className="btn pr-0"
                          onClick={handleSalesClose}
                        >
                          <ion-icon
                            name="close-circle-outline"
                            size="large"
                            style={{ width: "28px" }}
                          ></ion-icon>
                        </button>
                      </div>
                      <Modal.Body>
                        {validationMessage1 && (
                          <Alert severity="error" className="mt-0">
                            {validationMessage1
                              .split("\n")
                              .map((message, index) => (
                                <div key={index}>{message}</div>
                              ))}
                          </Alert>
                        )}{" "}
                        <div className="row pt-2">
                          <div className="modalsalesinput">
                            <label className="form-label">
                              <span style={{ color: "red" }}>Name *</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Name"
                              id="name"
                              name="name"
                              type="text"
                              value={salesData.name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="row pt-4">
                          <div className="modalsalesinput">
                            <label className="form-label">
                              <span>Email </span>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Email"
                              id="email"
                              name="email"
                              type="text"
                              value={salesData.email}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="p-2">
                        <div className="modal-bottombtns">
                          <button
                            type="button"
                            class="modalbtn-1 btn mt-0"
                            onClick={handleSalesClose}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="modalbtn-3 btn btn-primary"
                            onClick={handleSalesPerson}
                            data-bs-dismiss="modal"
                          >
                            Create Sales Person
                          </button>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="salespersonbox">
                    <div className="text-style">Tracking No.</div>
                    <input
                      className="form-control mt-2"
                      placeholder="Tracking No"
                      type="text"
                      name="inquiryId"
                      value={enqNo}
                      readOnly
                      style={{
                        padding: "12px",
                        width: "250px",
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        color: "black",
                        height: "38px",
                      }}
                    />
                  </div>
                </div>

                <Row className="mt-4">
                  <Col md="3">
                    <label
                      className="text-primary"
                      style={{ fontSize: 14, fontWeight: "600" }}
                    >
                      ITEM INFO
                    </label>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Card className="mb-3">
                      <div className="" style={{ overflowX: "auto" }}>
                        <table
                          className="table table-bordered mb-0"
                          style={{
                            width: "100%",
                          }}
                        >
                          <thead className="text-center">
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "350px",
                                }}
                              >
                                ITEM DETAILS
                              </th>
                              <th
                                scope="col"
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "100px",
                                }}
                              >
                                ITEM CODE
                              </th>
                              {discountCheck.hsnCheck === "YES" && (
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  HSN/SAC
                                </th>
                              )}
                              {discountCheck.sizeCheck === "YES" && (
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  SIZE
                                </th>
                              )}
                              <th
                                scope="col"
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "180px",
                                }}
                              >
                                PER
                              </th>
                              {discountCheck.quantityCheck === "YES" && (
                              <th
                                scope="col"
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "180px",
                                }}
                              >
                                QUANTITY
                              </th>
                              )}
                              {discountCheck.netRateCheck === "YES" && (
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  RATE
                                </th>
                              )}
                              <th
                                scope="col"
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "100px",
                                }}
                              >
                                {discountCheck.netRateCheck === "YES"
                                  ? "NET RATE"
                                  : "RATE"}
                              </th>
                              {discountCheck.packCheck === "YES" && (
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  PACK
                                </th>
                              )}
                              <th
                                scope="col"
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "100px",
                                }}
                              >
                                MAKE
                              </th>
                              {discountCheck.discountCheck === "YES" && (
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  DISCOUNT
                                </th>
                              )}{" "}
                              {bussinessRegistered === "YES" && (
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  TAX
                                </th>
                              )}
                              <th
                                scope="col"
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "50px",
                                }}
                              >
                                AMOUNT
                              </th>
                              <th
                                scope="col"
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "50px",
                                }}
                              >
                                ACTION
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {row.map((data, i) => {
                              const amount = calculateAmount(data, data.tax);
                              const totalAmountWithTax = parseFloat(amount);
                              const itemDescription = itemDescriptions.find(
                                (desc) => desc.itemDetails === data.itemDetails
                              );
                              return (
                                <tr key={i}>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        className="form-control noBorder"
                                        placeholder="Search items..."
                                        value={data.itemDetails}
                                        onChange={(e) =>
                                          handleItemDetailsChange(e, i)
                                        }
                                        style={{ flex: 1 }}
                                      />
                                      {data.itemDetails && (
                                        <button
                                          type="button"
                                          className="btn p-0"
                                          onClick={() =>
                                            handleItemShow(data.itemDetails)
                                          }
                                          style={{ marginLeft: "10px" }}
                                        >
                                          <span
                                            className="text-primary"
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            Edit
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                    {itemDescription && (
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          color: "#6c757d",
                                          whiteSpace: "pre-line",
                                        }}
                                      >
                                        {itemDescription.description}
                                      </div>
                                    )}
                                    {searchPerformed &&
                                      data.itemDetails &&
                                      i === searchIndex && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            zIndex: 9999, // Higher than scrollbar
                                            top: "100%", // Position below input
                                            left: 0,
                                            width: "30%",
                                          }}
                                        >
                                          {searchResults.length === 0 && ""}
                                          {searchResults.length > 0 && (
                                            <ul
                                              style={{
                                                listStyleType: "none",
                                                padding: 0,
                                                position: "absolute",
                                                top: "calc(100% + 10px)",
                                                left: 0,
                                                zIndex: 1000,
                                                backgroundColor: "#FFF",
                                                boxShadow:
                                                  "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                                borderRadius: "5px",
                                                width: "100%",
                                                maxHeight: "200px", // Set a max height for the list
                                                overflowY: "auto", // Enable vertical scrolling
                                              }}
                                            >
                                              {searchResults.map(
                                                (result, index) => (
                                                  <li
                                                    key={index}
                                                    onClick={() =>
                                                      handleItemSelect(
                                                        result.name,
                                                        result.id,
                                                        i
                                                      )
                                                    }
                                                    onMouseEnter={(e) => {
                                                      e.target.style.backgroundColor =
                                                        "#408dfb";
                                                      e.target.style.color =
                                                        "#FFF";
                                                      e.target.style.borderRadius =
                                                        "5px"; // Apply border radius on hover
                                                    }}
                                                    onMouseLeave={(e) => {
                                                      e.target.style.backgroundColor =
                                                        "";
                                                      e.target.style.color =
                                                        "#000";
                                                      e.target.style.borderRadius =
                                                        ""; // Reset border radius
                                                    }}
                                                    style={{
                                                      padding: "12px",
                                                      cursor: "pointer",
                                                      whiteSpace: "nowrap", // Prevent text from overflowing
                                                      overflow: "hidden", // Hide overflow text
                                                      textOverflow: "ellipsis", // Add ellipsis for overflowing text
                                                    }}
                                                  >
                                                    {result.name}
                                                    <br />
                                                    Rate : ₹
                                                    {result.taxableAmount}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          )}
                                        </div>
                                      )}
                                  </td>

                                  <Modal
                                    show={showItemModal}
                                    onHide={handleItemClose}
                                  >
                                    <div class="modal-header d-flex align-items-center pb-2 pt-2">
                                      <h5
                                        class="modal-title fs-5"
                                        id="salespersonHead"
                                      >
                                        Add Item Description
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn pr-0"
                                        onClick={handleItemClose}
                                      >
                                        <ion-icon
                                          name="close-circle-outline"
                                          size="large"
                                          style={{ width: "28px" }}
                                        ></ion-icon>
                                      </button>
                                    </div>
                                    <Modal.Body>
                                      <Row>
                                        <Col md="12">
                                          <div className="row pt-2">
                                            <div className="col-lg-6">
                                              <label
                                                className="form-label"
                                                style={{
                                                  fontSize: "14px",
                                                  color: "grey",
                                                  borderRadius: "7px",
                                                }}
                                              >
                                                Item
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-lg-12 pl-0 pr-0">
                                            <input
                                              className="form-control"
                                              placeholder="Name"
                                              id="name"
                                              name="name"
                                              type="text"
                                              value={itemName}
                                              readOnly
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md="12">
                                          <div className="row pt-2">
                                            <div className="col-lg-6">
                                              <label
                                                className="form-label"
                                                style={{
                                                  fontSize: "14px",
                                                  color: "grey",
                                                  borderRadius: "7px",
                                                }}
                                              >
                                                Description
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-lg-12 pl-0 pr-0">
                                            <textarea
                                              className="cn-textarea"
                                              type="text"
                                              placeholder="Item Description"
                                              name="description"
                                              value={itemData.description}
                                              onChange={handleItemChange}
                                              rows={2}
                                              style={{
                                                backgroundColor: "#F6F6F6",
                                                borderRadius: "9px",
                                                padding: "12px",
                                                width: "100%",
                                              }}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </Modal.Body>
                                    <Modal.Footer className="p-2">
                                      <div className="modal-bottombtns">
                                        <button
                                          type="button"
                                          class="modalbtn-1 btn mt-0"
                                          onClick={handleItemClose}
                                        >
                                          Close
                                        </button>
                                        <button
                                          type="button"
                                          class="modalbtn-3 btn btn-primary"
                                          onClick={handleItem}
                                          data-bs-dismiss="modal"
                                        >
                                          Add Description
                                        </button>
                                      </div>
                                    </Modal.Footer>
                                  </Modal>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <input
                                      className="form-control noArrowNumber noBorder"
                                      value={data.itemCode || ""}
                                      name="itemCode"
                                      type=""
                                      placeholder="itemCode"
                                      onChange={(e) =>
                                        handleItemCodeChange(e, i)
                                      }
                                    />
                                  </td>
                                  {discountCheck.hsnCheck === "YES" && (
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.hsnSac || ""}
                                        name="hsnSac"
                                        type=""
                                        placeholder="hsnSac"
                                        onChange={(e) =>
                                          handleHsnSacChange(e, i)
                                        }
                                      />
                                    </td>
                                  )}

                                  {discountCheck.sizeCheck === "YES" && (
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.size || ""}
                                        name="size"
                                        type=""
                                        placeholder="size"
                                        onChange={(e) => handleSizeChange(e, i)}
                                      />
                                    </td>
                                  )}

                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <div className="d-flex">
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.per || ""}
                                        type="number"
                                        name="per"
                                        readOnly
                                        style={{ backgroundColor: "white" }}
                                        // onChange={(e) =>
                                        //   handleQuantityChange(e, i)
                                        // }
                                      />
                                      {data.alternateUnit &&
                                        data.alternateUnitValue && (
                                          <input
                                            className="form-control px-1"
                                            style={{}}
                                            value={`${data.alternateUnitValue} ${data.alternateUnit}`}
                                            type="text"
                                            readOnly
                                          />
                                        )}
                                    </div>
                                  </td>
                                  {discountCheck.quantityCheck === "YES" && (
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <div className="d-flex">
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.quantity || ""}
                                        type="number"
                                        name="quantity"
                                        onChange={(e) =>
                                          handleQuantityChange(e, i)
                                        }
                                      />
                                      {data.alternateUnit &&
                                        data.alternateUnitValue && (
                                          <input
                                            className="form-control px-1"
                                            style={{}}
                                            value={`${data.calculatedAltUnitValue} ${data.alternateUnit}`}
                                            type="text"
                                            readOnly
                                          />
                                        )}
                                    </div>
                                  </td>
                                  )}
                                  {discountCheck.netRateCheck === "YES" && (
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.rate1}
                                        type="number"
                                        onChange={(e) =>
                                          handleRateChange1(e, i)
                                        }
                                      />
                                    </td>
                                  )}
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <input
                                      className="form-control noArrowNumber noBorder"
                                      value={data.rate}
                                      type="number"
                                      onChange={(e) => handleRateChange(e, i)}
                                    />
                                  </td>
                                  {discountCheck.packCheck === "YES" && (
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.pack}
                                        type=""
                                        placeholder="pack"
                                        onChange={(e) => handlePackChange(e, i)}
                                      />
                                    </td>
                                  )}
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <input
                                      className="form-control noArrowNumber noBorder"
                                      value={data.make || ""}
                                      name="make"
                                      type=""
                                      placeholder="make"
                                      onChange={(e) => handleMakeChange(e, i)}
                                    />
                                  </td>
                                  {discountCheck.discountCheck === "YES" && (
                                    <td
                                      style={{ width: "10%", padding: "0px" }}
                                    >
                                      <div className="d-flex justify-content-center">
                                        <input
                                          className="form-control col-md-6 noBorder"
                                          value={data.discount || ""}
                                          type="number"
                                          onChange={(e) =>
                                            handleDiscountChange(e, i)
                                          }
                                          name="discount"
                                          style={{
                                            textAlign: "center",
                                            padding: "0px",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <select
                                          className="border-0 noBorder"
                                          value={data.discountType}
                                          onChange={(e) =>
                                            handleDiscountChange(e, i)
                                          }
                                          name="discountType"
                                        >
                                          <option>₹</option>
                                          <option>%</option>
                                        </select>
                                      </div>
                                    </td>
                                  )}
                                  {bussinessRegistered === "YES" && (
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <select
                                        className="form-control"
                                        value={data.tax}
                                        onChange={(e) => handleTaxChange(e, i)}
                                      >
                                        <option value="0">N/A</option>
                                        <option value="5">5 GST [5%]</option>
                                        <option value="12">12 GST [12%]</option>
                                        <option value="18">18 GST [18%]</option>
                                        <option value="28">28 GST [28%]</option>
                                      </select>
                                    </td>
                                  )}
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <span>
                                      {parseFloat(totalAmountWithTax).toFixed(
                                        2
                                      )}
                                    </span>
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <button
                                      className="btn px-2 btn-light icon-border btn-sm"
                                      onClick={() => handleDeleteRow(i)}
                                    >
                                      <i className="fa fa-trash text-danger"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Card>
                  </Col>
                </Row>

                {/* endtable */}

                <Row>
                  <Col>
                    <button className="btn addline-btn" onClick={handleAdd}>
                      + Add Another Line
                    </button>
                  </Col>
                </Row>

                <div className="grid-container">
                  <div className="flex-item d-flex flex-column-reverse p-0">
                    <div className="row pt-3">
                      <div className="col-lg-6">
                        <label
                          className="form-label required mg-b-0"
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          Customer Notes
                        </label>
                      </div>
                      <div className="col-lg-12">
                        <textarea
                          className="cn-textarea"
                          placeholder="Customer Notes..."
                          name="customerNote"
                          value={customerNote || "Thank You For Business."}
                          onChange={(e) => setCustomerNote(e.target.value)}
                          rows={1}
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "9px",
                            padding: "12px",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="customer-subtotal-box p-1">
                    <div className="flex-item">
                      <div className="row">
                        <div className="col-lg-8">
                          <label className="form-label">Sub Total</label>
                        </div>
                        <div className="col-lg-4 text-end pl-0">
                          <label className="form-label">
                            {calculateSubTotal()}
                          </label>
                        </div>
                      </div>
                      {discountCheck.discountCheck === "YES" && (
                        <div className="row d-flex align-items-baseline mt-1">
                          <div className="col-lg-5 pr-0">
                            <label>Discount</label>
                          </div>
                          <div className="col-lg-3 pl-0 d-flex">
                            <input
                              className="form-control"
                              value={disAllValue || "0"}
                              type="number"
                              name="discount"
                              onChange={handleDisAllValueChange}
                              style={{
                                height: "36px",
                                padding: "3px 8px",
                                backgroundColor: "#F6F6F6",
                              }}
                            />
                            <select
                              className="form-control ml-1 pl-0 rounded"
                              name="discountType"
                              value={discountType}
                              onChange={handleDiscountTypeChange}
                              style={{
                                height: "36px",
                                width: "50px",
                                padding: "3px 8px",
                                backgroundColor: "#F6F6F6",
                              }}
                            >
                              <option disabled>Select</option>
                              <option value="₹">₹</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                          <div className="col-lg-4 text-end pl-0">
                            <label className="form-label textColor-red">
                              {isNaN(parseFloat(disValue).toFixed(2))
                                ? "0.00"
                                : -parseFloat(disValue).toFixed(2)}
                            </label>
                          </div>
                        </div>
                      )}

                      {bussinessRegistered === "YES" && (
                        <div className="row mt-2">
                          {userState.state !== customerState && (
                            <>
                              {calculateIGSTForTax().map((taxResult, index) => (
                                <React.Fragment key={index}>
                                  <div className="col-lg-8">
                                    <label className="form-label required">
                                      IGST (
                                      {(taxResult.taxRate * 100).toFixed(0)}%)
                                    </label>
                                  </div>
                                  <div className="col-lg-4 text-end pl-0">
                                    <label className="form-label required">
                                      {taxResult.totalIGST.toFixed(2)}
                                    </label>
                                  </div>
                                </React.Fragment>
                              ))}
                            </>
                          )}

                          {userState.state === customerState && (
                            <>
                              {Object.entries(
                                calculateGroupedSGSTandCGST()
                              ).map(([taxRate, taxData], index) => (
                                <React.Fragment key={index}>
                                  <div className="col-lg-8">
                                    <label className="form-label required">
                                      CGST (
                                      {((parseFloat(taxRate) * 100) / 2)
                                        .toFixed(1)
                                        .replace(/\.0$/, "")}
                                      %)
                                    </label>
                                  </div>
                                  <div className="col-lg-4 text-end pl-0">
                                    <label className="form-label required">
                                      {taxData.totalCGST.toFixed(2)}
                                    </label>
                                  </div>
                                  <div className="col-lg-8 mt-2">
                                    <label className="form-label required">
                                      SGST (
                                      {((parseFloat(taxRate) * 100) / 2)
                                        .toFixed(1)
                                        .replace(/\.0$/, "")}
                                      %)
                                    </label>
                                  </div>
                                  <div className="col-lg-4 text-end pl-0 mt-2">
                                    <label className="form-label required">
                                      {taxData.totalSGST.toFixed(2)}
                                    </label>
                                  </div>
                                </React.Fragment>
                              ))}
                            </>
                          )}
                        </div>
                      )}
                      <div className="row mt-3 mb-3">
                        <div className="col-lg-5 pr-0">
                          <input
                            className="form-control col-md-10"
                            type="text"
                            placeholder="Adjustment Label"
                            value={adjustmentLabel}
                            onChange={(e) => setAdjustmentLabel(e.target.value)}
                            name="adjustmentLabel"
                            style={{
                              backgroundColor: "#F6F6F6",
                              borderRadius: "10px",
                              color: "black",
                              "::placeholder": {
                                color:
                                  "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                              },
                            }}
                          />
                        </div>
                        <div className="col-lg-5 pl-0">
                          <input
                            className="form-control col-md-10"
                            type="text"
                            placeholder="Adjustment Value"
                            value={adjustmentValue}
                            onChange={(e) => setAdjustmentValue(e.target.value)}
                            style={{
                              backgroundColor: "#F6F6F6",
                              borderRadius: "10px",
                              color: "black",
                              "::placeholder": {
                                color:
                                  "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                              },
                            }}
                          />
                        </div>
                        <div className="col-lg-2 text-end pl-0">
                          <label className="form-label mt-2">
                            {oppositeValue}
                          </label>
                        </div>
                      </div>

                      <div className="row d-flex align-items-baseline mb-3">
                        <div className="col-lg-5 pr-0">
                          <label className="form-label">TCS</label>
                        </div>
                        <div className="col-lg-5 pl-0">
                          <Select
                            className="p-0 col-md-10"
                            options={tcs}
                            value={selectedTcsOption}
                            onChange={handleTcsOptionChange}
                            placeholder="Select TCS..."
                            styles={customStyles3}
                          />
                        </div>
                        <div className="col-lg-2 text-end pl-0">
                          <label className="form-label mt-2">
                            {discountAmountTcs}
                          </label>
                        </div>
                      </div>

                      <hr className="mt-0" />

                      <div className="row mt-3">
                        <div className="col-lg-8">
                          <label className="form-label required ">
                            Total (₹) :
                          </label>
                        </div>
                        <div className="col-lg-4 text-end pl-0">
                          <label className="form-label required">
                            {calculateTotal()}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="mt-4" />

                <div className="row pt-2 align-items-center">
                  <div className="col-lg-6">
                    <label
                      className="form-label required mg-b-0"
                      style={{ fontSize: "14px", color: "grey" }}
                    >
                      Terms & Condition
                    </label>
                  </div>
                  <div className="col-lg-12">
                    {/* <textarea
                      className="invoicetextarea"
                      placeholder="Add your terms & condition"
                      name="termsAndCondition"
                      value={termsAndCondition}
                      onChange={(e) => setTermsAndCondition(e.target.value)}
                      rows={4}
                      style={{
                        backgroundColor: "#F6F6F6",
                        borderRadius: "9px",
                        padding: "12px",
                        height: "100%",
                      }}
                    /> */}
                    <ReactQuill
                      value={termsAndCondition || ""}
                      onChange={handleEditorChange} // Function to update state
                      theme="snow" // Use "bubble" for a minimal UI
                      style={{ height: "300px", marginBottom: "50px" }}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
                          [{ font: [] }], // Font options
                          [{ size: [] }], // Font size
                          [{ color: [] }, { background: [] }], // Text color & background
                          ["bold", "italic", "underline", "strike"], // Basic text styles
                          [{ script: "sub" }, { script: "super" }], // Subscript & superscript
                          [{ align: [] }], // Text alignment
                          [{ list: "ordered" }, { list: "bullet" }], // Lists
                          [{ indent: "-1" }, { indent: "+1" }], // Indentation
                          ["blockquote", "code-block"], // Blockquote & code block
                          ["link", "image", "video"], // Media links
                          ["clean"], // Remove formatting
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "color",
                        "background",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "script",
                        "align",
                        "list",
                        "bullet",
                        "indent",
                        "blockquote",
                        "code-block",
                        "link",
                        "image",
                        "video",
                      ]}
                    />
                    <i className="text-muted">
                      This Terms and Condition will be displayed on the sales
                      order
                    </i>
                  </div>
                </div>

                {/* </Card.Body> */}
                {/* </Card> */}
              </div>
              <div
                className="text-right mt-4 mb-4"
                style={{ marginRight: "1.5rem" }}
              >
                <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
                  Cancel
                </button>
                <button
                  className="btn-1 btn mt-0"
                  onClick={insertDataIntoDatabaseAsDraft}
                >
                  {isLoading2 ? "Saving..." : "Save as Draft"}
                </button>
                <button
                  className="btn-3 btn btn-primary"
                  onClick={insertDataIntoDatabase}
                >
                  {isLoading ? "Saving..." : "Create Sales"}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      )}

      {showMailPage && (
        <>
          <div className="d-flex align-items-center ml-1 mt-4">
            <div
              className="cursor-pointer d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                height: "35px",
                width: "38px",
                cursor: "pointer",
              }}
            >
              <ArrowBackIosIcon
                width={2}
                onClick={handleInvoicesClick}
                className="arrow-child"
              />
            </div>
            <div className="headitems ml-2">
              <h5
                className="ml-1 mb-0"
                style={{ letterSpacing: "1px", color: "#086bd5de" }}
              >
                Mail
              </h5>
            </div>
          </div>
          <div
            className="profit-loss-section my-3 mx-0"
            style={{
              padding: "30px",
              backgroundColor: "#fff",
              borderRadius: "9px",
            }}
          >
            <br />
            <div className="radio row mg-t-15 pt-1 align-items-center mg-b-20">
              <div className="col-md-1">
                <label className="form-label">From</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="from"
                  id=""
                  name="from"
                  type="text"
                  value={userEmail}
                  onChange={handleUserEmail}
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-10  align-items-left mg-b-20">
              <div className="col-md-1">
                <label className="form-label">Send To</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="Send To"
                  id=""
                  name="to"
                  type="text"
                  value={custEmail}
                  onChange={handleCustomerEmail}
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-15 align-items-center mg-b-20">
              <div className="col-md-1">
                <label className="form-label">CC</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="CC"
                  id=""
                  name=""
                  type="text"
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-15  align-items-center">
              <div className="col-md-1">
                <label className="form-label">Subject</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="Subject"
                  id=""
                  value={subject}
                  name="subject"
                  type="text"
                  onChange={handleSubjectChange}
                />
              </div>
            </div>
            <br />
            <div className="radio col-md-12 row mg-t-15 align-items-center">
              <div className="col-md-1 pl-0">
                {/* <label className="form-label">
   <b>Subject : </b>
 </label> */}
              </div>
              <div className="col-md-10 pl-0">
                <div className="body">
                  <TinyMceEditor
                    onChange={setEditorContent}
                    initialContent={initialContent}
                  />

                  <div className="mx-10 mt-4">
                    <button
                      className="btn-1 btn mt-0"
                      onClick={handleInvoicesClick}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn-3 btn btn-primary"
                      onClick={handleMailSubmit}
                    >
                      {isLoading3 ? "Sending..." : "Send"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SalesOrderNew;
