import React, { useState, useEffect } from "react";
import "./popup.css";
import { addDays, endOfMonth, addMonths } from "date-fns";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import { differenceInDays } from "date-fns";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, Form, Row, Col } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function PurchaseOrderNew({ navigation }) {
  const [row, setRow] = useState([
    {
      itemDetails: "",
      account: "",
      size: "",
      per:"1",
      quantity: "1",
      rate: "0.0",
      discount: "0.0",
      tax: "0",
      discountType: "₹",
      description1: "",
      description2: "",
    },
  ]);
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");

  const handleAdd = () => {
    const newItem = {
      itemDetails: "",
      account: "",
      hsnSac: "",
      per:"1",
      quantity: "1",
      rate: "0.0",
      discount: "0.0",
      discountType: "₹",
    };
    const newRow = [...row, newItem];
    setRow(newRow);
  };

  const handleTaxChange = (e, index) => {
    setSelectedTaxRate(e.target.value);
    const newTaxRate = parseFloat(e.target.value);
    const updatedRow = [...row];
    updatedRow[index].tax = newTaxRate;
    setRow(updatedRow);
  };

  const handleDeleteRow = (i) => {
    const updatedRow = [...row];
    updatedRow.splice(i, 1);
    setRow(updatedRow);
    updateTotal(updatedRow);
  };
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handlePurchaseClick = () => {
    handleNavigation("purchaseOrder");
  };
  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      handlePurchaseClick();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  const [show1, setShow1] = useState(false);
  const [manualCheck, setManualCheck] = useState("No");
  const [bussinessRegistered, setBussinessRegistered] = useState("");
  const [discountCheck, setDiscountCheck] = useState("");
  const [quantityCheck,setQuantityCheck] = useState("");
  const [sizeCheck, setSizeCheck] = useState("");

  const handleDiscountChange = (e, i) => {
    const { name, value } = e.target;
    const newValues =
      name === "discount" ? { discount: value } : { discountType: value };
    const newRow = [...row];
    newRow[i] = { ...newRow[i], ...newValues };
    setRow(newRow);
    updateTotal(newRow);
  };

  const handleSizeChange = (e, i) => {
    const newQuantity = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], size: newQuantity };
    setRow(newRow);
    updateTotal(row);
  };

  const handleQuantityChange = (e, i) => {
    const newQuantity = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], quantity: newQuantity };
    setRow(newRow);
    updateTotal(row);
  };

  const handleRateChange = (e, i) => {
    const newRate = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], rate: newRate };
    setRow(newRow);
    updateTotal(row);
  };

  const updateTotal = (rowData) => {
    let calculatedTotal = 0;
    rowData.forEach((data) => {
      const amount = calculateAmount(data);
      calculatedTotal += parseFloat(amount);
    });
    setTotal(calculatedTotal.toFixed(2));
  };

  const [total, setTotal] = useState(0);

  const calculateAmount = (data, tax) => {
    const { quantity, rate, discount, discountType } = data;
    let amount = quantity * rate;

    if (discount) {
      amount -= discountType === "%" ? (amount * discount) / 100 : discount;
    }

    return amount;
  };

  const [selectedTaxRate, setSelectedTaxRate] = useState(0);
  const calculateSubTotal = () => {
    let subTotal = 0;
    row.forEach((data) => {
      const amount = calculateAmount(data);
      subTotal += parseFloat(amount);
    });
    return subTotal.toFixed(2);
  };

  const [discountAmountTcs, setDiscountAmountTcs] = useState(0.0);

  const applyDiscountTcs = (subtotal, discountPercentage) => {
    const discountAmount = (subtotal * discountPercentage) / 100;
    const tcsDis = (subtotal - discountAmount).toFixed(2);
    setDiscountAmountTcs(parseFloat(discountAmount.toFixed(2))); // Set discountAmount in state

    return tcsDis;
  };

  const handleTcsOptionChange = (selectedOption) => {
    setSelectedTcsOption(selectedOption);
    const subtotal = calculateSubTotal();

    // Check if a TDS option is selected and has a discount
    if (selectedOption && selectedOption.discount) {
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedOption.discount
      );
      console.log(
        `Subtotal: ${subtotal}, Discounted Total: ${discountedTotal}`
      );
    }
  };

  // => IGST - 1
  const calculateIGST = () => {
    let totalIGST = 0;

    row.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      if (taxRate > 0) {
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = (parseFloat(amount) * taxRate) / 100;
        totalIGST += increasedTaxAmount;
      }
    });

    return totalIGST.toFixed(2);
  };

  const calculateIGSTForTax = () => {
    const taxResults = [];

    row.forEach((data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate > 0) {
        let existingTaxResult = taxResults.find(
          (result) => result.taxRate === taxRate
        );
        if (!existingTaxResult) {
          existingTaxResult = { taxRate, totalIGST: 0 };
          taxResults.push(existingTaxResult);
        }
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = amount * taxRate;
        existingTaxResult.totalIGST += increasedTaxAmount;
      }
    });
    return taxResults;
  };

  const calculateSGSTandCGST = () => {
    let totalSGST = 0;
    let totalCGST = 0;

    row.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = (parseFloat(amount) * taxRate) / 100;
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;
      totalSGST += sgst;
      totalCGST += cgst;
    });
    return {
      totalSGST: totalSGST.toFixed(2),
      totalCGST: totalCGST.toFixed(2),
    };
  };

  const calculateGroupedSGSTandCGST = () => {
    const taxGroups = row.reduce((acc, data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate === 0) return acc; // Skip 0% GST

      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = amount * taxRate;
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;

      if (!acc[taxRate]) {
        acc[taxRate] = { totalSGST: 0, totalCGST: 0 };
      }

      acc[taxRate].totalSGST += sgst;
      acc[taxRate].totalCGST += cgst;

      return acc;
    }, {});

    return taxGroups;
  };
  const tcs = [
    // { value: "0 GST [0%]", label: "0 GST [0%]", discount: 0 },
    { value: "5 GST [5%]", label: "5 GST [5%]", discount: 5 },
    { value: "12 GST [12%]", label: "12 GST [12%]", discount: 12 },
    { value: "18 GST [18%]", label: "18 GST [18%]", discount: 18 },
    { value: "28 GST [28%]", label: "28 GST [28%]", discount: 28 },
  ];

  const [adjustmentValue, setAdjustmentValue] = useState(""); // Step 1: State for adjustment value
  const [oppositeValue, setOppositeValue] = useState(""); // State for opposite value
  const [disAllValue, setDisAllValue] = useState("");
  const [discountType, setDiscountType] = useState("₹");
  const [disValue, setDisValue] = useState("");
  const [selectedTcsOption, setSelectedTcsOption] = useState(null);
  const totalTax =
    calculateIGST() ||
    calculateSGSTandCGST().totalSGST + calculateSGSTandCGST().totalCGST;

  const calculateDiscount = () => {
    const amount = parseFloat(calculateSubTotal()).toFixed(2);

    if (discountType === "₹") {
      setDisValue(disAllValue);
    } else if (discountType === "%") {
      const percentageDiscount = (amount * disAllValue) / 100;
      setDisValue(percentageDiscount.toFixed(2));
    }
  };

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
  };

  const handleDisAllValueChange = (e) => {
    setDisAllValue(e.target.value);
  };

  useEffect(() => {
    calculateDiscount();
  }, [disAllValue, discountType]);

  useEffect(() => {
    const parsedValue = parseFloat(adjustmentValue);
    if (!isNaN(parsedValue)) {
      const opposite = parsedValue * 1;
      setOppositeValue(opposite.toFixed(2));
    } else {
      setOppositeValue("");
    }
  }, [adjustmentValue]);

  const calculateTotal = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const igst = parseFloat(calculateIGST());
    const adjustment = parseFloat(adjustmentValue) || 0; // Use the adjustment value if it's a valid number, otherwise, default to 0
    const total1 = subTotal + igst + adjustment;
    const total = total1 - disValue;
    const totalFinal = discountAmountTcs + total;
    return totalFinal.toFixed(2);
  };

  const fetchSalesData = () => {
    fetch(BASE_URL + `/getAllSalesPersonByUniqueId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setSalesPersonData(data.responseMessage);
        } else {
          console.error("Failed to fetch salesperson:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching salesperson:", error);
      });
  };

  useEffect(() => {
    fetchSalesData();
    fetchTermsData();
  }, []);

  const [custNames, setCustNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(""); // State to store the selected customer's ID

  useEffect(() => {
    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  useEffect(() => {
    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage[0].businessRegistered;
          setBussinessRegistered(Data);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  useEffect(() => {
    fetch(BASE_URL + `/fetchDiscountCheck`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage.discountCheck;
          const sizeCheck = data.responseMessage.sizeCheck;
          setDiscountCheck(Data);
          setQuantityCheck(data.responseMessage.quantityCheck);
          setSizeCheck(sizeCheck);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const [userState, setUserState] = useState([]);
  const [customerState, setCustomerState] = useState("");
  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");

  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userState = data.responseMessage;
          setUserState(userState);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle customer selection
  const handleCustomerChange = (value, id) => {
    const selectedValue = value;
    const selectedValueId = id;
    setSelectedCustomer(selectedValue);
    setSelectedCustomerId(selectedValueId);
    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );

    if (selectedCustomerObject) {
      setSelectedCustomerId(selectedCustomerObject.id);
    } else {
      console.log("Selected Customer: (Not found)");
    }
  };

  const [subject, setSubject] = useState("");
  const [customerNote, setCustomerNote] = useState("");
  const [termsAndCondition, setTermsAndCondition] = useState("");
  const [salesPersonData, setSalesPersonData] = useState([]);
  const [dilvTO, setDilvTo] = useState(false);
  const [purchaseNumber, setPurchaseNumber] = useState("");
  const [destinationOfSupply, setDestinationOfSupply] = useState("");
  const [reference, setReference] = useState("");
  const [adjustmentLabel, setAdjustmentLabel] = useState("");
  const [purchaseNumberPrefix, setPurchaseNumberPrefix] = useState([]);
  const [purchaseNumberNext, setPurchaseNumberNext] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const [validationMessage1, setValidationMessage1] = useState("");

  const insertDataIntoDatabase = async (e) => {
    e.preventDefault();
    const itemDetails = row.map((data) => {
      return {
        itemDetails: data.itemDetails || "",
        account: data.account,
        per:data.per,
        quantity: data.quantity,
        size: data.size,
        rate: data.rate,
        discount: data.discount || 0,
        discountType: data.discountType,
        tax: data.tax,
        amount: calculateAmount(data),
        description1: data.description1 || "",
        description2: data.description2 || "",
        customerTaxType: userState.state === customerState ? 1 : 2,
        cgst:
          userState.state === customerState
            ? (calculateAmount(data) * data.tax) / 100 / 2
            : 0,
        sgst:
          userState.state === customerState
            ? (calculateAmount(data) * data.tax) / 100 / 2
            : 0,
        igst:
          userState.state !== customerState
            ? (calculateAmount(data) * data.tax) / 100
            : 0,
      };
    });
    const formData = {
      vendorName: selectedVendor,
      uniqueId: decryptedUniqueId,
      vendorId: selectedVendorId,
      purchaseNumber: purchaseNumber,
      purchaseDate: dateValue || normalDateFormat(startDate),
      dueDate:
        showDueDate === true
          ? dueDate
          : dateValue1 || normalDateFormat(startDate),
      terms: selectedTerms,
      reference: reference,
      subject: subject,
      items: itemDetails,
      deliverTo: dilvTO ? "Customer" : "Organization",
      customerName: selectedCustomer,
      destinationOfSupply: destinationOfSupply,
      subtotal: calculateSubTotal(),
      adjustment: parseFloat(adjustmentValue) || 0,
      adjustmentLabel: adjustmentLabel,
      total: calculateTotal(),
      customerNote: customerNote || "Thank You For Business.",
      termsAndCondition: termsAndCondition,
      discountCheck: discountCheck || 0,
      quantityCheck: quantityCheck || "",
      taxCheck: bussinessRegistered || 0,
      overallDiscount: disValue || 0,
      totalDiscount: disAllValue || 0,
      totalDiscountType: discountType || 0,
      taxInAmount: totalTax,
      type: "TCS",
      value: discountAmountTcs,
      tcsTax: selectedTcsOption?.value,
      status: "Sent",
      manualCheck: manualCheck,
      state: customerState,
      totalAmount: calculateTotal(),
    };

    let newValidationMessage = "";
    if (!formData.vendorName) {
      newValidationMessage += "Please select a Vendor \n";
    } else if (!formData.purchaseNumber) {
      newValidationMessage += "Please enter purchase number.\n";
    } else if (!formData.purchaseDate) {
      newValidationMessage += "Please select a purchase date \n";
    } else if (!formData.dueDate) {
      newValidationMessage += "Please select a due date\n";
    }

    row.forEach((item, index) => {
      if (item.itemDetails.trim() === "") {
        newValidationMessage += `Item name is required.\n`;
      }
      if (item.rate.trim() === "") {
        newValidationMessage += `Rate is required.\n`;
      } else if (item.rate.trim() === "0.0") {
        newValidationMessage += `Rate cannot be 0.0.\n`;
      }
      if (item.quantity.trim() === "") {
        newValidationMessage += `Quantity is required.\n`;
      }
      if (item.account === "") {
        newValidationMessage += `A valid account must be selected.\n`;
      }
    });

    setValidationMessage1(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(BASE_URL + "/insertPurchaseOrder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Data inserted successfully
        swal({
          icon: "success",
          title: "Purchase Order Created",
        });
        setIsLoading(false);
        handlePurchaseClick();
      } else {
        // Handle error
        swal({
          icon: "erorr",
          title: "Failed to Create Purchase Order!",
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const insertDataIntoDatabaseAsDraft = async (e) => {
    e.preventDefault();
    const itemDetails = row.map((data) => {
      return {
        itemDetails: data.itemDetails || "",
        account: data.account,
        per:data.per,
        quantity: data.quantity,
        size: data.size,
        rate: data.rate,
        discount: data.discount || 0,
        discountType: data.discountType,
        tax: data.tax * 100,
        amount: calculateAmount(data, selectedTaxRate),
        description1: data.description1 || "",
        description2: data.description2 || "",
        customerTaxType: userState.state === customerState ? 1 : 2,
        cgst:
          userState.state === customerState
            ? (calculateAmount(data, selectedTaxRate) * (data.tax * 100)) /
              100 /
              2
            : 0,
        sgst:
          userState.state === customerState
            ? (calculateAmount(data, selectedTaxRate) * (data.tax * 100)) /
              100 /
              2
            : 0,
        igst:
          userState.state !== customerState
            ? (calculateAmount(data, selectedTaxRate) * (data.tax * 100)) / 100
            : 0,
      };
    });
    const formData = {
      vendorName: selectedVendor,
      uniqueId: decryptedUniqueId,
      vendorId: selectedVendorId,
      purchaseNumber: purchaseNumber,
      purchaseDate: dateValue || normalDateFormat(startDate),
      dueDate:
        showDueDate === true
          ? dueDate
          : dateValue1 || normalDateFormat(startDate),
      terms: selectedTerms,
      reference: reference,
      subject: subject,
      items: itemDetails,
      deliverTo: dilvTO ? selectedCustomer : "Organization",
      destinationOfSupply: destinationOfSupply,
      subtotal: calculateSubTotal(),
      adjustment: parseFloat(adjustmentValue) || 0,
      adjustmentLabel: adjustmentLabel,
      total: calculateTotal(),
      customerNote: customerNote || "Thank You For Business.",
      termsAndCondition: termsAndCondition,
      discountCheck: discountCheck || 0,
      quantityCheck: quantityCheck || "",
      taxCheck: bussinessRegistered || 0,
      overallDiscount: disValue || 0,
      totalDiscount: disAllValue || 0,
      totalDiscountType: discountType || 0,
      taxInAmount: totalTax,
      type: "TCS",
      value: discountAmountTcs,
      tcsTax: selectedTcsOption?.value,
      status: "Draft",
      manualCheck: manualCheck,
      state: customerState,

      totalAmount: calculateTotal(), // Assuming you have a function to calculate the total amount
      // Add other fields here
    };

    let newValidationMessage = "";
    if (!formData.vendorName) {
      newValidationMessage += "Please select a Vendor \n";
    } else if (!formData.purchaseNumber) {
      newValidationMessage += "Please enter purchase number.\n";
    } else if (!formData.purchaseDate) {
      newValidationMessage += "Please select a purchase date \n";
    } else if (!formData.dueDate) {
      newValidationMessage += "Please select a due date\n";
    }
    row.forEach((item, index) => {
      if (item.itemDetails.trim() === "") {
        newValidationMessage += `Item name is required.\n`;
      }
      if (item.rate.trim() === "") {
        newValidationMessage += `Rate is required.\n`;
      } else if (item.rate.trim() === "0.0") {
        newValidationMessage += `Rate cannot be 0.0.\n`;
      }
      if (item.quantity.trim() === "") {
        newValidationMessage += `Quantity is required.\n`;
      }
      if (item.account === "") {
        newValidationMessage += `A valid account must be selected.\n`;
      }
    });

    setValidationMessage1(newValidationMessage);

    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(BASE_URL + "/insertPurchaseOrder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Data inserted successfully
        swal({
          icon: "success",
          title: "Purchase Order Created",
        });
        setIsLoading(false);
        handlePurchaseClick();
      } else {
        // Handle error
        swal({
          icon: "erorr",
          title: "Failed to Create Purchase Order!",
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const copyAdd = () => {
    if (show1) {
      setPurchaseNumber(purchaseNumberPrefix + purchaseNumberNext);
      setManualCheck("No");
    } else {
      setPurchaseNumber("");
      setManualCheck("Yes");
    }
  };
  const handlePurchaseNumberChange = (e) => {
    setPurchaseNumber(e.target.value);
    setManualCheck("Yes");
  };
  const setBill = () => {
    setShow1(true);
    fetchBill();
  };

  const fetchBill = () => {
    const requestData = {
      uniqueId: decryptedUniqueId,
      type: "purchaseorder",
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
        // Add any other headers if required
      },
      body: JSON.stringify(requestData), // Convert the data to JSON format
    };

    // Make the POST request to your API endpoint
    fetch(BASE_URL + `/getCurruntNumberList2`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setPurchaseNumberPrefix(data.responseMessage.prefix);
        setPurchaseNumberNext(data.responseMessage.numbers);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  useEffect(() => {
    fetch(BASE_URL + `/getCurruntNumberList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        type: "purchaseorder",
        uniqueId: decryptedUniqueId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setPurchaseNumber(data.responseMessage);
        } else {
          console.error("Failed to fetch numberList:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Number List:", error);
      });
  }, []);

  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().substr(0, 10)
  );

  const [selectedTerms, setSelectedTerms] = useState("Due On Receipt");
  const [dueDate, setDueDate] = useState(null);
  const [dueDateChangedManually, setDueDateChangedManually] = useState(false);

  useEffect(() => {
    if (currentDate && selectedTerms && !dueDateChangedManually) {
      updateDueDate(new Date(currentDate), selectedTerms);
    }
  }, [currentDate, selectedTerms, dueDateChangedManually]);

  const [dropdownData, setDropdownData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const fetchItemData = () => {
    const postApiUrl = BASE_URL + `/getAllItemData`;
    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, vendorId: "" }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseMessage;
        setDropdownData(responseData.map((item) => item));
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  const [searchIndex, setSearchIndex] = useState(0);

  useEffect(() => {
    const results = dropdownData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, dropdownData]);

  const handleSearchChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...row];
    newRow[index].itemDetails = value;
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setRow(newRow);
    setSearchIndex(index);
  };

  const handleItemDetailsChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...row];
    newRow[index].itemDetails = value;
    setRow(newRow);
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setSearchIndex(index);
    if (value) {
      fetchItemRate(value, index);
    } else {
      handleSearchChange(value, index);
    }
  };

  const handleItemSelect = (selectedItem, selectedItemId, index) => {
    setSearchResults([]);
    const newRow = [...row];
    newRow[index].itemDetails = selectedItem;
    setRow(newRow);
    fetchItemRate(selectedItemId, index);
  };

  const fetchItemRate = (itemId, index) => {
    const getApiUrl = BASE_URL + `/getItemData/${itemId}`;
    fetch(getApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const rate = data.data.purchasePrice;
        const description1 = data.data.description;
        const hsnSac = data.data.hsnCode;
        const itemName = data.data.name;
        const updatedRow = [...row];
        updatedRow[index].rate = rate;
        updatedRow[index].description1 = description1;
        updatedRow[index].hsnSac = hsnSac;
        updatedRow[index].itemDetails = itemName;
        setRow(updatedRow);
        setSearchResults([]);
        handleAdd();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const [showItemModal, setShowItemModal] = useState(false);
  const [itemName, setItemName] = useState("");

  const [itemData, setItemData] = useState({
    uniqueId: decryptedUniqueId,
    description: "",
  });

  const handleItemChange = (event) => {
    const { name, value } = event.target;
    setItemData({ ...itemData, [name]: value });
  };

  const handleItemClose = () => {
    setShowItemModal(false);
    setItemData({
      ...itemData,
      description: "",
    });
  };

  const [itemDescriptions, setItemDescriptions] = useState([]);

  const handleItem = (event) => {
    event.preventDefault();

    const newItemDescription = {
      itemDetails: itemName,
      description: itemData.description,
    };

    setItemDescriptions((prevDescriptions) => {
      const existingIndex = prevDescriptions.findIndex(
        (desc) => desc.itemDetails === itemName
      );

      if (existingIndex !== -1) {
        const updatedDescriptions = [...prevDescriptions];
        updatedDescriptions[existingIndex] = newItemDescription;
        return updatedDescriptions;
      } else {
        return [...prevDescriptions, newItemDescription];
      }
    });

    setRow((prevRows) => {
      return prevRows.map((row) =>
        row.itemDetails === itemName
          ? { ...row, description2: itemData.description }
          : row
      );
    });

    setShowItemModal(false);
  };

  const handleItemShow = (itemDetails) => {
    const itemDescription = itemDescriptions.find(
      (desc) => desc.itemDetails === itemDetails
    );
    if (itemDescription) {
      setItemData({ description: itemDescription.description });
    } else {
      setItemData({ description: "" });
    }
    setItemName(itemDetails);
    setShowItemModal(true);
  };

  const [showDueDate, setShowDueDate] = useState(true);

  const updateDueDate = (date, terms) => {
    setDueDateChangedManually(false);
    if (terms.startsWith("Net ")) {
      const daysToAdd = parseInt(terms.replace("Net ", ""));
      setDueDate(normalDateFormat(addDays(startDate, daysToAdd)));
      setShowDueDate(true);
    } else if (terms === "Due End Of The Month") {
      setDueDate(normalDateFormat(endOfMonth(startDate)));
      setShowDueDate(true);
    } else if (terms === "Due End Of Next Month") {
      setDueDate(normalDateFormat(endOfMonth(addMonths(startDate, 1))));
      setShowDueDate(true);
    } else if (terms === "Due On Receipt") {
      setDueDate(normalDateFormat(startDate));
      setShowDueDate(true);
    } else if (terms === "Custom") {
      setShowDueDate(false);
    } else {
      setDueDate("");
    }
  };

  const updateDueDate1 = (date, terms) => {
    setDueDateChangedManually(false);
    if (terms.startsWith("Net ")) {
      const daysToAdd = parseInt(terms.replace("Net ", ""));
      setDueDate(normalDateFormat(addDays(date, daysToAdd)));
      setShowDueDate(true);
    } else if (terms === "Due End Of The Month") {
      setDueDate(normalDateFormat(endOfMonth(date)));
      setShowDueDate(true);
    } else if (terms === "Due End Of Next Month") {
      setDueDate(normalDateFormat(endOfMonth(addMonths(date, 1))));
      setShowDueDate(true);
    } else if (terms === "Due On Receipt") {
      setDueDate(normalDateFormat(date));
      setShowDueDate(true);
    } else if (terms === "Custom") {
      setShowDueDate(false);
    } else {
      setDueDate("");
    }
  };

  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());

  useEffect(() => {
    setStartDate(new Date());
    setStartDate1(new Date());
  }, []);

  const [dateValue, setDateValue] = useState("");
  const [dateValue1, setDateValue1] = useState("");

  const handleDate = (date) => {
    setStartDate(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue(normalFormat);
    updateDueDate1(date, selectedTerms);
  };

  const handleDate1 = (date) => {
    setStartDate1(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue1(normalFormat);
  };

  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return date;
  };

  const options = custNames.map((customer) => ({
    value: customer.id,
    label: customer.customerName,
  }));

  const optionSales = salesPersonData.map((person) => ({
    value: person.id,
    label: person.name,
  }));

  const optionPaymentTerms = [
    { value: "Net 15", label: "Net 15" },
    { value: "Net 30", label: "Net 30" },
    { value: "Net 45", label: "Net 45" },
    { value: "Net 60", label: "Net 60" },
    { value: "Due End Of The Month", label: "Due End Of The Month" },
    { value: "Due End Of Next Month", label: "Due End Of Next Month" },
    { value: "Due On Receipt", label: "Due On Receipt" },
    { value: "Custom", label: "Custom" },
  ];

  const handleChange = (selectedOption) => {
    setSelectedTerms(selectedOption.value);
    updateDueDate(dateValue, selectedOption.value);
  };

  const handleCreateVendor = (event) => {
    const { name, value } = event.target;
    setFormDataVendor((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [taxValues, setTaxValues] = useState(Array(row.length).fill(0));

  const [vendorNames, setVendorNames] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState("");

  // FetchVendor
  const fetchAllVendors = () => {
    fetch(BASE_URL + `/fetchVendors`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setVendorNames(data.responseMessage);
        } else {
          console.error("Failed to fetch vendor names:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching vendor names:", error);
      });
  };
  useEffect(() => {
    fetchAllVendors();
  }, []);

  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption.value); // Store the selected vendor's value

    const selectedVendorObject = vendorNames.find(
      (vendor) => vendor.vendorDisplayName === selectedOption.value
    );

    if (selectedVendorObject) {
      const vendorTerms = selectedVendorObject.paymentTerms;
      const customerState = selectedVendorObject.billingState;
      setCustomerState(customerState);
      setSelectedTerms(vendorTerms);
      setSelectedVendorId(selectedVendorObject.id);
    } else {
      setSelectedTerms("");
    }
  };

  const vendorOptions = vendorNames.map((names) => ({
    label: names.vendorDisplayName,
    value: names.vendorDisplayName,
  }));

  const handleAccoutChange = (e, i) => {
    const { value } = e.target;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], account: value };
    setRow(newRow);
  };

  const optionsCustomers = custNames.map((customer) => ({
    value: customer.id,
    label: customer.customerName,
  }));

  const customStyles2 = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      background: "#F6F6F6",
      borderRadius: "10px",
      // minheight: "38px",
      height: "20px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginLeft: "8px",
      height: "20px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      // height: "38px",
    }),
  };
  const [formDataVendor, setFormDataVendor] = useState({
    uniqueId: decryptedUniqueId,
    primaryContact: "Mr.",
    firstName: "",
    lastName: "",
    companyName: "",
    vendorDisplayName: "",
    vendorEmail: "",
    vendorPhone1: "",
    vendorPhone2: "",
    designation: "",
    department: "",
    website: "",
    currency: "INR- Indian Rupee",
    openingBalance: "0",
    paymentTerms: "Net 15",
    facebook: "",
    twitter: "",
    billingAttention: "",
    billingCountry: "",
    billingAddress1: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingZipcode: "",
    billingPhone: "",
    shipAttention: "",
    shipCountry: "",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "",
    shipZipcode: "",
    shipPhone: "",
    sourceOfSupply: "[MH] - Maharashtra",
    gstTreatment: "",
    contactPerson: [
      {
        other: "",
        firstName: "",
        lastName: "",
        email: "",
        workphone: "",
        mobile: "",
      },
    ],
  });
  const [showNewVendor, setShowNewVendor] = useState(false);

  const handleNewVendorClose = () => setShowNewVendor(false);
  const handleNewVendorShow = () => setShowNewVendor(true);
  const [validationMessage, setValidationMessage] = useState("");

  const handleAddVendor = () => {
    let newValidationMessage = "";

    if (!formDataVendor.vendorDisplayName) {
      newValidationMessage += "Please enter a vendor display name.\n";
    } else if (
      formDataVendor.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formDataVendor.email)
    ) {
      newValidationMessage += "Please enter a valid email address.\n";
    }

    setValidationMessage(newValidationMessage);
    if (newValidationMessage) {
      return; // Exit function if validation fails
    }

    fetch(BASE_URL + "/insertVendor", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formDataVendor),
    })
      .then((response) => {
        if (response.ok) {
          swal({
            icon: "success",
            title: "Vendor Created",
            text: "",
          });
          setShowNewVendor(false);
          fetchAllVendors();
        } else {
          swal({
            icon: "error",
            title: "Failed to Create Vendor",
            text: "",
          });
          setShowNewVendor(true);
          fetchAllVendors();
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        setShowNewVendor(true);
      });
  };

  const generateNamePairs = () => {
    const { primaryContact, firstName, lastName, companyName } = formDataVendor;
    const pairs = [];

    if (firstName && lastName) {
      pairs.push(`${primaryContact} ${firstName} ${lastName}`);
      pairs.push(`${firstName} ${lastName}`);
      pairs.push(`${lastName} , ${firstName}`);
      pairs.push(`${companyName}`);
    }

    return pairs;
  };

  useEffect(() => {
    if (selectedTcsOption && selectedTcsOption.discount) {
      const subtotal = calculateSubTotal();
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedTcsOption.discount
      );
    }
  }, [row]);

  const handleEditorChange = (value) => {
    setTermsAndCondition(value); // Update the state with the content of the editor
  };

  const fetchTermsData = () => {
    const postApiUrl = BASE_URL + `/getCheckedTermsAndCondition`;
    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseCode;
        // setDropdownData(responseData.map((item) => item));
        setTermsAndCondition(responseData.join("") || "");
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };
  return (
    <>
      <Row>
        <Col>
          <div className="my-3">
            <div className="d-flex align-items-center ml-1 mt-4">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancleClick}
                  className="arrow-child"
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5
                  className="ml-1 mb-0"
                  style={{ letterSpacing: "1px", color: "#086bd5de" }}
                >
                  New Purchase Order
                </h5>
              </div>
            </div>
          </div>
          {validationMessage1 && (
            <Alert severity="error" className="mt-0">
              {validationMessage1.split("\n").map((message, index) => (
                <div key={index}>{message}</div>
              ))}
            </Alert>
          )}{" "}
          <Card className="card-style p-4">
            {/* <Card.Body> */}
            <Row>
              <Col className="" md="3">
                <Form.Group>
                  <label
                    className="text-primary"
                    style={{ fontSize: 16, fontWeight: "600" }}
                  >
                    VENDOR INFO
                  </label>
                </Form.Group>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col className="" md="3">
                  <Form.Group>
                    <label
                      className="label-title"
                      style={{ fontSize: "14px", color: "grey" }}
                    >
                      Vendor Name <span className="valid"> *</span>
                    </label>
                    <Select
                      className="mt-0"
                      value={vendorOptions.find(
                        (option) => option.value === selectedVendor
                      )}
                      onChange={handleVendorChange}
                      options={vendorOptions}
                      placeholder="Select a Vendor"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",
                          borderRadius: "7px",
                          height: "46px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          height: "19px",
                        }),
                      }}
                    />

                    <button
                      type="button"
                      class="btn p-0 text-left"
                      onClick={handleNewVendorShow}
                    >
                      <span
                        class="text-primary"
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        New Vendor
                      </span>
                    </button>
                  </Form.Group>
                  <Modal
                    size="lg"
                    show={showNewVendor}
                    onHide={handleNewVendorClose}
                  >
                    <div class="modal-header d-flex align-items-center pb-2 pt-2">
                      <h5 class="modal-title fs-5" id="newcustomerPopup">
                        New Vendor
                      </h5>
                      <button
                        type="button"
                        className="btn pr-0"
                        onClick={handleNewVendorClose}
                      >
                        <ion-icon
                          name="close-circle-outline"
                          size="large"
                          style={{ width: "28px" }}
                        ></ion-icon>
                      </button>
                    </div>
                    <Modal.Body className="p-0 pb-3">
                      {validationMessage && (
                        <Alert severity="error" className="mt-0">
                          {validationMessage
                            .split("\n")
                            .map((message, index) => (
                              <div key={index}>{message}</div>
                            ))}
                        </Alert>
                      )}{" "}
                      <div className="container">
                        <div className="row d-inline-flex">
                          <div className="modalinputchild">
                            <div className="text-style"> Salutation </div>
                            <select
                              className="form-control"
                              name="primaryContact"
                              value={formDataVendor.primaryContact}
                              onChange={handleCreateVendor}
                              style={{
                                borderRadius: "7px",
                                backgroundColor: "#f5f5f5",
                                width: "200px",
                                height: "35px",
                                fontSize: "13px",
                                marginTop: "10px",
                              }}
                            >
                              <option value="Mr.">Mr.</option>
                              <option value="Mrs.">Mrs.</option>
                              <option value="Ms.">Ms.</option>
                              <option value="Miss.">Miss.</option>
                              <option value="Dr.">Dr.</option>
                            </select>
                          </div>
                          <div className="modalinputchild">
                            <div className="text-style">First Name</div>
                            <input
                              className="form-control"
                              placeholder="First Name"
                              name="firstName"
                              value={formDataVendor.firstName}
                              onChange={handleCreateVendor}
                              id="firstName"
                              type="text"
                            />
                          </div>
                          <div className="modalinputchild">
                            <div className="text-style">Last Name</div>
                            <input
                              className="form-control"
                              placeholder="Last Name"
                              id="lastName"
                              name="lastName"
                              value={formDataVendor.lastName}
                              onChange={handleCreateVendor}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="row d-inline-flex pt-2">
                          <div className="modalinputchild">
                            <div className="text-style"> Vendor Email</div>
                            <input
                              className="form-control"
                              placeholder="Customer Email"
                              name="vendorEmail"
                              type="text"
                              value={formDataVendor.vendorEmail}
                              onChange={handleCreateVendor}
                            />
                          </div>
                          <div className="modalinputchild">
                            <div className="text-style">
                              {" "}
                              Vendor Display Name{" "}
                              <span className="valid">*</span>{" "}
                            </div>
                            <input
                              className="form-control"
                              placeholder="Vendor Display Name"
                              list="vendorDisplayName"
                              name="vendorDisplayName"
                              value={formDataVendor.vendorDisplayName}
                              onChange={handleCreateVendor}
                              style={{
                                borderRadius: "7px",
                                backgroundColor: "#f5f5f5",
                                width: "200px",
                                height: "40px",
                                fontSize: "13px",
                                marginTop: "10px",
                              }}
                            />
                            <datalist id="vendorDisplayName">
                              {generateNamePairs().map((pair, index) => (
                                <option key={index} value={pair} />
                              ))}
                            </datalist>{" "}
                          </div>

                          <div className="modalinputchild">
                            <div className="text-style">Company Name</div>
                            <input
                              className="form-control"
                              placeholder="Add company name"
                              name="companyName"
                              type="text"
                              value={formDataVendor.companyName}
                              onChange={handleCreateVendor}
                            />
                          </div>
                        </div>
                        <div className="row d-inline-flex pt-2">
                          <div className="modalinputchild">
                            <div className="text-style">Vendor Phone</div>
                            <input
                              className="form-control"
                              placeholder="Work Phone"
                              name="vendorPhone1"
                              type="text"
                              value={formDataVendor.vendorPhone1}
                              onChange={handleCreateVendor}
                            />
                          </div>
                          <div className="modalinputchild">
                            <div className="text-style">Mobile</div>
                            <input
                              className="form-control"
                              placeholder="Mobile"
                              name="vendorPhone2"
                              type="text"
                              value={formDataVendor.vendorPhone2}
                              onChange={handleCreateVendor}
                            />
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="p-2">
                      <div className="modal-bottombtns">
                        <button
                          type="button"
                          class="modalbtn-1 btn mt-0"
                          onClick={handleNewVendorClose}
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="modalbtn-3 btn btn-primary"
                          onClick={handleAddVendor}
                        >
                          Create Vendor
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Deliver To
                    </label>
                    <div className="mt-2">
                      <label className="rdiobox d-inline-flex align-items-center">
                        <input
                          className="mr-1"
                          type="radio"
                          name="cus_type"
                          id="cus_bus"
                          value="Organization"
                          onClick={() => setDilvTo(false)}
                        />
                        <span>Organization</span>
                      </label>
                      <label className="rdiobox ml-3 d-inline-flex align-items-center">
                        <input
                          className="mr-1"
                          type="radio"
                          name="cus_type"
                          id="cus_indivi"
                          value="Customer"
                          onClick={() => setDilvTo(true)}
                        />
                        <span>Customer</span>
                      </label>
                    </div>
                  </Form.Group>
                </Col>
                {dilvTO && (
                  <Col md="3">
                    <div className="form-group mb-0">
                      <label
                        htmlFor="customerSelect"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Select Customer
                      </label>
                      <Select
                        // className="dropdown-field"
                        options={optionsCustomers}
                        value={optionsCustomers.find(
                          (option) => option.value === selectedCustomer
                        )}
                        onChange={(selectedOption) =>
                          handleCustomerChange(
                            selectedOption.label,
                            selectedOption.value
                          )
                        }
                        placeholder="Select Customer"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            height: "46px",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            height: "19px",
                          }),
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>

              <Row className="mt-3">
                <Col md="3">
                  <Form.Group>
                    <label
                      className=""
                      style={{ fontSize: "14px", color: "grey" }}
                    >
                      Purchase Number<span className="valid"> *</span>
                    </label>
                    <Form.Control
                      placeholder="Purchase Number"
                      type="text"
                      name="purchaseNumber"
                      value={purchaseNumber}
                      onChange={handlePurchaseNumberChange}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        color: "black",
                        height: "38px",
                        "::placeholder": {
                          color: "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                        },
                      }}
                    />

                    <button
                      type="button"
                      className="btn p-0 text-left"
                      data-bs-toggle="modal"
                      data-bs-target="#generatePop"
                    >
                      <span
                        className="text-primary"
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        Generate
                      </span>
                    </button>
                  </Form.Group>

                  <div
                    className="modal fade"
                    id="generatePop"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header d-flex align-items-center pb-2 pt-2">
                          <h5
                            className="modal-title fs-5"
                            id="generateinvoiceHead"
                          >
                            Configure Purchase Order# Preferences
                          </h5>
                          <button
                            type="button"
                            className="btn pr-0"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <ion-icon
                              name="close-circle-outline"
                              size="large"
                              style={{ width: "28px" }}
                            ></ion-icon>
                          </button>
                        </div>
                        <div className="modal-body pt-1 pb-1">
                          <div className="popupinvoice-container ml-2 mt-2 mr-2 mb-0">
                            <div className="row headline">
                              <div className="customer-headline-label">
                                <label className="form-label">
                                  <span className="headline">
                                    Your purchase order numbers are set on
                                    auto-generate mode to save your
                                    <br /> time. Are you sure about changing
                                    this setting?
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <label className="customer-rdiobox-label d-flex align-items-center">
                                <input
                                  className="mr-2"
                                  type="radio"
                                  name="cus_type"
                                  id="cus_bus"
                                  value="Business"
                                  onClick={setBill}
                                />
                                <span className="customer-option">
                                  Continue auto-generating purchase order
                                  numbers
                                </span>
                              </label>
                            </div>
                            {show1 && (
                              <div className="row ml-3 mb-3">
                                <div className="customer-generate-inputfield">
                                  <span>Prefix</span>
                                  <input
                                    className="form-control mt-1"
                                    placeholder="Prefix"
                                    value={purchaseNumberPrefix}
                                    onChange={(e) =>
                                      setPurchaseNumberPrefix(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="customer-generate-inputfield2">
                                  <span>Next Number</span>
                                  <input
                                    className="form-control mt-1"
                                    placeholder="Next Number"
                                    value={purchaseNumberNext}
                                    onChange={(e) =>
                                      setPurchaseNumberNext(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            <div className="row mt-1">
                              <label className="customer-rdiobox-label d-flex align-items-center">
                                <input
                                  className="mr-2"
                                  type="radio"
                                  name="cus_type"
                                  id="cus_bus"
                                  value="Business"
                                  onClick={() => {
                                    setShow1(false);
                                  }}
                                />
                                <span className="customer-option">
                                  Enter purchase order numbers manually
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <div className="modal-bottombtns">
                            <button
                              type="button"
                              class="modalbtn-1 btn mt-0"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="modalbtn-3 btn btn-primary"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                copyAdd();
                              }}
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Reference
                    </label>
                    <Form.Control
                      placeholder="Reference"
                      name="reference"
                      value={reference}
                      onChange={(e) => setReference(e.target.value)}
                      type="text"
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        color: "black",
                        height: "38px",
                        "::placeholder": {
                          color: "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                        },
                      }}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col className="" md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>
                      Purchase Date <span className="valid"> *</span>
                    </label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        dateFormat="dd-MM-yyyy"
                        showIcon
                        toggleCalendarOnIconClick
                        onChange={(date) => handleDate(date)}
                      />
                    </div>
                    {/* </div> */}
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Payment Terms
                    </label>
                    <Select
                      // className="dropdown-field"
                      options={optionPaymentTerms}
                      value={optionPaymentTerms.find(
                        (option) => option.value === selectedTerms
                      )}
                      onChange={handleChange}
                      placeholder="Select Terms"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",
                          borderRadius: "7px",
                          height: "46px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          height: "19px",
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Due Date <span className="valid"> *</span>
                    </label>
                    {showDueDate ? (
                      <input
                        className="form-control mt-0"
                        type="text"
                        value={dueDate}
                        onClick={() => {
                          setShowDueDate(false);
                          setSelectedTerms("Custom");
                        }}
                        style={{
                          padding: "12px",
                          backgroundColor: "#F6F6F6",
                          borderRadius: "7px",
                          color: "black",
                          height: "38px",
                          "::placeholder": {
                            color:
                              "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                          },
                        }}
                        showIcon
                        toggleCalendarOnIconClick
                        readOnly
                      />
                    ) : (
                      <div className="customDatePickerWidth">
                        <DatePicker
                          className="form-control"
                          selected={startDate1}
                          dateFormat="dd-MM-yyyy"
                          onChange={(date) => handleDate1(date)}
                          showIcon
                          toggleCalendarOnIconClick
                          placeholderText="Select due date"
                        />
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md="3">
                  <Form.Group>
                    <label
                      className="text-primary"
                      style={{ fontSize: 14, fontWeight: "600" }}
                    >
                      ITEM INFO
                    </label>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card className="mb-3">
                    <div className="" style={{ overflowX: "auto" }}>
                      <table
                        className="table table-bordered mb-0"
                        style={{
                          width: "100%",
                        }}
                      >
                        <thead className="text-center">
                          <tr>
                            <th
                              scope="col"
                              style={{
                                whiteSpace: "nowrap",
                                minWidth: "350px",
                              }}
                            >
                              ITEM DETAILS
                            </th>
                            <th
                              scope="col"
                              style={{
                                whiteSpace: "nowrap",
                                minWidth: "100px",
                              }}
                            >
                              ACCOUNT
                            </th>
                            {sizeCheck === "YES" && (
                              <th
                                scope="col"
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "100px",
                                }}
                              >
                                SIZE
                              </th>
                            )}
                             <th
                              scope="col"
                              style={{
                                whiteSpace: "nowrap",
                                minWidth: "100px",
                              }}
                            >
                              PER
                            </th>
                            {quantityCheck === "YES" && (
                            <th
                              scope="col"
                              style={{
                                whiteSpace: "nowrap",
                                minWidth: "100px",
                              }}
                            >
                              QUANTITY
                            </th>
                            )}
                            <th
                              scope="col"
                              style={{
                                whiteSpace: "nowrap",
                                minWidth: "100px",
                              }}
                            >
                              RATE
                            </th>
                            {discountCheck === "YES" && (
                              <th
                                scope="col"
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "100px",
                                }}
                              >
                                DISCOUNT
                              </th>
                            )}{" "}
                            {bussinessRegistered === "YES" && (
                              <th
                                scope="col"
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "100px",
                                }}
                              >
                                TAX
                              </th>
                            )}
                            <th
                              scope="col"
                              style={{
                                whiteSpace: "nowrap",
                                minWidth: "100px",
                              }}
                            >
                              AMOUNT
                            </th>
                            <th
                              scope="col"
                              style={{
                                whiteSpace: "nowrap",
                                minWidth: "100px",
                              }}
                            >
                              ACTION
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {row.map((data, i) => {
                            const amount = calculateAmount(data, data.tax);
                            const totalAmountWithTax = parseFloat(amount);
                            const itemDescription = itemDescriptions.find(
                              (desc) => desc.itemDetails === data.itemDetails
                            );
                            return (
                              <tr key={i}>
                                <td style={{ whiteSpace: "nowrap" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      className="form-control noBorder"
                                      placeholder="Search items..."
                                      value={data.itemDetails}
                                      onChange={(e) =>
                                        handleItemDetailsChange(e, i)
                                      }
                                      style={{ flex: 1 }}
                                    />
                                    {data.itemDetails && (
                                      <button
                                        type="button"
                                        className="btn p-0"
                                        onClick={() =>
                                          handleItemShow(data.itemDetails)
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        <span
                                          className="text-primary"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Edit
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                  {itemDescription && (
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        color: "#6c757d",
                                        whiteSpace: "pre-line",
                                      }}
                                    >
                                      {itemDescription.description}
                                    </div>
                                  )}
                                  {searchPerformed &&
                                    data.itemDetails &&
                                    i === searchIndex && (
                                      <div
                                        style={{
                                          position: "absolute",
                                          zIndex: 9999, // Higher than scrollbar
                                          top: "100%", // Position below input
                                          left: 0,
                                          width: "30%",
                                        }}
                                      >
                                        {searchResults.length === 0 && ""}
                                        {searchResults.length > 0 && (
                                          <ul
                                            style={{
                                              listStyleType: "none",
                                              padding: 0,
                                              position: "absolute",
                                              top: "calc(100% + 10px)",
                                              left: 0,
                                              zIndex: 1000,
                                              backgroundColor: "#FFF",
                                              boxShadow:
                                                "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                              borderRadius: "5px",
                                              width: "100%",
                                              maxHeight: "200px", // Set a max height for the list
                                              overflowY: "auto", // Enable vertical scrolling
                                            }}
                                          >
                                            {searchResults.map(
                                              (result, index) => (
                                                <li
                                                  key={index}
                                                  onClick={() =>
                                                    handleItemSelect(
                                                      result.name,
                                                      result.id,
                                                      i
                                                    )
                                                  }
                                                  onMouseEnter={(e) => {
                                                    e.target.style.backgroundColor =
                                                      "#408dfb";
                                                    e.target.style.color =
                                                      "#FFF";
                                                    e.target.style.borderRadius =
                                                      "5px"; // Apply border radius on hover
                                                  }}
                                                  onMouseLeave={(e) => {
                                                    e.target.style.backgroundColor =
                                                      "";
                                                    e.target.style.color =
                                                      "#000";
                                                    e.target.style.borderRadius =
                                                      ""; // Reset border radius
                                                  }}
                                                  style={{
                                                    padding: "12px",
                                                    cursor: "pointer",
                                                    whiteSpace: "nowrap", // Prevent text from overflowing
                                                    overflow: "hidden", // Hide overflow text
                                                    textOverflow: "ellipsis", // Add ellipsis for overflowing text
                                                  }}
                                                >
                                                  {result.name}
                                                  <br />
                                                  Rate : ₹{result.purchasePrice}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        )}
                                      </div>
                                    )}
                                </td>

                                <Modal
                                  show={showItemModal}
                                  onHide={handleItemClose}
                                >
                                  <div class="modal-header d-flex align-items-center pb-2 pt-2">
                                    <h5
                                      class="modal-title fs-5"
                                      id="salespersonHead"
                                    >
                                      Add Item Description
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn pr-0"
                                      onClick={handleItemClose}
                                    >
                                      <ion-icon
                                        name="close-circle-outline"
                                        size="large"
                                        style={{ width: "28px" }}
                                      ></ion-icon>
                                    </button>
                                  </div>
                                  <Modal.Body>
                                    <Row>
                                      <Col md="12">
                                        <div className="row pt-2">
                                          <div className="col-lg-6">
                                            <label
                                              className="form-label"
                                              style={{
                                                fontSize: "14px",
                                                color: "grey",
                                                borderRadius: "7px",
                                              }}
                                            >
                                              Item
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-lg-12 pl-0 pr-0">
                                          <input
                                            className="form-control"
                                            placeholder="Name"
                                            id="name"
                                            name="name"
                                            type="text"
                                            value={itemName}
                                            readOnly
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="12">
                                        <div className="row pt-2">
                                          <div className="col-lg-6">
                                            <label
                                              className="form-label"
                                              style={{
                                                fontSize: "14px",
                                                color: "grey",
                                                borderRadius: "7px",
                                              }}
                                            >
                                              Description
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-lg-12 pl-0 pr-0">
                                          <textarea
                                            className="cn-textarea"
                                            type="text"
                                            placeholder="Item Description"
                                            name="description"
                                            value={itemData.description}
                                            onChange={handleItemChange}
                                            rows={2}
                                            style={{
                                              backgroundColor: "#F6F6F6",
                                              borderRadius: "9px",
                                              padding: "12px",
                                              width: "100%",
                                            }}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Modal.Body>
                                  <Modal.Footer className="p-2">
                                    <div className="modal-bottombtns">
                                      <button
                                        type="button"
                                        class="modalbtn-1 btn mt-0"
                                        onClick={handleItemClose}
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="button"
                                        class="modalbtn-3 btn btn-primary"
                                        onClick={handleItem}
                                        data-bs-dismiss="modal"
                                      >
                                        Add Description
                                      </button>
                                    </div>
                                  </Modal.Footer>
                                </Modal>

                                <td style={{ whiteSpace: "nowrap" }}>
                                  <select
                                    className="form-control"
                                    style={{ width: "100px" }}
                                    name="account"
                                    value={data.account}
                                    onChange={(e) => handleAccoutChange(e, i)}
                                  >
                                    <option selected={true} value="">
                                      Select Account
                                    </option>
                                    <option
                                      disabled="true"
                                      style={{ color: "black" }}
                                    >
                                      Other Current Assets
                                    </option>
                                    <option value="Advance Tax">
                                      Advance Tax
                                    </option>
                                    <option value="Employee Advance">
                                      Employee Advance
                                    </option>
                                    <option value="Input Tax Credits">
                                      Input Tax Credits
                                    </option>
                                    <option value="Input CGST">
                                      {" "}
                                      - Input CGST
                                    </option>
                                    <option value="Input IGST">
                                      {" "}
                                      - Input IGST
                                    </option>
                                    <option value="Input SGST">
                                      {" "}
                                      - Input SGST
                                    </option>
                                    <option value="Prepaid Expenses">
                                      Prepaid Expenses
                                    </option>
                                    <option value="Reverse Charge Tax Input but not due">
                                      Reverse Charge Tax Input but not due
                                    </option>
                                    <option value="TDS Receivable">
                                      TDS Receivable
                                    </option>
                                    <option
                                      disabled="true"
                                      style={{ color: "black" }}
                                    >
                                      Fixed Assets
                                    </option>
                                    <option value="Furniture and Equipment">
                                      Furniture and Equipment
                                    </option>
                                    <option
                                      disabled="true"
                                      style={{ color: "black" }}
                                    >
                                      Other Current Liability
                                    </option>
                                    <option value="Employee Reimbursements">
                                      Employee Reimbursements
                                    </option>
                                    <option value="GST Payable">
                                      GST Payable
                                    </option>
                                    <option value="Output CGST">
                                      {" "}
                                      - Output CGST
                                    </option>
                                    <option value="Output IGST">
                                      {" "}
                                      - Output IGST
                                    </option>
                                    <option value="Output SGST">
                                      {" "}
                                      - Output SGST
                                    </option>
                                    <option value="Opening Balance Adjustments">
                                      Opening Balance Adjustments
                                    </option>
                                    <option value="Tax Payable">
                                      Tax Payable
                                    </option>
                                    <option value="TDS Payable">
                                      TDS Payable
                                    </option>
                                    <option value="Unearned Revenue">
                                      Unearned Revenue
                                    </option>
                                    <option
                                      disabled="true"
                                      style={{ color: "black" }}
                                    >
                                      Expense
                                    </option>
                                    <option value="Advertising And Marketing">
                                      Advertising And Marketing
                                    </option>
                                    <option value="Automobile Expense">
                                      Automobile Expense
                                    </option>
                                    <option value="Bad Debt">Bad Debt</option>
                                    <option value="Bank Fees and Charges">
                                      Bank Fees and Charges
                                    </option>
                                    <option value="Consultant Expense">
                                      Consultant Expense
                                    </option>
                                    <option value="Contract Assets">
                                      Contract Assets
                                    </option>
                                    <option value="Credit Card Charges">
                                      Credit Card Charges
                                    </option>
                                    <option value="Depreciation And Amortisation">
                                      Depreciation And Amortisation
                                    </option>
                                    <option value="Depreciation Expense">
                                      Depreciation Expense
                                    </option>
                                    <option value="IT and Internet Expenses">
                                      IT and Internet Expenses
                                    </option>
                                    <option value="Janitorial Expense">
                                      Janitorial Expense
                                    </option>
                                    <option value="Lodging">Lodging</option>
                                    <option value="Meals and Entertainment">
                                      Meals and Entertainment
                                    </option>
                                    <option value="Merchandise">
                                      Merchandise
                                    </option>
                                    <option value="Office Supplies">
                                      Office Supplies
                                    </option>
                                    <option value="Other Expenses">
                                      Other Expenses
                                    </option>
                                    <option value="Postage">Postage</option>
                                    <option value="Printing and Stationery">
                                      Printing and Stationery
                                    </option>
                                    <option value="Raw Materials And Consumables">
                                      Raw Materials And Consumables
                                    </option>
                                    <option value="Rent Expense">
                                      Rent Expense
                                    </option>
                                    <option value="Repairs and Maintenance">
                                      Repairs and Maintenance
                                    </option>
                                    <option value="Salaries and Employee Wages">
                                      Salaries and Employee Wages
                                    </option>
                                    <option value="Telephone Expense">
                                      Telephone Expense
                                    </option>
                                    <option value="Transportation Expense">
                                      Transportation Expense
                                    </option>
                                    <option value="Travel Expense">
                                      Travel Expense
                                    </option>
                                    <option value="Uncategorized">
                                      Uncategorized
                                    </option>
                                    <option
                                      value=""
                                      disabled=""
                                      style={{ color: "black" }}
                                    >
                                      Cost Of Good Sold
                                    </option>
                                    <option value="Cost of Goods Sold">
                                      Cost of Goods Sold
                                    </option>
                                    <option value="Job Costing">
                                      Job Costing
                                    </option>
                                    <option value="Labor">Labor</option>
                                    <option value="Materials">Materials</option>
                                    <option value="Subcontractor">
                                      Subcontractor
                                    </option>
                                  </select>
                                </td>
                                {sizeCheck === "YES" && (
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <input
                                      className="form-control noArrowNumber noBorder"
                                      value={data.size || ""}
                                      type="number"
                                      onChange={(e) => handleSizeChange(e, i)}
                                    />
                                  </td>
                                )}
                                  <td style={{ whiteSpace: "nowrap" }}>
                                  <input
                                    className="form-control noArrowNumber noBorder"
                                    value={data.per || ""}
                                    type="number"
                                    readOnly
                                    // onChange={(e) => handleQuantityChange(e, i)}
                                  />
                                </td>
                                {quantityCheck === "YES" && (
                                <td style={{ whiteSpace: "nowrap" }}>
                                  <input
                                    className="form-control noArrowNumber noBorder"
                                    value={data.quantity || ""}
                                    type="number"
                                    onChange={(e) => handleQuantityChange(e, i)}
                                  />
                                </td>
                                )}

                                <td style={{ whiteSpace: "nowrap" }}>
                                  <input
                                    className="form-control noArrowNumber noBorder"
                                    value={data.rate}
                                    type="number"
                                    onChange={(e) => handleRateChange(e, i)}
                                  />
                                </td>
                                {discountCheck === "YES" && (
                                  <td style={{ width: "10%", padding: "0px" }}>
                                    <div className="d-flex justify-content-center">
                                      <input
                                        className="form-control col-md-6 noBorder"
                                        value={data.discount || ""}
                                        type="number"
                                        onChange={(e) =>
                                          handleDiscountChange(e, i)
                                        }
                                        name="discount"
                                        style={{
                                          textAlign: "center",
                                          padding: "0px",
                                          marginRight: "5px",
                                        }}
                                      />
                                      <select
                                        className="border-0 noBorder"
                                        value={data.discountType}
                                        onChange={(e) =>
                                          handleDiscountChange(e, i)
                                        }
                                        name="discountType"
                                      >
                                        <option>₹</option>
                                        <option>%</option>
                                      </select>
                                    </div>
                                  </td>
                                )}
                                {bussinessRegistered === "YES" && (
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <select
                                      className="form-control"
                                      value={data.tax}
                                      onChange={(e) => handleTaxChange(e, i)}
                                      style={{ padding: "0px" }}
                                    >
                                      <option value="0">Not Applicable</option>
                                      <option value="5">5 GST [5%]</option>
                                      <option value="12">12 GST [12%]</option>
                                      <option value="18">18 GST [18%]</option>
                                      <option value="28">28 GST [28%]</option>
                                    </select>
                                  </td>
                                )}
                                <td style={{ whiteSpace: "nowrap" }}>
                                  <span>
                                    {parseFloat(totalAmountWithTax).toFixed(2)}
                                  </span>
                                </td>
                                <td
                                  className="text-center"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  <button
                                    type="button"
                                    className="btn px-2 btn-light icon-border btn-sm"
                                    onClick={() => handleDeleteRow(i)}
                                  >
                                    <i className="fa fa-trash text-danger"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* endtable */}

              <Row>
                <Col>
                  <button
                    type="button"
                    className="btn addline-btn"
                    onClick={handleAdd}
                  >
                    + Add Another Line
                  </button>
                </Col>
              </Row>

              {/* <Row> */}
              <div className="grid-container">
                <div className="flex-item p-0 d-flex flex-column-reverse pb-0">
                  <div className="row pt-3">
                    <div className="col-lg-6">
                      <label
                        className="form-label"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Customer Notes
                      </label>
                    </div>
                    <div className="col-lg-12">
                      <textarea
                        className="cn-textarea"
                        placeholder="Customer Notes..."
                        name="customerNote"
                        value={customerNote || "Thank You For Business."}
                        onChange={(e) => setCustomerNote(e.target.value)}
                        type="text"
                        rows={1}
                        style={{
                          // border: "1px solid #ced4da",
                          backgroundColor: "#F6F6F6",
                          borderRadius: "7px",
                          padding: "12px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="customer-subtotal-box p-2">
                  <div className="customer-flex-item">
                    <div className="row">
                      <div className="col-lg-8">
                        <label className="form-label">Sub Total</label>
                      </div>
                      <div className="col-lg-4 text-end pl-0">
                        <label className="form-label">
                          {calculateSubTotal()}
                        </label>
                      </div>
                    </div>

                    {discountCheck === "YES" && (
                      <div className="row d-flex align-items-baseline mt-1">
                        <div className="col-lg-5 pr-0">
                          <label>Discount</label>
                        </div>
                        <div className="col-lg-3 pl-0 d-flex">
                          <input
                            className="form-control"
                            value={disAllValue || "0"}
                            type="number"
                            name="discount"
                            onChange={handleDisAllValueChange}
                            style={{
                              height: "36px",
                              padding: "3px 8px",
                              backgroundColor: "#F6F6F6",
                            }}
                          />
                          <select
                            className="form-control ml-1 pl-0 rounded"
                            name="discountType"
                            value={discountType}
                            onChange={handleDiscountTypeChange}
                            style={{
                              height: "44px",
                              width: "40px",
                              padding: "3px 8px",
                              backgroundColor: "#F6F6F6",
                            }}
                          >
                            <option disabled>Select</option>
                            <option value="₹">₹</option>
                            <option value="%">%</option>
                          </select>
                        </div>

                        <div className="col-lg-4 text-end pl-0">
                          <label className="form-label textColor-red">
                            {" "}
                            {isNaN(parseFloat(disValue).toFixed(2))
                              ? "0.00"
                              : -parseFloat(disValue).toFixed(2)}
                          </label>
                        </div>
                      </div>
                    )}

                    {bussinessRegistered === "YES" && (
                      <div className="row mt-2">
                        {userState.state !== customerState && (
                          <>
                            {calculateIGSTForTax().map((taxResult, index) => (
                              <React.Fragment key={index}>
                                <div className="col-lg-8">
                                  <label className="form-label required">
                                    IGST ({(taxResult.taxRate * 100).toFixed(0)}
                                    %)
                                  </label>
                                </div>
                                <div className="col-lg-4 text-end pl-0">
                                  <label className="form-label required">
                                    {taxResult.totalIGST.toFixed(2)}
                                  </label>
                                </div>
                              </React.Fragment>
                            ))}
                          </>
                        )}

                        {userState.state === customerState && (
                          <>
                            {Object.entries(calculateGroupedSGSTandCGST()).map(
                              ([taxRate, taxData], index) => (
                                <React.Fragment key={index}>
                                  <div className="col-lg-8">
                                    <label className="form-label required">
                                      CGST (
                                      {((parseFloat(taxRate) * 100) / 2)
                                        .toFixed(1)
                                        .replace(/\.0$/, "")}
                                      %)
                                    </label>
                                  </div>
                                  <div className="col-lg-4 text-end pl-0">
                                    <label className="form-label required">
                                      {taxData.totalCGST.toFixed(2)}
                                    </label>
                                  </div>
                                  <div className="col-lg-8 mt-2">
                                    <label className="form-label required">
                                      SGST (
                                      {((parseFloat(taxRate) * 100) / 2)
                                        .toFixed(1)
                                        .replace(/\.0$/, "")}
                                      %)
                                    </label>
                                  </div>
                                  <div className="col-lg-4 text-end pl-0 mt-2">
                                    <label className="form-label required">
                                      {taxData.totalSGST.toFixed(2)}
                                    </label>
                                  </div>
                                </React.Fragment>
                              )
                            )}
                          </>
                        )}
                      </div>
                    )}

                    <div className="row mt-3 mb-3">
                      <div className="col-lg-5 pr-0">
                        <input
                          className="form-control col-md-10"
                          type="text"
                          placeholder="Adjustment"
                          value={adjustmentLabel}
                          onChange={(e) => setAdjustmentLabel(e.target.value)}
                          name="adjustmentLabel"
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            color: "black",
                            "::placeholder": {
                              color:
                                "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                            },
                          }}
                        />
                      </div>
                      <div className="col-lg-5 pl-0">
                        <input
                          className="form-control col-md-10"
                          type="text"
                          placeholder="Label"
                          value={adjustmentValue}
                          onChange={(e) => setAdjustmentValue(e.target.value)}
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "10px",
                            color: "black",
                          }}
                        />
                      </div>
                      <div className="col-lg-2 text-end pl-0">
                        <label className="form-label mt-2">
                          {oppositeValue}
                        </label>
                      </div>
                    </div>

                    <div className="my-4 d-flex justify-content-between">
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ width: "70%" }}
                      >
                        <div className="col-lg-7 pl-0">
                          <label className="rdiobox">
                            <span>TCS</span>
                          </label>
                        </div>
                        <div
                          className="col-lg-6 p-0"
                          style={{ width: "190px" }}
                        >
                          <Select
                            options={tcs}
                            value={selectedTcsOption}
                            onChange={handleTcsOptionChange}
                            placeholder="Select TCS..."
                            styles={customStyles2}
                          />
                        </div>
                      </div>
                      <label className="form-label">{discountAmountTcs}</label>
                    </div>

                    <hr />
                    <div className="d-flex justify-content-between">
                      <label className="form-label required ">
                        Total (in Rupees) :
                      </label>
                      <label className="form-label required">
                        {calculateTotal()}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-4" />
              {/* </Row> */}

              <Row>
                <Col md="12">
                  <div className="row pt-2">
                    <div className="col-lg-6">
                      <label
                        className="form-label required mg-b-0"
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          borderRadius: "7px",
                        }}
                      >
                        Terms & Conditions
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-12 pl-0 pr-0">
                    <ReactQuill
                      value={termsAndCondition}
                      onChange={handleEditorChange} // Function to update state
                      theme="snow" // Use "bubble" for a minimal UI
                      style={{ height: "300px", marginBottom: "50px" }}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
                          [{ font: [] }], // Font options
                          [{ size: [] }], // Font size
                          [{ color: [] }, { background: [] }], // Text color & background
                          ["bold", "italic", "underline", "strike"], // Basic text styles
                          [{ script: "sub" }, { script: "super" }], // Subscript & superscript
                          [{ align: [] }], // Text alignment
                          [{ list: "ordered" }, { list: "bullet" }], // Lists
                          [{ indent: "-1" }, { indent: "+1" }], // Indentation
                          ["blockquote", "code-block"], // Blockquote & code block
                          ["link", "image", "video"], // Media links
                          ["clean"], // Remove formatting
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "color",
                        "background",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "script",
                        "align",
                        "list",
                        "bullet",
                        "indent",
                        "blockquote",
                        "code-block",
                        "link",
                        "image",
                        "video",
                      ]}
                    />
                    <i className="text-muted">
                      This Terms and Condition will be displayed on the purchase
                      order
                    </i>
                  </div>
                </Col>
              </Row>

              <div className="text-right invoice-bottombtns">
                <button
                  type="button"
                  className="btn-1 btn mt-0"
                  onClick={handleCancleClick}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-1 btn mt-0"
                  onClick={insertDataIntoDatabaseAsDraft}
                >
                  {isLoading2 ? "Saving..." : "Save as Draft"}
                </button>
                <button
                  type="button"
                  className="btn-3 btn btn-primary"
                  onClick={insertDataIntoDatabase}
                >
                  {isLoading ? "Saving..." : "Create Order"}
                </button>
              </div>
              <div className="clearfix"></div>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default PurchaseOrderNew;
