import React, { useState, useEffect } from "react";
import { Row, Col, Card, Modal } from "react-bootstrap";
import Select from "react-select";
import "./popup.css";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function DeliveryChallanUpdate(props) {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");

  const handleAdd = () => {
    setUserDetails([
      ...userDetails,
      {
        itemsDetails: "",
        size: "",
        per:"1",
        quantity: "1",
        rate: "0.0",
        discount: "0.0",
        discountType: "",
        tax: "0",
        alternateUnit: "", // Add this
        alternateUnitValue: "0.0", // Add this
        calculatedAltUnitValue: "0.0", // Add this
        singleAltUnitValue: "0.0",
        hasAlternateUnit: "",
      },
    ]);
  };

  const [manualCheck, setManualCheck] = useState("No");

  const handleDeleteRow = (i) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this estimate!"
    );

    if (userConfirmed) {
      const updatedUserDetails = [...userDetails];
      // Check if there is only one row remaining
      if (updatedUserDetails.length === 1) {
        alert("Cannot delete the last remaining row Data.");
        return;
      }

      updatedUserDetails.splice(i, 1);
      setUserDetails(updatedUserDetails); // Update the state with the modified array
    } else {
      // If the user clicks "Cancel", show a cancellation message
      alert("Delivery Note deletion canceled!");
    }
  };
  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      props.handleCancel();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };
  const [termsAndCondition, setTermsAndCondition] = useState("");

  const [deliveryChallan1, setDeliveryChallan] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(BASE_URL + `/getCurruntNumberList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        type: "deliverychallan",
        uniqueId: decryptedUniqueId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setLoading(false);
          setDeliveryChallan(data.responseMessage);
        } else {
          setLoading(false);
          console.error("Failed to fetch numberList:", data.responseMessage);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching Number List:", error);
      });
  }, []);

  const [fetchedCustomerName, setFetchedCustomerName] = useState("");

  const [fetchedChallanType, setFetchedChallanType] = useState("");

  const [selectedTerms, setSelectedTerms] = useState("");
  const handleEditorChange = (value) => {
    setTermsAndCondition(value);
  };

  const updateTotal = (rowData) => {
    let calculatedTotal = 0;
    rowData.forEach((data) => {
      const amount = calculateAmount(data);
      calculatedTotal += parseFloat(amount);
    });
  };

  const calculateAmount = (data, tax) => {
    const { quantity, rate, discount, discountType } = data;
    let amount = quantity * rate;

    if (discount) {
      amount -= discountType === "%" ? (amount * discount) / 100 : discount;
    }

    return amount;
  };

  const [selectedTaxRate, setSelectedTaxRate] = useState(0);
  const calculateTaxAmount = (amount, taxRate) => {
    if (taxRate > 0) {
      return amount * taxRate;
    }
    return 0;
  };

  const calculateSubTotal = () => {
    let subTotal = 0;
    userDetails.forEach((data) => {
      const amount = calculateAmount(data);
      subTotal += parseFloat(amount);
    });
    return subTotal.toFixed(2);
  };

  const calculateIGST = () => {
    let totalIGST = 0;

    userDetails.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      if (taxRate > 0) {
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = (parseFloat(amount) * taxRate) / 100;
        totalIGST += increasedTaxAmount;
      }
    });
    return totalIGST.toFixed(2);
  };

  const calculateIGSTForTax = () => {
    const taxResults = [];

    userDetails.forEach((data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate > 0) {
        let existingTaxResult = taxResults.find(
          (result) => result.taxRate === taxRate
        );
        if (!existingTaxResult) {
          existingTaxResult = { taxRate, totalIGST: 0 };
          taxResults.push(existingTaxResult);
        }
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = amount * taxRate;
        existingTaxResult.totalIGST += increasedTaxAmount;
      }
    });
    return taxResults;
  };

  const calculateGroupedSGSTandCGST = () => {
    const taxGroups = userDetails.reduce((acc, data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate === 0) return acc; // Skip 0% GST
      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = amount * taxRate;
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;
      if (!acc[taxRate]) {
        acc[taxRate] = { totalSGST: 0, totalCGST: 0 };
      }
      acc[taxRate].totalSGST += sgst;
      acc[taxRate].totalCGST += cgst;
      return acc;
    }, {});

    return taxGroups;
  };

  const calculateSGSTandCGST = () => {
    let totalSGST = 0;
    let totalCGST = 0;
    userDetails.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = parseFloat(amount) - amount / (1 + taxRate);
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;
      totalSGST += sgst;
      totalCGST += cgst;
    });
    return {
      totalSGST: totalSGST.toFixed(2),
      totalCGST: totalCGST.toFixed(2),
    };
  };

  const [adjustmentValue, setAdjustmentValue] = useState(""); // Step 1: State for adjustment value
  const [oppositeValue, setOppositeValue] = useState(""); // State for opposite value

  useEffect(() => {
    const parsedValue = parseFloat(adjustmentValue);
    if (!isNaN(parsedValue)) {
      const opposite = parsedValue * 1;
      setOppositeValue(opposite.toFixed(2));
    } else {
      setOppositeValue("");
    }
  }, [adjustmentValue]);

  const calculateTotal = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const igst = parseFloat(calculateIGST() || userDetails.igst);
    const adjustment = parseFloat(adjustmentValue || formData.adjustment) || 0;
    const total1 = subTotal + igst + adjustment;
    const total = total1 - disValue;
    return total.toFixed(2);
  };

  const [formData, setFormData] = useState([]);

  const [userDetails, setUserDetails] = useState([
    {
      itemDetails: "",
      hsnSac: "",
      per:"",
      quantity: "",
      rate: "",
      rateOne: "",
      pack: "",
      discount: "",
      discountType: "%",
      tax: "",
      selectedTaxRate: 0,
      description1: "",
      description2: "",
      alternateUnit: "", // Add this
      alternateUnitValue: "0.0", // Add this
      calculatedAltUnitValue: "0.0", // Add this
      singleAltUnitValue: "0.0",
      hasAlternateUnit: "",
    },
  ]);

  const [customerFormData, setCustomerFormData] = useState({
    uniqueId: decryptedUniqueId,
    customerType: "Business",
    status: "Active",
    someName: "Mr.",
    firstName: "",
    lastName: "",
    companyName: "",
    displayName:"",
    customerName: "",
    email: "",
    workphone: "",
    mobile: "",
    designation: "",
    department: "",
    website: "",
    currency: "INR- Indian Rupee",
    openingBalance: "0",
    paymentTerms: "",
    facebook: "",
    twitter: "",
    billAttention: "",
    billCountry: "India",
    billAddress1: "",
    billAddress2: "",
    billCity: "",
    billState: "Maharashtra",
    billZipcode: "",
    billPhone: "",
    billFax: "",
    shipAttention: "",
    shipCountry: "India",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "Maharashtra",
    shipZipcode: "",
    shipPhone: "",
    shipFax: "",
    gstin: "",
    pan: "",
    placeOfSupply: "[MH] - Maharashtra",
    taxable: "",
    reason: "N/A",
    activeStatus: "Active",
    gstTreatment: "",
    contactPerson: [
      {
        other: "",
        otherFirstname: "",
        otherLastname: "",
        otherEmail: "",
        otherWorkphone: "",
        otherMobile: "",
      },
    ],
  });
  const [ids, setIds] = useState([]);
  const { deliveryChallan } = props;

  const [validationMessage1, setValidationMessage1] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const insertDataIntoDatabase = async () => {
    let newValidationMessage = "";
    if (!formData.customerName) {
      newValidationMessage += "Please select a customer \n";
    } else if (!formData.deliveryChallan) {
      newValidationMessage += "Please enter challan number.\n";
    } else if (!formData.deliveryChallanDate) {
      newValidationMessage += "Please select a challan date \n";
    }

    setValidationMessage1(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0);
      return;
    }

    const formDataObject = {
      uniqueId: decryptedUniqueId,
      inquiryId: formData.inquiryId,
      ids: ids.map((id) => ({ id })),
      items: userDetails.map((user) => ({
        itemDetails: user.itemDetails || "",
        hsnSac: user.hsnSac || "",
        size: user.size || "",
        per: user.per || "",
        quantity: user.quantity || "",
        rateOne: user.rateOne,
        pack: user.pack,
        rate: user.rate || formData.rate,
        discount: user.discount || formData.discount,
        discountType: user.discountType || formData.discountType,
        tax: user.tax || formData.selectedTaxRate,
        amount: calculateAmount(user) || 0,
        account: user.account || "",
        description1: user.description1 || "",
        description2: user.description2 || "",
        customerTaxType: userState.state === customerState ? 1 : 2,
        cgst:
          userState.state === customerState
            ? (calculateAmount(user) * user.tax) / 100 / 2
            : 0,
        sgst:
          userState.state === customerState
            ? (calculateAmount(user) * user.tax) / 100 / 2
            : 0,
        igst:
          userState.state !== customerState
            ? (calculateAmount(user) * user.tax) / 100
            : 0,
        alternateUnitValue: user.calculatedAltUnitValue,
        alternateUnit: user.alternateUnit,
      })),
      deliveryChallanDate: dateValue || formData.deliveryChallanDate,
      customerName: formData.customerName || "",
      customerId: selectedCustomerId || formData.customerId,
      deliveryChallan: formData.deliveryChallan || "",
      oldDeliveryChallan: oldDeliveryChallan,
      challanType: fetchedChallanType,
      terms: formData.terms || "",
      salesPerson: formData.salesPerson || "",
      reference: formData.reference || "",
      subject: formData.subject || "",
      adjustment: formData.adjustment || 0,
      adjustmentLabel: formData.adjustmentLabel || "",
      customerNotes: formData.customerNotes || "",
      termsAndConditions: formData.termsAndConditions || "",
      taxCheck: bussinessRegistered,
      discountCheck: discountCheck,
      overallDiscount: disAllValue || 0,
      overallDiscountType: discountType || 0,
      totalDiscount: disValue || 0,
      subtotal: calculateSubTotal(),
      total: calculateTotal(),
      status: "Sent",
      manualCheck: manualCheck,
      state: customerState,
      sizeCheck: sizeCheck,
      hsnCheck: hsnCheck,
      quantityCheck:quantityCheck,
      netRateCheck: netRateCheck,
      gstNumber: formData.gstNumber,
      deliveryChallanCalculateCheck: formData.deliveryChallanCalculateCheck,
      packCheck: packCheck,
    };
    setIsLoading(true);
    const response = await fetch(BASE_URL + "/updateDeliveryChallan", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formDataObject),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: `Updated Delivery Note`,
          });
          setIsLoading(false);
          props.handleCancel();
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetch(BASE_URL + `/fetchByDeliveryChallan/${deliveryChallan}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setLoading(false);
          setUserDetails(data.responseMessage);

          const updatedItems = data.responseMessage.map((item) => {
            const quantity = parseFloat(item.quantity) || 1;
            const alterUV = parseFloat(item.alternateUnitValue) || 0;
            // Only calculate singleAltUnitValue if this item actually has an alternateUnitValue
            const hasAltUnit = item.alternateUnit && item.alternateUnitValue;
            const singleUV =
              hasAltUnit && quantity > 0 ? alterUV / quantity : 0;

            return {
              ...item,
              calculatedAltUnitValue: alterUV,
              singleAltUnitValue: singleUV,
              hasAlternateUnit: hasAltUnit, // Flag to check if this item has an alternate unit
            };
          });
          setUserDetails(updatedItems);

          const itemIds = data.responseMessage.map((item) => item.id);
          setIds(itemIds);
          setItemDescriptions(
            data.responseMessage.map((item) => ({
              itemDetails: item.itemDetails,
              description: item.description2 || "",
            }))
          );
          const Data = data.responseMessage[0];
          setBussinessRegistered(Data.taxCheck);
          setOldDeliveryChallan(Data.deliveryChallan);
          setDiscountCheck(Data.discountCheck);
          setSizeCheck(Data.sizeCheck);
          setHsnCheck(Data.hsnCheck);
          setQuantityCheck(Data.quantityCheck);
          setNetRateCheck(Data.netRateCheck);
          setPackCheck(Data.packCheck);
          setFormData({
            customerName: Data.customerName,
            customerId: Data.customerId,
            inquiryId: Data.inquiryId,
            deliveryChallan: Data.deliveryChallan,
            reference: Data.reference,
            deliveryChallanDate: Data.deliveryChallanDate,
            dueDate: Data.dueDate,
            salesPerson: Data.salesPerson,
            subject: Data.subject,
            challanType: Data.challanType,
            customerNotes: Data.customerNotes,
            adjustment: Data.adjustment,
            adjustmentLabel: Data.adjustmentLabel,
            subtotal: Data.subtotal,
            total: Data.total,
            termsAndConditions: Data.termsAndConditions,
            terms: Data.terms,
            value: Data.value,
            cgst: Data.cgst,
            sgst: Data.sgst,
          });
          setCustomerState(Data.state);
          setFetchedCustomerName(Data.customerName);
          setFetchedChallanType(Data.challanType);
          setDisAllValue(Data.overallDiscount || ""); // Assuming the discount value is in Data.discount
          setDiscountType(Data.overallDiscountType || "₹");
        } else {
          setLoading(false);
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);

  // Call the function when the user submits the form
  const handleSubmit = () => {
    insertDataIntoDatabase();
    // Optionally, you can perform other actions after data insertion
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setManualCheck("Yes");
    if (name === "adjustment") {
      setAdjustmentValue(value);
    }
  };

  const handleInputChangess = (e, i) => {
    const { name, value } = e.target;
    const updatedUserDetails = [...userDetails]; // Create a copy of the state array
    updatedUserDetails[i][name] = value; // Update the specific property

    if (name === "quantity") {
      const quantity = parseFloat(value) || 0;
      // Only calculate if this item has an alternate unit
      if (
        updatedUserDetails[i].hasAlternateUnit ||
        updatedUserDetails[i].alternateUnit
      ) {
        const singleUV = updatedUserDetails[i].singleAltUnitValue || 0;
        const newAlternateUnitValue = quantity * singleUV;
        updatedUserDetails[i].calculatedAltUnitValue = newAlternateUnitValue;
      }
    }

    setUserDetails(updatedUserDetails); // Update the state with the modified data

    if (name === "tax") {
      const taxRate = parseFloat(value);
      setSelectedTaxRate(taxRate);
    }
  };

  const [salesPersonData, setSalesPersonData] = useState([]);

  useEffect(() => {
    fetch(BASE_URL + `/getAllSalesPersonByUniqueId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setSalesPersonData(data.responseMessage);
        } else {
          console.error("Failed to fetch salesperson:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching salesperson:", error);
      });
  }, []);

  const [custNames, setCustNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(""); // State to store the selected customer's ID

  const fetchAllCustomers = () => {
    setLoading(true);
    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setLoading(false);
          setCustNames(data.responseMessage);
        } else {
          setLoading(false);
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching Customer Name:", error);
      });
  };
  useEffect(() => {
    fetchAllCustomers();
  }, []);

  useEffect(() => {
    if (fetchedCustomerName) {
      const selectedCustomerObject = custNames.find(
        (person) => person.customerName === fetchedCustomerName
      );

      if (selectedCustomerObject) {
        const customerState = selectedCustomerObject.billState;
        setCustomerState(customerState);
      }
    }
  }, [fetchedCustomerName, custNames]);

  // Handle customer selection
  const handleCustomerChange = (selectedOption) => {
    const selectedValue = selectedOption.value; // Assuming selectedOption has a 'value' property
    setSelectedCustomer(selectedValue);
    setFetchedCustomerName(selectedValue);
    setFormData({ ...formData, customerName: selectedValue });
    // Find the selected customer object in the fetched data
    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );
    const fetchId = formData.customerId;
    const selectId = selectedCustomerObject.id;
    if (fetchId == selectId) {
      setCustomerState(formData.state);
      setSelectedCustomerId(selectedCustomerObject.id);
      setSelectedTerms(selectedCustomerObject.paymentTerms);
    } else if (selectedCustomerObject) {
      setSelectedCustomerId(selectedCustomerObject.id);
      const customerState = selectedCustomerObject.billState;
      setCustomerState(customerState);
    } else {
      console.log("Selected Customer: (Not found)");
    }
  };

  const [bussinessRegistered, setBussinessRegistered] = useState("");
  const [oldDeliveryChallan, setOldDeliveryChallan] = useState("");
  const [discountCheck, setDiscountCheck] = useState("");
  const [sizeCheck, setSizeCheck] = useState("");
  const [hsnCheck, setHsnCheck] = useState("");
  const [quantityCheck,setQuantityCheck] = useState("");
  const [netRateCheck, setNetRateCheck] = useState("");
  const [packCheck, setPackCheck] = useState("");
  const [userState, setUserState] = useState([]);
  const [customerState, setCustomerState] = useState("");

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");
  const fetchData = () => {
    setLoading(true);
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setLoading(false);
          const userState = data.responseMessage;
          setUserState(userState);
        } else {
          setLoading(false);
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setLoading(false);
          const Data = data.responseMessage[0].businessRegistered;
        } else {
          setLoading(false);
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const [itemDescriptions, setItemDescriptions] = useState([]);
  const [itemName, setItemName] = useState("");
  const [showItemModal, setShowItemModal] = useState(false);

  const [itemData, setItemData] = useState({
    uniqueId: decryptedUniqueId,
    description: "",
  });

  const handleItemClose = () => {
    setShowItemModal(false);
    setItemData({
      ...itemData,
      description: "",
    });
  };

  const handleItemChange = (event) => {
    const { name, value } = event.target;
    setItemData({ ...itemData, [name]: value });
  };

  const handleItem = (event) => {
    event.preventDefault();

    const newItemDescription = {
      itemDetails: itemName,
      description: itemData.description,
    };

    setItemDescriptions((prevDescriptions) => {
      const existingIndex = prevDescriptions.findIndex(
        (desc) => desc.itemDetails === itemName
      );

      if (existingIndex !== -1) {
        // Update existing description
        const updatedDescriptions = [...prevDescriptions];
        updatedDescriptions[existingIndex] = newItemDescription;
        return updatedDescriptions;
      } else {
        // Add new description
        return [...prevDescriptions, newItemDescription];
      }
    });
    setUserDetails((prevRows) =>
      prevRows.map((row) =>
        row.itemDetails === itemName
          ? { ...row, description2: itemData.description }
          : row
      )
    );
    setShowItemModal(false);
  };

  const handleItemShow = (itemDetails) => {
    const itemDescription = itemDescriptions.find(
      (desc) => desc.itemDetails === itemDetails
    );
    if (itemDescription) {
      setItemData({ description: itemDescription.description });
    } else {
      setItemData({ description: "" });
    }
    setItemName(itemDetails);
    setShowItemModal(true);
  };

  const [dropdownData, setDropdownData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const fetchItemData = () => {
    const postApiUrl = BASE_URL + `/getAllItemData`;

    setLoading(true);
    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, vendorId: "" }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        const responseData = data.responseMessage;
        setDropdownData(responseData.map((item) => item));
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  const [searchIndex, setSearchIndex] = useState(0);

  useEffect(() => {
    const results = dropdownData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, dropdownData]);

  const handleSearchChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...userDetails];
    newRow[index].itemDetails = value;
    setUserDetails(newRow);
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setSearchIndex(index);
  };

  const handleItemDetailsChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...userDetails];
    newRow[index].itemDetails = value;
    setUserDetails(newRow);
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setSearchIndex(index);
    if (value) {
      fetchItemRate(value, index);
    } else {
      handleSearchChange(value, index);
    }
  };

  const handleItemSelect = (selectedItem, selectedItemId, index) => {
    setSearchResults([]);
    const newRow = [...userDetails];
    newRow[index].itemDetails = selectedItem;
    setUserDetails(newRow);
    fetchItemRate(selectedItemId, index);
  };

  const fetchItemRate = (itemId, index) => {
    const getApiUrl = BASE_URL + `/getItemData/${itemId}`;

    setLoading(true);
    fetch(getApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        const rate = data.data.taxableAmount;
        const hsnSac = data.hsnCode;
        const description1 = data.data.description;
        const itemName = data.data.name;
        const tax = data.data.intraStateTaxRate;
        const pack = data.data.pack;
        const make = data.data.make;
        const alternateUnitValue = data.data.alternateUnitValue;
        const alternateUnit = data.data.alternateUnit;
        const singleAltUnitValue = data.data.alternateUnitValue;
        const updatedRow = [...userDetails];
        updatedRow[index].rate = rate;
        updatedRow[index].hsnSac = hsnSac;
        updatedRow[index].description1 = description1;
        updatedRow[index].itemDetails = itemName;
        updatedRow[index].tax = tax;
        updatedRow[index].pack = pack;
        updatedRow[index].make = make;
        updatedRow[index].alternateUnitValue = alternateUnitValue;
        updatedRow[index].alternateUnit = alternateUnit;
        updatedRow[index].calculatedAltUnitValue = alternateUnitValue;
        updatedRow[index].singleAltUnitValue = singleAltUnitValue;
        setUserDetails(updatedRow);
        setSearchResults([]);
        handleAdd();
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error making POST request:", error);
      });
  };

  const handleTaxChange = (e, index) => {
    setSelectedTaxRate(e.target.value);
    const newTaxRate = parseFloat(e.target.value);
    const updatedRow = [...userDetails];
    updatedRow[index].tax = newTaxRate;
    setUserDetails(updatedRow);
    calculateTotal();
  };

  const [disAllValue, setDisAllValue] = useState("");
  const [discountType, setDiscountType] = useState("₹");
  const [disValue, setDisValue] = useState("");

  const calculateDiscount = () => {
    const amount = parseFloat(calculateSubTotal()).toFixed(2);

    if (discountType === "₹") {
      setDisValue(disAllValue);
    } else if (discountType === "%") {
      const percentageDiscount = (amount * disAllValue) / 100;
      setDisValue(percentageDiscount.toFixed(2));
    }
  };

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
  };

  const handleDisAllValueChange = (e) => {
    setDisAllValue(e.target.value);
  };

  useEffect(() => {
    calculateDiscount();
  }, [disAllValue, discountType]);

  const [showDueDate, setShowDueDate] = useState(true);
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    setStartDate(new Date());
  }, []);

  const [dateValue, setDateValue] = useState("");

  const handleDate = (date) => {
    setStartDate(date);
    setFormData({ ...formData, terms: selectedTerms || formData.terms });
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue(normalFormat);
  };

  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return date;
  };

  const handleChange = (event) => {
    // setSelectedOption(event.target.value);
    // const { name, value } = event.target;
    // setCustomerFormData((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }));


    const { name, value } = event.target;
  
    setCustomerFormData((prevData) => {
      const newData = {
        ...prevData,
        [name]: value,
      };
      
      // If customerName is being changed, also update displayName with the same value
      if (name === 'customerName') {
        newData.displayName = value;
      }
      
      return newData;
    });
  };

  const optionsCustomers = custNames.map((customer) => ({
    value: customer.customerName,
    label: customer.customerName,
  }));

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validationMessage, setValidationMessage] = useState("");

  const handleSaveClick = () => {
    let newValidationMessage = "";

    if (!customerFormData.customerName) {
      newValidationMessage += "Please enter a customer display name.\n";
    } else if (
      customerFormData.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerFormData.email)
    ) {
      newValidationMessage += "Please enter a valid email address.\n";
    }
    setValidationMessage(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      // alert(newValidationMessage.trim()); // Trim trailing newline for better formatting
      return; // Exit function if validation fails
    }

    setLoading(true);
    fetch(BASE_URL + "/createCustomer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(customerFormData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setLoading(false);
          swal({
            icon: "success",
            title: "Customer Created",
            text: "",
          });
          setShow(false);
          fetchAllCustomers();
        } else if (data.responseCode === "400") {
          setLoading(false);
          swal({
            icon: "error",
            title: "This email already exists, please insert another email.",
            text: "",
          });
          setShow(true);
        } else {
          setLoading(false);
          swal({
            icon: "error",
            title: "Failed to Create Customer",
            text: "",
          });
          setShow(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        setShow(true);
      });
  };

  const [show1, setShow1] = useState(false);
  const [invoiceNumberPrefix, setInvoiceNumberPrefix] = useState([]);
  const [invoiceNumberNext, setInvoiceNumberNext] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");

  const setBill = () => {
    setShow1(true);
    fetchBill();
  };

  const [showInvoice, setShowInvoice] = useState(false);

  const closeInvoice = () => {
    setShowInvoice(false);
  };

  const copyAdd = () => {
    if (show1) {
      // If auto-generating is selected, set the generatedInvoiceNumber
      setInvoiceNumber(invoiceNumberPrefix + invoiceNumberNext);
      setFormData({
        ...formData,
        deliveryChallan: invoiceNumberPrefix + invoiceNumberNext,
      });
      setManualCheck("No");
    } else {
      // If manually adding is selected, set it to null
      setManualCheck("Yes");
      setInvoiceNumber("");
      setFormData({
        ...formData,
        deliveryChallan: "",
      });
    }

    // Close the popup
    closeInvoice();
  };

  const fetchBill = () => {
    const requestData = {
      // Include any other data you need to send in the request
      uniqueId: decryptedUniqueId,
      type: "salesorder",
    };

    // Define the POST request options
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
        // Add any other headers if required
      },
      body: JSON.stringify(requestData), // Convert the data to JSON format
    };

    // Make the POST request to your API endpoint
    setLoading(true);
    fetch(BASE_URL + `/getCurruntNumberList2`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setInvoiceNumberPrefix(data.responseMessage.prefix);
        setInvoiceNumberNext(data.responseMessage.numbers);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data: ", error);
      });
  };

  const optionsChallanType = [
    { value: "Supply Of Liquid Gas", label: "Supply Of Liquid Gas" },
    { value: "Job work", label: "Job work" },
    { value: "Supply On Approval", label: "Supply On Approval" },
    { value: "Other", label: "Other" },
  ];

  const handleChallanType = (selectedOption) => {
    setSelectedTerms(selectedOption.value);
    setFetchedChallanType(selectedOption.value);
    setFormData({ ...formData, challanType: selectedOption.value });
  };
  const generateNamePairs = () => {
    const { someName, firstName, lastName, companyName } = customerFormData;
    const pairs = [];

    if (firstName && lastName) {
      pairs.push(`${someName} ${firstName} ${lastName}`);
      pairs.push(`${firstName} ${lastName}`);
      pairs.push(`${lastName} , ${firstName}`);
      pairs.push(`${companyName}`);
    }

    return pairs;
  };

  return (
    <>
      <div className="my-3">
        <div className="d-flex align-items-center ml-1 mt-4">
          <div
            className="cursor-pointer d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              height: "35px",
              width: "38px",
              cursor: "pointer",
            }}
          >
            <ArrowBackIosIcon
              width={2}
              onClick={handleCancleClick}
              className="arrow-child"
            />
          </div>
          <div className="headitems ml-2">
            <h5
              className="ml-1 mb-0"
              style={{ letterSpacing: "1px", color: "#086bd5de" }}
            >
              Update Delivery Note
            </h5>
          </div>
        </div>
      </div>
      {validationMessage1 && (
        <Alert severity="error" className="mt-0">
          {validationMessage1.split("\n").map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </Alert>
      )}{" "}
      <div className="card border-2 mt-3">
        <div className="pl-4 pr-4">
          <div className="row pt-3">
            <div className="salespersonbox">
              <div className="text-style">
                Customer Name <span className="valid"> *</span>
              </div>
              <Select
                // className="dropdown-field"
                value={optionsCustomers.find(
                  (option) => option.value === fetchedCustomerName
                )}
                options={optionsCustomers}
                onChange={(selectedOption) =>
                  handleCustomerChange(selectedOption)
                }
                placeholder="Select Customer"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: "250px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    marginTop: "10px",
                    backgroundColor: "#F6F6F6",
                    borderRadius: "7px",
                    height: "46px",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    height: "19px",
                  }),
                }}
              />
              <button type="button" class="btn p-0" onClick={handleShow}>
                <span
                  class="text-primary"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  New Customer
                </span>
              </button>

              {/* Add customer popup*/}
              <Modal size="lg" show={show} onHide={handleClose}>
                <div class="modal-header d-flex align-items-center pb-2 pt-2">
                  <h5 class="modal-title fs-5" id="newcustomerPopup">
                    New Customer
                  </h5>
                  <button
                    type="button"
                    className="btn pr-0"
                    onClick={handleClose}
                  >
                    <ion-icon
                      name="close-circle-outline"
                      size="large"
                      style={{ width: "28px" }}
                    ></ion-icon>
                  </button>
                </div>
                <Modal.Body className="p-0 pb-3">
                  {validationMessage && (
                    <Alert severity="error" className="mt-0">
                      {validationMessage.split("\n").map((message, index) => (
                        <div key={index}>{message}</div>
                      ))}
                    </Alert>
                  )}{" "}
                  <div className="container">
                    <div className="row d-inline-flex">
                      <div className="modalinputchild">
                        <div className="text-style"> Salutation </div>
                        <select
                          className="form-control"
                          name="someName"
                          value={customerFormData.someName}
                          onChange={handleChange}
                          style={{
                            borderRadius: "9px",
                            backgroundColor: "#f5f5f5",
                            width: "200px",
                            height: "35px",
                            fontSize: "13px",
                            marginTop: "10px",
                          }}
                        >
                          <option value="Mr.">Mr.</option>
                          <option value="Mrs.">Mrs.</option>
                          <option value="Ms.">Ms.</option>
                          <option value="Miss.">Miss.</option>
                          <option value="Dr.">Dr.</option>
                        </select>
                      </div>
                      <div className="modalinputchild">
                        <div className="text-style">First Name</div>
                        <input
                          className="form-control"
                          placeholder="First Name"
                          name="firstName"
                          id="firstName"
                          value={customerFormData.firstName}
                          onChange={handleChange}
                          type="text"
                        />
                      </div>
                      <div className="modalinputchild">
                        <div className="text-style">Last Name</div>
                        <input
                          className="form-control"
                          placeholder="Last Name"
                          id="lastName"
                          name="lastName"
                          value={customerFormData.lastName}
                          onChange={handleChange}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="row d-inline-flex pt-2">
                      <div className="modalinputchild">
                        <div className="text-style"> Customer Email</div>
                        <input
                          className="form-control"
                          placeholder="Customer Email"
                          name="email"
                          type="text"
                          value={customerFormData.email}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="modalinputchild">
                        <div className="text-style">
                          {" "}
                          Customer Display Name
                          <span style={{ color: "red" }}> *</span>
                        </div>
                        <input
                          className="form-control"
                          placeholder="Customer Display Name"
                          list="customerNames"
                          name="customerName"
                          value={customerFormData.customerName}
                          onChange={handleChange}
                          style={{
                            borderRadius: "7px",
                            backgroundColor: "#f5f5f5",
                            width: "200px",
                            height: "40px",
                            fontSize: "13px",
                            marginTop: "10px",
                          }}
                        />
                        <datalist id="customerNames">
                          {generateNamePairs().map((pair, index) => (
                            <option key={index} value={pair} />
                          ))}
                        </datalist>{" "}
                      </div>
                      <div className="modalinputchild">
                        <div className="text-style">Company Name</div>
                        <input
                          className="form-control"
                          placeholder="Add company name"
                          name="companyName"
                          type="text"
                          value={customerFormData.companyName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row d-inline-flex pt-2">
                      <div className="modalinputchild">
                        <div className="text-style">Customer Phone</div>
                        <input
                          className="form-control"
                          placeholder="Work Phone"
                          name="workphone"
                          type="text"
                          value={customerFormData.workphone}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="modalinputchild">
                        <div className="text-style">Mobile</div>
                        <input
                          className="form-control"
                          placeholder="Mobile"
                          name="mobile"
                          type="text"
                          value={customerFormData.mobile}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="p-2">
                  <div className="modal-bottombtns">
                    <button
                      type="button"
                      class="modalbtn-1 btn mt-0"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="modalbtn-3 btn btn-primary"
                      onClick={handleSaveClick}
                    >
                      Create Customer
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="inputchild">
              <div className="text-style">
                Delivery Note <span className="valid"> *</span>
              </div>
              <input
                className="form-control"
                placeholder="Delivery Note"
                id=""
                name="deliveryChallan"
                value={formData.deliveryChallan}
                onChange={handleInputChange}
                type="text"
                style={{
                  padding: "12px",
                  backgroundColor: "#F6F6F6",
                  borderRadius: "7px",
                  color: "black",
                }}
              />

              <button
                type="button"
                className="btn p-0"
                data-bs-toggle="modal"
                data-bs-target="#generatePop"
              >
                <span
                  className="text-primary"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  Generate
                </span>
              </button>

              <div
                className="modal fade"
                id="generatePop"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header d-flex align-items-center pb-2 pt-2">
                      <h5 className="modal-title fs-5" id="generateinvoiceHead">
                        Sales Order Number
                      </h5>
                      <button
                        type="button"
                        className="btn pr-0"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <ion-icon
                          name="close-circle-outline"
                          size="large"
                          style={{ width: "28px" }}
                        ></ion-icon>
                      </button>
                    </div>
                    <div className="modal-body pt-1 pb-1">
                      <div className="popupinvoice-container ml-2 mt-2 mr-2 mb-0">
                        <div className="row headline">
                          <div className="customer-headline-label">
                            <label className="form-label">
                              <span className="headline">
                                Your sales order numbers are set on
                                auto-generate mode to save your time.
                                <br /> Are you sure about changing this setting?
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <label className="customer-rdiobox-label d-flex align-items-center">
                            <input
                              className="mr-2"
                              type="radio"
                              name="cus_type"
                              id="cus_bus"
                              value="Business"
                              checked={show1 === true ? true : false}
                              onClick={setBill}
                            />
                            <span className="customer-option">
                              Continue auto-generating sales order numbers
                            </span>
                          </label>
                        </div>
                        {show1 && (
                          <div className="row ml-3 mb-3">
                            <div className="customer-generate-inputfield">
                              <span>Prefix</span>
                              <input
                                className="form-control mt-1"
                                placeholder="Prefix"
                                value={invoiceNumberPrefix}
                                onChange={(e) =>
                                  setInvoiceNumberPrefix(e.target.value)
                                }
                              />
                            </div>
                            <div className="customer-generate-inputfield2">
                              <span>Next Number</span>
                              <input
                                className="form-control mt-1"
                                placeholder="Next Number"
                                value={invoiceNumberNext}
                                onChange={(e) =>
                                  setInvoiceNumberNext(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="row mt-1">
                          <label className="customer-rdiobox-label d-flex align-items-center">
                            <input
                              className="mr-2"
                              type="radio"
                              name="cus_type"
                              id="cus_bus"
                              value="Business"
                              checked={show1 === false ? true : false}
                              onClick={() => {
                                setShow1(false);
                              }}
                            />
                            <span className="customer-option">
                              I will add them manually each time
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="modal-bottombtns">
                        <button
                          type="button"
                          class="modalbtn-1 btn mt-0"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="modalbtn-3 btn btn-primary"
                          onClick={() => {
                            copyAdd();
                          }}
                          data-bs-dismiss="modal"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="inputchild">
              <div className="text-style">Reference</div>
              <input
                className="form-control"
                placeholder="Reference"
                name="reference"
                value={formData.reference}
                onChange={handleInputChange}
                type="text"
                style={{
                  padding: "12px",
                  backgroundColor: "#F6F6F6",
                  borderRadius: "7px",
                  color: "black",
                }}
              />
            </div>
          </div>

          <div className="row pt-2">
            <div className="inputchild">
              <div className="text-style">
                Delivery Note Date <span className="valid"> *</span>
              </div>
              {showDueDate ? (
                <input
                  className="form-control"
                  type="text"
                  value={formData.deliveryChallanDate}
                  onClick={(e) => {
                    setShowDueDate(false);
                    setFormData({
                      ...formData,
                      deliveryChallanDate: normalDateFormat(startDate),
                    });
                  }}
                  style={{
                    padding: "12px",
                    backgroundColor: "#F6F6F6",
                    borderRadius: "7px",
                    color: "black",
                  }}
                  readOnly
                />
              ) : (
                <div className="customDatePickerWidth">
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => handleDate(date)}
                  />
                </div>
              )}
            </div>

            <div className="salespersonbox">
              <div className="text-style">Note Type</div>

              <Select
                // className="dropdown-field"
                options={optionsChallanType}
                value={optionsChallanType.find(
                  (option) => option.value === fetchedChallanType
                )}
                onChange={handleChallanType}
                placeholder="Select a Note Type"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    marginTop: "10px",
                    width: "250px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#F6F6F6",
                    borderRadius: "7px",
                    height: "46px",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    height: "19px",
                  }),
                }}
              />
            </div>

            <div className="inputchild">
              <div className="text-style">Tracking No</div>
              <input
                className="form-control"
                placeholder="Tracking No"
                name="inquiryId"
                value={formData.inquiryId}
                readOnly
                type="text"
                style={{
                  backgroundColor: "#F6F6F6",
                  borderRadius: "7px",
                  padding: "12px",
                  color: "black",
                  height: "40px",
                  "::placeholder": {
                    color: "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                  },
                }}
              ></input>
            </div>
          </div>

          <>
            <Row className="mt-4">
              <Col md="3">
                <label
                  className="text-primary"
                  style={{ fontSize: 14, fontWeight: "600" }}
                >
                  ITEM INFO
                </label>
              </Col>
            </Row>
            <div>
              <div>
                <Row>
                  <Col>
                    <Card className="mb-3">
                      {/* <div className="table-responsive"> */}
                      <div style={{ overflowX: "auto" }}>
                        <table
                          className="table table-bordered  mb-0"
                          style={{
                            width: "100%",
                          }}
                        >
                          <thead className="text-center">
                            <tr>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "350px",
                                }}
                              >
                                ITEM DETAILS
                              </th>
                              {hsnCheck === "YES" && (
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  HSN/SAC
                                </th>
                              )}
                               <th
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "180px",
                                }}
                              >
                                PER
                              </th>
                              {quantityCheck === "YES" && (
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "180px",
                                }}
                              >
                                QUANTITY
                              </th>
                              )}
                              {formData.deliveryChallanCalculateCheck ===
                                "YES" && (
                                <>
                                  {sizeCheck === "YES" && (
                                    <th
                                      scope="col"
                                      style={{
                                        whiteSpace: "nowrap",
                                        minWidth: "100px",
                                      }}
                                    >
                                      SIZE
                                    </th>
                                  )}
                                  {netRateCheck === "YES" && (
                                    <th
                                      style={{
                                        whiteSpace: "nowrap",
                                        minWidth: "100px",
                                      }}
                                    >
                                      RATE
                                    </th>
                                  )}
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      minWidth: "100px",
                                    }}
                                  >
                                    {netRateCheck === "YES"
                                      ? "NET RATE"
                                      : "RATE"}
                                  </th>
                                  {packCheck === "YES" && (
                                    <th
                                      style={{
                                        whiteSpace: "nowrap",
                                        minWidth: "100px",
                                      }}
                                    >
                                      PACK
                                    </th>
                                  )}
                                  {discountCheck === "YES" && (
                                    <th
                                      style={{
                                        whiteSpace: "nowrap",
                                        minWidth: "100px",
                                      }}
                                    >
                                      DISCOUNT
                                    </th>
                                  )}{" "}
                                  {bussinessRegistered === "YES" && (
                                    <th
                                      style={{
                                        whiteSpace: "nowrap",
                                        minWidth: "100px",
                                      }}
                                    >
                                      TAX
                                    </th>
                                  )}
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      minWidth: "50px",
                                    }}
                                  >
                                    AMOUNT
                                  </th>
                                </>
                              )}
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "50px",
                                }}
                              >
                                ACTION
                              </th>
                            </tr>
                          </thead>

                          {userDetails.map((data, i) => {
                            const amount = calculateAmount(data, data.tax);
                            const totalAmountWithTax = parseFloat(amount);
                            const itemDescription = itemDescriptions.find(
                              (desc) => desc.itemDetails === data.itemDetails
                            );
                            return (
                              <tbody key={i}>
                                <tr>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        className="form-control noBorder"
                                        placeholder="Search items..."
                                        value={data.itemDetails}
                                        onChange={(e) =>
                                          handleItemDetailsChange(e, i)
                                        }
                                        style={{ flex: 1 }}
                                      />
                                      {data.itemDetails && (
                                        <button
                                          type="button"
                                          className="btn p-0"
                                          onClick={() =>
                                            handleItemShow(data.itemDetails)
                                          }
                                          style={{ marginLeft: "10px" }}
                                        >
                                          <span
                                            className="text-primary"
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            Edit
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                    {itemDescription && (
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          color: "#6c757d",
                                          whiteSpace: "pre-line",
                                        }}
                                      >
                                        {itemDescription.description}
                                      </div>
                                    )}
                                    {searchPerformed &&
                                      data.itemDetails &&
                                      i === searchIndex && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            zIndex: 9999, // Higher than scrollbar
                                            top: "100%", // Position below input
                                            left: 0,
                                            width: "30%",
                                          }}
                                        >
                                          {searchResults.length === 0 && ""}
                                          {searchResults.length > 0 && (
                                            <ul
                                              style={{
                                                listStyleType: "none",
                                                padding: 0,
                                                position: "absolute",
                                                top: "calc(100% + 10px)",
                                                left: 0,
                                                zIndex: 999,
                                                backgroundColor: "#FFF",
                                                boxShadow:
                                                  "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                                borderRadius: "5px",
                                                width: "100%",
                                              }}
                                            >
                                              {searchResults.map(
                                                (result, index) => (
                                                  <li
                                                    key={index}
                                                    onClick={() =>
                                                      handleItemSelect(
                                                        result.name,
                                                        result.id,
                                                        i
                                                      )
                                                    }
                                                    onMouseEnter={(e) => {
                                                      e.target.style.backgroundColor =
                                                        "#408dfb";
                                                      e.target.style.color =
                                                        "#FFF";
                                                      e.target.style.borderRadius =
                                                        "5px";
                                                    }}
                                                    onMouseLeave={(e) => {
                                                      e.target.style.backgroundColor =
                                                        "";
                                                      e.target.style.color =
                                                        "#000";
                                                      e.target.style.borderRadius =
                                                        "";
                                                    }}
                                                    style={{
                                                      padding: "12px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {result.name}
                                                    <br />
                                                    Rate : ₹
                                                    {result.taxableAmount}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          )}
                                        </div>
                                      )}
                                    <Modal
                                      show={showItemModal}
                                      onHide={handleItemClose}
                                    >
                                      <div className="modal-header d-flex align-items-center pb-2 pt-2">
                                        <h5
                                          className="modal-title fs-5"
                                          id="salespersonHead"
                                        >
                                          Add Item Description
                                        </h5>
                                        <button
                                          type="button"
                                          className="btn pr-0"
                                          onClick={handleItemClose}
                                        >
                                          <ion-icon
                                            name="close-circle-outline"
                                            size="large"
                                            style={{ width: "28px" }}
                                          ></ion-icon>
                                        </button>
                                      </div>
                                      <Modal.Body>
                                        <Row>
                                          <Col md="12">
                                            <div className="row pt-2">
                                              <div className="col-lg-6">
                                                <label
                                                  className="form-label"
                                                  style={{
                                                    fontSize: "14px",
                                                    color: "grey",
                                                    borderRadius: "7px",
                                                  }}
                                                >
                                                  Item
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 pl-0 pr-0">
                                              <input
                                                className="form-control"
                                                placeholder="Name"
                                                id="name"
                                                name="name"
                                                type="text"
                                                value={itemName}
                                                readOnly
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md="12">
                                            <div className="row pt-2">
                                              <div className="col-lg-6">
                                                <label
                                                  className="form-label"
                                                  style={{
                                                    fontSize: "14px",
                                                    color: "grey",
                                                    borderRadius: "7px",
                                                  }}
                                                >
                                                  Description
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 pl-0 pr-0">
                                              <textarea
                                                className="cn-textarea"
                                                type="text"
                                                placeholder="Item Description"
                                                name="description"
                                                value={itemData.description}
                                                onChange={handleItemChange}
                                                rows={2}
                                                style={{
                                                  backgroundColor: "#F6F6F6",
                                                  borderRadius: "9px",
                                                  padding: "12px",
                                                  width: "100%",
                                                }}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </Modal.Body>
                                      <Modal.Footer className="p-2">
                                        <div className="modal-bottombtns">
                                          <button
                                            type="button"
                                            className="modalbtn-1 btn mt-0"
                                            onClick={handleItemClose}
                                          >
                                            Close
                                          </button>
                                          <button
                                            type="button"
                                            className="modalbtn-3 btn btn-primary"
                                            onClick={handleItem}
                                            data-bs-dismiss="modal"
                                          >
                                            Add Description
                                          </button>
                                        </div>
                                      </Modal.Footer>
                                    </Modal>
                                  </td>
                                  {hsnCheck === "YES" && (
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <input
                                        className="form-control noArrowNumber noBorder bg-white"
                                        value={data.hsnSac || ""}
                                        type="number"
                                        name="hsnSac"
                                        onChange={(e) =>
                                          handleInputChangess(e, i)
                                        }
                                      />
                                    </td>
                                  )}
                                  <td style={{ whiteSpace: "nowrap" }}>
                                  <div className="d-flex">
                                    <input
                                      className="form-control noArrowNumber noBorder"
                                      value={data.per || ""}
                                      type="number"
                                      name="per"
                                      readOnly
                                      style={{ backgroundColor: "white" }}
                                      // onChange={(e) =>
                                      //   handleQuantityChange(e, i)
                                      // }
                                    />
                                    {data.alternateUnit &&
                                      data.alternateUnitValue && (
                                        <input
                                          className="form-control px-1"
                                          style={{}}
                                          value={`${data.singleAltUnitValue} ${data.alternateUnit}`}
                                          type="text"
                                          readOnly
                                        />
                                      )}
                                  </div>
                                </td>
                                {quantityCheck === "YES" && (
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <div className="d-flex">
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.quantity || ""}
                                        type="number"
                                        name="quantity"
                                        onChange={(e) =>
                                          handleInputChangess(e, i)
                                        }
                                      />
                                      {(data.hasAlternateUnit ||
                                        data.alternateUnit) && (
                                        <input
                                          className="form-control px-1"
                                          name="alternateUnitValue"
                                          style={{}}
                                          value={`${
                                            data.calculatedAltUnitValue
                                          } ${data.alternateUnit || ""}`}
                                          type="text"
                                          readOnly
                                        />
                                      )}
                                    </div>
                                  </td>
                                )}
                                  {formData.deliveryChallanCalculateCheck ===
                                    "YES" && (
                                    <>
                                      {sizeCheck === "YES" && (
                                        <td style={{ whiteSpace: "nowrap" }}>
                                          <input
                                            className="form-control noArrowNumber noBorder"
                                            value={data.size || ""}
                                            type="text"
                                            name="size"
                                            onChange={(e) =>
                                              handleInputChangess(e, i)
                                            }
                                          />
                                        </td>
                                      )}

                                      {netRateCheck === "YES" && (
                                        <td style={{ whiteSpace: "nowrap" }}>
                                          <input
                                            className="form-control noArrowNumber noBorder"
                                            name="rateOne"
                                            value={data.rateOne}
                                            type="number"
                                            onChange={(e) =>
                                              handleInputChangess(e, i)
                                            }
                                          />
                                        </td>
                                      )}
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        <input
                                          className="form-control noBorder"
                                          type="text"
                                          name="rate"
                                          value={data.rate}
                                          onChange={(e) =>
                                            handleInputChangess(e, i)
                                          }
                                          style={{ backgroundColor: "white" }}
                                        />
                                      </td>
                                      {packCheck === "YES" && (
                                        <td style={{ whiteSpace: "nowrap" }}>
                                          <input
                                            className="form-control noArrowNumber noBorder"
                                            name="pack"
                                            value={data.pack}
                                            type=""
                                            onChange={(e) =>
                                              handleInputChangess(e, i)
                                            }
                                          />
                                        </td>
                                      )}

                                      {discountCheck === "YES" && (
                                        <td style={{ whiteSpace: "nowrap" }}>
                                          <div className="d-flex justify-content-center">
                                            <input
                                              className="form-control col-md-6 noBorder"
                                              value={data.discount}
                                              type="text"
                                              onChange={(e) =>
                                                handleInputChangess(e, i)
                                              }
                                              name="discount"
                                              style={{
                                                textAlign: "center",
                                                padding: "0px",
                                                marginRight: "5px",
                                                backgroundColor: "white",
                                              }}
                                            />
                                            <select
                                              className="border-0"
                                              value={data.discountType}
                                              onChange={(e) =>
                                                handleInputChangess(e, i)
                                              }
                                              name="discountType"
                                            >
                                              <option value="₹">₹</option>
                                              <option value="%">%</option>
                                            </select>
                                          </div>
                                        </td>
                                      )}
                                      {bussinessRegistered === "YES" && (
                                        <td style={{ whiteSpace: "nowrap" }}>
                                          <select
                                            className="form-control"
                                            name="tax"
                                            value={data.tax}
                                            onChange={(e) =>
                                              handleTaxChange(e, i)
                                            }
                                            style={{ padding: "0px" }}
                                          >
                                            <option value="0">N/A</option>
                                            <option value="5">
                                              5 GST [5%]
                                            </option>
                                            <option value="12">
                                              12 GST [12%]
                                            </option>
                                            <option value="18">
                                              18 GST [18%]
                                            </option>
                                            <option value="28">
                                              28 GST [28%]
                                            </option>
                                          </select>
                                        </td>
                                      )}
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        <span>
                                          {parseFloat(
                                            totalAmountWithTax
                                          ).toFixed(2)}
                                        </span>
                                      </td>
                                    </>
                                  )}
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <button
                                      className="btn px-2 btn-light icon-border btn-sm"
                                      onClick={() =>
                                        handleDeleteRow(i, data.id)
                                      }
                                    >
                                      <i className="fa fa-trash text-danger"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </table>
                      </div>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <button
                      className="btn addline-btn"
                      type="button"
                      onClick={handleAdd}
                    >
                      + Add Another Line
                    </button>
                  </Col>
                </Row>
              </div>

              <div className="grid-container">
                <div className="flex-item d-flex flex-column-reverse p-0">
                  <div className="row pt-3">
                    <div className="col-lg-6">
                      <label
                        className="form-label required mg-b-0"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Customer Notes
                      </label>
                    </div>

                    <div className="col-lg-12">
                      <textarea
                        className="cn-textarea"
                        placeholder="Customer Notes..."
                        name="customerNotes"
                        value={formData.customerNotes}
                        onChange={handleInputChange}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                {formData.deliveryChallanCalculateCheck === "YES" && (
                  <>
                    <div className="subtotal-box">
                      <div className="flex-item">
                        <div className="row">
                          <div className="col-lg-8">
                            <label className="form-label required">
                              Sub Total
                            </label>
                          </div>
                          <div className="col-lg-4 text-end pl-0">
                            <label className="form-label required">
                              {calculateSubTotal()}
                            </label>
                          </div>
                        </div>

                        {discountCheck === "YES" && (
                          <div className="row d-flex align-items-baseline mt-1">
                            <div className="col-lg-5 pr-0">
                              <label>Discount</label>
                            </div>
                            <div className="col-lg-3 pl-0 d-flex">
                              <input
                                className="form-control"
                                value={disAllValue || "0"}
                                type="number"
                                name="discount"
                                onChange={handleDisAllValueChange}
                                style={{
                                  height: "36px",
                                  padding: "3px 8px",
                                  backgroundColor: "#F6F6F6",
                                }}
                              />
                              <select
                                className="form-control ml-1 pl-0 rounded"
                                name="discountType"
                                value={discountType}
                                onChange={handleDiscountTypeChange}
                                style={{
                                  height: "36px",
                                  width: "50px",
                                  padding: "3px 8px",
                                  backgroundColor: "#F6F6F6",
                                }}
                              >
                                <option disabled>Select</option>
                                <option value="₹">₹</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                            <div className="col-lg-4 text-end pl-0">
                              <label className="form-label textColor-red">
                                {isNaN(parseFloat(disValue).toFixed(2))
                                  ? "0.00"
                                  : -parseFloat(disValue).toFixed(2)}
                              </label>
                            </div>
                          </div>
                        )}

                        {bussinessRegistered === "YES" && (
                          <div className="row mt-3">
                            {userState.state !== customerState && (
                              <>
                                {calculateIGSTForTax().map(
                                  (taxResult, index) => (
                                    <React.Fragment key={index}>
                                      <div className="col-lg-8">
                                        <label className="form-label required">
                                          IGST (
                                          {(taxResult.taxRate * 100).toFixed(0)}
                                          %)
                                        </label>
                                      </div>
                                      <div className="col-lg-4 text-end pl-0">
                                        <label className="form-label required">
                                          {taxResult.totalIGST.toFixed(2)}
                                        </label>
                                      </div>
                                    </React.Fragment>
                                  )
                                )}
                              </>
                            )}

                            {userState.state === customerState && (
                              <>
                                {Object.entries(
                                  calculateGroupedSGSTandCGST()
                                ).map(([taxRate, taxData], index) => (
                                  <React.Fragment key={index}>
                                    <div className="col-lg-8">
                                      <label className="form-label required">
                                        CGST (
                                        {((parseFloat(taxRate) * 100) / 2)
                                          .toFixed(1)
                                          .replace(/\.0$/, "")}
                                        %)
                                      </label>
                                    </div>
                                    <div className="col-lg-4 text-end pl-0">
                                      <label className="form-label required">
                                        {taxData.totalCGST.toFixed(2)}
                                      </label>
                                    </div>
                                    <div className="col-lg-8 mt-2">
                                      <label className="form-label required">
                                        SGST (
                                        {((parseFloat(taxRate) * 100) / 2)
                                          .toFixed(1)
                                          .replace(/\.0$/, "")}
                                        %)
                                      </label>
                                    </div>
                                    <div className="col-lg-4 text-end pl-0 mt-2">
                                      <label className="form-label required">
                                        {taxData.totalSGST.toFixed(2)}
                                      </label>
                                    </div>
                                  </React.Fragment>
                                ))}
                              </>
                            )}
                          </div>
                        )}

                        <div className="row mt-3 mb-3">
                          <div className="col-lg-5 pr-0">
                            <input
                              className="form-control col-md-10"
                              type="text"
                              placeholder="Adjustment" // Provide a placeholder for user input
                              value={formData.adjustmentLabel}
                              onChange={handleInputChange}
                              name="adjustmentLabel"
                              style={{
                                backgroundColor: "#F6F6F6",
                                borderRadius: "7px",
                                color: "black",
                              }}
                            />
                          </div>
                          <div className="col-lg-5 pl-0">
                            <input
                              className="form-control col-md-10"
                              type="text"
                              name="adjustment"
                              value={formData.adjustment}
                              onChange={handleInputChange}
                              style={{
                                backgroundColor: "#F6F6F6",
                                borderRadius: "7px",
                                color: "black",
                              }}
                            />
                          </div>
                          <div className="col-lg-2 text-end pl-0">
                            <label className="form-label">
                              {formData.adjustment || oppositeValue}
                            </label>
                          </div>
                        </div>

                        <hr className="mt-0" />

                        <div className="row mt-3">
                          <div className="col-lg-8">
                            <label className="form-label required ">
                              Total (₹) :
                            </label>
                          </div>
                          <div className="col-lg-4 text-end pl-0">
                            <label className="form-label required">
                              {calculateTotal()}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <hr className="mt-4" />

              <div className="row pt-2 align-items-center">
                <div className="col-lg-6">
                  <label
                    className="form-label required mg-b-0"
                    style={{ fontSize: "14px", color: "grey" }}
                  >
                    Terms & Condition
                  </label>
                </div>

                <div className="col-lg-12">
                  <ReactQuill
                    value={termsAndCondition || formData.termsAndConditions}
                    onChange={handleEditorChange} // Function to update state
                    theme="snow" // Use "bubble" for a minimal UI
                    style={{ height: "300px", marginBottom: "50px" }}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
                        [{ font: [] }], // Font options
                        [{ size: [] }], // Font size
                        [{ color: [] }, { background: [] }], // Text color & background
                        ["bold", "italic", "underline", "strike"], // Basic text styles
                        [{ script: "sub" }, { script: "super" }], // Subscript & superscript
                        [{ align: [] }], // Text alignment
                        [{ list: "ordered" }, { list: "bullet" }], // Lists
                        [{ indent: "-1" }, { indent: "+1" }], // Indentation
                        ["blockquote", "code-block"], // Blockquote & code block
                        ["link", "image", "video"], // Media links
                        ["clean"], // Remove formatting
                      ],
                    }}
                    formats={[
                      "header",
                      "font",
                      "size",
                      "color",
                      "background",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "script",
                      "align",
                      "list",
                      "bullet",
                      "indent",
                      "blockquote",
                      "code-block",
                      "link",
                      "image",
                      "video",
                    ]}
                  />
                  <i className="text-muted">
                    This Terms and Condition will be displayed on the challan
                  </i>
                </div>
              </div>
            </div>
          </>
        </div>

        <div className="text-right mt-4 mb-4" style={{ marginRight: "1.5rem" }}>
          <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
            Cancel
          </button>
          <button className="btn-3 btn btn-primary" onClick={handleSubmit}>
            {isLoading ? "Updating..." : "Update"}
          </button>
        </div>
      </div>
    </>
  );
}
export default DeliveryChallanUpdate;
