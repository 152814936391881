import React from "react";
import newzerosblack from "../../asset/images/jpslogo.png";
import swal from "sweetalert";
import CryptoJS from "crypto-js";
import { BASE_URL } from "../Dashboard/CommonApi";
import { AuthURL } from "../Dashboard/CommonApi";
import "./Login.css";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Alert from "@mui/material/Alert";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      show1: true,
      show2: false,
      navigateform: false,
      errors: {},
      companyName: "",
      email: "",
      contact: "",
      password: "",
      state: "Maharashtra", // Set the default state here
      loginEmail: "",
      loginPass: "",
      forgetEmail: "",
      forgetPass: "",
      confirmPass: "",
      loading: false,
      btn1: true,
      btn2: false,
      btn3: false, // Add loading state property
      currentDataFormatIndex: 0,
      emailforAuth: "",
      otplessInstance: null,
    };
  }
  autoFetchData = async () => {
    const apiUrl = AuthURL + "/refreshToken";
    const refreshToken1 = localStorage.getItem("refreshToken");

    const tryApiCall = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refreshToken: refreshToken1 }),
        });

        if (!response.ok) {
          throw new Error("POST request failed");
        }

        const responseData = await response.json();
        const token = responseData.jwtToken;
        const refreshToken = responseData.refreshToken;
        const uniqueId = responseData.uniqueId;
        const encryptedUniqueId = CryptoJS.AES.encrypt(
          uniqueId,
          "1234567812345678"
        ).toString();

        localStorage.setItem("jwtToken", token);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("uniqueId1", encryptedUniqueId);
        this.setState({ navigateform: true });

        // Stop reloading when API call is successful
        clearInterval(reloadInterval);
      } catch (error) {
        console.error("Error:", error);
        localStorage.removeItem("uniqueId1");
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("refreshToken");
        clearInterval(reloadInterval);
      }
    };

    const reloadInterval = setInterval(() => {
      tryApiCall();
    }, 1000); // Retry every 1 second until successful

    // Immediately try to call the API on first load
    tryApiCall();
  };

  componentDidMount() {
    this.dataFormatInterval = setInterval(this.changeDataFormat, 5000);
    window.history.pushState(null, null, window.location.pathname);

    this.handlePopState = () => {
      window.history.pushState(null, null, window.location.pathname);
    };
    window.addEventListener("popstate", this.handlePopState);

    this.autoFetchData();
    const callback = (userinfo) => {
      const emailMap = userinfo.identities.find(
        (item) => item.identityType === "EMAIL"
      );

      const mobileMap = userinfo.identities.find(
        (item) => item.identityType === "MOBILE"
      );

      const token = userinfo.token;
      const email = emailMap?.identityValue;
      const mobile = mobileMap?.identityValue;
      const name = emailMap?.name || mobileMap?.name;
    };

    if (window.OTPless) {
      const instance = new window.OTPless(callback);
      this.setState({ otplessInstance: instance });
    } else {
      console.error("OTPless SDK is not loaded.");
    }
  }

  componentWillUnmount() {
    clearInterval(this.dataFormatInterval);
    window.removeEventListener("popstate", this.handlePopState);
  }

  oauth = (provider) => {
    const { otplessInstance } = this.state;
    if (otplessInstance) {
      otplessInstance.initiate({ channel: "OAUTH", channelType: provider });
    } else {
      console.error("OTPless instance is not initialized.");
    }
  };

  changeDataFormat = () => {
    const { currentDataFormatIndex } = this.state;
    // Determine the next data format index
    const nextDataFormatIndex = (currentDataFormatIndex + 1) % 2; // Assuming you have 3 data formats

    // Update the state to change the data format
    this.setState({
      currentDataFormatIndex: nextDataFormatIndex,
    });
  };

  validation = () => {
    let errors = {};
    const namePattern = /^[A-Za-z\s]+$/;
    const passPattern = /^[A-Za-z0-9_@./#&+-]*$/;
    const contactPattern = /^[6-9]{1}[0-9]{9}$/;
    const emailPattern = /^[a-z0-9._%+-]+@[a-z]+\.[a-z]{2,4}(\.[a-z]{2,4})?$/;

    if (!this.state.companyName) {
      errors.companyName = "* Company Name is required";
    }

    if (!this.state.email) {
      errors.email = "* Email is required";
    } else if (!emailPattern.test(this.state.email)) {
      errors.email = "* Enter a valid email address";
    }

    if (!this.state.contact) {
      errors.contact = "* Contact Number is required";
    } else if (!contactPattern.test(this.state.contact)) {
      errors.contact = "* Enter a valid Contact Number";
    }

    if (!this.state.password) {
      errors.password = "* Password is required";
    } else if (!passPattern.test(this.state.password)) {
      errors.password = "* Invalid Password";
    }

    if (this.state.termCheck !== "YES") {
      errors.termCheck = "* You must accept the terms and conditions";
    }

    return errors;
  };

  validation1 = () => {
    let errors = {};
    const emailPattern = /^[a-z0-9._%+-]+@[a-z]+\.[a-z]{2,4}$/;

    if (!this.state.loginEmail) {
      errors.loginEmail = "* Email is required";
    } else if (!emailPattern.test(this.state.loginEmail)) {
      errors.loginEmail = "* Enter a valid email address";
    }

    if (!this.state.loginPass) {
      errors.loginPass = "*Password is required";
    }

    if (!this.state.confirmPass) {
      errors.confirmPass = "*Confirm password is required";
    }

    return errors;
  };

  handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const errors = { ...this.state.errors };
    delete errors[name]; // Remove the error for the specific field being changed

    const newValue = type === "checkbox" ? (checked ? "YES" : "NO") : value;
    this.setState({ [name]: newValue, errors, validationMessage1: "" });
  };

  callback = (userinfo) => {
    const emailMap = userinfo.identities.find(
      (item) => item.identityType === "EMAIL"
    );

    const mobileMap = userinfo.identities.find(
      (item) => item.identityType === "MOBILE"
    )?.identityValue;

    const token = userinfo.token;

    const email = emailMap?.identityValue;
    const mobile = mobileMap?.identityValue;
    const name = emailMap?.name || mobileMap?.name;
  };

  handleGoogleSignIn = async (e) => {
    e.preventDefault();
    window.location = BASE_URL + "/oauth2/authorization/google";
  };

  onSuccess = (res) => {
    console.log("LOGIN SUCCESS! current user", res.profileObj);
  };

  onFailure = (res) => {
    console.log("Login Failed", res);
  };

  premiumStyle = {
    fontFamily: "YourFontName, sans-serif", // Replace 'YourFontName' with the actual font name
    fontSize: "16px", // Replace '16px' with the desired font size
  };

  sendData = (e) => {
    e.preventDefault();
    const validationErrors = this.validation();
    this.setState({ loading: true });
    const jwtToken = localStorage.getItem("jwtToken");

    const { companyName, email, contact, password, state, termCheck } =
      this.state;
    const [emailName] = email.split("@");

    const values = {
      companyName: companyName,
      email: email,
      contact: contact,
      password: password,
      state: state || "Maharashtra",
      name: emailName,
      termCheck: termCheck, // No need to change this, it's already set to "YES" or "NO"
    };

    if (Object.keys(validationErrors).length === 0) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(values),
      };
      // First fetch for signup
      fetch(AuthURL + "/signUp", requestOptions)
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(errorData.responseMessage);
            });
          }
          return response.json();
        })
        .then((data) => {
          swal({
            icon: "success",
            title: "Registered Successfully",
          });
          this.setState({ loading: false });
          this.setState({ show: false, show1: true, show2: false });
        })
        .catch((error) => {
          swal({
            icon: "error",
            title: "Oops...",
            text: error.message,
          });
          this.setState({ loading: false });
        });
    } else {
      // Validation failed, update the state with errors
      this.setState({
        errors: validationErrors,
        loading: false, // Set loading to false if validation fails
      });
    }
  };

  validate = () => {
    const errors = {};
    const { forgetEmail, forgetPass, confirmPass } = this.state;
    let validationMessage = "";

    if (!forgetEmail) {
      errors.forgetEmail = "Email is required";
      validationMessage += "Email is required\n";
    }
    if (!forgetPass) {
      errors.forgetPass = "Password is required";
      validationMessage += "Password is required\n";
    }
    if (!confirmPass) {
      errors.confirmPass = "Confirm Password is required";
      validationMessage += "Confirm Password is required\n";
    }
    if (forgetPass && confirmPass && forgetPass !== confirmPass) {
      errors.confirmPass = "Passwords do not match";
      validationMessage += "Passwords do not match\n";
    }

    this.setState({ validationMessage1: validationMessage });

    return errors;
  };

  updatePassword = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const errors = this.validate();
    let newValidationMessage = "";
    if (Object.keys(errors).length > 0) {
      for (const key in errors) {
        if (errors[key]) {
          newValidationMessage += `${errors[key]}\n`;
        }
      }
      this.setState({
        errors,
        loading: false,
        validationMessage1: newValidationMessage,
      });
      if (newValidationMessage) {
        window.scrollTo(0, 0); // Scroll to top
        return;
      }
    } else {
      this.setState({ validationMessage1: "" });
    }

    const jwtToken = localStorage.getItem("jwtToken");
    const { forgetEmail, forgetPass, confirmPass } = this.state;
    const values = {
      email: forgetEmail,
      password: forgetPass,
      confirmPassword: confirmPass,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(values),
    };

    fetch(AuthURL + "/forgetPassword", requestOptions)
      .then((response) => {
        return response.json().then((data) => {
          if (!response.ok) {
            throw new Error(
              data.responseMessage || "Failed to update password"
            );
          }
          return data;
        });
      })
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Password Changed Successfully",
          });
          this.setState({
            loading: false,
            show: false,
            show1: true,
            show2: false,
          });
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        swal({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
        this.setState({ loading: false });
      });
  };

  getData = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const { loginEmail, loginPass } = this.state;
    const values = {
      email: loginEmail,
      password: loginPass,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };

    fetch(AuthURL + "/login", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const uniqueId = data.uniqueId; // Get uniqueId from response
          const token = data.JWTToken;
          const refreshToken = data.refreshToken;
          const userId = data.userId;
          const encryptedUniqueId = CryptoJS.AES.encrypt(
            uniqueId,
            "1234567812345678"
          ).toString();
          localStorage.setItem("uniqueId1", encryptedUniqueId);
          localStorage.setItem("userId", userId);
          localStorage.setItem("jwtToken", token);
          localStorage.setItem("refreshToken", refreshToken);
          // Get the current date and format it as dd-mm-yyyy
          const currentDate = new Date();
          const formattedCurrentDate = `${String(
            currentDate.getDate()
          ).padStart(2, "0")}-${String(currentDate.getMonth() + 1).padStart(
            2,
            "0"
          )}-${currentDate.getFullYear()}`;

          // Store the formatted date in local storage
          localStorage.setItem("loginDate", formattedCurrentDate);

          swal({
            icon: "success",
            title: "Logged In Successfully",
          });
          this.setState({ loading: false, navigateform: true });

          // Redirect to another page if needed
        } else if (data.responseMessage === "Trial Period Expired") {
          swal({
            icon: "error",
            title: "Login Failed",
            text: data.responseMessage,
            buttons: {
              goToRegister: {
                text: "Activate",
                value: "register",
                className: "swal-button--register",
              },
            },
          }).then((value) => {
            switch (value) {
              case "retry":
                break;
              case "register":
                window.location.href = `https://zarp.software/app/paymentScreen?email=${loginEmail}`;
                break;
            }
          });
          this.setState({ loading: false });
        } else if (data.responseCode === "500") {
          swal({
            icon: "error",
            title: "Login Failed",
            text: data.responseMessage,
          });
          this.setState({ loading: false });
        } else if (data.responseCode === "400") {
          swal({
            icon: "error",
            title: "Login Failed",
            text: data.responseMessage,
          });
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
        this.setState({ loading: false });
      });
  };

  getAuth = (email) => {
    this.setState({ loading: true });

    const values = {
      email: email,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };

    fetch(AuthURL + "/authCheck", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const uniqueId = data.uniqueId; // Get uniqueId from response
          const token = data.JWTToken;
          const refreshToken = data.refreshToken;
          const userId = data.userId;

          // Encrypt the uniqueId using crypto-js
          const encryptedUniqueId = CryptoJS.AES.encrypt(
            uniqueId,
            "1234567812345678"
          ).toString();

          localStorage.setItem("uniqueId1", encryptedUniqueId);
          localStorage.setItem("userId", userId);
          localStorage.setItem("jwtToken", token);
          localStorage.setItem("refreshToken", refreshToken);

          swal({
            icon: "success",
            title: "Logged In Successfully",
          });
          this.setState({ loading: false, navigateform: true });

          // Redirect to another page if needed
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: "Login Failed",
            text: data.responseMessage,
            buttons: {
              goToRegister: {
                text: "Activate",
                value: "register",
                className: "swal-button--register",
              },
            },
          }).then((value) => {
            switch (value) {
              case "retry":
                // Code to retry login
                break;
              case "register":
                window.location.href = `https://zarp.software/app/paymentScreen?email=${email}`;
                break;
            }
          });
          this.setState({ loading: false });
        } else if (data.responseCode === "500") {
          swal({
            icon: "error",
            title: "Login Failed",
            text: data.responseMessage,
          });
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Login Failed",
          text: error.message,
        });
        this.setState({ loading: false });
      });
  };

  handleGoogleSignInSuccess = (credentialResponse) => {
    const decode = jwtDecode(credentialResponse?.credential);
    this.setState({ emailforAuth: decode.email }, () => {
      // Call another function after state has been set
      this.getAuth(decode.email);
    });
    // You can perform further actions like setting state or calling functions here
  };

  handleGoogleSignInError = () => {
    console.log("login failed");
    // You can handle errors here
  };

  handleButtonClick = (event) => {
    event.preventDefault(); // Prevent page reload
    // Trigger Google sign-in when the button is clicked
    GoogleLogin.signIn({
      onSuccess: this.handleGoogleSignInSuccess,
      onError: this.handleGoogleSignInError,
    });
  };

  render() {
    const {
      companyName,
      email,
      contact,
      password,
      state,
      errors,
      loginEmail,
      loginPass,
      forgetEmail,
      forgetPass,
      confirmPass,
      loading,
      currentDataFormatIndex,
    } = this.state;
    const { navigation } = this.props;
    {
      this.state.navigateform && navigation.navigate("dashboard");
    }

    return (
      <div className="auth-container">
        <div className="auth-wrapper">
          <div className="auth-left">
            <div className="auth-form-container">
              <div className="logo-container">
                <img
                  src={newzerosblack}
                  alt="Zeros ERP"
                  className="logo-image"
                />
              </div>

              {this.validationMessage1 && (
                <Alert severity="error" className="validation-alert">
                  {this.validationMessage1.split("\n").map((message, index) => (
                    <div key={index}>{message}</div>
                  ))}
                </Alert>
              )}

              {/* SIGN UP FORM */}
              {this.state.show && (
                <div className="auth-form signup-form">
                  <div className="auth-header">
                    <h2 className="auth-title">Register</h2>
                    <p className="auth-subtitle">
                      Experience PREMIUM plan for 14 days.
                    </p>
                  </div>

                  <form className="form-fields">
                    <div className="form-group">
                      <label className="form-label">Company Name</label>
                      <input
                        type="text"
                        className="form-input"
                        name="companyName"
                        value={companyName}
                        onChange={this.handleInputChange}
                        placeholder="Enter your company name"
                      />
                      {errors.companyName && (
                        <div className="error-message">
                          {errors.companyName}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-input"
                        name="email"
                        value={email}
                        onChange={this.handleInputChange}
                        placeholder="Enter your email address"
                      />
                      {errors.email && (
                        <div className="error-message">{errors.email}</div>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Mobile Number</label>
                      <input
                        type="text"
                        className="form-input"
                        name="contact"
                        value={contact}
                        onChange={this.handleInputChange}
                        placeholder="Enter your mobile number"
                      />
                      {errors.contact && (
                        <div className="error-message">{errors.contact}</div>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Password</label>
                      <input
                        type="password"
                        className="form-input"
                        name="password"
                        value={password}
                        onChange={this.handleInputChange}
                        placeholder="Create a password"
                      />
                      {errors.password && (
                        <div className="error-message">{errors.password}</div>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="form-label">State</label>
                      <select
                        className="form-input"
                        name="state"
                        value={state}
                        onChange={this.handleInputChange}
                      >
                        <option value="Maharashtra" selected>
                          Maharashtra
                        </option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Andaman and Nicobar Islands">
                          Andaman and Nicobar Islands
                        </option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadar and Nagar Haveli">
                          Dadar and Nagar Haveli
                        </option>
                        <option value="Daman and Diu">Daman and Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">
                          Himachal Pradesh
                        </option>
                        <option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                      </select>
                    </div>

                    <div className="form-group checkbox-group">
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          name="termCheck"
                          checked={this.state.termCheck === "YES"}
                          onChange={this.handleInputChange}
                          required
                        />
                        <span className="checkbox-text">
                          I agree to the{" "}
                          <a href="#" className="link-text">
                            Terms of Service
                          </a>{" "}
                          and{" "}
                          <a href="#" className="link-text">
                            Privacy Policy
                          </a>
                          .
                        </span>
                      </label>
                      {errors.termCheck && (
                        <div className="error-message">{errors.termCheck}</div>
                      )}
                    </div>

                    {loading ? (
                      <div className="loading-spinner"></div>
                    ) : (
                      <button
                        type="submit"
                        className="auth-button"
                        onClick={this.sendData}
                      >
                        SIGN UP
                      </button>
                    )}

                    <div className="divider">
                      <span>or</span>
                    </div>

                    {/* <div className="google-login-container">
                      <GoogleLogin
                        onSuccess={this.handleGoogleSignInSuccess}
                        onError={this.handleGoogleSignInError}
                      />
                    </div> */}

                    <div className="auth-redirect">
                      <span>
                        Already have a JPS account?{" "}
                        <a
                          href="#"
                          className="link-text"
                          onClick={() => {
                            this.setState({
                              show1: true,
                              show: false,
                              show2: false,
                              loginEmail: "",
                              loginPass: "",
                            });
                          }}
                        >
                          Sign in
                        </a>
                      </span>
                    </div>
                  </form>
                </div>
              )}

              {/* LOGIN FORM */}
              {this.state.show1 && (
                <div className="auth-form login-form">
                  <div className="auth-header">
                    <h2 className="auth-title">Login To Your Account</h2>
                  </div>

                  <form className="form-fields">
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-input"
                        name="loginEmail"
                        value={loginEmail}
                        onChange={this.handleInputChange}
                        placeholder="Enter your email address"
                      />
                    </div>

                    <div className="form-group">
                      <label className="form-label">Password</label>
                      <input
                        type="password"
                        className="form-input"
                        name="loginPass"
                        value={loginPass}
                        onChange={this.handleInputChange}
                        placeholder="Enter your password"
                      />
                      <div className="forgot-password">
                        <a
                          href="#"
                          className="link-text"
                          onClick={() => {
                            this.setState({
                              show: false,
                              show1: false,
                              show2: true,
                              loginPass: "",
                              confirmPass: "",
                            });
                          }}
                        >
                          Forgot Password?
                        </a>
                      </div>
                    </div>

                    {loading ? (
                      <div className="loading-spinner"></div>
                    ) : (
                      <button
                        type="submit"
                        className="auth-button"
                        onClick={this.getData}
                      >
                        SIGN IN
                      </button>
                    )}

                    <div className="divider">
                      <span>or</span>
                    </div>

                    {/* <div className="google-login-container">
                      <GoogleLogin
                        onSuccess={this.handleGoogleSignInSuccess}
                        onError={this.handleGoogleSignInError}
                      />
                    </div> */}

                    <div className="auth-redirect">
                      <span>
                        Don't have an account?{" "}
                        <a
                          href="#"
                          className="link-text"
                          onClick={() => {
                            this.setState({
                              companyName: "",
                              email: "",
                              contact: "",
                              password: "",
                              state: "",
                              show: true,
                              show1: false,
                              show2: false,
                            });
                          }}
                        >
                          Sign up
                        </a>
                      </span>
                    </div>
                  </form>
                </div>
              )}

              {/* FORGOT PASSWORD FORM */}
              {this.state.show2 && (
                <div className="auth-form forgot-form">
                  <div className="auth-header">
                    <h2 className="auth-title">Change ZARP Password</h2>
                  </div>

                  <form className="form-fields">
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-input"
                        name="forgetEmail"
                        value={forgetEmail}
                        onChange={this.handleInputChange}
                        placeholder="Enter your email address"
                      />
                      {errors.forgetEmail && (
                        <div className="error-message">
                          {errors.forgetEmail}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="form-label">New Password</label>
                      <input
                        type="password"
                        className="form-input"
                        name="forgetPass"
                        value={forgetPass}
                        onChange={this.handleInputChange}
                        placeholder="Enter new password"
                      />
                      {errors.forgetPass && (
                        <div className="error-message">{errors.forgetPass}</div>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Confirm Password</label>
                      <input
                        type="password"
                        className="form-input"
                        name="confirmPass"
                        value={confirmPass}
                        onChange={this.handleInputChange}
                        placeholder="Confirm new password"
                      />
                      {errors.confirmPass && (
                        <div className="error-message">
                          {errors.confirmPass}
                        </div>
                      )}
                    </div>

                    {loading ? (
                      <div className="loading-spinner"></div>
                    ) : (
                      <button
                        type="button"
                        className="auth-button"
                        onClick={this.updatePassword}
                      >
                        UPDATE PASSWORD
                      </button>
                    )}

                    <div className="divider">
                      <span>or</span>
                    </div>

                    <div className="auth-redirect">
                      <span>
                        Back to{" "}
                        <a
                          href="#"
                          className="link-text"
                          onClick={() => {
                            this.setState({
                              show: false,
                              show1: true,
                              show2: false,
                              forgetEmail: "",
                              forgetPass: "",
                              confirmPass: "",
                            });
                          }}
                        >
                          Login
                        </a>
                        ?
                      </span>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>

          <div className="auth-right">
            <div className="feature-content">
              <h2>Welcome to JPS Chemicals</h2>
              <h3>Industrial Solutions Specialist</h3>

              <p className="feature-tagline my-2">
                Your trusted partner for quality laboratory products since 2017
              </p>

              <ul className="feature-list">
                <li>
                  <i className="feature-icon">✓</i>
                  <span>
                    Premium industrial chemicals & laboratory products
                  </span>
                </li>
                <li>
                  <i className="feature-icon">✓</i>
                  <span>Complete QC and R&D laboratory solutions</span>
                </li>
                <li>
                  <i className="feature-icon">✓</i>
                  <span>Water treatment products & services</span>
                </li>
                <li>
                  <i className="feature-icon">✓</i>
                  <span>Life science & sanitization product specialists</span>
                </li>
                <li>
                  <i className="feature-icon">✓</i>
                  <span>24/7 dedicated customer support</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
