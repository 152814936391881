import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import Select from "react-select";
import { Card, Form, Row, Col, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function EnquiryNew({ navigation }) {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleEnquiryClick = () => {
    handleNavigation("Enquiry");
  };
  const [custNames, setCustNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(""); // State to store the selected customer's ID
  const [startDate, setStartDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const [enquiryId, setEnquiryId] = useState("");

  useEffect(() => {
    setStartDate(new Date());
  }, []);
  const handleDate = (date) => {
    setStartDate(date);
  };

  const [formData, setFormData] = useState({
    uniqueId: decryptedUniqueId,
    customerType: "Business",
    status: "Active",
    someName: "Mr.",
    firstName: "",
    lastName: "",
    companyName: "",
    customerName: "",
    displayName: "",
    email: "",
    workphone: "",
    mobile: "",
    designation: "",
    department: "",
    website: "",
    currency: "INR- Indian Rupee",
    openingBalance: "0",
    paymentTerms: "",
    facebook: "",
    twitter: "",
    billAttention: "",
    billCountry: "India",
    billAddress1: "",
    billAddress2: "",
    billCity: "",
    billState: "Maharashtra",
    billZipcode: "",
    billPhone: "",
    billFax: "",
    shipAttention: "",
    shipCountry: "India",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "Maharashtra",
    shipZipcode: "",
    shipPhone: "",
    shipFax: "",
    gstin: "",
    pan: "",
    placeOfSupply: "[MH] - Maharashtra",
    taxable: "",
    reason: "N/A",
    activeStatus: "Active",
    gstTreatment: "",
    contactPerson: [
      {
        other: "",
        otherFirstname: "",
        otherLastname: "",
        otherEmail: "",
        otherWorkphone: "",
        otherMobile: "",
      },
    ],
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSaveClick = () => {
    let newValidationMessage = "";

    if (!formData.displayName) {
      newValidationMessage += "Please enter a customer display name.\n";
    } else if (
      formData.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
    ) {
      newValidationMessage += "Please enter a valid email address.\n";
    }

    setValidationMessage(newValidationMessage);
    if (newValidationMessage) {
      return; // Exit function if validation fails
    }

    fetch(BASE_URL + "/createCustomer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          swal({
            icon: "success",
            title: "Customer Created Successfully",
            text: "",
          });
          setShow(false);
          fetchAllCustomers();
        } else if (data.responseCode === "400") {
          swal({
            icon: "error",
            title: data.responseMessage,
            text: "",
          });
          setShow(true);
        } else {
          swal({
            icon: "error",
            title: "Failed to post customer data",
            text: "",
          });
          setShow(true);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        setShow(true);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
  
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        [name]: value,
      };
      
      // If customerName is being changed, also update displayName with the same value
      if (name === 'customerName') {
        newData.displayName = value;
      }
      
      return newData;
    });    
  };

  const generateNamePairs = () => {
    const { someName, firstName, lastName } = formData;
    const pairs = [];

    if (firstName && lastName) {
      pairs.push(`${someName} ${firstName} ${lastName}`);
      pairs.push(`${firstName} ${lastName}`);
      pairs.push(`${lastName} , ${firstName}`);
    }

    return pairs;
  };

  const fetchBatchNumbers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/getNextInquiryId`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      });
      const data = await response.json();
      if (data.status === "success") {
        setEnquiryId(data.nextInuiryId);
      } else {
        console.error("Failed to fetch batch numbers:", data.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching batch numbers:", error);
    }
  };
  const fetchAllCustomers = () => {
    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };
  useEffect(() => {
    fetchAllCustomers();
    fetchBatchNumbers();
  }, []);

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure you want to cancel?");
    if (confirmCancel) {
      handleEnquiryClick();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  const options = custNames.map((customer) => ({
    value: customer.id,
    label: customer.customerName,
  }));
  const handleCustomerChange = (value, id) => {
    const selectedValue = value;
    const selectedValueId = id;
    setSelectedCustomer(selectedValue);
    setSelectedCustomerId(selectedValueId);
    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );
  };

  const [loading, setLoading] = useState(false);
  const [fileBlocks, setFileBlocks] = useState([
    { id: Date.now(), file: null, preview: null, name: null },
  ]);

  const handleAddFileBlock = () => {
    setFileBlocks([
      ...fileBlocks,
      { id: Date.now(), file: null, preview: null, name: null },
    ]);
  };

  const handleRemoveFileBlock = (id) => {
    setFileBlocks(fileBlocks.filter((block) => block.id !== id));
  };

  // const handleFileChange = (id, file) => {
  //   if (!file) return;

  //   const fileType = file.type;
  //   const fileName = file.name;

  //   if (fileType.startsWith("image/")) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       setFileBlocks((blocks) =>
  //         blocks.map((block) =>
  //           block.id === id
  //             ? { ...block, file, preview: e.target.result, name: null }
  //             : block
  //         )
  //       );
  //     };
  //     reader.readAsDataURL(file);
  //   } else if (fileType === "application/pdf") {
  //     setFileBlocks((blocks) =>
  //       blocks.map((block) =>
  //         block.id === id
  //           ? { ...block, file, preview: null, name: fileName }
  //           : block
  //       )
  //     );
  //   } else {
  //     setFileBlocks((blocks) =>
  //       blocks.map((block) =>
  //         block.id === id
  //           ? { ...block, file, preview: null, name: "Unsupported file type" }
  //           : block
  //       )
  //     );
  //   }
  // };

  const handleFileChange = (id, file) => {
    if (!file) return;

    // Validate file size (e.g., 5MB limit)
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSize) {
      swal({
        icon: "error",
        title: "File too large",
        text: "Please select a file smaller than 5MB",
      });
      return;
    }

    // Validate file type
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (!allowedTypes.includes(file.type)) {
      swal({
        icon: "error",
        title: "Invalid file type",
        text: "Please select a JPG, PNG, PDF or DOC file",
      });
      return;
    }

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileBlocks((blocks) =>
          blocks.map((block) =>
            block.id === id
              ? {
                  ...block,
                  file,
                  preview: e.target.result,
                  name: file.name,
                  type: file.type,
                }
              : block
          )
        );
      };
      reader.readAsDataURL(file);
    } else {
      setFileBlocks((blocks) =>
        blocks.map((block) =>
          block.id === id
            ? {
                ...block,
                file,
                preview: null,
                name: file.name,
                type: file.type,
              }
            : block
        )
      );
    }
  };

  const [validationMessage, setValidationMessage] = useState("");

  if (loading) {
    return (
      <div className="center-div">
        <div className="spinner"></div>
      </div>
    );
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      const inquiryData = {
        customerId: selectedCustomerId,
        customerName: selectedCustomer,
        uniqueId: decryptedUniqueId,
        description,
        createdDate: startDate.toISOString().split("T")[0], // Format: YYYY-MM-DD
      };
      formData.append("inquiry", JSON.stringify(inquiryData));
      fileBlocks.forEach((block, index) => {
        if (block.file) {
          formData.append(`files`, block.file);
        }
      });

      for (let pair of formData.entries()) {
        console.log("----------------------");
        console.log(pair[0], pair[1]);
      }
      const response = await fetch(BASE_URL + "/addInquiry", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`, // JWT token
        },
        body: formData, // Attach FormData
      });

      const result = await response.json();

      if (response.ok) {
        swal({
          icon: "success",
          title: "Enquiry Inserted Successfully",
        });
        handleEnquiryClick();
      } else {
        swal({
          icon: "error",
          title: "Failed to insert item",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error submitting inquiry: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <Row>
          <Col>
            <div className="my-3">
              <div className="d-flex align-items-center ml-1 mt-4">
                <div
                  className="cursor-pointer d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    height: "35px",
                    width: "38px",
                  }}
                >
                  <ArrowBackIosIcon
                    width={2}
                    onClick={handleCancleClick}
                    className="arrow-child"
                  />
                </div>
                <div className="newcustomer-head ml-2">
                  <h5
                    className="ml-1 mb-0"
                    style={{ letterSpacing: "1px", color: "#086bd5de" }}
                  >
                    New Enquiry
                  </h5>
                </div>
              </div>
            </div>
            {validationMessage && (
              <Alert severity="error" className="mt-0">
                {validationMessage.split("\n").map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </Alert>
            )}{" "}
            <Card className="card-style p-4">
              <Row>
                <Col className="" md="3">
                  <Form.Group>
                    <label
                      className="text-primary"
                      style={{ fontSize: 16, fontWeight: "600" }}
                    >
                      CUSTOMER INFO
                    </label>
                  </Form.Group>
                </Col>
              </Row>
              <Form>
                <Row>
                  <Col className="" md="3">
                    <Form.Group>
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Customer Name <span className="valid"> *</span>
                      </label>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <Select
                        className="mt-0"
                        options={options}
                        value={options.find(
                          (option) => option.value === selectedCustomer
                        )}
                        onChange={(selectedOption) =>
                          handleCustomerChange(
                            selectedOption.label,
                            selectedOption.value
                          )
                        }
                        placeholder="Select Customer"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            height: "46px",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            height: "19px",
                          }),
                        }}
                      />
                      <button
                        type="button"
                        class="btn p-0 text-left"
                        onClick={handleShow}
                      >
                        <span
                          class="text-primary"
                          style={{ fontSize: "12px", fontWeight: "500" }}
                        >
                          New Customer
                        </span>
                      </button>

                      <Modal size="lg" show={show} onHide={handleClose}>
                        <div class="modal-header d-flex align-items-center pb-2 pt-2">
                          <h5 class="modal-title fs-5" id="newcustomerPopup">
                            New Customer
                          </h5>
                          <button
                            type="button"
                            className="btn pr-0"
                            onClick={handleClose}
                          >
                            <ion-icon
                              name="close-circle-outline"
                              size="large"
                              style={{ width: "28px" }}
                            ></ion-icon>
                          </button>
                        </div>
                        <Modal.Body className="p-0 pb-3">
                          {validationMessage && (
                            <Alert severity="error" className="mt-0">
                              {validationMessage
                                .split("\n")
                                .map((message, index) => (
                                  <div key={index}>{message}</div>
                                ))}
                            </Alert>
                          )}{" "}
                          <div className="container">
                            <div className="row d-inline-flex">
                              <div className="modalinputchild">
                                <div className="text-style"> Salutation </div>
                                <select
                                  className="form-control"
                                  name="someName"
                                  value={formData.someName}
                                  onChange={handleChange}
                                  style={{
                                    borderRadius: "9px",
                                    backgroundColor: "#f5f5f5",
                                    width: "200px",
                                    height: "35px",
                                    fontSize: "13px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <option value="Mr.">Mr.</option>
                                  <option value="Mrs.">Mrs.</option>
                                  <option value="Ms.">Ms.</option>
                                  <option value="Miss.">Miss.</option>
                                  <option value="Dr.">Dr.</option>
                                </select>
                              </div>
                              <div className="modalinputchild">
                                <div className="text-style">First Name</div>
                                <input
                                  className="form-control"
                                  placeholder="First Name"
                                  name="firstName"
                                  id="firstName"
                                  value={formData.firstName}
                                  onChange={handleChange}
                                  type="text"
                                />
                              </div>
                              <div className="modalinputchild">
                                <div className="text-style">Last Name</div>
                                <input
                                  className="form-control"
                                  placeholder="Last Name"
                                  id="lastName"
                                  name="lastName"
                                  value={formData.lastName}
                                  onChange={handleChange}
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="row d-inline-flex pt-2">
                              <div className="modalinputchild">
                                <div className="text-style">
                                  {" "}
                                  Customer Email{" "}
                                </div>
                                <input
                                  className="form-control"
                                  placeholder="Customer Email"
                                  name="email"
                                  type="text"
                                  value={formData.email}
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="modalinputchild">
                                <div className="text-style">
                                  {" "}
                                  Customer Display Name{" "}
                                  <span style={{ color: "red" }}> *</span>
                                </div>
                                <input
                                  className="form-control"
                                  placeholder="Customer Display Name"
                                  list="customerNames"
                                  name="customerName"
                                  value={formData.customerName}
                                  onChange={handleChange}
                                  style={{
                                    borderRadius: "7px",
                                    backgroundColor: "#f5f5f5",
                                    width: "200px",
                                    height: "40px",
                                    fontSize: "13px",
                                    marginTop: "10px",
                                  }}
                                />
                                <datalist id="customerNames">
                                  {generateNamePairs().map((pair, index) => (
                                    <option key={index} value={pair} />
                                  ))}
                                </datalist>{" "}
                              </div>
                              <div className="modalinputchild">
                                <div className="text-style">Company Name</div>
                                <input
                                  className="form-control"
                                  placeholder="Add company name"
                                  name="companyName"
                                  type="text"
                                  value={formData.companyName}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row d-inline-flex pt-2">
                              <div className="modalinputchild">
                                <div className="text-style">Customer Phone</div>
                                <input
                                  className="form-control"
                                  placeholder="Work Phone"
                                  name="workphone"
                                  type="text"
                                  value={formData.workphone}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="modalinputchild">
                                <div className="text-style">Mobile</div>
                                <input
                                  className="form-control"
                                  placeholder="Mobile"
                                  name="mobile"
                                  type="text"
                                  value={formData.mobile}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer className="p-2">
                          <div className="modal-bottombtns">
                            <button
                              type="button"
                              class="modalbtn-1 btn mt-0"
                              onClick={handleClose}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="modalbtn-3 btn btn-primary"
                              onClick={handleSaveClick}
                            >
                              Create Customer
                            </button>
                          </div>
                        </Modal.Footer>
                      </Modal>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Form.Group>
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Enquiry Date <span className="valid"> *</span>
                      </label>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <div className="customDatePickerWidth">
                        <DatePicker
                          className="form-control"
                          selected={startDate}
                          dateFormat="dd-MM-yyyy"
                          onChange={(date) => handleDate(date)}
                          showIcon
                          toggleCalendarOnIconClick
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Form.Group>
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Tracking No <span className="valid"> *</span>
                      </label>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Control
                      placeholder="Enter Enquiry No."
                      type="text"
                      name="enquiryId"
                      value={enquiryId}
                      onChange={(e) => setEnquiryId(e.target.value)}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        color: "black",
                        height: "40px",
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="row pt-2">
                      <div className="col-lg-6">
                        <label
                          className="form-label"
                          style={{
                            fontSize: "14px",
                            color: "grey",
                            borderRadius: "7px",
                          }}
                        >
                          Discrption :
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12 pl-0 pr-0">
                      <ReactQuill
                        value={description} // Replace with your state variable
                        onChange={setDescription} // Function to update state
                        theme="snow" // Use "bubble" for a minimal UI
                        style={{ height: "300px", marginBottom: "50px" }}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
                            [{ font: [] }], // Font options
                            [{ size: [] }], // Font size
                            [{ color: [] }, { background: [] }], // Text color & background
                            ["bold", "italic", "underline", "strike"], // Basic text styles
                            [{ script: "sub" }, { script: "super" }], // Subscript & superscript
                            [{ align: [] }], // Text alignment
                            [{ list: "ordered" }, { list: "bullet" }], // Lists
                            [{ indent: "-1" }, { indent: "+1" }], // Indentation
                            ["blockquote", "code-block"], // Blockquote & code block
                            ["link", "image", "video"], // Media links
                            ["clean"], // Remove formatting
                          ],
                        }}
                        formats={[
                          "header",
                          "font",
                          "size",
                          "color",
                          "background",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "script",
                          "align",
                          "list",
                          "bullet",
                          "indent",
                          "blockquote",
                          "code-block",
                          "link",
                          "image",
                          "video",
                        ]}
                      />
                    </div>
                  </Col>{" "}
                </Row>
                <Row className="mt-4">
                  <h5 className="text-primary mb-4">Upload Attachments</h5>
                  <Card
                    className="p-3 mb-4"
                    style={{
                      backgroundColor: "#f9f9f9",
                      border: "1px solid #ddd",
                    }}
                  >
                    {fileBlocks.map((block, index) => (
                      <div key={block.id} className="mb-4">
                        <Form.Control
                          type="file"
                          accept=".jpg,.jpeg,.png,.pdf"
                          onChange={(e) =>
                            handleFileChange(block.id, e.target.files[0])
                          }
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            padding: "10px",
                          }}
                        />

                        {/* Preview Section */}
                        {block.preview && (
                          <div className="mt-3">
                            <img
                              src={block.preview}
                              alt="Preview"
                              style={{
                                maxWidth: "50%",
                                height: "200px",
                                borderRadius: "5px",
                              }}
                            />
                          </div>
                        )}

                        {/* File Name Section for PDFs */}
                        {block.name && (
                          <div className="mt-2 text-secondary">
                            <strong>Selected File:</strong> {block.name}
                          </div>
                        )}

                        {/* Remove Button */}
                        {index > 0 && (
                          <Button
                            variant="outline-danger"
                            className="mt-2"
                            onClick={() => handleRemoveFileBlock(block.id)}
                          >
                            Remove
                          </Button>
                        )}

                        {/* Add Button */}
                        {index === fileBlocks.length - 1 && (
                          <Button
                            variant="outline-secondary"
                            className="mt-2"
                            onClick={handleAddFileBlock}
                          >
                            Add More
                          </Button>
                        )}
                      </div>
                    ))}
                  </Card>
                </Row>
              </Form>
              <div className="text-right invoice-bottombtns">
                <button
                  type="button"
                  className="btn-1 btn mt-0"
                  disabled={loading}
                  onClick={handleCancleClick}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="btn-3 btn btn-primary"
                  onClick={handleSubmit}
                >
                  {isLoading ? "Saving..." : "Create Enquiry"}
                </button>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EnquiryNew;
