// export const BASE_URL = "https://api.zarp.software/api";
// export const AuthURL = "https://api.zarp.software/auth";
export const BASE_URL = "https://qaapi.zarp.software/api";
export const AuthURL = "https://qaapi.zarp.software/auth";
// export const BASE_URL = "http://192.168.31.95:8099/api";
// export const AuthURL = "http://192.168.31.95:8099/auth";

// export const BASE_URL = "https://manufacturing.zarp.software/api";
// export const AuthURL = "https://manufacturing.zarp.software/auth";
//export NODE_OPTIONS="--openssl-legacy-provider --max-old-space-size=12288";
