import React, { useState, useEffect } from "react";
import newzerosblackmainmenu from "../../asset/images/jpslogo.png";
import Mainmenu from "./Mainmenu";
import { AES, enc } from "crypto-js";

function Leftbar(props) {
  const { navigation } = props;
  const [apiData, setApiData] = useState([]); // State to store the API data
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const apiUrl = `https://qabooks.zarp.software/jps/JpserpApi/getMenuData/${decryptedUniqueId}`;

  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setApiData(data.message); // Set the fetched data to the state
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  // Use useEffect to call the API when the component mounts
  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div className="sidebar text-dark" style={{ backgroundColor: "#ffffff" }}>
      <div className="sidebar-scroll">
        <div className="user-account">
          <img
            src={newzerosblackmainmenu}
            alt="User Profile Picture"
            style={{ width: "60%" }}
          />
        </div>

        <Mainmenu navigation={navigation} apiData={apiData} />
      </div>
    </div>
  );
}

export default Leftbar;
