import React, { useState, useEffect } from "react";
import "./popup.css";
import { addDays, endOfMonth, addMonths } from "date-fns";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import { differenceInDays } from "date-fns";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, Form, Row, Col } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Modal from "react-bootstrap/Modal";
import "./InvoicesNew.css";
import TinyMceEditor from "../Settings/TinyMceEditor";
import Alert from "@mui/material/Alert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function ProformaNew({ navigation }) {
  const [row, setRow] = useState([
    {
      itemDetails: "",
      hsnSac: "",
      size: "",
      per:"1",
      quantity: "1",
      rate: "0",
      discount: "0",
      discountType: "₹",
      description1: "",
      description2: "",
    },
  ]);

  const jwtToken = localStorage.getItem("jwtToken");

  const handleAdd = () => {
    const newItem = {
      itemDetails: "",
      account: "",
      hsnSac: "",
      per:"1",
      quantity: "1",
      rate: "0.0",
      discount: "0.0",
      discountType: "₹",
    };
    const newRow = [...row, newItem];
    setRow(newRow);
  };

  const [showFormaPage, setShowFormaPage] = useState(true);
  const [showMailPage, setShowMailPage] = useState(false);
  const [userDetail, setUserDetail] = useState("");
  const [userEmail, setUserEmail] = useState([]);
  const [custEmail, setCustomerEmail] = useState([]);
  const [subjectMail, setSubjectMail] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [manualCheck, setManualCheck] = useState("No");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const handleSubjectChange = (event) => {
    setSubjectMail(event.target.value);
  };

  const handleUserEmail = (event) => {
    setUserEmail(event.target.value);
  };

  const handleCustomerEmail = (event) => {
    setCustomerEmail(event.target.value);
  };

  const handleMailClick = () => {
    setShowFormaPage(false);
    setShowMailPage(true);
  };

  const handleTaxChange = (e, index) => {
    setSelectedTaxRate(e.target.value);
    const newTaxRate = parseFloat(e.target.value);
    const updatedRow = [...row];
    updatedRow[index].tax = newTaxRate;
    setRow(updatedRow);
  };

  const handleDeleteRow = (e, i) => {
    e.preventDefault();
    const updatedRow = [...row];
    updatedRow.splice(i, 1);
    setRow(updatedRow);
    updateTotal(updatedRow);
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleInvoicesClick();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const [show1, setShow1] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const closePopup = () => {
    setShowPopup(false);
    fetchSalesData();
  };

  const [showInvoice, setShowInvoice] = useState(false);

  const closeInvoice = () => {
    setShow1(false);
    setShowInvoice(false);
  };

  // const [selectedTerms, setSelectedTerms] = useState("");
  const [bussinessRegistered, setBussinessRegistered] = useState("");
  const [discountCheck, setDiscountCheck] = useState("");
  const [sizeCheck, setSizeCheck] = useState("");
  const [hsnCheck, setHsnCheck] = useState("");
  const [quantityCheck,setQuantityCheck] = useState("");

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleInvoicesClick = () => {
    handleNavigation("Proforma");
  };

  const handleDiscountChange = (e, i) => {
    const { name, value } = e.target;
    const newValues =
      name === "discount" ? { discount: value } : { discountType: value };
    const newRow = [...row];
    newRow[i] = { ...newRow[i], ...newValues };
    setRow(newRow);
    updateTotal(newRow);
  };

  const handleQuantityChange = (e, i) => {
    const newQuantity = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], quantity: newQuantity };
    setRow(newRow);
    updateTotal(row);
  };

  const handleRateChange = (e, i) => {
    const newRate = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], rate: newRate };
    setRow(newRow);
    updateTotal(row);
  };

  const updateTotal = (rowData) => {
    let calculatedTotal = 0;
    rowData.forEach((data) => {
      const amount = calculateAmount(data);
      calculatedTotal += parseFloat(amount);
    });
    setTotal(calculatedTotal.toFixed(2));

    // if (selectedTaxType === "TDS" && selectedTdsOption && selectedTdsOption.discount) {
    //   const discountedTotal = applyDiscount(calculatedTotal, selectedTdsOption.discount);
    //   setDiscountAmountTds(parseFloat(discountedTotal));
    // } else if (selectedTaxType === "TCS" && selectedTcsOption && selectedTcsOption.discount) {
    //   const discountedTotal = applyDiscountTcs(calculatedTotal, selectedTcsOption.discount);
    //   setDiscountAmountTcs(parseFloat(discountedTotal));
    // }
  };

  const [total, setTotal] = useState(0);

  const calculateAmount = (data, tax) => {
    const { quantity, rate, discount, discountType } = data;
    let amount = quantity * rate;

    if (discount) {
      amount -= discountType === "%" ? (amount * discount) / 100 : discount;
    }

    return amount;
  };

  const calculateDisRupees = (data, taxRate) => {
    const quantity = parseFloat(data.quantity || 0);
    const rate = parseFloat(data.rate || 0);
    const discount = parseFloat(data.discount || 0);
    const amount = quantity * rate;
    let discountedAmount = 0;
    if (data.discountType === "%") {
      discountedAmount = (amount * discount) / 100;
    } else if (data.discountType === "₹") {
      discountedAmount = discount;
    }

    return discountedAmount.toFixed(2);
  };

  const [selectedTaxRate, setSelectedTaxRate] = useState(0);

  //CalculateTaxAmount
  const calculateTaxAmount = (amount, taxRate) => {
    if (taxRate > 0) {
      let taxIncrease = 0;

      // Set tax increase based on tax rate
      switch (taxRate) {
        case 0.05:
          taxIncrease = 0.5;
          break;
        case 0.12:
          taxIncrease = 1.0;
          break;
        case 0.18:
          taxIncrease = 1.5;
          break;
        case 0.28:
          taxIncrease = 2.0;
          break;
        default:
          break;
      }

      return amount * (taxRate + taxIncrease);
    }
    return 0;
  };

  const calculateSubTotal = () => {
    let subTotal = 0;
    row.forEach((data) => {
      const amount = calculateAmount(data);
      subTotal += parseFloat(amount);
    });
    return subTotal.toFixed(2);
  };

  const [discountAmountTds, setDiscountAmountTds] = useState(0.0);

  const applyDiscount = (subtotal, discountPercentage) => {
    const discountAmount = (subtotal * discountPercentage) / 100;
    const tdsDis = (subtotal - discountAmount).toFixed(2);
    setDiscountAmountTds(parseFloat(discountAmount.toFixed(2))); // Set discountAmount in state

    return tdsDis;
  };

  const handleTdsOptionChange = (selectedOption) => {
    setSelectedTdsOption(selectedOption);
    const subtotal = calculateSubTotal();

    // Check if a TDS option is selected and has a discount
    if (selectedOption && selectedOption.discount) {
      const discountedTotal = applyDiscount(subtotal, selectedOption.discount);
    }
  };

  const [discountAmountTcs, setDiscountAmountTcs] = useState(0.0);

  const applyDiscountTcs = (subtotal, discountPercentage) => {
    const discountAmount = (subtotal * discountPercentage) / 100;
    const tcsDis = (subtotal - discountAmount).toFixed(2);
    setDiscountAmountTcs(parseFloat(discountAmount.toFixed(2))); // Set discountAmount in state

    return tcsDis;
  };

  const handleTcsOptionChange = (selectedOption) => {
    setSelectedTcsOption(selectedOption);
    const subtotal = calculateSubTotal();

    // Check if a TCS option is selected and has a discount
    if (selectedOption && selectedOption.discount) {
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedOption.discount
      );
    }
  };
  const calculateIGST = () => {
    let totalIGST = 0;

    row.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      if (taxRate > 0) {
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = (parseFloat(amount) * taxRate) / 100;
        totalIGST += increasedTaxAmount;
      }
    });

    return totalIGST.toFixed(2);
  };

  const calculateSGSTandCGST = () => {
    let totalSGST = 0;
    let totalCGST = 0;
    row.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = (parseFloat(amount) * taxRate) / 100;
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;
      totalSGST += sgst;
      totalCGST += cgst;
    });
    return {
      totalSGST: totalSGST.toFixed(2),
      totalCGST: totalCGST.toFixed(2),
    };
  };

  const calculateIGSTForTax = () => {
    const taxResults = [];

    row.forEach((data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate > 0) {
        let existingTaxResult = taxResults.find(
          (result) => result.taxRate === taxRate
        );
        if (!existingTaxResult) {
          existingTaxResult = { taxRate, totalIGST: 0 };
          taxResults.push(existingTaxResult);
        }
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = amount * taxRate;
        existingTaxResult.totalIGST += increasedTaxAmount;
      }
    });
    return taxResults;
  };

  const calculateGroupedSGSTandCGST = () => {
    const taxGroups = row.reduce((acc, data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate === 0) return acc; // Skip 0% GST

      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = amount * taxRate;
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;

      if (!acc[taxRate]) {
        acc[taxRate] = { totalSGST: 0, totalCGST: 0 };
      }

      acc[taxRate].totalSGST += sgst;
      acc[taxRate].totalCGST += cgst;

      return acc;
    }, {});

    return taxGroups;
  };

  const [showing1, setShowing1] = useState(false);
  const [showing2, setShowing2] = useState(false);
  const tds = [
    {
      value: "Commission or Brokerag 5%",
      label: "Commission or Brokerag 5%",
      discount: 5,
    },
    {
      value: "Commission or Brokerag (Reduced) 3.75%",
      label: "Commission or Brokerag (Reduced) 3.75%",
      discount: 3.75,
    },
    { value: "Divident 10%", label: "Divident 10%", discount: 10 },
    {
      value: "Divident (Reduced) 7.5%",
      label: "Divident (Reduced) 7.5%",
      discount: 7.5,
    },
    {
      value: "Other Interest then securities 10%",
      label: "Other Interest then securities 10%",
      discount: 10,
    },
    {
      value: "Other Interest then securities (Reduced) 7.5%",
      label: "Other Interest then securities (Reduced) 7.5%",
      discount: 7.5,
    },
    {
      value: "Payment of Contractors Others 2%",
      label: "Payment of Contractors Others 2%",
      discount: 2,
    },
    {
      value: "Payment of Contractors Others (Reduced) 1.5%",
      label: "Payment of Contractors Others (Reduced) 1.5%",
      discount: 1.5,
    },
    {
      value: "Payment of Contractors Others HUD/Indiv 1%",
      label: "Payment of Contractors Others HUD/Indiv 1%",
      discount: 1,
    },
    {
      value: "Payment of Contractors Others HUD/Indiv (Reduced) 0.75%",
      label: "Payment of Contractors Others HUD/Indiv (Reduced) 0.75%",
      discount: 0.75,
    },
    {
      value: "Professional Fees 10%",
      label: "Professional Fees 10%",
      discount: 10,
    },
    {
      value: "Rent on land or furniture etc 10%",
      label: "Rent on land or furniture etc 10%",
      discount: 10,
    },
  ];
  // const jwtToken=localStorage.getItem("jwtToken");

  const tcs = [
    // { value: "0 GST [0%]", label: "0 GST [0%]", discount: 0 },
    { value: "5 GST [5%]", label: "5 GST [5%]", discount: 5 },
    { value: "12 GST [12%]", label: "12 GST [12%]", discount: 12 },
    { value: "18 GST [18%]", label: "18 GST [18%]", discount: 18 },
    { value: "28 GST [28%]", label: "28 GST [28%]", discount: 28 },
  ];

  const [adjustmentValue, setAdjustmentValue] = useState(""); // Step 1: State for adjustment value
  const [oppositeValue, setOppositeValue] = useState(""); // State for opposite value

  // const [disAllValue , setDisAllValue] = useState("");
  const [disAllValue, setDisAllValue] = useState("");
  const [discountType, setDiscountType] = useState("₹");
  const [disValue, setDisValue] = useState("");
  // const [totalTaxAmount , setTotalTaxAmount] = useState("");
  const [selectedTaxType, setSelectedTaxType] = useState(""); // To store the selected tax type (TDS or TCS)
  const [selectedTdsOption, setSelectedTdsOption] = useState(null); // To store the selected TDS option
  const [selectedTcsOption, setSelectedTcsOption] = useState(null);
  const [tType, setTType] = useState("");
  const totalTax =
    calculateIGST() ||
    calculateSGSTandCGST().totalSGST + calculateSGSTandCGST().totalCGST;

  const calculateDiscount = () => {
    const amount = parseFloat(calculateSubTotal()).toFixed(2);

    if (discountType === "₹") {
      setDisValue(disAllValue);
    } else if (discountType === "%") {
      const percentageDiscount = (amount * disAllValue) / 100;
      setDisValue(percentageDiscount.toFixed(2));
    }
  };

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
  };

  const handleDisAllValueChange = (e) => {
    setDisAllValue(e.target.value);
  };

  useEffect(() => {
    calculateDiscount();
  }, [disAllValue, discountType]);

  const handleRadioClick = (taxType) => {
    setSelectedTaxType(taxType);

    if (taxType === "TDS") {
      setSelectedTcsOption(null);
      setDiscountAmountTds(0);
      setDiscountAmountTcs(0);
      setTType("TDS");
    } else if (taxType === "TCS") {
      setSelectedTdsOption(null);
      setDiscountAmountTcs(0);
      setDiscountAmountTds(0);
      setTType("TCS");
    }

    // Toggle the show/hide state
    setShowing1(taxType === "TDS");
    setShowing2(taxType === "TCS");
  };
  useEffect(() => {
    const parsedValue = parseFloat(adjustmentValue);
    if (!isNaN(parsedValue)) {
      // Calculate the opposite value only if the adjustmentValue is a valid number
      const opposite = parsedValue * 1;
      setOppositeValue(opposite.toFixed(2));
    } else {
      // If adjustmentValue is not a valid number, set the opposite value to an empty string
      setOppositeValue("");
    }
  }, [adjustmentValue]);

  const calculateTotal = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const igst = parseFloat(calculateIGST());
    const adjustment = parseFloat(adjustmentValue) || 0; // Use the adjustment value if it's a valid number, otherwise, default to 0
    const total1 = subTotal + igst + adjustment;
    const total = total1 - disValue;
    const totalFinal = total - discountAmountTcs;
    return totalFinal.toFixed(2);
  };

  const calculateTotal2 = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const igst = parseFloat(calculateIGST());
    const adjustment = parseFloat(adjustmentValue) || 0; // Use the adjustment value if it's a valid number, otherwise, default to 0
    const total1 = subTotal + igst + adjustment;
    const total = total1 - disValue;
    const totalFinal = total - discountAmountTds;
    return totalFinal.toFixed(2);
  };

  const fetchSalesData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/getAllSalesPersonByUniqueId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const filteredData = data.responseMessage.filter(
            (item) => item.name !== "Others"
          );
          setSalesPersonData(filteredData);
        } else {
          console.error("Failed to fetch salesperson:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching salesperson:", error);
      });
  };
  useEffect(() => {
    fetchSalesData();
    fetchTermsData();
  }, []);

  const [custNames, setCustNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(""); // State to store the selected customer's ID

  // Fetch customer data
  const fetchAllCustomers = () => {
    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };
  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    fetchAllCustomers();
  }, []);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage[0].businessRegistered;

          setBussinessRegistered(Data);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchDiscountCheck`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage.discountCheck;
          const sizeCheck = data.responseMessage.sizeCheck;
          setDiscountCheck(Data);
          setSizeCheck(sizeCheck);
          setHsnCheck(data.responseMessage.hsnCheck);
          setQuantityCheck(data.responseMessage.quantityCheck);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const [userState, setUserState] = useState([]);
  const [gstNumber, setGstNumber] = useState("");
  const [customerState, setCustomerState] = useState("");

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");

  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userState = data.responseMessage;
          setUserDetail(data.responseMessage);
          setUserEmail(data.responseMessage.email);
          setUserState(userState);
          setGstNumber(userState.gstNumber);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle customer selection
  const handleCustomerChange = (value, id) => {
    const selectedValue = value;
    const selectedValueId = id;
    setSelectedCustomer(selectedValue);
    setSelectedCustomerId(selectedValueId);

    // Find the selected customer object in the fetched data
    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );

    if (selectedCustomerObject) {
      const customerState = selectedCustomerObject.billState;
      const customerTerms = selectedCustomerObject.paymentTerms;
      setSelectedTerms(customerTerms);
      const custEmail = selectedCustomerObject.email;
      setCustomerEmail(custEmail);
      setCustomerState(customerState);
      setSelectedCustomerId(selectedCustomerObject.id);
    } else {
      setSelectedTerms("");
    }
  };

  const handleSalesPersonChange = (value, id) => {
    const selectedValue = value.value;
    setSelectedSalesPerson(selectedValue);

    const selectedSalesPersonObject = salesPersonData.find(
      (person) => person.name === selectedValue
    );
  };

  const [invoiceNumberPrefix, setInvoiceNumberPrefix] = useState([]);
  const [invoiceNumberNext, setInvoiceNumberNext] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");

  const [orderNumber, setOrderNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [adjustmentLabel, setAdjustmentLabel] = useState("");
  const [customerNote, setCustomerNote] = useState("");
  const [termsAndCondition, setTermsAndCondition] = useState("");
  const [salesPersonData, setSalesPersonData] = useState([]);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState("");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");

  const handleEditorChange = (value) => {
    setTermsAndCondition(value); // Update the state with the content of the editor
  };

  // Decrypt the uniqueId using your decryption method
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [validationMessage1, setValidationMessage1] = useState("");

  const insertDataIntoDatabase = async (e) => {
    e.preventDefault();
    const itemDetails = row.map((data) => {
      return {
        itemDetails: data.itemDetails || "", // Assuming you have an input for itemDetails
        hsnSac: data.hsnSac,
        size: data.size,
        quantity: data.quantity,
        rate: data.rate,
        discount: data.discount || 0,
        discountType: data.discountType,
        tax: data.tax,
        amount: calculateAmount(data, selectedTaxRate),
        rowDiscount: calculateDisRupees(data),
        description1: data.description1 || "",
        description2: data.description2 || "",
        customerTaxType: userState.state === customerState ? 1 : 2,
        cgst:
          userState.state === customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100 / 2
            : 0,
        sgst:
          userState.state === customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100 / 2
            : 0,
        igst:
          userState.state !== customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100
            : 0,
      };
    });

    const formData = {
      uniqueId: decryptedUniqueId,
      customerName: selectedCustomer,
      customerId: selectedCustomerId,
      invoiceNumber: invoiceNumber,
      invoiceDate: dateValue || normalDateFormat(startDate),
      terms: selectedTerms,
      dueDate: showDueDate === true ? dueDate : dateValue1,
      salesPerson: selectedSalesPerson,
      orderNumber: orderNumber,
      subject: subject,
      items: itemDetails,
      subtotal: calculateSubTotal(),
      adjustment: parseFloat(adjustmentValue) || 0,
      adjustmentLabel: adjustmentLabel,
      total: calculateTotal(),
      customerNotes: customerNote || "Thank You For Business.",
      receivables: calculateTotal(),
      taxInAmount: totalTax || 0,
      termsAndCondition: termsAndCondition,
      totalAmount: calculateTotal() || 0,
      discountCheck: discountCheck || 0,
      sizeCheck: sizeCheck || 0,
      overallDiscount: disValue || 0,
      taxCheck: bussinessRegistered || 0,
      totalDiscount: disAllValue || 0,
      totalDiscountType: discountType || 0,
      tdsTax: selectedTdsOption?.value,
      tcsTax: selectedTcsOption?.value,
      taxType: selectedTaxType,
      type: tType,
      value: showing1 ? discountAmountTds : discountAmountTcs,
      status: "Sent",
      manualCheck: manualCheck,
      state: customerState,
      hsnCheck: hsnCheck || 0,
      quantityCheck: quantityCheck,
      gstNumber: gstNumber,
    };

    let newValidationMessage = "";
    if (!formData.customerName) {
      newValidationMessage += "Please select a customer \n";
    } else if (!formData.invoiceNumber) {
      newValidationMessage += "Please enter invoice number.\n";
    } else if (!formData.invoiceDate) {
      newValidationMessage += "Please select a invoice date \n";
    } else if (!formData.dueDate) {
      newValidationMessage += "Please select a due date\n";
    }
    row.forEach((item, index) => {
      if (
        item.itemDetails.trim() === "" ||
        item.rate.trim() === "" ||
        item.rate.trim() === "0.0" ||
        item.quantity.trim() === ""
      ) {
        newValidationMessage +=
          "Enter the valid item name, rate and quantity.\n";
      }
    });

    setValidationMessage1(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    setIsLoading(true);
    const response = await fetch(BASE_URL + `/insertProFormaInvoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Pro Forma Invoice Created",
          });
          handleMailClick();
          setIsLoading(false);
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        setIsLoading(false);
      });
  };

  const insertDataIntoDatabaseAsDraft = async (e) => {
    e.preventDefault();
    const itemDetails = row.map((data) => {
      return {
        itemDetails: data.itemDetails || "", // Assuming you have an input for itemDetails
        hsnSac: data.hsnSac,
        size: data.size,
        quantity: data.quantity,
        rate: data.rate,
        discount: data.discount || 0,
        discountType: data.discountType,
        tax: data.tax,
        amount: calculateAmount(data, selectedTaxRate),
        rowDiscount: calculateDisRupees(data),
        description1: data.description1 || "",
        description2: data.description2 || "",
        customerTaxType: userState.state === customerState ? 1 : 2,
        cgst:
          userState.state === customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100 / 2
            : 0,
        sgst:
          userState.state === customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100 / 2
            : 0,
        igst:
          userState.state !== customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100
            : 0,
      };
    });

    const formData = {
      uniqueId: decryptedUniqueId,
      customerName: selectedCustomer,
      customerId: selectedCustomerId,
      invoiceNumber: invoiceNumber,
      invoiceDate: dateValue || normalDateFormat(startDate),
      terms: selectedTerms,
      dueDate: showDueDate === true ? dueDate : dateValue1,
      salesPerson: selectedSalesPerson,
      orderNumber: orderNumber,
      subject: subject,
      items: itemDetails,
      subtotal: calculateSubTotal(),
      adjustment: parseFloat(adjustmentValue) || 0,
      adjustmentLabel: adjustmentLabel,
      total: calculateTotal(),
      customerNotes: customerNote || "Thank You For Business.",
      receivables: calculateTotal(),
      igst: calculateIGST(),
      sgst: calculateSGSTandCGST().totalSGST,
      cgst: calculateSGSTandCGST().totalCGST,
      taxInAmount: totalTax || 0,
      termsAndCondition: termsAndCondition,
      totalAmount: calculateTotal() || 0,
      discountCheck: discountCheck || 0,
      sizeCheck: sizeCheck || 0,
      overallDiscount: disValue || 0,
      taxCheck: bussinessRegistered || 0,
      totalDiscount: disAllValue || 0,
      totalDiscountType: discountType || 0,
      tdsTax: selectedTdsOption?.value,
      tcsTax: selectedTcsOption?.value,
      taxType: selectedTaxType,
      type: tType,
      value: showing1 ? discountAmountTds : discountAmountTcs,
      status: "Draft",
      manualCheck: manualCheck,
      state: customerState,
      hsnCheck: hsnCheck,
      quantityCheck:quantityCheck,
    };

    let newValidationMessage = "";
    if (!formData.customerName) {
      newValidationMessage += "Please select a customer \n";
    } else if (!formData.invoiceNumber) {
      newValidationMessage += "Please enter invoice number.\n";
    } else if (!formData.invoiceDate) {
      newValidationMessage += "Please select a invoice date \n";
    } else if (!formData.dueDate) {
      newValidationMessage += "Please select a due date\n";
    }
    row.forEach((item, index) => {
      if (
        item.itemDetails.trim() === "" ||
        item.rate.trim() === "" ||
        item.rate.trim() === "0.0" ||
        item.quantity.trim() === ""
      ) {
        newValidationMessage +=
          "Enter the valid item name, rate and quantity.\n";
      }
    });

    setValidationMessage1(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    setIsLoading2(true);
    const response = await fetch(BASE_URL + `/insertProFormaInvoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Pro Forma Invoice Created",
          });
          handleMailClick();
          setIsLoading2(false);
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading2(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        setIsLoading2(false);
      });
  };

  const handleHsnSacChange = (e, i) => {
    const { value } = e.target;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], hsnSac: value };
    setRow(newRow);
  };

  const handleSizeChange = (e, i) => {
    const { value } = e.target;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], size: value };
    setRow(newRow);
  };
  // const [show1, setShow1] = useState(false);

  const copyAdd = () => {
    if (show1) {
      // If auto-generating is selected, set the generatedInvoiceNumber
      setInvoiceNumber(invoiceNumberPrefix + invoiceNumberNext);
      setManualCheck("No");
    } else {
      // If manually adding is selected, set it to null
      setInvoiceNumber("");
      setManualCheck("Yes");
    }

    // Close the popup
    closeInvoice();
  };
  const handleInvoiceNumberChange = (e) => {
    setInvoiceNumber(e.target.value);
    setManualCheck("Yes");
  };

  const setBill = () => {
    setShow1(true);
    fetchBill();
  };

  const fetchBill = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Prepare the data to send in the POST request
    const requestData = {
      // Include any other data you need to send in the request
      uniqueId: decryptedUniqueId,
      type: "proforma",
    };

    // Define the POST request options
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
        // Add any other headers if required
      },
      body: JSON.stringify(requestData), // Convert the data to JSON format
    };

    // Make the POST request to your API endpoint
    fetch(BASE_URL + `/getCurruntNumberList2`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // Assuming your API returns an object with prefix and next number properties
        const { prefix, nextNumber, uniqueId } = data;
        // Set the fetched values in your state
        setInvoiceNumberPrefix(data.responseMessage.prefix);
        setInvoiceNumberNext(data.responseMessage.numbers);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }; // Empty dependency array to fetch data once when the component mounts

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/getCurruntNumberList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ type: "proforma", uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setInvoiceNumber(data.responseMessage);
        } else {
          console.error("Failed to fetch numberList:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Number List:", error);
      });
  }, []);

  const [salesData, setSalesData] = useState({
    uniqueId: decryptedUniqueId,
    name: "",
    email: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSalesData({ ...salesData, [name]: value });
  };

  const [showSalesModal, setShowSalesModal] = useState(false);
  const [validationMessage2, setValidationMessage2] = useState("");

  const handleSalesClose = () => setShowSalesModal(false);
  const handleSalesShow = () => setShowSalesModal(true);

  const handleSalesPerson = (event) => {
    event.preventDefault();
    let newValidationMessage = "";

    if (!salesData.name) {
      newValidationMessage += "Please enter a name.\n";
    }
    setValidationMessage2(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0);
      // alert(newValidationMessage.trim()); // Trim trailing newline for better formatting
      return; // Exit function if validation fails
    }
    fetch(BASE_URL + "/createSalesperson", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(salesData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Sales Person Created ",
          });
          setShowSalesModal(false);
          fetchSalesData();
          closePopup();
        } else {
          swal({
            icon: "error",
            title: "Failed To Create Sales Person! ",
          });
          setShowSalesModal(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occured While Creating Sales Person ",
        });
        setShowSalesModal(true);
      });
  };

  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().substr(0, 10)
  );

  const [selectedTerms, setSelectedTerms] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [dueDateChangedManually, setDueDateChangedManually] = useState(false);

  // const handleSalesOrderDateChange = (e) => {
  //   const newDate = e.target.value;
  //   setCurrentDate(newDate);

  //   if (selectedTerms) {
  //     updateDueDate(new Date(newDate), selectedTerms);
  //   }
  // };

  // const handlePaymentTermsChange = (e) => {
  //   const terms = e.target.value;
  //   setSelectedTerms(terms);

  //   if (currentDate && terms) {
  //     updateDueDate(new Date(currentDate), terms);
  //   }
  // };

  const handleExpectedShipmentDateChange = (e) => {
    setDueDateChangedManually(true);
    setDueDate(new Date(e.target.value));
    updatePaymentTerms(new Date(e.target.value));
    // Add any validation or error handling for the expected shipment date here if needed
  };

  const updatePaymentTerms = (newDueDate) => {
    // Logic to determine the terms based on the newDueDate
    // For example, you might want to check the difference in days and set terms accordingly
    const daysDifference = differenceInDays(newDueDate, new Date());

    if (daysDifference === 15) {
      setSelectedTerms("Net 15");
    } else if (daysDifference === 30) {
      setSelectedTerms("Net 30");
    } else if (daysDifference === 45) {
      setSelectedTerms("Net 45");
    } else if (daysDifference === 60) {
      setSelectedTerms("Net 60");
    } else {
      setSelectedTerms("Custom");
    }
  };

  useEffect(() => {
    if (currentDate && selectedTerms && !dueDateChangedManually) {
      updateDueDate(new Date(currentDate), selectedTerms);
    }
  }, [currentDate, selectedTerms, dueDateChangedManually]);

  const [showItemModal, setShowItemModal] = useState(false);
  const [itemName, setItemName] = useState("");

  const [itemData, setItemData] = useState({
    uniqueId: decryptedUniqueId,
    description: "",
  });

  const handleItemChange = (event) => {
    const { name, value } = event.target;
    setItemData({ ...itemData, [name]: value });
  };

  const handleItemClose = () => {
    setShowItemModal(false);
    setItemData({
      ...itemData,
      description: "",
    });
  };

  const [itemDescriptions, setItemDescriptions] = useState([]);

  const handleItem = (event) => {
    event.preventDefault();

    const newItemDescription = {
      itemDetails: itemName,
      description: itemData.description,
    };

    setItemDescriptions((prevDescriptions) => {
      const existingIndex = prevDescriptions.findIndex(
        (desc) => desc.itemDetails === itemName
      );

      if (existingIndex !== -1) {
        const updatedDescriptions = [...prevDescriptions];
        updatedDescriptions[existingIndex] = newItemDescription;
        return updatedDescriptions;
      } else {
        return [...prevDescriptions, newItemDescription];
      }
    });

    setRow((prevRows) => {
      return prevRows.map((row) =>
        row.itemDetails === itemName
          ? { ...row, description2: itemData.description }
          : row
      );
    });

    setShowItemModal(false);
  };

  const handleItemShow = (itemDetails) => {
    const itemDescription = itemDescriptions.find(
      (desc) => desc.itemDetails === itemDetails
    );
    if (itemDescription) {
      setItemData({ description: itemDescription.description });
    } else {
      setItemData({ description: "" });
    }
    setItemName(itemDetails);
    setShowItemModal(true);
  };

  const [dropdownData, setDropdownData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const fetchItemData = (selectedItemId) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");

    const postApiUrl = BASE_URL + `/getAllItemData`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, vendorId: "" }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseMessage;
        // setDropdownData(responseData.map((item) => item));
        const filteredData = responseData.filter(
          (item) => item.id !== selectedItemId
        );
        if (filteredData.length !== responseData.length) {
          // If there's a match, update dropdownData with the filtered data
          setDropdownData(filteredData);
        } else {
          // If there's no match, set dropdownData with the whole response data
          setDropdownData(responseData.map((item) => item));
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  const [searchIndex, setSearchIndex] = useState(0);

  useEffect(() => {
    const results = dropdownData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, dropdownData]);

  const handleSearchChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...row];
    newRow[index].itemDetails = value;
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setRow(newRow);
    setSearchIndex(index);
  };

  const handleItemDetailsChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...row];
    newRow[index].itemDetails = value;
    setRow(newRow);
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setSearchIndex(index);
    if (value) {
      fetchItemRate(value, index);
    } else {
      handleSearchChange(value, index);
    }
  };

  const handleItemSelect = (selectedItem, selectedItemId, index) => {
    setSearchResults([]);
    const newRow = [...row];
    newRow[index].itemDetails = selectedItem;
    setRow(newRow);
    fetchItemRate(selectedItemId, index);
  };

  const fetchItemRate = (itemId, index) => {
    const jwtToken = localStorage.getItem("jwtToken");
    const getApiUrl = BASE_URL + `/getItemData/${itemId}`;

    fetch(getApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const rate = data.data.taxableAmount;
        const hsnSac = data.data.hsnCode;
        const description1 = data.data.description;
        const itemName = data.data.name;
        const tax = data.data.intraStateTaxRate;
        const updatedRow = [...row];
        updatedRow[index].rate = rate;
        updatedRow[index].hsnSac = hsnSac;
        updatedRow[index].description1 = description1;
        updatedRow[index].itemDetails = itemName;
        updatedRow[index].tax = tax;
        setRow(updatedRow);
        setSearchResults([]);
        handleAdd();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const [showDueDate, setShowDueDate] = useState(true);

  const updateDueDate = (date, terms) => {
    setDueDateChangedManually(false);
    if (terms.startsWith("Net ")) {
      const daysToAdd = parseInt(terms.replace("Net ", ""));
      setDueDate(normalDateFormat(addDays(startDate, daysToAdd)));
      setShowDueDate(true);
    } else if (terms === "Due End Of The Month") {
      setDueDate(normalDateFormat(endOfMonth(startDate)));
      setShowDueDate(true);
    } else if (terms === "Due End Of Next Month") {
      setDueDate(normalDateFormat(endOfMonth(addMonths(startDate, 1))));
      setShowDueDate(true);
    } else if (terms === "Due On Receipt") {
      setDueDate(normalDateFormat(startDate));
      setShowDueDate(true);
    } else if (terms === "Custom") {
      setShowDueDate(false);
    } else {
      setDueDate("");
    }
  };

  const updateDueDate1 = (date, terms) => {
    setDueDateChangedManually(false);
    if (terms.startsWith("Net ")) {
      const daysToAdd = parseInt(terms.replace("Net ", ""));
      setDueDate(normalDateFormat(addDays(date, daysToAdd)));
      setShowDueDate(true);
    } else if (terms === "Due End Of The Month") {
      setDueDate(normalDateFormat(endOfMonth(date)));
      setShowDueDate(true);
    } else if (terms === "Due End Of Next Month") {
      setDueDate(normalDateFormat(endOfMonth(addMonths(date, 1))));
      setShowDueDate(true);
    } else if (terms === "Due On Receipt") {
      setDueDate(normalDateFormat(date));
      setShowDueDate(true);
    } else if (terms === "Custom") {
      setShowDueDate(false);
    } else {
      setDueDate("");
    }
  };

  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());

  useEffect(() => {
    setStartDate(new Date());
    setStartDate1(new Date());
  }, []);

  const [dateValue, setDateValue] = useState("");
  const [dateValue1, setDateValue1] = useState("");

  const handleDate = (date) => {
    setStartDate(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue(normalFormat);
    updateDueDate1(date, selectedTerms);
  };

  const handleDate1 = (date) => {
    setStartDate1(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue1(normalFormat);
  };

  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return date;
  };

  const [showDisType1, setShowDisType1] = useState("%");
  const changeDiscountType = () => {
    setShowDisType1((prevType) => (prevType === "₹" ? "%" : "₹"));
  };

  const [showDisType2, setShowDisType2] = useState("%");
  const changeDiscountType1 = () => {
    setShowDisType2((prevType) => (prevType === "₹" ? "%" : "₹"));
  };

  const options = custNames.map((customer) => ({
    value: customer.id,
    label: customer.customerName,
  }));

  const optionSales = salesPersonData.map((person) => ({
    value: person.id,
    label: person.name,
  }));

  const optionPaymentTerms = [
    { value: "Net 15", label: "Net 15" },
    { value: "Net 30", label: "Net 30" },
    { value: "Net 45", label: "Net 45" },
    { value: "Net 60", label: "Net 60" },
    { value: "Due End Of The Month", label: "Due End Of The Month" },
    { value: "Due End Of Next Month", label: "Due End Of Next Month" },
    { value: "Due On Receipt", label: "Due On Receipt" },
    { value: "Custom", label: "Custom" },
  ];

  const handleChange = (event) => {
    // setSelectedOption(event.target.value);
    // const { name, value } = event.target;
    // setFormData((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }));

    const { name, value } = event.target;
  
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        [name]: value,
      };
      
      // If customerName is being changed, also update displayName with the same value
      if (name === 'customerName') {
        newData.displayName = value;
      }
      
      return newData;
    });
  };

  const handlePaymentTerms = (selectedOption) => {
    setSelectedTerms(selectedOption.value);
    updateDueDate(dateValue, selectedOption.value);
  };

  const optionTaxes = [
    { value: "0", label: "0 GST [0%]" },
    { value: "0.05", label: "5 GST [5%]" },
    { value: "0.12", label: "12 GST [12%]" },
    { value: "0.18", label: "18 GST [18%]" },
    { value: "0.28", label: "28 GST [28%]" },
  ];

  const [taxValues, setTaxValues] = useState(Array(row.length).fill(0));

  const handleTaxChangeForRow = (taxValue, rowIndex) => {
    const newTaxValues = [...taxValues];
    newTaxValues[rowIndex] = taxValue;
    setTaxValues(newTaxValues);
  };

  const optionsSalesPerson = salesPersonData.map((sales) => ({
    value: sales.name,
    label: sales.name,
  }));

  const customStyles2 = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      background: "#F6F6F6",
      borderRadius: "10px",
      // minHeight: "40px",
      height: "40px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginLeft: "8px",
      height: "40px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      // height: "40px",
    }),
  };
  const customStyles3 = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      background: "#F6F6F6",
      borderRadius: "10px",
      // minHeight: "40px",
      height: "20px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginLeft: "8px",
      height: "20px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      // height: "40px",
    }),
  };
  const [formData, setFormData] = useState({
    uniqueId: decryptedUniqueId,
    customerType: "Business",
    status: "Active",
    someName: "Mr.",
    firstName: "",
    lastName: "",
    companyName: "",
    displayName:"",
    customerName: "",
    email: "",
    workphone: "",
    mobile: "",
    designation: "",
    department: "",
    website: "",
    currency: "INR- Indian Rupee",
    openingBalance: "0",
    paymentTerms: "",
    facebook: "",
    twitter: "",
    billAttention: "",
    billCountry: "India",
    billAddress1: "",
    billAddress2: "",
    billCity: "",
    billState: "Maharashtra",
    billZipcode: "",
    billPhone: "",
    billFax: "",
    shipAttention: "",
    shipCountry: "India",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "Maharashtra",
    shipZipcode: "",
    shipPhone: "",
    shipFax: "",
    gstin: "",
    pan: "",
    placeOfSupply: "[MH] - Maharashtra",
    taxable: "",
    reason: "N/A",
    activeStatus: "Active",
    gstTreatment: "",
    contactPerson: [
      {
        other: "",
        otherFirstname: "",
        otherLastname: "",
        otherEmail: "",
        otherWorkphone: "",
        otherMobile: "",
      },
    ],
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validationMessage, setValidationMessage] = useState("");

  const handleSaveClick = () => {
    let newValidationMessage = "";

    if (!formData.customerName) {
      newValidationMessage += "Please enter a customer display name.\n";
    } else if (
      formData.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
    ) {
      newValidationMessage += "Please enter a valid email address.\n";
    }

    setValidationMessage(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0);
      // alert(newValidationMessage.trim()); // Trim trailing newline for better formatting
      return; // Exit function if validation fails
    }
    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + "/createCustomer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          swal({
            icon: "success",
            title: "Customer Created",
            text: "",
          });
          setShow(false);
          fetchAllCustomers();
        } else if (data.responseCode === "400") {
          swal({
            icon: "error",
            title: "This email already exists, please insert another email.",
            text: "",
          });
          setShow(true);
        } else {
          swal({
            icon: "error",
            title: "Failed to Create Customer!",
            text: "",
          });
          setShow(true);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        setShow(true);
      });
  };
  const generateNamePairs = () => {
    const { someName, firstName, lastName, companyName } = formData;
    const pairs = [];

    if (firstName && lastName) {
      pairs.push(`${someName} ${firstName} ${lastName}`);
      pairs.push(`${firstName} ${lastName}`);
      pairs.push(`${lastName} , ${firstName}`);
      pairs.push(`${companyName}`);
    }

    return pairs;
  };

  useEffect(() => {
    if (
      selectedTaxType === "TDS" &&
      selectedTdsOption &&
      selectedTdsOption.discount
    ) {
      const subtotal = calculateSubTotal();
      const discountedTotal = applyDiscount(
        subtotal,
        selectedTdsOption.discount
      );
    } else if (
      selectedTaxType === "TCS" &&
      selectedTcsOption &&
      selectedTcsOption.discount
    ) {
      const subtotal = calculateSubTotal();
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedTcsOption.discount
      );
    }
  }, [row]);
  const initialContent = `
  <div className="main-container" style="margin: 10px; padding: 10px; text-align: center; background-color: #4190f2;">
  <h2 style="margin: 10px; padding: 10px; text-align: center;">${invoiceNumber}</h2>
  </div>

<div className="content-container">
  <h4 style="margin: 20px; padding: 20px;"> Dear ${selectedCustomer},</h4>
  <p style="margin: 20px; padding: 20px;" className="my-custom-paragraph">Thank you for your business. Your pro forma can be viewed, printed and downloaded as PDF from the link below. You can also choose to pay it online.</p>
  <div className="card" style="border: 1px solid #e8deb5; border-radius: 3px; padding: 26px; margin: 26px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); width: 50%; margin: 0 auto;  background-color: #fefff1;">
      <h4 style="margin: 5px; padding: 5px; text-align: center;">INVOICE AMOUNT</h4>
      <h4 style=" text-align: center; color:red;">₹ ${calculateTotal()}</h4>
      <table style="width: 100%; text-align: center;">
      <tr>
          <td>ProForma No</td>
          <td>${invoiceNumber}</td>
      </tr>
      <tr>
          <td>ProForma Date</td>
          <td>${dateValue || normalDateFormat(startDate)}</td>
      </tr>
      <tr>
          <td>Due Date</td>
          <td>${showDueDate === true ? dueDate : dateValue1}</td>
      </tr>
  
  </table>
  <div style="text-align: center; padding-top:5px;">

  </div>

  </div>

    <div style=" padding: 10px; width: 80%; margin: 0 auto;">
      <h4>Regards</h3>
      <p>${userDetail.companyName}</p>
    </div>
</div>`;
  const handleMailSubmit = async () => {
    const itemDetails = row.map((data) => {
      return {
        itemS: data.itemDetails || "", // Assuming you have an input for itemDetails
        // hsnSac: data.hsnSac,
        // size: data.size,
        quantity: data.quantity || "",
        rate: data.rate || "",
        discount: data.discount || 0,
        discountType: data.discountType || "",
        tax: data.tax, // Assuming the tax rate is the same for all items
        amount: calculateAmount(data, selectedTaxRate),
        // rowDiscount: calculateDisRupees(data),
      };
    });
    const postData = {
      to: custEmail,
      from: userEmail,
      subject: subject,
      body: editorContent,
      total: calculateTotal(),
      invoiceNumber: invoiceNumber,
      invoiceDate: dateValue || normalDateFormat(startDate),
      dueDate: showDueDate === true ? dueDate : dateValue1,
      payButtonNow: "Pay Now",
      organizationName: userDetail.companyName,
      customerName: selectedCustomer,
      companyName: userDetail.companyName,
      terms: termsAndCondition,
      items: itemDetails,
      // name: bankData.name,
      // bank: bankData.bankName,
      // accountNo: bankData.accountNumber,
      // ifscCode: bankData.ifsc,
      // type: bankData.accountType,
      totalInWords: "One Thousand ",
      subTotal: calculateSubTotal(),
      adjustment: parseFloat(adjustmentValue) || 0,
      adjustmentLabel: adjustmentLabel,
      totalDue: calculateTotal(),
      notes: customerNote,
      state: customerState,
      address: userDetail.address1,
      imageName: "kunal.PNG",
      uniqueId: decryptedUniqueId,
    };

    setIsLoading3(true);
    const response = await fetch(BASE_URL + "/send-email-with-pdf", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Mail Send Successfully",
          });
          handleInvoicesClick();
          setIsLoading3(false);
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading3(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        setIsLoading3(false);
      });
  };

  const fetchTermsData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");

    const postApiUrl = BASE_URL + `/getCheckedTermsAndCondition`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseCode;
        // setDropdownData(responseData.map((item) => item));
        setTermsAndCondition(responseData.join("") || "");
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  return (
    <>
      {showFormaPage && (
        <div className="">
          <Row>
            <Col>
              <div className="my-3">
                <div className="d-flex align-items-center ml-1 mt-4">
                  <div
                    className="cursor-pointer d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      height: "35px",
                      width: "38px",
                    }}
                  >
                    <ArrowBackIosIcon
                      width={2}
                      onClick={handleCancleClick}
                      className="arrow-child"
                    />
                  </div>
                  <div className="newcustomer-head ml-2">
                    <h5
                      className="ml-1 mb-0"
                      style={{ letterSpacing: "1px", color: "#086bd5de" }}
                    >
                      Create New Pro Forma Invoice
                    </h5>
                  </div>
                </div>
              </div>
              {validationMessage1 && (
                <Alert severity="error" className="mt-0">
                  {validationMessage1.split("\n").map((message, index) => (
                    <div key={index}>{message}</div>
                  ))}
                </Alert>
              )}{" "}
              <Card className="card-style p-4">
                <Row>
                  <Col className="" md="3">
                    <Form.Group>
                      <label
                        className="text-primary"
                        style={{ fontSize: 16, fontWeight: "600" }}
                      >
                        CUSTOMER INFO
                      </label>
                    </Form.Group>
                  </Col>
                </Row>
                <Form>
                  <Row>
                    {/* <Col className="" md="3">
                    <Form.Group>
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Customer Name
                      </label>
                      <Select
                      className="mt-0"
                        // className="dropdown-field"                       
                        options={options}
                        value={options.find(
                          (option) => option.value === selectedCustomer
                        )}
                        onChange={(selectedOption) =>
                          handleCustomerChange(
                            selectedOption.label,
                            selectedOption.value
                          )
                        }
                        placeholder="Select Customer"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            border: "none",
                            padding: "5px",
                          }),
                        }}
                      />
                      {errors.customerName && (
                        <div className="text-danger mb-4">
                          {errors.customerName}
                        </div>
                      )}
                    </Form.Group>
                  </Col> */}

                    <Col className="" md="3">
                      <Form.Group>
                        <label
                          className=""
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          Customer Name <span className="valid"> *</span>
                        </label>
                        <Select
                          className="mt-0"
                          // className="dropdown-field"
                          options={options}
                          value={options.find(
                            (option) => option.value === selectedCustomer
                          )}
                          onChange={(selectedOption) =>
                            handleCustomerChange(
                              selectedOption.label,
                              selectedOption.value
                            )
                          }
                          placeholder="Select Customer"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              borderRadius: "7px",
                              height: "46px",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              height: "19px",
                            }),
                          }}
                        />
                        <button
                          type="button"
                          class="btn p-0 text-left"
                          onClick={handleShow}
                        >
                          <span
                            class="text-primary"
                            style={{ fontSize: "12px", fontWeight: "500" }}
                          >
                            New Customer
                          </span>
                        </button>

                        {/* Add customer popup*/}
                        <Modal size="lg" show={show} onHide={handleClose}>
                          <div class="modal-header d-flex align-items-center pb-2 pt-2">
                            <h5 class="modal-title fs-5" id="newcustomerPopup">
                              New Customer
                            </h5>
                            <button
                              type="button"
                              className="btn pr-0"
                              onClick={handleClose}
                            >
                              <ion-icon
                                name="close-circle-outline"
                                size="large"
                                style={{ width: "28px" }}
                              ></ion-icon>
                            </button>
                          </div>
                          <Modal.Body className="p-0 pb-3">
                            {validationMessage && (
                              <Alert severity="error" className="mt-0">
                                {validationMessage
                                  .split("\n")
                                  .map((message, index) => (
                                    <div key={index}>{message}</div>
                                  ))}
                              </Alert>
                            )}{" "}
                            <div className="container">
                              <div className="row d-inline-flex">
                                <div className="modalinputchild">
                                  <div className="text-style"> Salutation </div>
                                  <select
                                    className="form-control"
                                    name="someName"
                                    value={formData.someName}
                                    onChange={handleChange}
                                    style={{
                                      borderRadius: "9px",
                                      backgroundColor: "#f5f5f5",
                                      width: "200px",
                                      height: "35px",
                                      fontSize: "13px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <option value="Mr.">Mr.</option>
                                    <option value="Mrs.">Mrs.</option>
                                    <option value="Ms.">Ms.</option>
                                    <option value="Miss.">Miss.</option>
                                    <option value="Dr.">Dr.</option>
                                  </select>
                                </div>
                                <div className="modalinputchild">
                                  <div className="text-style">First Name</div>
                                  <input
                                    className="form-control"
                                    placeholder="First Name"
                                    name="firstName"
                                    id="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    type="text"
                                  />
                                </div>
                                <div className="modalinputchild">
                                  <div className="text-style">Last Name</div>
                                  <input
                                    className="form-control"
                                    placeholder="Last Name"
                                    id="lastName"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="row d-inline-flex pt-2">
                                <div className="modalinputchild">
                                  <div className="text-style">
                                    {" "}
                                    Customer Email{" "}
                                  </div>
                                  <input
                                    className="form-control"
                                    placeholder="Customer Email"
                                    name="email"
                                    type="text"
                                    value={formData.email}
                                    onChange={handleChange}
                                  />
                                </div>

                                <div className="modalinputchild">
                                  <div className="text-style">
                                    {" "}
                                    Customer Display Name{" "}
                                    <span style={{ color: "red" }}> *</span>
                                  </div>
                                  <input
                                    className="form-control"
                                    placeholder="Customer Display Name"
                                    list="customerNames"
                                    name="customerName"
                                    value={formData.customerName}
                                    onChange={handleChange}
                                    style={{
                                      borderRadius: "7px",
                                      backgroundColor: "#f5f5f5",
                                      width: "200px",
                                      height: "40px",
                                      fontSize: "13px",
                                      marginTop: "10px",
                                    }}
                                  />
                                  <datalist id="customerNames">
                                    {generateNamePairs().map((pair, index) => (
                                      <option key={index} value={pair} />
                                    ))}
                                  </datalist>{" "}
                                </div>
                                <div className="modalinputchild">
                                  <div className="text-style">Company Name</div>
                                  <input
                                    className="form-control"
                                    placeholder="Add company name"
                                    name="companyName"
                                    type="text"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row d-inline-flex pt-2">
                                <div className="modalinputchild">
                                  <div className="text-style">
                                    Customer Phone
                                  </div>
                                  <input
                                    className="form-control"
                                    placeholder="Work Phone"
                                    name="workphone"
                                    type="text"
                                    value={formData.workphone}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="modalinputchild">
                                  <div className="text-style">Mobile</div>
                                  <input
                                    className="form-control"
                                    placeholder="Mobile"
                                    name="mobile"
                                    type="text"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer className="p-2">
                            <div className="modal-bottombtns">
                              <button
                                type="button"
                                class="modalbtn-1 btn mt-0"
                                onClick={handleClose}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                class="modalbtn-3 btn btn-primary"
                                onClick={handleSaveClick}
                              >
                                Create Customer
                              </button>
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </Form.Group>
                    </Col>

                    <Col className="" md="3">
                      <Form.Group>
                        <label
                          className=""
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          Invoice Number <span className="valid"> *</span>
                        </label>
                        <Form.Control
                          placeholder="Invoice Number"
                          type="text"
                          name="invoiceNumber"
                          value={invoiceNumber}
                          onChange={handleInvoiceNumberChange}
                          style={{
                            padding: "12px",
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            color: "black",
                            height: "40px",
                          }}
                        />
                        {/* <button type="button" className="btn p-0" onClick={openInvoice}>
                      <span class="text-primary" style={{ fontSize: "12px", fontWeight: "500" }}>
                        Generate
                      </span>
                    </button> */}
                        <button
                          type="button"
                          className="btn p-0 text-left"
                          data-bs-toggle="modal"
                          data-bs-target="#generatePop"
                        >
                          <span
                            className="text-primary"
                            style={{ fontSize: "12px", fontWeight: "500" }}
                          >
                            Generate
                          </span>
                        </button>

                        {/* Invoice generate popup*/}
                        <div
                          className="modal fade"
                          id="generatePop"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header d-flex align-items-center pb-2 pt-2">
                                <h5
                                  className="modal-title fs-5"
                                  id="generateinvoiceHead"
                                >
                                  Invoice Number
                                </h5>
                                <button
                                  type="button"
                                  className="btn pr-0"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <ion-icon
                                    name="close-circle-outline"
                                    size="large"
                                    style={{ width: "28px" }}
                                  ></ion-icon>
                                </button>
                              </div>
                              <div className="modal-body pt-1 pb-1">
                                <div className="popupinvoice-container ml-2 mt-2 mr-2 mb-0">
                                  <div className="row headline">
                                    <div className="customer-headline-label">
                                      <label className="form-label">
                                        <span className="headline">
                                          Your invoice numbers are set on
                                          auto-generate mode to save your time.
                                          <br /> Are you sure about changing
                                          this setting?
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <label className="customer-rdiobox-label d-flex align-items-center">
                                      <input
                                        className="mr-2"
                                        type="radio"
                                        name="cus_type"
                                        id="cus_bus"
                                        value="Business"
                                        onClick={setBill}
                                      />
                                      <span className="customer-option">
                                        Continue auto-generating invoice numbers
                                      </span>
                                    </label>
                                  </div>
                                  {show1 && (
                                    <div className="row ml-3 mb-3">
                                      <div className="customer-generate-inputfield">
                                        <span>Prefix</span>
                                        <input
                                          className="form-control mt-1"
                                          placeholder="Prefix"
                                          value={invoiceNumberPrefix}
                                          onChange={(e) =>
                                            setInvoiceNumberPrefix(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="customer-generate-inputfield2">
                                        <span>Next Number</span>
                                        <input
                                          className="form-control mt-1"
                                          placeholder="Next Number"
                                          value={invoiceNumberNext}
                                          onChange={(e) =>
                                            setInvoiceNumberNext(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className="row mt-1">
                                    <label className="customer-rdiobox-label d-flex align-items-center">
                                      <input
                                        className="mr-2"
                                        type="radio"
                                        name="cus_type"
                                        id="cus_bus"
                                        value="Business"
                                        onClick={() => {
                                          setShow1(false);
                                        }}
                                      />
                                      <span className="customer-option">
                                        I will add them manually each time
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => {
                                copyAdd();
                              }}
                              data-bs-dismiss="modal"
                            >
                              Save changes
                            </button> */}
                                <div className="modal-bottombtns">
                                  <button
                                    type="button"
                                    class="modalbtn-1 btn mt-0"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="button"
                                    class="modalbtn-3 btn btn-primary"
                                    onClick={() => {
                                      copyAdd();
                                    }}
                                    data-bs-dismiss="modal"
                                  >
                                    Save Changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                    </Col>

                    <Col className="" md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Order Number
                        </label>
                        <Form.Control
                          placeholder="Order Number"
                          name="orderNumber"
                          value={orderNumber}
                          onChange={(e) => setOrderNumber(e.target.value)}
                          type="text"
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            padding: "12px",
                            color: "black",
                            height: "40px",
                            "::placeholder": {
                              color:
                                "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                            },
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col className="" md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Invoice Date <span className="valid"> *</span>
                        </label>
                        <div className="customDatePickerWidth">
                          <DatePicker
                            className="form-control"
                            selected={startDate}
                            dateFormat="dd-MM-yyyy"
                            onChange={(date) => handleDate(date)}
                            showIcon
                            toggleCalendarOnIconClick
                            // No need to apply inline styles since we're using className
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col className="" md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Payment Terms
                        </label>
                        <Select
                          // className="dropdown-field"
                          className="mt-0"
                          options={optionPaymentTerms}
                          value={optionPaymentTerms.find(
                            (option) => option.value === selectedTerms
                          )}
                          onChange={handlePaymentTerms}
                          placeholder="Select Terms"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              borderRadius: "7px",
                              height: "46px",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              height: "19px",
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col className="" md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Due Date <span className="valid"> *</span>
                        </label>
                        <div>
                          {showDueDate ? (
                            <input
                              className="form-control"
                              type="text"
                              value={dueDate}
                              onClick={() => {
                                setShowDueDate(false);
                                setSelectedTerms("Custom");
                              }}
                              style={{
                                backgroundColor: "#F6F6F6",
                                borderRadius: "7px",
                                padding: "12px",
                                height: "40px",
                                width: "260.8px",
                              }}
                              readOnly
                              showIcon
                              toggleCalendarOnIconClick
                            />
                          ) : (
                            <div className="customDatePickerWidth">
                              <DatePicker
                                className="form-control"
                                selected={startDate1}
                                dateFormat="dd-MM-yyyy"
                                onChange={(date) => handleDate1(date)}
                                placeholderText="Select due date"
                                showIcon
                                toggleCalendarOnIconClick
                              />
                            </div>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col className="" md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Sales Person
                        </label>
                        <Select
                          className="mt-0"
                          options={optionsSalesPerson}
                          value={optionsSalesPerson.find(
                            (option) => option.value === selectedSalesPerson
                          )}
                          onChange={handleSalesPersonChange}
                          placeholder="Select SalesPerson"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              borderRadius: "7px",
                              height: "46px",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              height: "19px",
                            }),
                          }}
                        />
                      </Form.Group>
                      {/* <button type="button" className="btn p-0" onClick={openPopup}>
                    <span class="text-primary" style={{ fontSize: "12px", fontWeight: "500" }}>
                      New Sales Person
                    </span>
                  </button> */}
                      <button
                        type="button"
                        class="btn p-0 text-left"
                        onClick={handleSalesShow}
                      >
                        <span
                          class="text-primary"
                          style={{ fontSize: "12px", fontWeight: "500" }}
                        >
                          New Sales Person
                        </span>
                      </button>

                      {/*Sales Person Popup*/}
                      <Modal show={showSalesModal} onHide={handleSalesClose}>
                        <div class="modal-header d-flex align-items-center pb-2 pt-2">
                          <h5 class="modal-title fs-5" id="salespersonHead">
                            Add Sales Person
                          </h5>
                          <button
                            type="button"
                            className="btn pr-0"
                            onClick={handleSalesClose}
                          >
                            <ion-icon
                              name="close-circle-outline"
                              size="large"
                              style={{ width: "28px" }}
                            ></ion-icon>
                          </button>
                        </div>
                        <Modal.Body>
                          {validationMessage2 && (
                            <Alert severity="error" className="mt-0">
                              {validationMessage1
                                .split("\n")
                                .map((message, index) => (
                                  <div key={index}>{message}</div>
                                ))}
                            </Alert>
                          )}{" "}
                          <div className="row pt-2">
                            <div className="modalsalesinput">
                              <label className="form-label">
                                <span style={{ color: "red" }}>Name *</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Name"
                                id="name"
                                name="name"
                                type="text"
                                value={salesData.name}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="row pt-4">
                            <div className="modalsalesinput">
                              <label className="form-label">
                                <span>Email </span>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Email"
                                id="email"
                                name="email"
                                type="text"
                                value={salesData.email}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer className="p-2">
                          <div className="modal-bottombtns">
                            <button
                              type="button"
                              class="modalbtn-1 btn mt-0"
                              onClick={handleSalesClose}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="modalbtn-3 btn btn-primary"
                              onClick={handleSalesPerson}
                              data-bs-dismiss="modal"
                            >
                              Create Sales Person
                            </button>
                          </div>
                        </Modal.Footer>
                      </Modal>
                    </Col>
                    <Col className="" md="6">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Subject
                        </label>
                        <Form.Control
                          placeholder="Subject"
                          name="subject"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          type="text"
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            padding: "12px",
                            color: "black",
                            height: "40px",
                            "::placeholder": {
                              color:
                                "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                            },
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col md="3">
                      <Form.Group>
                        <label
                          className="text-primary"
                          style={{ fontSize: 14, fontWeight: "600" }}
                        >
                          ITEM INFO
                        </label>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Card>
                        <div className="" style={{ overflowX: "auto" }}>
                          <table
                            className="table table-bordered mb-0"
                            style={{
                              width: "100%",
                            }}
                          >
                            <thead className="text-center">
                              <tr>
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "350px",
                                  }}
                                >
                                  ITEM DETAILS
                                </th>
                                {hsnCheck === "YES" && (
                                  <th
                                    scope="col"
                                    style={{
                                      whiteSpace: "nowrap",
                                      minWidth: "100px",
                                    }}
                                  >
                                    HSN/SAC
                                  </th>
                                )}
                                {sizeCheck === "YES" && (
                                  <th
                                    scope="col"
                                    style={{
                                      whiteSpace: "nowrap",
                                      minWidth: "100px",
                                    }}
                                  >
                                    SIZE
                                  </th>
                                )}
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  PER
                                </th>
                                {quantityCheck === "YES" && (
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  QUANTITY
                                </th>
                                )}
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100px",
                                  }}
                                >
                                  RATE
                                </th>
                                {discountCheck === "YES" && (
                                  <th
                                    scope="col"
                                    style={{
                                      whiteSpace: "nowrap",
                                      minWidth: "100px",
                                    }}
                                  >
                                    DISCOUNT
                                  </th>
                                )}{" "}
                                {bussinessRegistered === "YES" && (
                                  <th
                                    scope="col"
                                    style={{
                                      whiteSpace: "nowrap",
                                      minWidth: "100px",
                                    }}
                                  >
                                    TAX
                                  </th>
                                )}
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "50px",
                                  }}
                                >
                                  AMOUNT
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "50px",
                                  }}
                                >
                                  ACTION
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {row.map((data, i) => {
                                const amount = calculateAmount(data, data.tax);
                                const totalAmountWithTax = parseFloat(amount);
                                const itemDescription = itemDescriptions.find(
                                  (desc) =>
                                    desc.itemDetails === data.itemDetails
                                );
                                return (
                                  <tr key={i}>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="form-control noBorder"
                                          placeholder="Search items..."
                                          value={data.itemDetails}
                                          onChange={(e) =>
                                            handleItemDetailsChange(e, i)
                                          }
                                          style={{ flex: 1 }}
                                        />
                                        {data.itemDetails && (
                                          <button
                                            type="button"
                                            className="btn p-0"
                                            onClick={() =>
                                              handleItemShow(data.itemDetails)
                                            }
                                            style={{ marginLeft: "10px" }}
                                          >
                                            <span
                                              className="text-primary"
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              Edit
                                            </span>
                                          </button>
                                        )}
                                      </div>
                                      {itemDescription && (
                                        <div
                                          style={{
                                            marginTop: "10px",
                                            color: "#6c757d",
                                            whiteSpace: "pre-line",
                                          }}
                                        >
                                          {itemDescription.description}
                                        </div>
                                      )}
                                      {searchPerformed &&
                                        data.itemDetails &&
                                        i === searchIndex && (
                                          <div
                                            style={{
                                              position: "absolute",
                                              zIndex: 9999, // Higher than scrollbar
                                              top: "100%", // Position below input
                                              left: 0,
                                              width: "30%",
                                            }}
                                          >
                                            {searchResults.length === 0 && ""}
                                            {searchResults.length > 0 && (
                                              <ul
                                                style={{
                                                  listStyleType: "none",
                                                  padding: 0,
                                                  position: "absolute",
                                                  top: "calc(100% + 10px)",
                                                  left: 0,
                                                  zIndex: 1000,
                                                  backgroundColor: "#FFF",
                                                  boxShadow:
                                                    "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                                  borderRadius: "5px",
                                                  width: "100%",
                                                  maxHeight: "200px", // Set a max height for the list
                                                  overflowY: "auto", // Enable vertical scrolling
                                                }}
                                              >
                                                {searchResults.map(
                                                  (result, index) => (
                                                    <li
                                                      key={index}
                                                      onClick={() =>
                                                        handleItemSelect(
                                                          result.name,
                                                          result.id,
                                                          i
                                                        )
                                                      }
                                                      onMouseEnter={(e) => {
                                                        e.target.style.backgroundColor =
                                                          "#408dfb";
                                                        e.target.style.color =
                                                          "#FFF";
                                                        e.target.style.borderRadius =
                                                          "5px"; // Apply border radius on hover
                                                      }}
                                                      onMouseLeave={(e) => {
                                                        e.target.style.backgroundColor =
                                                          "";
                                                        e.target.style.color =
                                                          "#000";
                                                        e.target.style.borderRadius =
                                                          ""; // Reset border radius
                                                      }}
                                                      style={{
                                                        padding: "12px",
                                                        cursor: "pointer",
                                                        whiteSpace: "nowrap", // Prevent text from overflowing
                                                        overflow: "hidden", // Hide overflow text
                                                        textOverflow:
                                                          "ellipsis", // Add ellipsis for overflowing text
                                                      }}
                                                    >
                                                      {result.name}
                                                      <br />
                                                      Rate : ₹
                                                      {result.taxableAmount}
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            )}
                                          </div>
                                        )}
                                    </td>
                                    <Modal
                                      show={showItemModal}
                                      onHide={handleItemClose}
                                    >
                                      <div class="modal-header d-flex align-items-center pb-2 pt-2">
                                        <h5
                                          class="modal-title fs-5"
                                          id="salespersonHead"
                                        >
                                          Add Item Description
                                        </h5>
                                        <button
                                          type="button"
                                          className="btn pr-0"
                                          onClick={handleItemClose}
                                        >
                                          <ion-icon
                                            name="close-circle-outline"
                                            size="large"
                                            style={{ width: "28px" }}
                                          ></ion-icon>
                                        </button>
                                      </div>
                                      <Modal.Body>
                                        <Row>
                                          <Col md="12">
                                            <div className="row pt-2">
                                              <div className="col-lg-6">
                                                <label
                                                  className="form-label"
                                                  style={{
                                                    fontSize: "14px",
                                                    color: "grey",
                                                    borderRadius: "7px",
                                                  }}
                                                >
                                                  Item
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 pl-0 pr-0">
                                              <input
                                                className="form-control"
                                                placeholder="Name"
                                                id="name"
                                                name="name"
                                                type="text"
                                                value={itemName}
                                                readOnly
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md="12">
                                            <div className="row pt-2">
                                              <div className="col-lg-6">
                                                <label
                                                  className="form-label"
                                                  style={{
                                                    fontSize: "14px",
                                                    color: "grey",
                                                    borderRadius: "7px",
                                                  }}
                                                >
                                                  Description
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 pl-0 pr-0">
                                              <textarea
                                                className="cn-textarea"
                                                type="text"
                                                placeholder="Item Description"
                                                name="description"
                                                value={itemData.description}
                                                onChange={handleItemChange}
                                                rows={2}
                                                style={{
                                                  backgroundColor: "#F6F6F6",
                                                  borderRadius: "9px",
                                                  padding: "12px",
                                                  width: "100%",
                                                }}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </Modal.Body>
                                      <Modal.Footer className="p-2">
                                        <div className="modal-bottombtns">
                                          <button
                                            type="button"
                                            class="modalbtn-1 btn mt-0"
                                            onClick={handleItemClose}
                                          >
                                            Close
                                          </button>
                                          <button
                                            type="button"
                                            class="modalbtn-3 btn btn-primary"
                                            onClick={handleItem}
                                            data-bs-dismiss="modal"
                                          >
                                            Add Description
                                          </button>
                                        </div>
                                      </Modal.Footer>
                                    </Modal>

                                    {hsnCheck === "YES" && (
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        <input
                                          className="form-control noArrowNumber noBorder"
                                          value={data.hsnSac || ""}
                                          name="hsnSac"
                                          type=""
                                          placeholder="hsnSac"
                                          onChange={(e) =>
                                            handleHsnSacChange(e, i)
                                          }
                                        />
                                      </td>
                                    )}
                                    {sizeCheck === "YES" && (
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        <input
                                          className="form-control noArrowNumber noBorder"
                                          value={data.size || ""}
                                          name="size"
                                          type=""
                                          placeholder="size"
                                          onChange={(e) =>
                                            handleSizeChange(e, i)
                                          }
                                        />
                                      </td>
                                    )}
                                     <td style={{ whiteSpace: "nowrap" }}>
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.per || ""}
                                        type="number"
                                        readOnly
                                        // onChange={(e) =>
                                        //   handleQuantityChange(e, i)
                                        // }
                                      />
                                    </td>
                                     {quantityCheck === "YES" && (
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.quantity || ""}
                                        type="number"
                                        onChange={(e) =>
                                          handleQuantityChange(e, i)
                                        }
                                      />
                                    </td>
                                     )}

                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.rate}
                                        type="number"
                                        onChange={(e) => handleRateChange(e, i)}
                                      />
                                    </td>
                                    {discountCheck === "YES" && (
                                      <td
                                        style={{ width: "10%", padding: "0px" }}
                                      >
                                        <div className="d-flex justify-content-center">
                                          <input
                                            className="form-control col-md-6 noBorder"
                                            value={data.discount || ""}
                                            type="number"
                                            onChange={(e) =>
                                              handleDiscountChange(e, i)
                                            }
                                            name="discount"
                                            style={{
                                              textAlign: "center",
                                              padding: "0px",
                                              marginRight: "5px",
                                            }}
                                          />
                                          <select
                                            className="border-0"
                                            value={data.discountType}
                                            onChange={(e) =>
                                              handleDiscountChange(e, i)
                                            }
                                            name="discountType"
                                          >
                                            <option value="₹">₹</option>
                                            <option value="%">%</option>
                                          </select>
                                        </div>
                                      </td>
                                    )}
                                    {bussinessRegistered === "YES" && (
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        <select
                                          className="form-control"
                                          value={data.tax}
                                          onChange={(e) =>
                                            handleTaxChange(e, i)
                                          }
                                        >
                                          <option value="0">N/A</option>
                                          <option value="5">5 GST [5%]</option>
                                          <option value="12">
                                            12 GST [12%]
                                          </option>
                                          <option value="18">
                                            18 GST [18%]
                                          </option>
                                          <option value="28">
                                            28 GST [28%]
                                          </option>
                                        </select>
                                      </td>
                                    )}
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <span>
                                        {parseFloat(totalAmountWithTax).toFixed(
                                          2
                                        )}
                                      </span>
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      <button
                                        type="button"
                                        className="btn px-2 btn-light icon-border btn-sm"
                                        onClick={(e) => handleDeleteRow(e, i)}
                                      >
                                        <i className="fa fa-trash text-danger"></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Card>
                    </Col>
                  </Row>

                  {/* endtable */}

                  <Row>
                    <Col>
                      <button
                        type="button"
                        className="btn addline-btn"
                        onClick={handleAdd}
                      >
                        + Add Another Line
                      </button>
                    </Col>
                  </Row>

                  <div className="customer-grid-container">
                    <div className="customer-flex-item p-0 d-flex flex-column-reverse">
                      <div className="row pt-3">
                        <div className="col-lg-6">
                          <label
                            className="form-label required mg-b-0"
                            style={{ fontSize: "14px", color: "grey" }}
                          >
                            Customer Notes
                          </label>
                        </div>
                        <div className="col-lg-12">
                          <textarea
                            className="cn-textarea"
                            placeholder="Customer Notes..."
                            name="customerNote"
                            value={customerNote || "Thank You For Business."}
                            onChange={(e) => setCustomerNote(e.target.value)}
                            rows={1}
                            style={{
                              backgroundColor: "#F6F6F6",
                              borderRadius: "9px",
                              padding: "12px",
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="customer-subtotal-box p-3">
                      <div className="customer-flex-item p-0">
                        <div className="row">
                          <div className="col-lg-8">
                            <label className="form-label">Sub Total</label>
                          </div>
                          <div className="col-lg-4 text-end pl-0">
                            <label className="form-label">
                              {calculateSubTotal()}
                            </label>
                          </div>
                        </div>

                        {discountCheck === "YES" && (
                          <div className="row d-flex align-items-baseline mt-1">
                            <div className="col-lg-5 pr-0">
                              <label>Discount</label>
                            </div>
                            <div className="col-lg-3 pl-0 d-flex">
                              <input
                                className="form-control"
                                value={disAllValue || "0"}
                                type="number"
                                name="discount"
                                onChange={handleDisAllValueChange}
                                style={{
                                  height: "36px",
                                  width: "40px",
                                  backgroundColor: "#F6F6F6",
                                  padding: "3px 8px",
                                }}
                              />
                              <select
                                className="form-control ml-1 pl-0 rounded"
                                name="discountType"
                                value={discountType}
                                onChange={handleDiscountTypeChange}
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  backgroundColor: "#F6F6F6",
                                  padding: "3px 8px",
                                }}
                              >
                                <option disabled>Select</option>
                                <option value="₹">₹</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                            {/* <div class="input-group mb-3" style={{ width: "190px" }}>
                          <input type="text" class="form-control" name="discount" value={disAllValue} onChange={handleDisAllValueChange} style={{ backgroundColor: '#F6F6F6', padding: "10px", borderLeft: 'none', borderBottom: 'none', borderTop: 'none' }} />
                          <span class="input-group-text" name="discountType" onClick={(e) => changeDiscountType1(e)} style={{ backgroundColor: '#F6F6F6', padding: "10px", border: "none", width: "18%" }}>{showDisType2}</span>
                        </div> */}
                            <div className="col-lg-4 text-end pl-0">
                              <label className="form-label textColor-red">
                                {isNaN(parseFloat(disValue).toFixed(2))
                                  ? "0.00"
                                  : -parseFloat(disValue).toFixed(2)}
                              </label>
                            </div>
                          </div>
                        )}
                        {bussinessRegistered === "YES" && (
                          <div className="row mt-2">
                            {userState.state !== customerState && (
                              <>
                                {calculateIGSTForTax().map(
                                  (taxResult, index) => (
                                    <React.Fragment key={index}>
                                      <div className="col-lg-8">
                                        <label className="form-label required">
                                          IGST (
                                          {(taxResult.taxRate * 100).toFixed(0)}
                                          %)
                                        </label>
                                      </div>
                                      <div className="col-lg-4 text-end pl-0">
                                        <label className="form-label required">
                                          {taxResult.totalIGST.toFixed(2)}
                                        </label>
                                      </div>
                                    </React.Fragment>
                                  )
                                )}
                              </>
                            )}

                            {userState.state === customerState && (
                              <>
                                {Object.entries(
                                  calculateGroupedSGSTandCGST()
                                ).map(([taxRate, taxData], index) => (
                                  <React.Fragment key={index}>
                                    <div className="col-lg-8">
                                      <label className="form-label required">
                                        CGST (
                                        {((parseFloat(taxRate) * 100) / 2)
                                          .toFixed(1)
                                          .replace(/\.0$/, "")}
                                        %)
                                      </label>
                                    </div>
                                    <div className="col-lg-4 text-end pl-0">
                                      <label className="form-label required">
                                        {taxData.totalCGST.toFixed(2)}
                                      </label>
                                    </div>
                                    <div className="col-lg-8 mt-2">
                                      <label className="form-label required">
                                        SGST (
                                        {((parseFloat(taxRate) * 100) / 2)
                                          .toFixed(1)
                                          .replace(/\.0$/, "")}
                                        %)
                                      </label>
                                    </div>
                                    <div className="col-lg-4 text-end pl-0 mt-2">
                                      <label className="form-label required">
                                        {taxData.totalSGST.toFixed(2)}
                                      </label>
                                    </div>
                                  </React.Fragment>
                                ))}
                              </>
                            )}
                          </div>
                        )}
                        <div className="row mt-3 mb-3">
                          <div className="col-lg-5 pr-0">
                            <input
                              className="form-control col-md-10"
                              type="text"
                              placeholder="Adjustment"
                              value={adjustmentLabel}
                              onChange={(e) =>
                                setAdjustmentLabel(e.target.value)
                              }
                              name="adjustmentLabel"
                              style={{
                                backgroundColor: "#F6F6F6",
                                borderRadius: "10px",
                                color: "black",
                                "::placeholder": {
                                  color:
                                    "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-5 pl-0">
                            <input
                              className="form-control col-md-10"
                              type="text"
                              placeholder="Label"
                              value={adjustmentValue}
                              onChange={(e) =>
                                setAdjustmentValue(e.target.value)
                              }
                              style={{
                                backgroundColor: "#F6F6F6",
                                borderRadius: "10px",
                                color: "black",
                                "::placeholder": {
                                  color:
                                    "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-2 text-end pl-0">
                            <label className="form-label mt-2">
                              {oppositeValue}
                            </label>
                          </div>
                        </div>

                        <div className="my-4 d-flex justify-content-between">
                          <div
                            className="d-flex justify-content-between"
                            style={{ width: "70%" }}
                          >
                            <div className="col-lg-7 pl-0">
                              <label className="rdiobox">
                                <input
                                  className="mx-1"
                                  type="radio"
                                  name="cus_type"
                                  id="cus_bus"
                                  value="TDS"
                                  onClick={() => handleRadioClick("TDS")}
                                />
                                <span>TDS</span>
                              </label>
                              <label className="rdiobox ml-3">
                                <input
                                  className="mx-1"
                                  type="radio"
                                  name="cus_type"
                                  id="cus_indivi"
                                  value="TCS"
                                  onClick={() => handleRadioClick("TCS")}
                                />
                                <span>TCS</span>
                              </label>
                            </div>
                            <div
                              className="col-lg-6 p-0"
                              style={{ width: "190px" }}
                            >
                              {showing1 && (
                                <Select
                                  options={tds}
                                  value={selectedTdsOption}
                                  onChange={handleTdsOptionChange}
                                  placeholder="Select TDS..."
                                  styles={customStyles3}
                                />
                              )}

                              {showing2 && (
                                <Select
                                  options={tcs}
                                  value={selectedTcsOption}
                                  onChange={handleTcsOptionChange}
                                  placeholder="Select TCS..."
                                  styles={customStyles3}
                                />
                              )}
                            </div>
                          </div>
                          {showing1 && (
                            <label className="form-label">
                              {discountAmountTds}
                            </label>
                          )}

                          {showing2 && (
                            <label className="form-label">
                              {discountAmountTcs}
                            </label>
                          )}
                        </div>

                        <hr />
                        <div className="d-flex justify-content-between">
                          <label className="form-label required ">
                            Total (in Rupees) :
                          </label>
                          <label className="form-label required">
                            {showing2 ? calculateTotal() : calculateTotal2()}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-4" />
                  <Row>
                    <Col md="12">
                      <div className="row pt-2">
                        <div className="col-lg-6">
                          <label
                            className="form-label"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              borderRadius: "7px",
                            }}
                          >
                            Terms & Conditions
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12 pl-0 pr-0">
                        {/* <textarea
                          className="invoicetextarea"
                          placeholder="Add your terms & condition"
                          name="termsAndCondition"
                          value={termsAndCondition || ""}
                          onChange={(e) => setTermsAndCondition(e.target.value)}
                          rows={4}
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "9px",
                            padding: "12px",
                            height: "100%",
                          }}
                        /> */}

                        <ReactQuill
                          value={termsAndCondition}
                          onChange={handleEditorChange} // Function to update state
                          theme="snow" // Use "bubble" for a minimal UI
                          style={{ height: "300px", marginBottom: "50px" }}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
                              [{ font: [] }], // Font options
                              [{ size: [] }], // Font size
                              [{ color: [] }, { background: [] }], // Text color & background
                              ["bold", "italic", "underline", "strike"], // Basic text styles
                              [{ script: "sub" }, { script: "super" }], // Subscript & superscript
                              [{ align: [] }], // Text alignment
                              [{ list: "ordered" }, { list: "bullet" }], // Lists
                              [{ indent: "-1" }, { indent: "+1" }], // Indentation
                              ["blockquote", "code-block"], // Blockquote & code block
                              ["link", "image", "video"], // Media links
                              ["clean"], // Remove formatting
                            ],
                          }}
                          formats={[
                            "header",
                            "font",
                            "size",
                            "color",
                            "background",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "script",
                            "align",
                            "list",
                            "bullet",
                            "indent",
                            "blockquote",
                            "code-block",
                            "link",
                            "image",
                            "video",
                          ]}
                        />
                        <i className="text-muted">
                          This Terms and Condition will be displayed on the pro
                          forma invoice
                        </i>
                      </div>
                    </Col>
                  </Row>

                  <div className="text-right invoice-bottombtns">
                    <button
                      type="button"
                      className="btn-1 btn mt-0"
                      onClick={handleCancleClick}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-1 btn mt-0"
                      onClick={insertDataIntoDatabaseAsDraft}
                    >
                      {isLoading2 ? "Saving..." : "Save as Draft"}
                    </button>
                    <button
                      type="button mt-1"
                      className="btn-3 btn btn-primary"
                      onClick={insertDataIntoDatabase}
                    >
                      {isLoading ? "Saving..." : "Create Proforma"}
                    </button>
                  </div>
                  <div className="clearfix"></div>
                </Form>
                {/* </Card.Body> */}
              </Card>
            </Col>
          </Row>
        </div>
      )}
      {showMailPage && (
        <>
          <div className="d-flex align-items-center ml-1 mt-4">
            <div
              className="cursor-pointer d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                height: "35px",
                width: "38px",
                cursor: "pointer",
              }}
            >
              <ArrowBackIosIcon
                width={2}
                onClick={handleInvoicesClick}
                className="arrow-child"
              />
            </div>
            <div className="headitems ml-2">
              <h5
                className="ml-1 mb-0"
                style={{ letterSpacing: "1px", color: "#086bd5de" }}
              >
                Mail
              </h5>
            </div>
          </div>
          <div
            className="profit-loss-section my-3 mx-0"
            style={{
              padding: "30px",
              backgroundColor: "#fff",
              borderRadius: "9px",
            }}
          >
            <br />
            <div className="radio row mg-t-15 pt-1 align-items-center mg-b-20">
              <div className="col-md-1">
                <label className="form-label">From</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="from"
                  id=""
                  name="from"
                  type="text"
                  value={userEmail}
                  onChange={handleUserEmail}
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-10  align-items-left mg-b-20">
              <div className="col-md-1">
                <label className="form-label">Send To</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="Send To"
                  id=""
                  name="to"
                  type="text"
                  value={custEmail}
                  onChange={handleCustomerEmail}
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-15 align-items-center mg-b-20">
              <div className="col-md-1">
                <label className="form-label">CC</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="CC"
                  id=""
                  name=""
                  type="text"
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-15  align-items-center">
              <div className="col-md-1">
                <label className="form-label">Subject</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="Subject"
                  id=""
                  value={subject}
                  name="subject"
                  type="text"
                  onChange={handleSubjectChange}
                />
              </div>
            </div>
            <br />
            <div className="radio col-md-12 row mg-t-15 align-items-center">
              <div className="col-md-1 pl-0"></div>
              <div className="col-md-10 pl-0">
                <div className="body">
                  <TinyMceEditor
                    onChange={setEditorContent}
                    initialContent={initialContent}
                  />

                  <div className="mx-10 mt-4">
                    <button
                      type="button"
                      className="btn-1 btn mt-0"
                      onClick={handleInvoicesClick}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn-3 btn btn-primary"
                      onClick={handleMailSubmit}
                    >
                      {isLoading3 ? "Sending..." : "Send"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ProformaNew;
