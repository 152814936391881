import React from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import swal from "sweetalert";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import { Description, InsertLink, Key, Lock, Password, Person, Visibility } from "@mui/icons-material";

const AddPassword = ({ navigation }) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    uniqueId: decryptedUniqueId,
    link: "",
    password: "",
    userId: "",
    description: "",
    makeVisible: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRadioChange = (e) => {
    setFormData({
      ...formData,
      makeVisible: e.target.value,
    });
  };

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleCancel = () => {
    handleNavigation("PasswordManager");
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleCancel();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const handleSavePassword = (e) => {
    e.preventDefault();
    const requestBody = {
      uniqueId: formData.uniqueId,
      link: formData.link,
      description: formData.description,
      makeVisible: formData.makeVisible,
      password: formData.password,
      userId: formData.userId,
    };

    setLoading(true);

    fetch(`${BASE_URL}/createPassManager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.message === "Password created successfully") {
          swal({
            icon: "success",
            title: "Password Saved Successfully",
          });
          handleCancel();
        } else {
          swal({
            icon: "error",
            title: "Failed to Save Password",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occurred While Saving Password",
        });
      });
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Row>
        <Col>
          <div className="my-3">
            <div className="d-flex align-items-center ml-1 mt-4">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                }}
              >
                <ArrowBackIosIcon
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5 className="ml-1 mb-0" style={{ color: "#086bd5de" }}>
                  Password Manager
                </h5>
              </div>
            </div>
          </div>

          <Card className="card-style p-4">
            <Row>
              <Col md="3">
                <Form.Group>
                  <label className="text-primary" style={{ fontWeight: "600" }}>
                    Password Details
                  </label>
                </Form.Group>
              </Col>
            </Row>

            <Form>
              <Row>
                {/* From Name */}
                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Link </label>
                    <div style={{ display: "flex", alignItems: "center",gap:"10px" }}>
                    <InsertLink sx={{fill:"#9A9A9A"}}/>
                    <Form.Control
                      placeholder="Enter Link"
                      name="link"
                      value={formData.link}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>User Name/Id</label>
                    <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
                 <Person sx={{fill:"#9A9A9A"}}/>
                    <Form.Control
                      placeholder="Enter User Name/Id"
                      name="userId"
                      value={formData.userId}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                    </div>
                  </Form.Group>
                </Col>

                
              </Row>

              <Row>
                {/* Material Description */}
                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Description</label>
                    <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
                      <Description sx={{fill:"#9A9A9A"}}/>
                    <Form.Control
                      name="description"
                      placeholder="Enter Description"
                      value={formData.description}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Password</label>
                    <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
                    <Lock sx={{fill:"#9A9A9A"}}/>

                    <Form.Control
                      placeholder="Enter Password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                    </div>
                  </Form.Group>
                </Col>
                 <Row>
                <Col md="6">
                  <Form.Group>
                    <label style={{marginLeft:"16px", color: "grey" }}>Visibility{" "}<span><Visibility sx={{fill:"#9A9A9A"}}/></span></label>
                    <div style={{display:"flex",alignItems:"center",gap:"40px"}} className="py-3 px-3">
                      <Form.Check
                        type="radio"
                        label="Admin"
                        className="mx-3 cursor-pointer"
                        name="makeVisible"
                        value="Admin"
                        checked={formData.makeVisible === "Admin"}
                        onChange={handleRadioChange}
                        inline
                      />
                      <Form.Check
                        type="radio"
                        label="General"
                        className="mx-3 cursor-pointer"
                        name="makeVisible"
                        value="General"
                        checked={formData.makeVisible === "General"}
                        onChange={handleRadioChange}
                        inline
                      />
                    </div>
                  </Form.Group>
                </Col>
                </Row>
              </Row>
            </Form>

            <hr className="mt-4" />

            <div className="text-right invoice-bottombtns">
              <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
                Cancel
              </button>
              {/* <button className="btn-1 btn mt-0">Save as Draft</button> */}
              <button
                className="btn-3 btn btn-primary"
                onClick={handleSavePassword}
              >
                Save Password
              </button>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddPassword;
