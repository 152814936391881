import React, { useState, useEffect } from "react";
import { AES, enc } from "crypto-js";
import swal from "sweetalert";
import { ArrowCircleDown } from "phosphor-react";
import { BASE_URL } from "../CommonApi";
import "./VendorNew2Style.css";
import { Card, Form, Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";

function VendorsUpdate(props) {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const jwtToken = localStorage.getItem("jwtToken");

  const [billingAttention, setBillingAttention] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const [billingAddress1, setBillingAddress1] = useState("");
  const [billingAddress2, setBillingAddress2] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingZipcode, setBillingZipcode] = useState("");
  const [billingPhone, setBillingPhone] = useState("");
  const [billingFax, setBillingFax] = useState("");

  const [shipAttention, setShippingAttention] = useState("");
  const [shipCountry, setShippingCountry] = useState("");
  const [shipAddress1, setShippingAddress1] = useState("");
  const [shipAddress2, setShippingAddress2] = useState("");
  const [shipCity, setShippingCity] = useState("");
  const [shipState, setShippingState] = useState("");
  const [shipZipcode, setShippingZipcode] = useState("");
  const [shipPhone, setShippingPhone] = useState("");
  const [shipFax, setShippingFax] = useState("");

  const copyData = () => {
    setShippingAttention(billingAttention);
    setShippingCountry(billingCountry);
    setShippingAddress1(billingAddress1);
    setShippingAddress2(billingAddress2);
    setShippingCity(billingCity);
    setShippingState(billingState);
    setShippingZipcode(billingZipcode);
    setShippingPhone(billingPhone);
    setShippingFax(billingFax);
    setItemData((prevFormData) => ({
      ...prevFormData,
      shipAttention: itemData.billingAttention,
      shipCountry: itemData.billingCountry,
      shipAddress1: itemData.billingAddress1,
      shipAddress2: itemData.billingAddress2,
      shipCity: itemData.billingCity,
      shipState: itemData.billingState,
      shipZipcode: itemData.billingZipcode,
      shipPhone: itemData.billingPhone,
      shipFax: itemData.billingFax,
    }));
  };

  const handleAdd = () => {
    const newContactPerson = {
      other: "Mr.",
      firstName: "",
      lastName: "",
      email: "",
      workphone: "",
      mobile: "",
    };

    setItemData((prevData) => ({
      ...prevData,
      contactPerson: [...prevData.contactPerson, newContactPerson],
    }));
  };

  const handleDelete = (i) => {
    const updatedContactPerson = [...itemData.contactPerson];
    updatedContactPerson.splice(i, 1);
    setItemData((prevData) => ({
      ...prevData,
      contactPerson: updatedContactPerson,
    }));
  };

  const [errors, setErrors] = useState({});

  const validation = () => {
    let errors = {};
    const namePattern = /^[A-Za-z\s]+$/; // Allows spaces
    const passPattern = /^[A-Za-z0-9_@./#&+-]*$/;
    const contactPattern = /^[6-9]{1}[0-9]{9}$/;
    const emailPattern = /^[a-z0-9._%+-]+@[a-z]+\.[a-z]{2,4}$/;

    if (!itemData.vendorDisplayName) {
      errors.vendorDisplayName = "Enter a valid Vendor Name";
    } else if (!namePattern.test(itemData.vendorDisplayName)) {
      errors.vendorDisplayName = "Enter a valid Vendor Name";
    }
    return errors;
  };

  const [validationMessage, setValidationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSaveClick = () => {
    const { id } = props;

    const requestData = {
      ...itemData,
      id: id,
    };

    let newValidationMessage = "";

    if (!requestData.vendorDisplayName) {
      newValidationMessage += "Please enter a vendor display name.\n";
    }
    if (
      requestData.vendorEmail &&
      !/^[a-z0-9._%+-]+@[a-z]+\.[a-z]{2,4}(\.[a-z]{2,4})?$/.test(
        requestData.vendorEmail
      )
    ) {
      newValidationMessage +=
        "Invalid Email Address. Please enter a valid email.\n";
    }
    if (
      requestData.vendorPhone1 &&
      !/^\d{10}$/.test(requestData.vendorPhone1)
    ) {
      newValidationMessage +=
        "Invalid Phone Number. Please enter a 10-digit number.\n";
    }
    if (
      requestData.vendorPhone2 &&
      !/^\d{10}$/.test(requestData.vendorPhone2)
    ) {
      newValidationMessage +=
        "Invalid Mobile Number. Please enter a 10-digit number.\n";
    }
    if (
      requestData.billingPhone &&
      !/^\d{10}$/.test(requestData.billingPhone)
    ) {
      newValidationMessage +=
        "Invalid Billing Phone Number. Please enter a 10-digit number.\n";
    }
    if (
      requestData.billingZipcode &&
      !/^\d{5,6}$/.test(requestData.billingZipcode)
    ) {
      newValidationMessage +=
        "Invalid Zipcode. Please enter a valid 5 or 6-digit zipcode.\n";
    }

    requestData.contactPerson.forEach((person, index) => {
      if (person.mobile && !/^\d{10}$/.test(person.mobile)) {
        newValidationMessage += `Invalid Contact Person Mobile Number for contact ${
          index + 1
        }. Please enter a 10-digit number.\n`;
      }
      if (person.workphone && !/^\d{10}$/.test(person.workphone)) {
        newValidationMessage += `Invalid Contact Person Work Phone Number for contact ${
          index + 1
        }. Please enter a 10-digit number.\n`;
      }
      if (
        person.email &&
        !/^[a-z0-9._%+-]+@[a-z]+\.[a-z]{2,4}(\.[a-z]{2,4})?$/.test(person.email)
      ) {
        newValidationMessage +=
          "Invalid Contact Person Email Address. Please enter a valid email.\n";
      }
    });

    setValidationMessage(newValidationMessage);

    if (newValidationMessage) {
      window.scrollTo(0, 0);
      return; // Exit function if validation fails
    }
    setIsLoading(true);

    fetch(BASE_URL + "/updateVendor", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (response.ok) {
          swal({
            icon: "success",
            title: "Vendor Updated",
            text: "",
          });
          props.handleCancle();
          setIsLoading(false);
        } else {
          swal({
            icon: "error",
            title: "Failed to update Vendor!",
            text: "",
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        setIsLoading(false);
      });
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      props.handleCancle();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  const [new1, showNew1] = useState(true);
  const [new2, showNew2] = useState(false);
  const [new3, showNew3] = useState(false);

  const handleOtherChange = (event, index) => {
    const { name, value } = event.target;
    const updatedFormData = { ...itemData };
    updatedFormData.contactPerson[index][name] = value;
    setItemData(updatedFormData);
  };
  const [selectedOption, setSelectedOption] = useState(""); // State to store the selected option

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItemData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleGSTtreatment = (event) => {
    setSelectedOption(event.value);
    setItemData({
      ...itemData,
      gstTreatment: event.value,
    });
  };

  const [id1, setId1] = useState("");

  const { id } = props;

  const [itemData, setItemData] = useState({
    uniqueId: decryptedUniqueId,
    primaryContact: "",
    companyName: "",
    vendorDisplayName: "",
    vendorEmail: "",
    vendorPhone1: "",
    vendorPhone2: "",
    designation: "",
    department: "",
    website: "",
    gstTreatment: "",
    sourceOfSupply: "",
    currency: "",
    openingBalance: "",
    paymentTerms: "",
    facebook: "",
    twitter: "",
    billingAttention: "",
    billingCountry: "",
    billingAddress1: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingZipcode: "",
    billingPhone: "",
    billingFax: "",
    shipAttention: "",
    shipCountry: "",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "",
    shipZipcode: "",
    shipPhone: "",
    shipFax: "",
    gstinUIN: "",
    pan: "",
    taxable: "",
    reason: "",
    activeStatus: "Active",
    sourceOfSupply: "[MH] - Maharashtra",
    gstTreatment: "",
    contactPerson: [
      {
        other: "",
        firstName: "",
        lastName: "",
        otherDepartment: "",
        email: "",
        workphone: "",
        mobile: "",
      },
    ],
  });

  const apiUrl = BASE_URL + `/getAllVendorName/${id}`;

  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())

      .then((data) => {
        const portfolioItem = data.responseMessage[0];
        const fetchid = portfolioItem.id;
        setId1(fetchid);
        setSelectedOption(portfolioItem.gstTreatment);
        setItemData({
          primaryContact: portfolioItem.primaryContact,
          companyName: portfolioItem.companyName,
          firstName: portfolioItem.firstName,
          lastName: portfolioItem.lastName,
          vendorDisplayName: portfolioItem.vendorDisplayName,
          vendorEmail: portfolioItem.vendorEmail,
          vendorPhone1: portfolioItem.vendorPhone1,
          vendorPhone2: portfolioItem.vendorPhone2,
          designation: portfolioItem.designation,
          department: portfolioItem.department,
          website: portfolioItem.website,
          gstTreatment: portfolioItem.gstTreatment,
          sourceOfSupply: portfolioItem.sourceOfSupply,
          currency: portfolioItem.currency,
          openingBalance: portfolioItem.openingBalance,
          paymentTerms: portfolioItem.paymentTerms,
          facebook: portfolioItem.facebook,
          twitter: portfolioItem.twitter,
          billingAttention: portfolioItem.billingAttention,
          billingCountry: portfolioItem.billingCountry,
          billingAddress1: portfolioItem.billingAddress1,
          billingAddress2: portfolioItem.billingAddress2,
          billingCity: portfolioItem.billingCity,
          billingState: portfolioItem.billingState,
          billingZipcode: portfolioItem.billingZipcode,
          billingPhone: portfolioItem.billingPhone,
          billingFax: portfolioItem.billingFax,
          shipAttention: portfolioItem.shipAttention,
          shipCountry: portfolioItem.shipCountry,
          shipAddress1: portfolioItem.shipAddress1,
          shipAddress2: portfolioItem.shipAddress2,
          shipCity: portfolioItem.shipCity,
          shipState: portfolioItem.shipState,
          shipPhone: portfolioItem.shipPhone,
          shipZipcode: portfolioItem.shipZipcode,
          shipFax: portfolioItem.shipFax,
          uniqueId: portfolioItem.uniqueId,
          gstinUIN: portfolioItem.gstinUIN,
          pan: portfolioItem.pan,
          taxable: portfolioItem.taxable,
          reason: portfolioItem.response,
          status: portfolioItem.status,
          sourceOfSupply: portfolioItem.sourceOfSupply,
          gstTreatment: portfolioItem.gstTreatment,
        });

        fetchContactPerson();
      });
  };

  const fetchContactPerson = () => {
    const postUrl = BASE_URL + `/getVendorAllContactPerson/${id}`;

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const Data = data.responseMessage;
        Data.map((contact) => ({
          other: contact.other,
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email,
          workphone: contact.workphone,
          mobile: contact.mobile,
        }));

        const extractedData = Data.map((item) => ({
          other: item.other,
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          workphone: item.workphone,
          mobile: item.mobile,
        }));
        setItemData((prevData) => ({
          ...prevData,
          contactPerson: extractedData,
        }));
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItemData({
      ...itemData,
      [name]: value,
    });
  };

  const [newData, setnewData] = useState({
    gst: "",
    pan: "",
  });

  const handlePanChange = (event) => {
    setnewData({
      ...newData,
      pan: event.target.value,
    });
  };

  const handleGstChange = (event) => {
    setnewData({
      ...newData,
      gst: event.target.value,
    });
  };

  const handleTaxable = (e, value) => {
    setItemData({
      ...itemData,
      taxable: value,
    });
  };

  const handleSalutationChange = (event) => {
    setItemData({
      ...itemData,
      primaryContact: event.value,
    });
  };

  const unitSalution = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
    { value: "Miss.", label: "Miss." },
    { value: "Dr.", label: "Dr." },
  ];

  const generateNamePairs = () => {
    const pairs = [];
    const { primaryContact, firstName, lastName, companyName } = itemData;
    if (firstName && lastName) {
      pairs.push(`${primaryContact} ${firstName} ${lastName}`);
      pairs.push(`${firstName} ${lastName}`);
      pairs.push(`${lastName} , ${firstName}`);
      pairs.push(`${companyName}`);
    }

    return pairs;
  };

  const GstTreatment = [
    { value: "", label: "Select a GST Treatment", isDisabled: true },
    {
      value: "Registered Business - Regular",
      label: "Registered Business - Regular",
    },
    {
      value: "Registered Business - Composition",
      label: "Registered Business - Composition",
    },
    { value: "Unregistered Business", label: "Unregistered Business" },
    { value: "Consumer", label: "Consumer" },
    { value: "Overseas", label: "Overseas" },
    { value: "Special Economic Zone", label: "Special Economic Zone" },
    { value: "Deemed Export", label: "Deemed Export" },
    { value: "Tax Deductor", label: "Tax Deductor" },
    { value: "SEZ Developer", label: "SEZ Developer" },
  ];

  const PlaceOfSupplyOptions = [
    { value: "", label: "Source Of Supply", isDisabled: true },
    {
      value: "[AN] - Andaman and Nicobar Islands",
      label: "[AN] - Andaman and Nicobar Islands",
    },
    { value: "[AD] - Andhra Pradesh", label: "[AD] - Andhra Pradesh" },
    { value: "[AR] - Arunachal Pradesh", label: "[AR] - Arunachal Pradesh" },
    { value: "[AS] - Assam", label: "[AS] - Assam" },
    { value: "[BR] - Bihar", label: "[BR] - Bihar" },
    { value: "[CH] - Chandigarh", label: "[CH] - Chandigarh" },
    { value: "[CG] - Chhattisgarh", label: "[CG] - Chhattisgarh" },
    {
      value: "[DN] - Dadra and Nagar Haveli and Daman and Diu",
      label: "[DN] - Dadra and Nagar Haveli and Daman and Diu",
    },
    { value: "[DD] - Daman and Diu", label: "[DD] - Daman and Diu" },
    { value: "[DL] - Delhi", label: "[DL] - Delhi" },
    { value: "[GA] - Goa", label: "[GA] - Goa" },
    { value: "[GJ] - Gujarat", label: "[GJ] - Gujarat" },
    { value: "[HR] - Haryana", label: "[HR] - Haryana" },
    { value: "[HP] - Himachal Pradesh", label: "[HP] - Himachal Pradesh" },
    { value: "[JK] - Jammu and Kashmir", label: "[JK] - Jammu and Kashmir" },
    { value: "[JH] - Jharkhand", label: "[JH] - Jharkhand" },
    { value: "[KA] - Karnataka", label: "[KA] - Karnataka" },
    { value: "[KL] - Kerala", label: "[KL] - Kerala" },
    { value: "[LA] - Ladakh", label: "[LA] - Ladakh" },
    { value: "[LD] - Lakshadweep", label: "[LD] - Lakshadweep" },
    { value: "[MP] - Madhya Pradesh", label: "[MP] - Madhya Pradesh" },
    { value: "[MH] - Maharashtra", label: "[MH] - Maharashtra" },
    { value: "[MN] - Manipur", label: "[MN] - Manipur" },
    { value: "[ML] - Meghalaya", label: "[ML] - Meghalaya" },
    { value: "[MZ] - Mizoram", label: "[MZ] - Mizoram" },
    { value: "[NL] - Nagaland", label: "[NL] - Nagaland" },
    { value: "[OD] - Odisha", label: "[OD] - Odisha" },
    { value: "[OT] - Other Territory", label: "[OT] - Other Territory" },
    { value: "[PY] - Puducherry", label: "[PY] - Puducherry" },
    { value: "[PB] - Punjab", label: "[PB] - Punjab" },
    { value: "[RJ] - Rajasthan", label: "[RJ] - Rajasthan" },
    { value: "[SK] - Sikkim", label: "[SK] - Sikkim" },
    { value: "[TN] - Tamil Nadu", label: "[TN] - Tamil Nadu" },
    { value: "[TS] - Telangana", label: "[TS] - Telangana" },
    { value: "[TR] - Tripura", label: "[TR] - Tripura" },
    { value: "[UP] - Uttar Pradesh", label: "[UP] - Uttar Pradesh" },
    { value: "[UK] - Uttarakhand", label: "[UK] - Uttarakhand" },
    { value: "[WB] - West Bengal", label: "[WB] - West Bengal" },
  ];

  const CurrencyOptions = [
    { value: "", label: "Select Currency ...", isDisabled: true },
    { value: "AED- UAE Dirham", label: "AED- UAE Dirham" },
    { value: "AUD- Australian Dollar", label: "AUD- Australian Dollar" },
    { value: "CAD- Canadian Dollar", label: "CAD- Canadian Dollar" },
    { value: "CNY- Yuan Renminbi", label: "CNY- Yuan Renminbi" },
    { value: "EUR- Euro", label: "EUR- Euro" },
    { value: "GBP- Pound Sterling", label: "GBP- Pound Sterling" },
    { value: "INR- Indian Rupee", label: "INR- Indian Rupee" },
    { value: "JPY- Japanese Yen", label: "JPY- Japanese Yen" },
    { value: "SAR- Saudi Riyal", label: "SAR- Saudi Riyal" },
    { value: "USD- United States Dollar", label: "USD- United States Dollar" },
    { value: "ZAR- South African Rand", label: "ZAR- South African Rand" },
  ];

  const PaymentTermsOptions = [
    { value: "", label: "Select Payment Terms", isDisabled: true },
    { value: "Net 15", label: "Net 15" },
    { value: "Net 30", label: "Net 30" },
    { value: "Net 45", label: "Net 45" },
    { value: "Net 60", label: "Net 60" },
    { value: "Due end of the month", label: "Due end of the month" },
    { value: "Due end of next month", label: "Due end of next month" },
    { value: "Due on Receipt", label: "Due on Receipt" },
  ];

  const countryOptions = [
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "American Samoa", label: "American Samoa" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Antarctica", label: "Antarctica" },
    { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Aruba", label: "Aruba" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belarus", label: "Belarus" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Bermuda", label: "Bermuda" },
    { value: "Bhutan", label: "Bhutan" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { value: "Botswana", label: "Botswana" },
    { value: "Bouvet Island", label: "Bouvet Island" },
    { value: "Brazil", label: "Brazil" },
    {
      value: "British Indian Ocean Territory",
      label: "British Indian Ocean Territory",
    },
    { value: "Brunei Darussalam", label: "Brunei Darussalam" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Cape Verde", label: "Cape Verde" },
    { value: "Cayman Islands", label: "Cayman Islands" },
    { value: "Central African Republic", label: "Central African Republic" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Christmas Island", label: "Christmas Island" },
    { value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo", label: "Congo" },
    {
      value: "Congo, the Democratic Republic of the",
      label: "Congo, the Democratic Republic of the",
    },
    { value: "Cook Islands", label: "Cook Islands" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
    { value: "Croatia", label: "Croatia" },
    { value: "Cuba", label: "Cuba" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czech Republic", label: "Czech Republic" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Ethiopia", label: "Ethiopia" },
    {
      value: "Falkland Islands (Malvinas)",
      label: "Falkland Islands (Malvinas)",
    },
    { value: "Faroe Islands", label: "Faroe Islands" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "French Guiana", label: "French Guiana" },
    { value: "French Polynesia", label: "French Polynesia" },
    {
      value: "French Southern Territories",
      label: "French Southern Territories",
    },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Ghana", label: "Ghana" },
    { value: "Gibraltar", label: "Gibraltar" },
    { value: "Greece", label: "Greece" },
    { value: "Greenland", label: "Greenland" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guadeloupe", label: "Guadeloupe" },
    { value: "Guam", label: "Guam" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guernsey", label: "Guernsey" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-Bissau", label: "Guinea-Bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    {
      value: "Heard Island and McDonald Islands",
      label: "Heard Island and McDonald Islands",
    },
    {
      value: "Holy See (Vatican City State)",
      label: "Holy See (Vatican City State)",
    },
    { value: "Honduras", label: "Honduras" },
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran, Islamic Republic of", label: "Iran, Islamic Republic of" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland", label: "Ireland" },
    { value: "Isle of Man", label: "Isle of Man" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jersey", label: "Jersey" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    {
      value: "Korea, Democratic People's Republic of",
      label: "Korea, Democratic People's Republic of",
    },
    { value: "Korea, Republic of", label: "Korea, Republic of" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    {
      value: "Lao People's Democratic Republic",
      label: "Lao People's Democratic Republic",
    },
    { value: "Latvia", label: "Latvia" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Liberia", label: "Liberia" },
    { value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Macao", label: "Macao" },
    {
      value: "Macedonia, the former Yugoslav Republic of",
      label: "Macedonia, the former Yugoslav Republic of",
    },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malawi", label: "Malawi" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Mali", label: "Mali" },
    { value: "Malta", label: "Malta" },
    { value: "Marshall Islands", label: "Marshall Islands" },
    { value: "Martinique", label: "Martinique" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mayotte", label: "Mayotte" },
    { value: "Mexico", label: "Mexico" },
    {
      value: "Micronesia, Federated States of",
      label: "Micronesia, Federated States of",
    },
    { value: "Moldova, Republic of", label: "Moldova, Republic of" },
    { value: "Monaco", label: "Monaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montenegro", label: "Montenegro" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Myanmar", label: "Myanmar" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "Netherlands Antilles", label: "Netherlands Antilles" },
    { value: "New Caledonia", label: "New Caledonia" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Niger", label: "Niger" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Niue", label: "Niue" },
    { value: "Norfolk Island", label: "Norfolk Island" },
    { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    {
      value: "Palestinian Territory, Occupied",
      label: "Palestinian Territory, Occupied",
    },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Pitcairn", label: "Pitcairn" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Puerto Rico", label: "Puerto Rico" },
    { value: "Qatar", label: "Qatar" },
    { value: "Réunion", label: "Réunion" },
    { value: "Romania", label: "Romania" },
    { value: "Russian Federation", label: "Russian Federation" },
    { value: "Rwanda", label: "Rwanda" },
    { value: "Saint Barthélemy", label: "Saint Barthélemy" },
    {
      value: "Saint Helena, Ascension and Tristan da Cunha",
      label: "Saint Helena, Ascension and Tristan da Cunha",
    },
    { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    {
      value: "Saint Martin (French part)",
      label: "Saint Martin (French part)",
    },
    { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
    {
      value: "Saint Vincent and the Grenadines",
      label: "Saint Vincent and the Grenadines",
    },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia", label: "Serbia" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "Somalia", label: "Somalia" },
    { value: "South Africa", label: "South Africa" },
    {
      value: "South Georgia and the South Sandwich Islands",
      label: "South Georgia and the South Sandwich Islands",
    },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
    { value: "Swaziland", label: "Swaziland" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
    { value: "Taiwan, Province of China", label: "Taiwan, Province of China" },
    { value: "Tajikistan", label: "Tajikistan" },
    {
      value: "Tanzania, United Republic of",
      label: "Tanzania, United Republic of",
    },
    { value: "Thailand", label: "Thailand" },
    { value: "Timor-Leste", label: "Timor-Leste" },
    { value: "Togo", label: "Togo" },
    { value: "Tokelau", label: "Tokelau" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
    {
      value: "United States Minor Outlying Islands",
      label: "United States Minor Outlying Islands",
    },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistan", label: "Uzbekistan" },
    { value: "Vanuatu", label: "Vanuatu" },
    {
      value: "Venezuela, Bolivarian Republic of",
      label: "Venezuela, Bolivarian Republic of",
    },
    { value: "Viet Nam", label: "Viet Nam" },
    { value: "Virgin Islands, British", label: "Virgin Islands, British" },
    { value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
    { value: "Wallis and Futuna", label: "Wallis and Futuna" },
    { value: "Western Sahara", label: "Western Sahara" },
    { value: "Yemen", label: "Yemen" },
    { value: "Zambia", label: "Zambia" },
    { value: "Zimbabwe", label: "Zimbabwe" },
  ];

  const handleCountryChange = (selectedOption) => {
    setBillingCountry(selectedOption.value);
    setItemData({
      ...itemData,
      billingCountry: selectedOption.value,
    });
  };

  const indianStateOptions = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    {
      value: "Andaman and Nicobar Islands",
      label: "Andaman and Nicobar Islands",
    },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Dadra and Nagar Haveli", label: "Dadra and Nagar Haveli" },
    { value: "Daman and Diu", label: "Daman and Diu" },
    { value: "Delhi", label: "Delhi" },
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
  ];
  const handleStateChange = (selectedOption) => {
    setBillingState(selectedOption.value);
    setItemData({
      ...itemData,
      billingState: selectedOption.value,
    });
  };

  const handlePaymentTerms = (selectedOption) => {
    setSelectedOption(selectedOption); // Update selected option
    setItemData({
      ...itemData,
      paymentTerms: selectedOption.value, // Update gstTreatment in formData
    });
  };

  const handlePlaceOfSupply = (selectedOption) => {
    setSelectedOption(selectedOption.value); // Update selected option
    setItemData({
      ...itemData,
      sourceOfSupply: selectedOption.value, // Update gstTreatment in formData
    });
  };

  const handleCurrency = (selectedOption) => {
    setSelectedOption(selectedOption); // Update selected option
    setItemData({
      ...itemData,
      currency: selectedOption.value, // Update gstTreatment in formData
    });
  };

  const handleShipStateChange = (selectedOption) => {
    setShippingCountry(selectedOption.value);
    setItemData({
      ...itemData,
      shipState: selectedOption.value,
    });
  };

  return (
    <Container fluid className="">
      <Row>
        <Col>
          <div className="my-3">
            <div className="d-flex align-items-center ml-1 mt-4">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancleClick}
                  className="arrow-child"
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5
                  className="ml-1 mb-0"
                  style={{ letterSpacing: "1px", color: "#086bd5de" }}
                >
                  Update Vendor
                </h5>
              </div>
            </div>
          </div>

          <Card className="card-style">
            {validationMessage && (
              <Alert severity="error" className="mt-3">
                {validationMessage.split("\n").map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </Alert>
            )}{" "}
            <Card.Body>
              <Row>
                <Col md="3" className="">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Salutation
                    </label>
                    <Select
                      className="mt-0"
                      name="primaryContact"
                      options={unitSalution}
                      value={unitSalution.find(
                        (option) => option.value === itemData.primaryContact
                      )}
                      onChange={handleSalutationChange}
                      placeholder="Select"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",
                          // border:'none',
                          borderRadius: "8px",
                          padding: "4px",
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md="3" className="">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      First Name
                    </label>
                    <Form.Control
                      placeholder="Add First Name"
                      name="firstName"
                      id="firstName"
                      value={itemData.firstName}
                      onChange={handleInputChange}
                      type="text"
                      style={{
                        backgroundColor: "#F6F6F6",
                        padding: "10px",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md="3" className="">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Last Name
                    </label>
                    <Form.Control
                      className="form-control"
                      placeholder="Add Last name"
                      id="lastName"
                      name="lastName"
                      value={itemData.lastName}
                      onChange={handleInputChange}
                      type="text"
                      style={{
                        backgroundColor: "#F6F6F6",
                        padding: "10px",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Vendor Display Name{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Form.Control
                      className="form-control"
                      placeholder="Vendor Display Name"
                      list="vendorDisplayName"
                      name="vendorDisplayName"
                      value={itemData.vendorDisplayName}
                      onChange={handleInputChange}
                      type="text"
                      style={{
                        backgroundColor: "#F6F6F6",
                        padding: "10px",
                        borderRadius: "6px",
                      }}
                    />
                    <datalist id="vendorDisplayName">
                      {generateNamePairs().map((pair, index) => (
                        <option key={index} value={pair} />
                      ))}
                    </datalist>{" "}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Company Name
                    </label>
                    <Form.Control
                      placeholder="Company Name"
                      name="companyName"
                      type="text"
                      value={itemData.companyName}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "#F6F6F6",
                        padding: "10px",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Vendor Email
                    </label>
                    <Form.Control
                      placeholder="Vendor  Email"
                      name="vendorEmail"
                      type="text"
                      value={itemData.vendorEmail}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "#F6F6F6",
                        padding: "10px",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Designation
                    </label>
                    <Form.Control
                      placeholder="Designation"
                      name="designation"
                      type="text"
                      value={itemData.designation}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "#F6F6F6",
                        padding: "10px",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Department
                    </label>
                    <Form.Control
                      placeholder="Department"
                      name="department"
                      type="text"
                      value={itemData.department}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "#F6F6F6",
                        padding: "10px",
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="" md="3">
                  <label style={{ fontSize: "14px", color: "grey" }}>
                    Vendor Phone
                  </label>
                  <Form.Control
                    className=""
                    placeholder="Work Phone"
                    name="vendorPhone1"
                    type="text"
                    value={itemData.vendorPhone1}
                    onChange={handleChange}
                    style={{
                      backgroundColor: "#F6F6F6",
                      padding: "10px",
                    }}
                  />
                  {errors.vendorPhone1 && (
                    <div className="text-danger mb-4">
                      {errors.vendorPhone1}
                    </div>
                  )}{" "}
                </Col>

                <Col className="" md="3">
                  <label style={{ fontSize: "14px", color: "grey" }}>
                    Mobile
                  </label>
                  <Form.Control
                    className=""
                    placeholder="Mobile"
                    name="vendorPhone2"
                    type="text"
                    value={itemData.vendorPhone2}
                    onChange={handleChange}
                    style={{
                      backgroundColor: "#F6F6F6",
                      padding: "10px",
                    }}
                  />
                  {errors.vendorPhone2 && (
                    <div className="text-danger mb-4">
                      {errors.vendorPhone2}
                    </div>
                  )}{" "}
                </Col>

                <Col className="" md="3">
                  <label style={{ fontSize: "14px", color: "grey" }}>
                    Website
                  </label>
                  <Form.Control
                    className=""
                    placeholder="Website"
                    name="website"
                    type="text"
                    value={itemData.website}
                    onChange={handleChange}
                    style={{
                      backgroundColor: "#F6F6F6",
                      padding: "10px",
                    }}
                  />
                </Col>
              </Row>

              <div className="mt-2 my-3">
                <div className="">
                  <button
                    className={`btn  ${new1 ? "btn-link" : "withoutbtn"}`}
                    onClick={() => {
                      showNew1(true);
                      showNew2(false);
                      showNew3(false);
                    }}
                  >
                    Other Details
                  </button>
                  <button
                    className={`btn  ml-2 ${new2 ? "btn-link" : "withoutbtn"}`}
                    onClick={() => {
                      showNew2(true);
                      showNew1(false);
                      showNew3(false);
                    }}
                  >
                    Address
                  </button>
                  <button
                    className={`btn btn ml-2 ${
                      new3 ? "btn-link" : "withoutbtn"
                    }`}
                    onClick={() => {
                      showNew3(true);
                      showNew1(false);
                      showNew2(false);
                    }}
                  >
                    Contact Person
                  </button>

                  <hr />
                  {new1 && (
                    <>
                      <Row className="mt-2">
                        <Col md="3" className="">
                          <label style={{ fontSize: "14px", color: "grey" }}>
                            GST Treatment
                          </label>
                          <Select
                            className="mt-0"
                            options={GstTreatment}
                            value={GstTreatment.find(
                              (option) => option.value === itemData.gstTreatment
                            )}
                            onChange={handleGSTtreatment}
                            placeholder="Select a GST Treatment"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: "#F6F6F6",
                                // border:'none',
                                borderRadius: "8px",
                                padding: "4px",
                              }),
                            }}
                          />
                          {errors.gstTreatment && (
                            <div className="text-danger mb-4">
                              {errors.gstTreatment}
                            </div>
                          )}{" "}
                        </Col>

                        {selectedOption !== "Overseas" ? (
                          <>
                            <Col md="3">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Source Of Supply
                              </label>
                              <Select
                                className="mt-0"
                                options={PlaceOfSupplyOptions}
                                name="placeOfSupply"
                                value={PlaceOfSupplyOptions.find(
                                  (option) =>
                                    option.value === itemData.sourceOfSupply
                                )}
                                onChange={handlePlaceOfSupply}
                                placeholder="Source Of Supply"
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: "#F6F6F6",
                                    borderRadius: "8px",
                                    padding: "4px",
                                  }),
                                }}
                              />
                              {errors.placeOfSupply && (
                                <div className="text-danger mb-4">
                                  {errors.placeOfSupply}
                                </div>
                              )}{" "}
                            </Col>
                          </>
                        ) : null}

                        <Col md="3" className="">
                          <label style={{ fontSize: "14px", color: "grey" }}>
                            Currency
                          </label>
                          <Select
                            className="mt-0"
                            options={CurrencyOptions}
                            value={CurrencyOptions.find(
                              (option) => option.value === itemData.currency
                            )}
                            onChange={handleCurrency}
                            placeholder="Select Currency ..."
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: "#F6F6F6",
                                borderRadius: "8px",
                                padding: "4px",
                              }),
                            }}
                          />
                          {errors.currency && (
                            <div className="text-danger mb-4">
                              {errors.currency}
                            </div>
                          )}{" "}
                        </Col>

                        <Col md="3">
                          <label style={{ fontSize: "14px", color: "grey" }}>
                            Opening Balance
                          </label>
                          <Form.Control
                            placeholder="Opening Balance"
                            name="openingBalance"
                            type="text"
                            value={itemData.openingBalance}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "#F6F6F6",
                              padding: "10px",
                            }}
                          />
                        </Col>
                      </Row>

                      {selectedOption === "Registered Business - Regular" ||
                      selectedOption === "Registered Business - Composition" ||
                      selectedOption === "Special Economic Zone" ||
                      selectedOption === "Deemed Export" ||
                      selectedOption === "Tax Deductor" ||
                      selectedOption === "SEZ Developer" ? (
                        <Row className="mt-3">
                          <Col className="" md="3">
                            <label style={{ fontSize: "14px", color: "grey" }}>
                              GSTIN / UIN
                            </label>
                            <Form.Control
                              className=""
                              placeholder="GSTIN / UIN"
                              name="gstinUIN"
                              type="text"
                              onChange={handleGstChange}
                              value={itemData.gstinUIN} // Add the value you want to show here
                              style={{
                                backgroundColor: "#F6F6F6",
                                padding: "10px",
                              }}
                            />
                          </Col>
                        </Row>
                      ) : null}

                      {selectedOption === "Unregistered Business" ||
                      selectedOption === "Consumer" ? (
                        <Row className="mt-3">
                          <Col className="" md="3">
                            <label style={{ fontSize: "14px", color: "grey" }}>
                              PAN
                            </label>
                            <Form.Control
                              className=""
                              placeholder="PAN"
                              name="pan"
                              type="text"
                              value={itemData.pan}
                              onChange={handlePanChange}
                              style={{
                                backgroundColor: "#F6F6F6",
                                padding: "10px",
                              }}
                            />
                          </Col>
                        </Row>
                      ) : null}

                      <Row className="mt-2">
                        <Col md="3">
                          <label style={{ fontSize: "14px", color: "grey" }}>
                            Payment Terms
                          </label>
                          <Select
                            className="mt-0"
                            options={PaymentTermsOptions}
                            value={PaymentTermsOptions.find(
                              (option) => option.value === itemData.paymentTerms
                            )}
                            onChange={handlePaymentTerms}
                            name="paymentTerms"
                            placeholder="Select Payment Terms"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: "#F6F6F6",
                                // border:'none',
                                borderRadius: "8px",
                                padding: "4px",
                              }),
                            }}
                          />
                        </Col>

                        <Col className="" md="3">
                          <label style={{ fontSize: "14px", color: "grey" }}>
                            Facebook
                          </label>
                          <Form.Control
                            placeholder="Facebook Id"
                            name="facebook"
                            type="text"
                            value={itemData.facebook}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "#F6F6F6",
                              padding: "10px",
                            }}
                          />
                        </Col>

                        <Col className="" md="3">
                          <label style={{ fontSize: "14px", color: "grey" }}>
                            Twitter
                          </label>
                          <Form.Control
                            placeholder="Twitter Id"
                            name="twitter"
                            type="text"
                            value={itemData.twitter}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "#F6F6F6",
                              padding: "10px",
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                  {new2 && (
                    <>
                      <div className=" grid-container ">
                        <div className="flex-item">
                          <p className="ml-1 text-center">BILLING ADDRESS</p>
                          <Row className="mt-2">
                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Attention
                              </label>
                              <Form.Control
                                className=""
                                placeholder="Attention"
                                id="billingAttention"
                                name="billingAttention"
                                type="text"
                                value={itemData.billingAttention}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setBillingAttention(value);
                                  setItemData((prevFormData) => ({
                                    ...prevFormData,
                                    [name]: value,
                                  }));
                                }}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>

                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Country
                              </label>
                              <Select
                                className="mt-0"
                                options={countryOptions}
                                value={{
                                  value: billingCountry,
                                  label:
                                    billingCountry || itemData.billingCountry,
                                }}
                                onChange={handleCountryChange}
                                placeholder="Select"
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: "#F6F6F6",
                                    borderRadius: "8px",
                                    padding: "4px",
                                  }),
                                }}
                              />
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Address
                              </label>
                              <Form.Control
                                className=""
                                placeholder="Site1"
                                name="billingAddress1"
                                type="text"
                                value={itemData.billingAddress1}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setBillingAddress1(value); // Update billingPhone state
                                  setItemData((prevFormData) => ({
                                    ...prevFormData,
                                    [name]: value, // Update billingPhone field in formData
                                  }));
                                }}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>

                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Address
                              </label>
                              <Form.Control
                                className=""
                                placeholder="Site2"
                                name="billingAddress2"
                                type="text"
                                value={itemData.billingAddress2}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setBillingAddress2(value); // Update billingPhone state
                                  setItemData((prevFormData) => ({
                                    ...prevFormData,
                                    [name]: value, // Update billingPhone field in formData
                                  }));
                                }}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                City
                              </label>
                              <Form.Control
                                className=""
                                placeholder="City"
                                id="billingCity"
                                name="billingCity"
                                type="text"
                                value={itemData.billingCity}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setBillingCity(value); // Update billingPhone state
                                  setItemData((prevFormData) => ({
                                    ...prevFormData,
                                    [name]: value, // Update billingPhone field in formData
                                  }));
                                }}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>
                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                State
                              </label>
                              <Select
                                id="billingState"
                                name="billingState"
                                value={indianStateOptions.find(
                                  (option) =>
                                    option.value === itemData.billingState
                                )} // Find the selected option
                                onChange={handleStateChange} // Handle state change
                                options={indianStateOptions} // Pass the state options
                                placeholder="Select State"
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: "#F6F6F6",
                                    borderRadius: "8px",
                                    padding: "4px",
                                  }),
                                }}
                              />
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col className="">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Zipcode
                              </label>
                              <Form.Control
                                className=""
                                placeholder="Zipcode"
                                id="billingZipcode"
                                name="billingZipcode"
                                type="text"
                                value={itemData.billingZipcode}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setBillingZipcode(value); // Update billingPhone state
                                  setItemData((prevFormData) => ({
                                    ...prevFormData,
                                    [name]: value, // Update billingPhone field in formData
                                  }));
                                }}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>
                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Phone
                              </label>
                              <Form.Control
                                className=""
                                placeholder="Phone"
                                id="billingPhone"
                                name="billingPhone"
                                type="text"
                                value={itemData.billingPhone}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setBillingPhone(value); // Update billingPhone state
                                  setItemData((prevFormData) => ({
                                    ...prevFormData,
                                    [name]: value, // Update billingPhone field in formData
                                  }));
                                }}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                              {errors.billingPhone && (
                                <div className="text-danger mb-4">
                                  {errors.billingPhone}
                                </div>
                              )}{" "}
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Fax
                              </label>
                              <Form.Control
                                className="form-control"
                                placeholder="Fax"
                                id="billingFax"
                                name="billingFax"
                                type="text"
                                value={itemData.billingFax}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setBillingFax(value);
                                  setItemData((prevFormData) => ({
                                    ...prevFormData,
                                    [name]: value,
                                  }));
                                }}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>
                          </Row>
                        </div>

                        <div className="flex-item">
                          <p className=" ml-1 text-center">
                            SHIPPING ADDRESS{" "}
                            <span
                              style={{
                                fontSize: 12,
                                color: "blue",
                                cursor: "pointer",
                              }}
                              onClick={copyData}
                            >
                              <ArrowCircleDown size={16} /> COPY BILLING ADDRESS
                            </span>
                          </p>

                          <Row className="">
                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Attention
                              </label>
                              <Form.Control
                                className=""
                                placeholder="Attention"
                                id="shippingAttention"
                                name="shipAttention"
                                type="text"
                                value={itemData.shipAttention}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setShippingAttention(value);
                                  setItemData((prevFormData) => ({
                                    ...prevFormData,
                                    [name]: value,
                                  }));
                                }}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>

                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Country
                              </label>
                              <Select
                                className="mt-0"
                                options={countryOptions}
                                placeholder="Select"
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: "#F6F6F6",
                                    borderRadius: "8px",
                                    padding: "4px",
                                  }),
                                }}
                                name="shipCountry"
                                id="shippingCountry"
                                value={countryOptions.find(
                                  (option) =>
                                    option.value === itemData.shipCountry
                                )} // Update value prop
                                onChange={(selectedOption) => {
                                  setShippingCountry(selectedOption);
                                  setItemData({
                                    ...itemData,
                                    shipCountry: selectedOption,
                                  });
                                }}
                              />
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Address
                              </label>
                              <Form.Control
                                className=""
                                placeholder="Site1"
                                name="shipAddress1"
                                type="text"
                                value={itemData.shipAddress1}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setShippingAddress1(value);
                                  setItemData((prevFormData) => ({
                                    ...prevFormData,
                                    [name]: value,
                                  }));
                                }}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>

                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Address
                              </label>
                              <Form.Control
                                className=""
                                placeholder="Site2"
                                name="shipAddress2"
                                type="text"
                                value={itemData.shipAddress2}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setShippingAddress2(value);
                                  setItemData((prevFormData) => ({
                                    ...prevFormData,
                                    [name]: value,
                                  }));
                                }}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                City
                              </label>
                              <Form.Control
                                className=""
                                placeholder="City"
                                id="shippingCity"
                                name="shipCity"
                                type="text"
                                value={itemData.shipCity}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setShippingCity(value);
                                  setItemData((prevFormData) => ({
                                    ...prevFormData,
                                    [name]: value,
                                  }));
                                }}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>
                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                State
                              </label>
                              <Select
                                placeholder="Select State"
                                id="shippingCountry"
                                value={indianStateOptions.find(
                                  (option) =>
                                    option.value === itemData.shipState
                                )} // Find the selected option
                                onChange={handleShipStateChange} // Handle state change
                                options={indianStateOptions} // Use formData to access the selected value
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: "#F6F6F6",
                                    borderRadius: "8px",
                                    padding: "4px",
                                  }),
                                }}
                              />
                            </Col>
                          </Row>

                          {/* third line end */}

                          <Row className="mt-2">
                            <Col className="">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Zipcode
                              </label>
                              <Form.Control
                                className=""
                                placeholder="Zipcode"
                                id="shippingZipcode"
                                name="shipZipcode"
                                type="text"
                                value={itemData.shipZipcode}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setShippingZipcode(value); // Update billingPhone state
                                  setItemData((prevFormData) => ({
                                    ...prevFormData,
                                    [name]: value, // Update billingPhone field in formData
                                  }));
                                }}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>
                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Phone
                              </label>
                              <Form.Control
                                className=""
                                placeholder="Phone"
                                id="shippingPhone"
                                name="shipPhone"
                                type="text"
                                value={itemData.shipPhone}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setShippingPhone(value);
                                  setItemData((prevFormData) => ({
                                    ...prevFormData,
                                    [name]: value,
                                  }));
                                }}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                              {errors.billingPhone && (
                                <div className="text-danger mb-4">
                                  {errors.billingPhone}
                                </div>
                              )}{" "}
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col className="" md="6">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Fax
                              </label>
                              <Form.Control
                                className="form-control"
                                placeholder="Fax"
                                id="shippingFax"
                                name="shipFax"
                                type="text"
                                value={itemData.shipFax}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setShippingFax(value);
                                  setItemData((prevFormData) => ({
                                    ...prevFormData,
                                    [name]: value,
                                  }));
                                }}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>
                          </Row>

                          {/* fifth line end */}
                        </div>
                      </div>
                    </>
                  )}

                  {new3 && (
                    <div>
                      <div className="container">
                        <div className="row mg-t-10 pt-3 lg-b-20">
                          {itemData && itemData.contactPerson ? (
                            itemData.contactPerson.map((contact, index) => (
                              <table key={index}>
                                <tr>
                                  <Row>
                                    <Col className="">
                                      <Form.Group>
                                        <label
                                          style={{
                                            fontSize: "14px",
                                            color: "grey",
                                          }}
                                        >
                                          Salutation
                                        </label>
                                        <Select
                                          className="mt-0"
                                          options={unitSalution}
                                          // value={selectedTcsOption}
                                          // onChange={handleTcsOptionChange}
                                          placeholder="Select"
                                          styles={{
                                            control: (provided) => ({
                                              ...provided,
                                              backgroundColor: "#F6F6F6",
                                              // border:'none',
                                              borderRadius: "8px",
                                              padding: "4px",
                                            }),
                                          }}
                                        />
                                      </Form.Group>
                                    </Col>

                                    <Col className="">
                                      <label
                                        style={{
                                          fontSize: "14px",
                                          color: "grey",
                                        }}
                                      >
                                        First Name
                                      </label>
                                      <Form.Control
                                        className="form-control"
                                        placeholder="First Name"
                                        name="firstName"
                                        type="text"
                                        value={contact.firstName}
                                        onChange={(e) =>
                                          handleOtherChange(e, index)
                                        }
                                        style={{
                                          backgroundColor: "#F6F6F6",
                                          padding: "10px",
                                        }}
                                      />
                                    </Col>

                                    <Col className="">
                                      <label
                                        style={{
                                          fontSize: "14px",
                                          color: "grey",
                                        }}
                                      >
                                        Last Name
                                      </label>
                                      <Form.Control
                                        className="form-control "
                                        placeholder="Last Name"
                                        name="lastName"
                                        type="text"
                                        value={contact.lastName}
                                        onChange={(e) =>
                                          handleOtherChange(e, index)
                                        }
                                        style={{
                                          backgroundColor: "#F6F6F6",
                                          padding: "10px",
                                        }}
                                      />
                                    </Col>

                                    <Col className="">
                                      <label
                                        style={{
                                          fontSize: "14px",
                                          color: "grey",
                                        }}
                                      >
                                        Department
                                      </label>
                                      <Form.Control
                                        className="form-control"
                                        placeholder="Department"
                                        name="otherDepartment"
                                        type="text"
                                        value={contact.otherDepartment}
                                        onChange={(e) =>
                                          handleOtherChange(e, index)
                                        }
                                        style={{
                                          backgroundColor: "#F6F6F6",
                                          padding: "10px",
                                        }}
                                      />
                                    </Col>

                                    <Col className="">
                                      <label
                                        style={{
                                          fontSize: "14px",
                                          color: "grey",
                                        }}
                                      >
                                        Email
                                      </label>
                                      <Form.Control
                                        className="form-control"
                                        placeholder="Email Address"
                                        name="email"
                                        type="text"
                                        value={contact.email}
                                        onChange={(e) =>
                                          handleOtherChange(e, index)
                                        }
                                        style={{
                                          backgroundColor: "#F6F6F6",
                                          padding: "10px",
                                        }}
                                      />
                                    </Col>

                                    <Col className="">
                                      <label
                                        style={{
                                          fontSize: "14px",
                                          color: "grey",
                                        }}
                                      >
                                        Work Phone
                                      </label>
                                      <Form.Control
                                        className="form-control"
                                        placeholder="Phone"
                                        name="workphone"
                                        type="text"
                                        value={contact.workphone}
                                        onChange={(e) =>
                                          handleOtherChange(e, index)
                                        }
                                        style={{
                                          backgroundColor: "#F6F6F6",
                                          padding: "10px",
                                        }}
                                      />
                                    </Col>

                                    <Col className="">
                                      <label
                                        style={{
                                          fontSize: "14px",
                                          color: "grey",
                                        }}
                                      >
                                        Mobile
                                      </label>
                                      <Form.Control
                                        className="form-control"
                                        placeholder="Mobile"
                                        name="mobile"
                                        type="text"
                                        value={contact.mobile}
                                        onChange={(e) =>
                                          handleOtherChange(e, index)
                                        }
                                        style={{
                                          backgroundColor: "#F6F6F6",
                                          padding: "10px",
                                        }}
                                      />
                                    </Col>

                                    <td className="px-1">
                                      <div className="col-md-1 mt-4">
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={handleDelete}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </button>
                                      </div>
                                    </td>
                                  </Row>
                                </tr>
                              </table>
                            ))
                          ) : (
                            <p>No contact person data available.</p>
                          )}
                          <div className="mt-3 ml-2">
                            <button
                              className="from-control btn btn-primary btn-sm mr-2"
                              onClick={handleAdd}
                            >
                              ADD
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="text-right mt-5">
                    <button className="btn-1" onClick={handleCancleClick}>
                      Cancel{" "}
                    </button>
                    <button
                      className="btn-3 btn btn-primary"
                      onClick={handleSaveClick}
                    >
                      {isLoading ? "Updating..." : "Update Vendor"}
                    </button>
                  </div>
                  {/* {new3 && <VendorNew3 />} */}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default VendorsUpdate;
