import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Modal } from "react-bootstrap";
import "../../../asset/css/inbox.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import NoDataImage from "../../../asset/images/nodata.jpg";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import Alert from "@mui/material/Alert";
import TermsAndConditionDetails from "./TermsAndConditionDetails"; // Import the modal component
import TermsAndConditionUpdate from "./TermsAndConditionUpdate";

function TermsAndConditionCard({ navigation }) {
  const [employeeData, setEmployeeData] = useState([]);
  const [newItem, setnewItem] = useState([]);
  const [itemId, setItemID] = useState("");
  const [loading, setLoading] = useState(true);
  const [showCard, setShowCard] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false); // State to control the modal
  const [selectedTerms, setSelectedTerms] = useState([]);

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleItemsClick = () => {
    handleNavigation("TermsAndCondition");
  };
  const handleEdit = (id) => {
    setItemID(id);
    setShowCard(false);
    setShowUpdate(true);
  };

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this item!"
    );

    if (userConfirmed) {
      // If the user clicks "OK", proceed with the deletion
      fetch(BASE_URL + `/deleteTermsAndCondition/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(), // Ensure id is sent as a string
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            // Remove the deleted item from the local state
            setEmployeeData((prevItems) =>
              prevItems.filter((employeeDatas) => employeeDatas.id !== id)
            );

            swal({
              icon: "success",
              title: "Item Deleted Successfully",
            });
            fetchEmployeeData();
          } else if (
            data.responseMessage ===
            "Items which are a part of other transactions cannot be deleted. Instead, mark them as inactive."
          ) {
            window.confirm(
              "Items which are a part of other transactions cannot be deleted!"
            );
          } else {
            console.error("Failed to delete Item");
          }
        })
        .catch((error) => {
          console.error("Error deleting Item:", error);
        });
    } else {
      // If the user clicks "Cancel", do nothing
      alert("Item deletion canceled!");
    }
  };

  const handleCancelUpdate = () => {
    fetchEmployeeData();
    setShowUpdate(false);
    setShowCard(true);
  };

  const fetchEmployeeData = () => {
    $("#DataTables_Table_0").DataTable();
    const postApiUrl = BASE_URL + `/getTermsAndCondition`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setEmployeeData(data.responseCode);
        } else {
          console.error("Failed to fetch employees:", data.data);
        }
        setLoading(false);
        // Initialize DataTable after data is fetched
        if ($.fn.DataTable.isDataTable("#DataTables_Table_0")) {
          $("#DataTables_Table_0").DataTable().destroy();
        }
        setTimeout(() => {
          $("#DataTables_Table_0").DataTable();
        }, 0);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
        setLoading(false);
        if ($.fn.DataTable.isDataTable("#DataTables_Table_0")) {
          $("#DataTables_Table_0").DataTable().destroy();
        }
        setTimeout(() => {
          $("#DataTables_Table_0").DataTable();
        }, 0);
      });
  };

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  let i = 1;

  const handleItem = (name, id) => {
    setItemID(id);
    setnewItem(name);
    setShowDetails(true);
    setShowCard(false);
    setShowUpdate(false);
  };

  // Function to open the modal
  const handleChooseTermsClick = () => {
    setShowTermsModal(true);
  };

  // Function to close the modal
  const handleCloseTermsModal = () => {
    setShowTermsModal(false);
  };

  // Function to handle selected terms
  const handleTermsSelected = (terms) => {
    setSelectedTerms(terms);
    console.log("Selected Terms:", terms);
    // Here, you can perform any operations you need with the selected terms, such as saving them to the backend or updating some other state
    handleCloseTermsModal();
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      {!showUpdate && !showDetails && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Terms And Condition
            </div>
            <div className="button-container d-flex">
              <button
                className="btn btn-primary px-1 py-2 new-button mr-1"
                onClick={handleItemsClick}
              >
                + Terms
              </button>
              <button
                className="btn btn-primary px-1 py-2 new-button me-2 mr-1"
                onClick={handleChooseTermsClick} // Open modal when clicked
              >
                Choose Terms
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Render the modal */}
      <TermsAndConditionDetails
        show={showTermsModal}
        onHide={handleCloseTermsModal}
        onTermsSelected={handleTermsSelected}
        jwtToken={jwtToken}
      />
      {showCard && (
        <div
          className="container-fluid"
          style={{ border: "none", borderRadius: "7px" }}
        >
          <div className="page-content ">
            <Container fluid={true} className="">
              <Row>
                <Col>
                  <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    {employeeData && employeeData.length > 0 ? (
                      <>
                        <div className="body">
                          <div className="table-responsive1">
                            <div
                              id="DataTables_Table_0_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                    id="DataTables_Table_0"
                                    role="grid"
                                    aria-describedby="DataTables_Table_0_info"
                                  >
                                    <thead className="heading-text">
                                      <tr role="row">
                                        <th
                                          className="sorting_asc"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Date: activate to sort column descending"
                                        >
                                          Serial no.
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                        >
                                          Terms And Condition
                                        </th>

                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Actions
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="text1">
                                      {employeeData.map((data) => (
                                        <tr
                                          role="row"
                                          className="odd"
                                          key={data.id}
                                        >
                                          <td>{i++}</td>
                                          <td
                                            className="sorting_1"
                                            dangerouslySetInnerHTML={{
                                              __html: data.termsAndCondition,
                                            }}
                                          ></td>
                                          <td>
                                            <button
                                              className="btn icon-border btn-sm mr-2"
                                              onClick={() =>
                                                handleEdit(data.id)
                                              }
                                            >
                                              <i className="fa fa-edit text1"></i>
                                            </button>
                                            <button
                                              className="btn icon-border btn-sm"
                                              onClick={() =>
                                                handleDelete(data.id)
                                              }
                                            >
                                              <i className="fa fa-trash text1"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-con
                                        trols="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Serial no.
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Terms And Condition
                                      </th>

                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                                <h6 className="text-center">
                                  <img
                                    src={NoDataImage}
                                    style={{ width: "50%", height: "50%" }}
                                    alt="No Data Logo"
                                  />{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
      {showUpdate && (
        <TermsAndConditionUpdate id={itemId} onClick={handleCancelUpdate} />
      )}
    </div>
  );
}

export default TermsAndConditionCard;
