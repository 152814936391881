import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const UpdateAppointment = ({navigation,appointmentId,handleCancel}) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [selectedDate, setSelectedDate] = useState(null);
  const [showTimeDropdown, setShowTimeDropdown] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null); // Change to null
  const [customerName, setCustomerName] = useState("");
  const [employeeData, setEmployeeData] = useState([]);
  const [staffName, setStaffName] = useState("");
  const [notes, setNotes] = useState("");
  const [status, setStatus] = useState("approved");
  const [selectedServices, setSelectedServices] = useState([]);
  const [custNames, setCustNames] = useState([]);
  const [employeeNames, setEmployeeNames] = useState([]);
  const [loading, setLoading] = useState(false);

  const services = [
    { value: "Service 1", label: "Service 1" },
    { value: "Service 2", label: "Service 2" },
    { value: "Service 3", label: "Service 3" },
    { value: "Service 4", label: "Service 4" },
  ];

  const statusData = [
    { value: "approved", label: "Approved" },
    { value: "cancelled", label: "Cancelled" },
  ];

  const fetchEmployeeData = () => {
    setLoading(true);

    const postApiUrl = BASE_URL + `/getHrsEmpDataByUniqueId`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setEmployeeData(data.data);
        } else {
          console.error("Failed to fetch invoices:", data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
        setLoading(false);
      });
  };

  const empNameOptions = employeeData.map((emp) => ({
    value: emp.id,
    label: emp.name,
  }));

  const fetchAllCustomers = () => {
    setLoading(true);
    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCustNames(data.responseMessage);
          setLoading(false);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchAllCustomers();
    fetchEmployeeData();
  }, []);

  const custNameOptions = custNames.map((customer) => ({
    value: customer.id,
    label: customer.customerName,
  }));

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowTimeDropdown(true);
  };

  const handleServiceChange = (selectedOptions) => {
    setSelectedServices(selectedOptions);
  };

  const handleStaffChange = (selectedOption) => {
    setStaffName(selectedOption);
  };

  const handleCustomerChange = (selectedOption) => {
    setCustomerName(selectedOption);
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption.value); // Set status value
  };

  const handleTimeChange = (selectedOption) => {
    setSelectedTime(selectedOption); // Set selected time option
  };

  const handleEditorChange = (value) => {
    setNotes(value);
  };

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleCancel();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();

    const formattedFromTime = fromTime
      ? fromTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      : "";
    const formattedToTime = toTime
      ? toTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      : "";

    const appointmentData = {
      uniqueId: decryptedUniqueId,
      service: selectedServices.map((service) => service.value),
      customerName: customerName.label, // Assuming customerName is an object with value and label
      staffName: staffName.label, // Assuming staffName is an object with value and label
      notes: notes,
      appointmentDate: selectedDate
        ? selectedDate.toISOString().split("T")[0]
        : "", // Format date as YYYY-MM-DD
      timeAuthentication: formattedFromTime, // Get the value from selected time
      status: status,
    };
    formData.append("appointmentData", JSON.stringify(appointmentData));
    // console.log(appointmentData);
    // return;

    setLoading(true);
    fetch(BASE_URL + `/createAppintment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(appointmentData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Appointment Created Successfully",
          }).then(() => {
            setLoading(false);
            handleCancel(); // Navigate back to the appointment list or another page
          });
        } else {
          swal({
            icon: "error",
            title: "Failed to Create Appointment",
            text: data.responseMessage || "Please try again later.",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error creating appointment:", error);
        swal({
          icon: "error",
          title: "Error",
          text: "An error occurred while creating the appointment. Please try again later.",
        });
        setLoading(false);
      });
  };

  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);

  useEffect(() => {
    if (fromTime) {
      // Set 'to' time to 1 hour after 'from' time by default
      const defaultToTime = new Date(fromTime);
      defaultToTime.setHours(defaultToTime.getHours() + 1);
      setToTime(defaultToTime);
    }
  }, [fromTime]);

  const handleFromTimeChange = (time) => {
    setFromTime(time);
  };

  const handleToTimeChange = (time) => {
    if (fromTime && time < fromTime) {
      // Don't allow 'to' time to be before 'from' time
      return;
    }
    setToTime(time);
  };

  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F6F6F6",
      borderRadius: "7px",
      height: "46px",
      border: "1px solid #ced4da",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #ced4da",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      height: "19px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "white",
      "&:hover": {
        backgroundColor: state.isSelected ? "#007bff" : "#f8f9fa",
      },
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px", // Fixed height for dropdown
      overflowY: "auto", // Makes it scrollable
    }),
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="my-3">
          <div className="d-flex align-items-center ml-1 mt-4">
              <div className="cursor-pointer d-flex justify-content-center align-items-center back-button">
                <ArrowBackIosIcon
                  width={2}
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5
                  className="mt-1 mx-2 text-primary"
                  style={{ letterSpacing: "1px" }}
                >
                  Update Appointment
                </h5>
              </div>
            </div>
          </div>
          <Card className="card-style">
            <Card.Body>
              <h4 className="text-primary mb-4">Appointment Form</h4>

              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Services <span className="text-danger">*</span>
                    </label>
                    <Select
                      isMulti
                      options={services}
                      onChange={handleServiceChange}
                      placeholder="Select Services"
                      value={selectedServices}
                      className="mt-0"
                      styles={customStyles}
                      isClearable
                    />
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Customer Name <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={custNameOptions}
                      onChange={handleCustomerChange}
                      placeholder="Select Customer"
                      value={customerName}
                      className="mt-0"
                      styles={customStyles}
                      isClearable
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Staff Name <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={empNameOptions}
                      onChange={handleStaffChange}
                      placeholder="Select Staff"
                      value={staffName}
                      className="mt-0"
                      styles={customStyles}
                      isClearable
                    />
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group className="new-appointment-date">
                    <label className="label-style">
                      Appointment Date <span className="text-danger">*</span>
                    </label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select Date"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              {showTimeDropdown && (
                <Row>
                  <Col md="3" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        From Time <span className="text-danger">*</span>
                      </label>
                      <DatePicker
                        selected={fromTime}
                        onChange={handleFromTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholderText="Select start time"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        To Time <span className="text-danger">*</span>
                      </label>
                      <DatePicker
                        selected={toTime}
                        onChange={handleToTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholderText="Select end time"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row>
                <Col md="12" className="mb-3">
                  <Form.Group>
                    <label className="label-style">Notes</label>
                    <ReactQuill
                      onChange={handleEditorChange}
                      theme="snow" // Use "bubble" for a minimal UI
                      style={{ height: "300px", marginBottom: "30px" }}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
                          [{ font: [] }], // Font options
                          [{ size: [] }], // Font size
                          [{ color: [] }, { background: [] }], // Text color & background
                          ["bold", "italic", "underline", "strike"], // Basic text styles
                          [{ script: "sub" }, { script: "super" }], // Subscript & superscript
                          [{ align: [] }], // Text alignment
                          [{ list: "ordered" }, { list: "bullet" }], // Lists
                          [{ indent: "-1" }, { indent: "+1" }], // Indentation
                          ["blockquote", "code-block"], // Blockquote & code block
                          ["link", "image", "video"], // Media links
                          ["clean"], // Remove formatting
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "color",
                        "background",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "script",
                        "align",
                        "list",
                        "bullet",
                        "indent",
                        "blockquote",
                        "code-block",
                        "link",
                        "image",
                        "video",
                      ]}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Status <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={statusData}
                      onChange={handleStatusChange}
                      placeholder="Select Status"
                      value={statusData.find(
                        (option) => option.value === status
                      )} // Set the selected status
                      className="mt-0"
                      styles={customStyles}
                      isClearable
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <div className="d-flex justify-content-end mt-4">
                    <Button variant="outline-secondary" className="mx-2">
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      className="mx-2"
                      onClick={handleSubmit}
                    >
                      Submit Appointment
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UpdateAppointment;
