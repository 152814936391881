import React, { useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Table,
  Container,
  Modal,
  Alert,
} from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NoDataImage from "../../../asset/images/nodata.jpg";
import swal from "sweetalert";
import { useState } from "react";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import { BASE_URL } from "../CommonApi";
import $ from "jquery";
import UpdateDocument from "./UpdateDocument";

const Documents = ({ navigation }) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const [documentData, setDocumentData] = useState([]);
  const [documentId, setDocumentId] = useState();
  const [recordId, setRecordId] = useState();
  const [loading, setLoading] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewFileName, setPreviewFileName] = useState("");
  const [error, setError] = useState(null);
  const [previewType, setPreviewType] = useState(""); // "image", "pdf", or "unsupported"

  const UPLOAD_BASE_URL =
    "https://qabooks.zarp.software/uploads/otherDocument/";

  const fetchDocuments = async () => {
    $("#DataTables_Table_0").DataTable();
    try {
      const response = await fetch(`${BASE_URL}/getOtherDocumentByUniqueId`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          uniqueId: decryptedUniqueId,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setDocumentData(data.data);
        setError(null);
        $("#DataTables_Table_0").DataTable();
      } else {
        setError(data.message || "Failed to fetch documents");
      }
    } catch (error) {
      setError("Error fetching documents: " + error.message);
      $("#DataTables_Table_0").DataTable();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const downloadFile = async (fileName) => {
    try {
      const fileUrl = `${UPLOAD_BASE_URL}${fileName}`;
      // If your server does not need authentication for downloads, you can remove the header.
      const response = await fetch(fileUrl, {
        method: "GET",
        // No Authorization header in this test version
      });

      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      // Remove prefix from filename if needed
      a.download = fileName.split("_").slice(1).join("_");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      setError(null);
    } catch (error) {
      setError("Error downloading file: " + error.message);
    }
  };

  const viewFile = (fileName) => {
    try {
      const fileUrl = `${UPLOAD_BASE_URL}${fileName}`;
      setPreviewUrl(fileUrl);
      setPreviewFileName(fileName.split("_").slice(1).join("_"));
      // Determine file type based on extension
      const extension = fileName.split(".").pop().toLowerCase();
      if (["jpg", "jpeg", "png", "gif", "bmp"].includes(extension)) {
        setPreviewType("image");
      } else if (extension === "pdf") {
        setPreviewType("pdf");
      } else {
        setPreviewType("unsupported");
      }
      setShowPreview(true);
      setError(null);
    } catch (error) {
      setError("Error viewing file: " + error.message);
    }
  };

  const handleUpdate = (id, documentId) => {
    setRecordId(id);
    setDocumentId(documentId);
    setShowUpdate(true);
    setShowCard(false);
  };

  const handleDelete = (id, documentId) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this item!"
    );

    setLoading(true);
    if (userConfirmed) {
      fetch(`${BASE_URL}/deleteSingleOtherDocument`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          id: id,
          documentId: documentId,
          uniqueId: decryptedUniqueId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.message === "Document deleted successfully") {
            setLoading(false);
            swal({
              icon: "success",
              title: "Document Deleted Successfully",
            });
            fetchDocuments();
          } else {
            setLoading(false);
            swal({
              icon: "error",
              title: "Failed to Delete Document",
              text: data.message || "An error occurred",
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
          swal({
            icon: "error",
            title: "An Error Occurred While Deleting Document",
          });
        });
    }
  };

  const handleCancelUpdate = () => {
    setShowCard(true);
    setShowUpdate(false);
    fetchDocuments();
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container-fluid">
      {error && (
        <Alert variant="danger" onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}

      {!showUpdate && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Documents
            </div>
            <div className="button-container d-flex">
              <Button
                className="btn btn-primary px-1 py-2 me-2 mr-1"
                onClick={() => navigation.navigate("AddDocument")}
              >
                + New Document
              </Button>
            </div>
          </div>
        </div>
      )}

      {showCard && (
        <div
          className="container-fluid"
          style={{ border: "none", borderRadius: "7px" }}
        >
          <div className="page-content ">
            <Container fluid>
              {/* <Card className="card-style p-3"> */}
              <Row>
                <Col>
                  <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    {documentData && documentData.length > 0 ? (
                      <div className="body">
                        <div
                          className="table-responsive1 dataTables_wrapper"
                          id="DataTables_Table_0_wrapper"
                        >
                          <Table
                            className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                            id="DataTables_Table_0"
                          >
                            <thead className="heading-text">
                              <tr role="row">
                                <th
                                  className="sorting_asc"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-sort="ascending"
                                >
                                  #
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Document ID
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Documents
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text1">
                              {documentData.map((entry, index) => (
                                <tr key={entry.id}>
                                  <td>{index + 1}</td>
                                  <td className="sorting_1">{entry.id}</td>
                                  <td>
                                    <div className="d-flex flex-column gap-2">
                                      {entry.documents.map((doc) => (
                                        <div
                                          key={doc.id}
                                          className="d-flex justify-content-between align-items-center border-bottom py-2"
                                        >
                                          <div>
                                            <div className="fw-bold">
                                              {doc.fileName
                                                .split("_")
                                                .slice(1)
                                                .join("_")}
                                            </div>
                                            <div className="text-muted small">
                                              {doc.description}
                                            </div>
                                          </div>
                                          <div className="d-flex gap-2">
                                            <button
                                              variant="info"
                                              size="sm"
                                              className="btn icon-border btn-sm mx-2"
                                              onClick={() =>
                                                viewFile(doc.fileName)
                                              }
                                            >
                                              <i className="fa fa-eye"></i>
                                            </button>
                                            <button
                                              variant="warning"
                                              size="sm"
                                              className="btn icon-border btn-sm mx-2"
                                              onClick={() =>
                                                handleUpdate(entry.id, doc.id)
                                              }
                                            >
                                              <i className="fa fa-edit"></i>{" "}
                                            </button>
                                            <button
                                              variant="danger"
                                              size="sm"
                                              className="btn icon-border btn-sm mx-2"
                                              onClick={() =>
                                                handleDelete(entry.id, doc.id)
                                              }
                                            >
                                              <i className="fa fa-trash"></i>{" "}
                                            </button>
                                            {/* <Button
                                          variant="primary"
                                          size="sm"
                                          onClick={() =>
                                            downloadFile(doc.fileName)
                                          }
                                        >
                                          <i className="fa fa-download"></i>{" "}
                                          Download
                                        </Button> */}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    ) : (
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                      >
                                        #
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        Document ID
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        Documents
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                                <h6 className="text-center">
                                  <img
                                    src={NoDataImage}
                                    style={{ width: "50%", height: "50%" }}
                                    alt="No Data Logo"
                                  />{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
              {/* </Card> */}
            </Container>
          </div>
        </div>
      )}

      {showUpdate && (
        <UpdateDocument
          handleCancel={handleCancelUpdate}
          documentId={documentId}
          recordId={recordId}
        />
      )}

      <Modal
        show={showPreview}
        onHide={() => {
          setShowPreview(false);
          setPreviewUrl(null);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{previewFileName}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "auto", maxHeight: "80vh" }}>
          {previewType === "image" && (
            <img
              src={previewUrl}
              alt={previewFileName}
              style={{
                width: "100%",
                objectFit: "contain",
                display: "block",
                margin: "0 auto",
              }}
            />
          )}
          {previewType === "pdf" && (
            <iframe
              src={previewUrl}
              style={{
                width: "100%",
                height: "70vh",
                border: "none",
              }}
              title="PDF Preview"
            />
          )}
          {previewType === "unsupported" && (
            <div className="text-center p-3">
              <p>
                Preview is not available for this file type. Please download the
                file to view it.
              </p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Documents;
