import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row, Table } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import $, { data } from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import NoDataImage from "../../../asset/images/nodata.jpg";
import { AES, enc } from "crypto-js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import "./custom-datepicker.css";
import PaySlip from "./PaySlip";

const TimeSheetDetails = ({ onCancel, employeeId, navigation }) => {
  const [details, setDetails] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [showPaySlipPage, setShowPaySlipPage] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");
  const [loading, setLoading] = useState(false);

  const IMAGE_BASE_URL = "https://qabooks.zarp.software/jps/uploads/";

  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (imagePath) => {
    setSelectedImage(imagePath);
    setShowImageModal(true);
  };

  useEffect(() => {
    if ($.fn.DataTable.isDataTable("#DataTables_Table_0")) {
      $("#DataTables_Table_0").DataTable().destroy();
    }

    // Then initialize a new one
    $("#DataTables_Table_0").DataTable();
    setLoading(true);
    fetch(
      `https://qabooks.zarp.software/jps/JpserpApi/getAttendance/${employeeId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setDetails(data.message || []);

        if ($.fn.DataTable.isDataTable("#DataTables_Table_0")) {
          $("#DataTables_Table_0").DataTable().destroy();
        }
        $("#DataTables_Table_0").DataTable();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        if ($.fn.DataTable.isDataTable("#DataTables_Table_0")) {
          $("#DataTables_Table_0").DataTable().destroy();
        }
        $("#DataTables_Table_0").DataTable();
        console.error("Error fetching Time Sheet data:", error);
      });
  }, []);

  const handleCancleClick = () => {
    onCancel();
  };

  const formatTime = (time) => {
    return time || "-";
  };

  const formatDate = (date) => {
    return date || "-";
  };

  const handlePayslipClick = () => {
    setShowCard(false);
    setShowPaySlipPage(true);
    // handleNavigation("PaySlip");
    console.log("Navigating to PaySlip");
  };

  const handleCancelPaySlip = () => {
    setShowCard(true);
    setShowPaySlipPage(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIsOpen(false);

    // Hardcoded attendance data
    const hardcodedData = [
      {
        id: 1,
        empId: "EMP001",
        name: "John Doe",
        checkin_date: "2025-03-01",
        checkin_time: "09:00 AM",
        checkout_time: "05:00 PM",
        checkin_location: "Office A",
        checkout_location: "Office A",
        checkin_desc: "Arrived on time",
        checkout_desc: "Left on time",
        status: "Present",
      },
      {
        id: 2,
        empId: "EMP001",
        name: "John Doe",
        checkin_date: "2025-03-02",
        checkin_time: "09:10 AM",
        checkout_time: "04:50 PM",
        checkin_location: "Office A",
        checkout_location: "Office A",
        checkin_desc: "Slightly late",
        checkout_desc: "Left early",
        status: "Present",
      },
    ];

    setFilteredDetails(hardcodedData);
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Container fluid className="py-4">
      <Row>
        <Col>
          {!showPaySlipPage && (
            <div className="my-3">
              <div className="px-2 d-flex">
                <div className="cursor-pointer d-flex justify-content-center align-items-center back-button">
                  <ArrowBackIosIcon
                    width={2}
                    className="arrow-child"
                    onClick={handleCancleClick}
                  />
                </div>
                <h5
                  className="mt-1 mx-2 text-primary"
                  style={{ letterSpacing: "1px" }}
                >
                  Attendance Details
                </h5>
              </div>
            </div>
          )}

          {/* <div className="datepicker-container">
              <label className="datepicker-label" style={{ fontWeight: "bold", marginBottom: "5px" }}>
                Select Month:
              </label>
              <div className="datepicker-wrapper">
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showPopperArrow={false}
                  popperPlacement="bottom-start"
                  calendarClassName="custom-calendar"
                  onClickOutside={() => setIsOpen(false)}
                  open={isOpen}
                  onInputClick={() => setIsOpen(true)}
                  customInput={
                    <div className="datepicker-input" onClick={() => setIsOpen(!isOpen)}>
                      <input
                        type="text"
                        value={selectedDate ? selectedDate.toLocaleDateString("en-US", { month: "2-digit", year: "numeric" }) : ""}
                        readOnly
                        style={{
                          padding: "8px",
                          // border: "1px solid #ced4da",
                          // borderRadius: "5px",
                          fontSize: "14px",
                          width: "120px",
                          textAlign: "center",
                        }}
                      />
                      <FaCalendarAlt className="calendar-icon" style={{ marginLeft: "5px", cursor: "pointer" }} />
                    </div>
                  }
                />
              </div>
              </div> */}

          {showCard && (
            <>
              <div
                style={{
                  background: "#ffffff",
                  borderRadius: "12px",
                  border: "1px solid #ccc", // Light black (grayish) border
                  padding: "30px",
                  transition: "0.3s ease-in-out",
                  marginBottom: "20px",
                  marginTop: "40px",
                  width: "100%",
                }}
              >
                <div className="datepicker-container">
                  <label
                    className="datepicker-label"
                    style={{ fontWeight: "bold", marginBottom: "5px" }}
                  >
                    Select Month:
                  </label>
                  <div className="datepicker-wrapper">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      showPopperArrow={false}
                      popperPlacement="bottom-start"
                      calendarClassName="custom-calendar"
                      onClickOutside={() => setIsOpen(false)}
                      open={isOpen}
                      onInputClick={() => setIsOpen(true)}
                      customInput={
                        <div
                          className="datepicker-input"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <input
                            type="text"
                            value={
                              selectedDate
                                ? selectedDate.toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    year: "numeric",
                                  })
                                : ""
                            }
                            readOnly
                            style={{
                              padding: "5px",
                              // border: "1px solid #ced4da",
                              // borderRadius: "5px",
                              fontSize: "14px",
                              width: "120px",
                              textAlign: "center",
                            }}
                          />
                          <FaCalendarAlt
                            className="calendar-icon"
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                          />
                        </div>
                      }
                    />
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <table
                    style={{
                      marginTop: "10px",
                      width: "48%", // Table takes half of the container width
                      borderCollapse: "collapse",
                      borderRadius: "8px",
                      overflow: "hidden",
                      backgroundColor: "#fff",
                      tableLayout: "fixed",
                    }}
                  >
                    <thead>
                      <tr
                        style={{
                          background: "#007bff",
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        <th
                          style={{
                            padding: "10px",
                            borderBottom: "2px solid #ddd",
                            fontSize: "14px",
                            fontWeight: "bold",
                            width: "150px",
                          }}
                        >
                          Field
                        </th>
                        <th
                          style={{
                            padding: "10px",
                            borderBottom: "2px solid #ddd",
                            fontSize: "14px",
                            fontWeight: "bold",
                            borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
                            width: "200px",
                          }}
                        >
                          Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        {
                          icon: "fas fa-id-badge",
                          label: "ID",
                          value: details[0]?.id || "N/A",
                        },
                        {
                          icon: "fas fa-user",
                          label: "Name",
                          value: details[0]?.name || "N/A",
                        },
                        {
                          icon: "fas fa-envelope",
                          label: "Email",
                          value: details[0]?.email || "N/A",
                        },
                      ].map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f8f9fa" : "#ffffff",
                            transition: "0.3s",
                          }}
                        >
                          <td
                            style={{
                              padding: "8px",
                              borderBottom: "1px solid #ddd",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            <i
                              className={item.icon}
                              style={{ marginRight: "6px", color: "#007bff" }}
                            ></i>
                            {item.label}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              borderBottom: "1px solid #ddd",
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#333",
                              borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            {item.value}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <table
                    style={{
                      marginTop: "10px",
                      width: "48%", // Second table also takes half width
                      borderCollapse: "collapse",
                      borderRadius: "8px",
                      overflow: "hidden",
                      backgroundColor: "#fff",
                      tableLayout: "fixed",
                    }}
                  >
                    <thead>
                      <tr
                        style={{
                          background: "#007bff",
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        <th
                          style={{
                            padding: "10px",
                            borderBottom: "2px solid #ddd",
                            fontSize: "14px",
                            fontWeight: "bold",
                            width: "150px",
                          }}
                        >
                          Field
                        </th>
                        <th
                          style={{
                            padding: "10px",
                            borderBottom: "2px solid #ddd",
                            fontSize: "14px",
                            fontWeight: "bold",
                            borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
                            width: "200px",
                          }}
                        >
                          Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        {
                          icon: "fas fa-phone",
                          label: "Contact",
                          value: details[1]?.contact || "N/A",
                        },
                        {
                          icon: "fas fa-briefcase",
                          label: "Designation",
                          value: details[1]?.designation || "N/A",
                        },
                        {
                          icon: "fas fa-building",
                          label: "Department",
                          value: details[1]?.department || "N/A",
                        },
                      ].map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f8f9fa" : "#ffffff",
                            transition: "0.3s",
                          }}
                        >
                          <td
                            style={{
                              padding: "8px",
                              borderBottom: "1px solid #ddd",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            <i
                              className={item.icon}
                              style={{ marginRight: "6px", color: "#007bff" }}
                            ></i>
                            {item.label}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              borderBottom: "1px solid #ddd",
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#333",
                              borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
                              gap: "5px",
                            }}
                          >
                            {item.value}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* 
<table
  style={{
    marginTop: "10px",
    width: "auto", 
    borderCollapse: "collapse",
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "#fff",
    tableLayout: "fixed",
  }}
>
  <thead>
    <tr style={{ background: "#007bff", color: "white", textAlign: "left" }}>
      <th style={{ padding: "10px", borderBottom: "2px solid #ddd", fontSize: "14px", fontWeight: "bold", width: "150px" }}>Field</th>
      <th style={{ padding: "10px", borderBottom: "2px solid #ddd", fontSize: "14px", fontWeight: "bold", borderLeft: "1px solid rgba(0, 0, 0, 0.1)", width: "200px" }}>Details</th>
    </tr>
  </thead>
  <tbody>
    {[
      { icon: "fas fa-id-badge", label: "ID", value: details[0]?.id || "N/A" },
      { icon: "fas fa-user", label: "Name", value: details[0]?.name || "N/A" },
      { icon: "fas fa-envelope", label: "Email", value: details[0]?.email || "N/A" },
      { icon: "fas fa-phone", label: "Contact", value: details[0]?.contact || "N/A" },
      { icon: "fas fa-briefcase", label: "Designation", value: details[0]?.designation || "N/A" },
      { icon: "fas fa-building", label: "Department", value: details[0]?.department || "N/A" },
    ].map((item, index) => (
      <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "#ffffff", transition: "0.3s" }}>
        <td style={{ padding: "8px", borderBottom: "1px solid #ddd", fontSize: "14px", fontWeight: "600" }}>
          <i className={item.icon} style={{ marginRight: "6px", color: "#007bff" }}></i>
          {item.label}
        </td>
        <td style={{
          padding: "8px",
          borderBottom: "1px solid #ddd",
          fontSize: "14px",
          fontWeight: "500",
          color: "#333",
          borderLeft: "1px solid rgba(0, 0, 0, 0.1)"
        }}>
          {item.value}
        </td>
      </tr>
    ))}
  </tbody>
</table> */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "15px",
                  }}
                >
                  <button
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      background: "#007bff",
                      color: "#fff",
                      border: "none",
                      borderRadius: "8px",
                      padding: "8px 12px",
                      fontWeight: "bold",
                      cursor: "pointer",
                      transition: "0.3s",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.background = "#0056b3")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.background = "#007bff")
                    }
                    onClick={() => handlePayslipClick()}
                  >
                    <i
                      className="fas fa-file-invoice-dollar"
                      style={{ marginRight: "8px" }}
                    ></i>{" "}
                    View Payslip
                  </button>
                </div>
              </div>

              <div className="page-content ">
                <Card
                  className="shadow-sm card-style"
                  style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                >
                  {details.length > 0 ? (
                    <div className="body">
                      <div className="d-flex align-items-center mb-3">
                        <div>
                          <strong>
                            Employee ID:{" "}
                            <span
                              style={{ color: "#007bff", fontSize: "18px" }}
                            >
                              {" "}
                              {details[0].empId || "-"}{" "}
                            </span>
                          </strong>{" "}
                          &nbsp;&nbsp; |&nbsp;&nbsp;
                        </div>
                        <div>
                          <strong>
                            Employee Name:{" "}
                            <span
                              style={{ color: "#007bff", fontSize: "18px" }}
                            >
                              {details[0].name || "-"}
                            </span>{" "}
                          </strong>
                        </div>
                      </div>
                      <div
                        className="table-responsive1 dataTables_wrapper"
                        id="DataTables_Table_0_wrapper"
                      >
                        <Table
                          className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                          id="DataTables_Table_0"
                        >
                          <thead className="heading-text">
                            <tr className="bg-light" role="row">
                              <th
                                className="sorting_asc"
                                tabIndex="0"
                                aria-controls="DataTables_Table_0"
                                rowSpan="1"
                                colSpan="1"
                                aria-sort="ascending"
                              >
                                Date
                              </th>

                              <th
                                className="sorting"
                                tabIndex="0"
                                aria-controls="DataTables_Table_0"
                                rowSpan="1"
                                colSpan="1"
                              >
                                Check In
                              </th>
                              <th
                                className="sorting"
                                tabIndex="0"
                                aria-controls="DataTables_Table_0"
                                rowSpan="1"
                                colSpan="1"
                              >
                                Check Out
                              </th>
                              <th
                                className="sorting"
                                tabIndex="0"
                                aria-controls="DataTables_Table_0"
                                rowSpan="1"
                                colSpan="1"
                              >
                                Location
                              </th>
                              <th
                                className="sorting"
                                tabIndex="0"
                                aria-controls="DataTables_Table_0"
                                rowSpan="1"
                                colSpan="1"
                              >
                                Description
                              </th>
                              <th
                                className="sorting"
                                tabIndex="0"
                                aria-controls="DataTables_Table_0"
                                rowSpan="1"
                                colSpan="1"
                              >
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {details.map((item, index) => (
                              <tr key={item.id} role="row" className="odd">
                                <td>{formatDate(item.checkin_date)}</td>

                                <td className="sorting_1">
                                  <div>{formatTime(item.checkin_time)}</div>
                                  {item.checkin_image && (
                                    <div className="mt-2">
                                      <Image
                                        src={`${IMAGE_BASE_URL}${item.checkin_image}`}
                                        alt="Check In"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          objectFit: "cover",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleImageClick(
                                            `${IMAGE_BASE_URL}${item.checkin_image}`
                                          )
                                        }
                                        className="rounded"
                                      />
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <div>{formatTime(item.checkout_time)}</div>
                                  {item.checkout_image && (
                                    <div className="mt-2">
                                      <Image
                                        src={`${IMAGE_BASE_URL}${item.checkout_image}`}
                                        alt="Check Out"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          objectFit: "cover",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleImageClick(
                                            `${IMAGE_BASE_URL}${item.checkout_image}`
                                          )
                                        }
                                        className="rounded"
                                      />
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <div>In: {item.checkin_location || "-"}</div>
                                  {item.checkout_location && (
                                    <div>Out: {item.checkout_location}</div>
                                  )}
                                </td>
                                <td>
                                  <div>In: {item.checkin_desc || "-"}</div>
                                  {item.checkout_desc && (
                                    <div>Out: {item.checkout_desc}</div>
                                  )}
                                </td>
                                <td>
                                  <span
                                    className={`badge ${
                                      item.status === "checkout"
                                        ? "bg-success"
                                        : "bg-warning"
                                    } text-white`}
                                  >
                                    {item.status}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  ) : (
                    <div className="body">
                      <div className="table-responsive1">
                        <div
                          id="DataTables_Table_0_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 no-footer"
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                id="DataTables_Table_0"
                                role="grid"
                                aria-describedby="DataTables_Table_0_info"
                              >
                                <thead className="heading-text">
                                  <tr className="bg-light" role="row">
                                    <th
                                      className="sorting_asc"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-sort="ascending"
                                    >
                                      Date
                                    </th>

                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                    >
                                      Check In
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                    >
                                      Check Out
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                    >
                                      Location
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                    >
                                      Description
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                    >
                                      Status
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                              <h6 className="text-center">
                                <img
                                  src={NoDataImage}
                                  style={{ width: "50%", height: "50%" }}
                                  alt="No Data Logo"
                                />{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Card>
              </div>

              {showImageModal && (
                <div
                  className="modal-backdrop"
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0,0,0,0.7)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1050,
                  }}
                  onClick={() => setShowImageModal(false)}
                >
                  <div
                    className="modal-content"
                    style={{
                      maxWidth: "50%",
                      maxHeight: "90%",
                      position: "relative",
                    }}
                  >
                    <Image
                      src={selectedImage}
                      alt="Full size"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "90vh",
                        objectFit: "contain",
                      }}
                    />
                    <button
                      className="btn btn-sm btn-light"
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                      onClick={() => setShowImageModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </>
          )}


          {showPaySlipPage && (
            <PaySlip onCancel={handleCancelPaySlip} />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default TimeSheetDetails;
