import React from "react";
import { Button, Card, Form, Row, Col } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import swal from "sweetalert";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";

const AddDocument = ({ navigation }) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [loading, setLoading] = useState(false);
  const [fileRows, setFileRows] = useState([
    { id: 1, file: null, preview: "", fileName: "", textValue: "" },
  ]);

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleCancel = () => {
    handleNavigation("Documents");
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      handleCancel();
    }
  };

  const handleFileChange = (id, file) => {
    if (!file) return;

    const fileType = file.type;
    const fileName = file.name;
    if (fileType.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileRows((blocks) =>
          blocks.map((block) =>
            block.id === id
              ? {
                  ...block,
                  file,
                  preview: e.target.result,
                  fileName: null,
                  textValue: "",
                }
              : block
          )
        );
      };
      reader.readAsDataURL(file);
    } else if (fileType === "application/pdf") {
      setFileRows((blocks) =>
        blocks.map((block) =>
          block.id === id
            ? {
                ...block,
                file,
                preview: null,
                fileName: fileName,
                textValue: "",
              }
            : block
        )
      );
    } else {
      setFileRows((blocks) =>
        blocks.map((block) =>
          block.id === id
            ? {
                ...block,
                file,
                preview: null,
                fileName: "Unsupported file type",
                textValue: "",
              }
            : block
        )
      );
    }
  };
  const handleTextChange = (id, event) => {
    const newFileRows = fileRows.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          textValue: event.target.value,
        };
      }
      return row;
    });
    setFileRows(newFileRows);
  };

  const handleAddMore = () => {
    const newId = Math.max(...fileRows.map((row) => row.id)) + 1;
    setFileRows([
      ...fileRows,
      { id: newId, file: null, fileName: "No file chosen", textValue: "" },
    ]);
  };

  const handleRemove = (id) => {
    if (fileRows.length > 1) {
      setFileRows(fileRows.filter((row) => row.id !== id));
    } else {
      setFileRows([
        { id: 1, file: null, fileName: "No file chosen", textValue: "" },
      ]);
    }
  };

  const handleSubmit = async () => {
    // Validate if all rows have both file and description
    const isValid = fileRows.every((row) => row.file && row.textValue.trim());
    if (!isValid) {
      swal(
        "Error",
        "Please provide both file and description for all rows",
        "error"
      );
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      // Add the request parameter as a JSON string
      formData.append(
        "request",
        JSON.stringify({
          uniqueId: decryptedUniqueId,
        })
      );

      // Append files and descriptions
      fileRows.forEach((row, index) => {
        formData.append(`files`, row.file);
        formData.append(`descriptions`, row.textValue);
      });

      const response = await fetch(`${BASE_URL}/uploadOtherDocument`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          // Remove Content-Type header to let the browser set it with the boundary
        },
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        swal("Success", "Documents uploaded successfully", "success");
        handleNavigation("Documents");
      } else {
        throw new Error(data.responseMessage || "Failed to upload documents");
      }
    } catch (error) {
      swal("Error", error.message || "Failed to upload documents", "error");
      console.error("Upload error:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col>
          <div className="my-3">
            <div className="d-flex align-items-center ml-1 mt-4">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                }}
              >
                <ArrowBackIosIcon
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5 className="ml-1 mb-0" style={{ color: "#086bd5de" }}>
                  New Documents
                </h5>
              </div>
            </div>
          </div>

          <Card className="p-3 mb-4 mx-3 attachments-card">
            {fileRows.map((block, index) => (
              <div key={block.id} className="mb-4">
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Control
                      className="form-control file-input-container"
                      type="file"
                      accept=".jpg,.jpeg,.png,.pdf"
                      onChange={(e) =>
                        handleFileChange(block.id, e.target.files[0])
                      }
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      value={block.textValue}
                      onChange={(e) => handleTextChange(block.id, e)}
                    />
                  </Col>
                </Row>
                {/* Preview Section */}
                {block.preview && (
                  <div className="mt-3">
                    <img
                      src={block.preview}
                      alt="Preview"
                      className="file-preview-image"
                    />
                  </div>
                )}

                {/* File Name Section for PDFs */}
                {block.fileName && (
                  <div className="mt-2 text-secondary">
                    <strong>Selected File:</strong> {block.fileName}
                  </div>
                )}

                {/* Remove Button */}
                {index > 0 && (
                  <Button
                    variant="outline-danger"
                    className="mt-2"
                    onClick={() => handleRemove(block.id)}
                  >
                    Remove
                  </Button>
                )}

                {/* Add Button */}
                {index === fileRows.length - 1 && (
                  <Button
                    variant="outline-secondary"
                    className="mt-2"
                    onClick={handleAddMore}
                  >
                    Add More
                  </Button>
                )}
              </div>
            ))}
            <Button
              variant="primary"
              onClick={handleSubmit}
              style={{ backgroundColor: "#007bff", borderColor: "#0056b3" }}
            >
              Upload Documents
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddDocument;
