import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import Alert from "@mui/material/Alert";

function TermsAndConditionUpdate(props) {
  const [termText, setTermText] = useState("");
  const [status, setStatus] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [termId, setTermId] = useState(null); // Store the term's ID
  const { id } = props;
  const jwtToken = localStorage.getItem("jwtToken");
  const decryptedUniqueId = AES.decrypt(
    localStorage.getItem("uniqueId1"),
    "1234567812345678"
  ).toString(enc.Utf8);

  useEffect(() => {
    setTermId(id); // Store the term's ID
    fetchTermData(id);
  }, []);

  const handleCancel = () => {
    if (window.confirm("Are you sure you want to leave this page?")) {
      props.onClick();
    }
  };

  const handleEditorChange = (value) => {
    setTermText(value);
  };

  const fetchTermData = async (id) => {
    try {
      const response = await fetch(
        BASE_URL + `/getTermsAndConditionById/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.status === "success") {
        setTermText(data.responseCode.termsAndCondition);
        setStatus(data.responseCode.status);
      } else {
        swal("Error", "Failed to fetch term data", "error");
      }
    } catch (error) {
      console.error("Error fetching term data:", error);
      swal("Error", "An error occurred while fetching term data", "error");
    }
  };
  const updateTerms = async () => {
    if (!termText.trim()) {
      setValidationMessage("Terms & Conditions are required");
      window.scrollTo(0, 0);
      return;
    }
    try {
      const response = await fetch(
        BASE_URL + `/updateTermsAndConditionjps/${termId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({
            uniqueId: decryptedUniqueId,
            termsAndCondition: termText,
            status: status,
          }),
        }
      );

      const data = await response.json();

      if (data.status === "success") {
        swal("Success", "Terms updated successfully!", "success");
        props.onClick();
      } else {
        swal("Error", "Failed to update terms", "error");
      }
    } catch (error) {
      console.error("Error updating terms:", error);
      swal("Error", "An error occurred while updating", "error");
    }
  };

  return (
    <>
      <div className="">
        <Row>
          <Col>
            <div className="my-3">
              <div className="d-flex align-items-center ml-1 mt-4">
                <div
                  className="cursor-pointer d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    height: "35px",
                    width: "38px",
                  }}
                >
                  <ArrowBackIosIcon
                    width={2}
                    onClick={handleCancel}
                    className="arrow-child"
                  />
                </div>
                <div className="newcustomer-head ml-2">
                  <h5
                    className="ml-1 mb-0"
                    style={{ letterSpacing: "1px", color: "#086bd5de" }}
                  >
                    Update Terms And Condition
                  </h5>
                </div>
              </div>
            </div>
            {validationMessage && (
              <Alert severity="error" className="mt-0">
                {validationMessage.split("\n").map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </Alert>
            )}
            <Card className="card-style p-4">
              <Row>
                <Col md="12">
                  <div className="mb-4">
                    <h6 className="section-title">Terms & Conditions</h6>
                    <ReactQuill
                      value={termText}
                      onChange={handleEditorChange}
                      theme="snow"
                      style={{ height: "200px" }}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          [{ font: [] }],
                          [{ size: [] }],
                          [{ color: [] }, { background: [] }],
                          ["bold", "italic", "underline", "strike"],
                          [{ script: "sub" }, { script: "super" }],
                          [{ align: [] }],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ indent: "-1" }, { indent: "+1" }],
                          ["blockquote", "code-block"],
                          ["link", "image", "video"],
                          ["clean"],
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "color",
                        "background",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "script",
                        "align",
                        "list",
                        "bullet",
                        "indent",
                        "blockquote",
                        "code-block",
                        "link",
                        "image",
                        "video",
                      ]}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="text-center py-4">
                  <button
                    className="btn btn-primary px-5"
                    onClick={updateTerms}
                  >
                    Update Terms
                  </button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default TermsAndConditionUpdate;
