import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Card,
  Button,
  Container,
  Modal,
} from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../asset/vendor/font-awesome/css/font-awesome.min.css";
import "./NewCampaign.css";
import {
  faUser,
  faBuilding,
  faBriefcase,
  faCalendarAlt,
  faPhone,
  faEnvelope,
  faTint,
  faIdCard,
  faCreditCard,
  faHeart,
  faUsers,
  faFile,
  faHome,
  faFileAlt,
  faGraduationCap,
  faIndianRupeeSign,
  faFilePdf,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { Alert } from "@mui/material";

const HRSEmployee = ({ navigation }) => {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");

  const [selectedDate, setSelectedDate] = useState(null); // Add state for DatePicker
  const [empName, setEmpName] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState(new Date());
  const [contactNumber, setContactNumber] = useState("");
  const [password, setPassword] = useState("");
  const [alternateContact, setAlternateContact] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState(new Date());
  const [bloodGroup, setBloodGroup] = useState("");
  const [salary, setSalary] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const [parentName, setParentName] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [permanentAddresss, setPermanentAddress] = useState("");
  const [highestQualification, setHightestQualification] = useState("");
  const [previousCompany, setPreviousCompany] = useState("");
  const [previousDesignation, setPreviousDesignation] = useState("");
  const [aadharFile, setAadharFile] = useState(null);
  const [panCardFile, setPanCardFile] = useState(null);
  const [bankPassbook, setBankPassbook] = useState(null);
  const [twelthOrDiplomaMarks, setTwelthOrDiplomaMarks] = useState(null);
  const [addressProof, setAddressProof] = useState(null);
  const [experienceLetter, setExperienceLetter] = useState(null);
  const [postGradMarks, setPostGradMarks] = useState(null);
  const [gradOrDiplomaMarks, setGradOrDiplomaMarks] = useState(null);
  const [certificates, setCertificates] = useState(null);
  const [addDepartment, setAddDepartment] = useState("");

  const departmentList = [
    { value: "Dispatch", label: "Dispatch" },
    { value: "Sales", label: "Sales" },
    { value: "HR", label: "HR" },
    { value: "Finance", label: "Finance" },
    { value: "Purchase", label: "Purchase" },
    { value: "Manager", label: "Manager" },
    { value:"Supervisor",label:"Supervisor"}
  ];
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleItemsClick = () => {
    handleNavigation("HRSEmployeeCard");
  };
  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]); // Save the selected file
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      handleItemsClick();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  // const handleSubmit = async () => {
  //   try {
  //     // Prepare FormData
  //     const formData = new FormData();
  //     const employeeData = {
  //       uniqueId: decryptedUniqueId,
  //       name: empName,
  //       department: department,
  //       designation: designation,
  //       dateOfJoining: dateOfJoining,
  //       contactNumber: contactNumber,
  //       password: password,
  //       alternateNumber: alternateContact,
  //       personalEmail: email,
  //       dateOfBirth: dob,
  //       bloodGroup: bloodGroup,
  //       salary: salary,
  //       spouseName: spouseName,
  //       parentName: parentName,
  //       currentAddress: currentAddress,
  //       permanentAddress: permanentAddresss,
  //       previousCompanyName: previousCompany,
  //       previousCompanyDesignation: previousDesignation,
  //       highestQualification: highestQualification,
  //     };

  //     // Append JSON as a string
  //     formData.append("employeeData", JSON.stringify(employeeData));
  //     if (aadharFile) formData.append("aadharCard", aadharFile);
  //     if (panCardFile) formData.append("panCard", panCardFile);
  //     if (twelthOrDiplomaMarks)
  //       formData.append("twelthOrDiplomaMarks", twelthOrDiplomaMarks);

  //     if (experienceLetter)
  //       formData.append("experienceLetter", experienceLetter);
  //     if (addressProof) formData.append("addressProof", addressProof);

  //     if (postGradMarks) formData.append("postGradMarks", postGradMarks);
  //     if (gradOrDiplomaMarks)
  //       formData.append("gradOrDiplomaMarks", gradOrDiplomaMarks);
  //     if (certificates) formData.append("certificates", certificates);

  //     if (bankPassbook) formData.append("bankPassbook", bankPassbook);

  //     console.log("formData : ", formData);
  //     console.log("employee formData : ",JSON.stringify(formData));
  //     console.log("employeeData : ", employeeData);
  //     const response = await fetch(BASE_URL + "/insertHrsEmpData", {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${jwtToken}`, // JWT token
  //       },
  //       body: formData, // Attach FormData
  //     });
  //     console.log("response : ", response);
  //     const result = await response.json();
  //     console.log("result : ", result);
  //     if (response.ok) {
  //       swal({
  //         icon: "success",
  //         title: "Employee Inserted Successfully",
  //       });
  //       handleItemsClick();
  //     } else {
  //       swal({
  //         icon: "error",
  //         title: "Failed to insert Employee",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert("Error submitting employee: " + error.message);
  //   }
  // };

  const handleSubmit = async () => {
    try {
      // Create FormData object
      const formData = new FormData();

      // Format department data properly
      const departmentValues = Array.isArray(department)
        ? department.map((dept) => dept.value)
        : [];

      // Convert dates to ISO string format
      const employeeData = {
        uniqueId: decryptedUniqueId,
        name: empName,
        department: departmentValues,
        designation: designation,
        dateOfJoining:
          dateOfJoining instanceof Date
            ? dateOfJoining.toISOString()
            : dateOfJoining,
        contactNumber: contactNumber,
        password: password,
        alternateNumber: alternateContact,
        personalEmail: email,
        dateOfBirth: dob instanceof Date ? dob.toISOString() : dob,
        bloodGroup: bloodGroup,
        salary: salary,
        spouseName: spouseName || "",
        parentName: parentName,
        currentAddress: currentAddress,
        permanentAddress: permanentAddresss,
        previousCompanyName: previousCompany || "",
        previousCompanyDesignation: previousDesignation || "",
        highestQualification: highestQualification,
      };

      // Debug logs
      console.log("Employee Data before JSON stringify:", employeeData);

      // Important: Append as 'employeeData', not 'HrsEmployeeData'
      formData.append("employeeData", JSON.stringify(employeeData));

      // Append files with specific checks
      if (aadharFile) formData.append("aadharCard", aadharFile);
      if (panCardFile) formData.append("panCard", panCardFile);
      if (bankPassbook) formData.append("bankPassbook", bankPassbook);
      if (addressProof) formData.append("addressProof", addressProof);
      if (experienceLetter)
        formData.append("experienceLetter", experienceLetter);
      if (postGradMarks) formData.append("postGradMarks", postGradMarks);
      if (gradOrDiplomaMarks)
        formData.append("gradOrDiplomaMarks", gradOrDiplomaMarks);
      if (twelthOrDiplomaMarks)
        formData.append("twelthOrDiplomaMarks", twelthOrDiplomaMarks);
      if (certificates) formData.append("certificates", certificates);

      // Debug: Log FormData contents
      for (let pair of formData.entries()) {
        console.log(pair[0] + ": ", pair[1]);
      }

      // Make the API call
      const response = await fetch(`${BASE_URL}/insertHrsEmpData`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          // Remove Content-Type header - let browser set it for FormData
        },
        body: formData,
      });

      console.log("Response status:", response.status);
      const result = await response.json();
      console.log("Response data:", result);

      if (response.ok) {
        swal({
          icon: "success",
          title: "Employee Inserted Successfully",
        });
        handleItemsClick();
      } else {
        // More detailed error message
        throw new Error(
          result.responseMessage ||
            `Failed to insert employee data: ${result.status}`
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      swal({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F6F6F6",
      borderRadius: "7px",
      height: "46px",
      border: "1px solid #ced4da",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #ced4da",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      height: "19px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "white",
      "&:hover": {
        backgroundColor: state.isSelected ? "#007bff" : "#f8f9fa",
      },
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px", // Fixed height for dropdown
      overflowY: "auto", // Makes it scrollable
    }),
  };

  const handleDepartmentChange = (value) => {
    setDepartment(value);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validationMessage, setValidationMessage] = useState("");

  const handleSaveClick = () => {};

  const handleNewDepartmentChange = () => {};

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="my-3">
            <div className="px-2 d-flex">
              <div className="cursor-pointer d-flex justify-content-center align-items-center back-button">
                <ArrowBackIosIcon
                  width={2}
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <h5
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                Employee Application
              </h5>
            </div>
          </div>
          <Card className="card-style">
            <Card.Body>
              <h4 className="text-primary mb-4">Employee Data Form</h4>

              <h5 className="text-secondary mb-3">Personal Information</h5>
              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Employee Name <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      <Form.Control
                        type="text"
                        name="empName"
                        value={empName}
                        onChange={(e) => setEmpName(e.target.value)}
                        required
                        placeholder="Enter Employee name"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Department <span className="text-danger">*</span>
                    </label>
                    <div className="input-group department-select">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faBuilding} />
                      </span>
                      <Select
                        isMulti
                        options={departmentList}
                        onChange={handleDepartmentChange}
                        placeholder="Select Department"
                        value={department}
                        className="mt-0"
                        styles={customStyles}
                        isClearable
                      />
                    </div>
                    <button type="button" class="btn p-0 text-left" onClick={handleShow}>
                      <span
                        class="text-primary"
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          marginLeft: "40px",
                        }}
                      >
                        New Department
                      </span>
                    </button>

                    <Modal size="lg" show={show} onHide={handleClose}>
                      <div class="modal-header d-flex align-items-center pb-2 pt-2">
                        <h5 class="modal-title fs-5" id="newcustomerPopup">
                          New Department
                        </h5>
                        <button
                          type="button"
                          className="btn pr-0"
                          onClick={handleClose}
                        >
                          <ion-icon
                            name="close-circle-outline"
                            size="large"
                            style={{ width: "28px" }}
                          ></ion-icon>
                        </button>
                      </div>
                      <Modal.Body className="pb-3">
                        {validationMessage && (
                          <Alert severity="error" className="mt-0">
                            {validationMessage
                              .split("\n")
                              .map((message, index) => (
                                <div key={index}>{message}</div>
                              ))}
                          </Alert>
                        )}{" "}
                        <div className="container">
                          <div className="row d-inline-flex">
                            <div className="modalinputchild">
                              <div className="text-style">Department Name</div>
                              <input
                                className="form-control w-100"
                                placeholder="Department Name"
                                name="newDepartment"
                                id="newDepartment"
                                value={addDepartment}
                                onChange={handleNewDepartmentChange}
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="p-2">
                        <div className="modal-bottombtns">
                          <button
                            type="button"
                            class="modalbtn-1 btn mt-0"
                            onClick={handleClose}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="modalbtn-3 btn btn-primary"
                            onClick={handleSaveClick}
                          >
                            Create Customer
                          </button>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Designation <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faBriefcase} />
                      </span>
                      <Form.Control
                        type="text"
                        name="designation"
                        value={designation}
                        onChange={(e) => setDesignation(e.target.value)}
                        required
                        placeholder="Enter Designation"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Date of Joining <span className="text-danger">*</span>
                    </label>
                    <div className="input-group emp-joining-date">
                      <span
                        className="input-group-text"
                        style={{ width: "7%" }}
                      >
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                      <DatePicker
                        selected={dateOfJoining}
                        onChange={(date) => setDateOfJoining(date)}
                        style={{ width: "93%" }}
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Select Date"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Contact Information */}
              <h5 className="text-secondary mb-2 mt-2">Contact Information</h5>
              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Contact Number (Primary Contact Number)
                      <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faPhone} />
                      </span>
                      <Form.Control
                        type="tel"
                        name="contactNumber"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        required
                        placeholder="Enter Contact Number"
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Password<span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faLock} />
                      </span>
                      <Form.Control
                        type="password"
                        name="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Enter Password"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Alternate Contact <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faPhone} />
                      </span>
                      <Form.Control
                        type="tel"
                        name="alternateContact"
                        value={alternateContact}
                        onChange={(e) => setAlternateContact(e.target.value)}
                        required
                        placeholder="Enter Alternate Contact"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Personal Details */}
              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Personal Email <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </span>
                      <Form.Control
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="Enter Personal Email"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Date of Birth <span className="text-danger">*</span>
                    </label>
                    <div className="input-group emp-dob-date">
                      <span
                        className="input-group-text"
                        style={{ width: "7%" }}
                      >
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                      <DatePicker
                        selected={dob}
                        onChange={(date) => setDob(date)}
                        style={{ width: "93%" }}
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Select Date"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Blood Group <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faTint} />
                      </span>
                      <Form.Control
                        type="text"
                        name="bloodGroup"
                        value={bloodGroup}
                        onChange={(e) => setBloodGroup(e.target.value)}
                        required
                        placeholder="Enter Blood Group"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Salary <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faIndianRupeeSign} />
                      </span>
                      <Form.Control
                        type="number"
                        name="salary"
                        value={salary}
                        onChange={(e) => setSalary(e.target.value)}
                        required
                        placeholder="Enter Salary"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Document Uploads Section */}
              <h5 className="text-secondary mb-3 mt-4">Document Uploads</h5>
              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Aadhar Card <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faIdCard} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="aadharCard"
                        onChange={(e) => handleFileChange(e, setAadharFile)}
                        required
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      PAN Card <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faCreditCard} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="panCardFile"
                        onChange={(e) => handleFileChange(e, setPanCardFile)}
                        required
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Family Information */}
              <h5 className="text-secondary mb-3 mt-4">Family Information</h5>
              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">Spouse Name</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faHeart} />
                      </span>
                      <Form.Control
                        type="text"
                        name="spouseName"
                        value={spouseName}
                        onChange={(e) => setSpouseName(e.target.value)}
                        placeholder="Enter Spouse Name"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Parent Name <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faUsers} />
                      </span>
                      <Form.Control
                        type="text"
                        name="parentName"
                        value={parentName}
                        onChange={(e) => setParentName(e.target.value)}
                        required
                        placeholder="Enter Parent Name"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Bank Details */}
              <h5 className="text-secondary mb-3 mt-4">Bank Details</h5>
              <Row>
                <Col md="12" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Bank Passbook/Cheque{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faFile} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="bankPassbook"
                        onChange={(e) => handleFileChange(e, setBankPassbook)}
                        required
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Address Information */}
              <h5 className="text-secondary mb-3 mt-4">Address Information</h5>
              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Current Address <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faHome} />
                      </span>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        className="form-control"
                        name="currentAddress"
                        value={currentAddress}
                        onChange={(e) => setCurrentAddress(e.target.value)}
                        required
                        placeholder="Enter Current Address"
                        style={{
                          minHeight: "100px",
                          resize: "vertical",
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Permanent Address <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faHome} />
                      </span>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="permanentAddress"
                        className="form-control"
                        value={permanentAddresss}
                        onChange={(e) => setPermanentAddress(e.target.value)}
                        required
                        placeholder="Enter Permanent Address"
                        style={{
                          minHeight: "100px",
                          resize: "vertical",
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="12" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Address Proof <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faFileAlt} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="addressProof"
                        onChange={(e) => handleFileChange(e, setAddressProof)}
                        required
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              {/* Educational Information */}
              <h5 className="text-secondary mb-3 mt-4">
                Educational Information
              </h5>
              <Row>
                <Col md="12" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Highest Qualification{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faGraduationCap} />
                      </span>
                      <Form.Control
                        type="text"
                        name="highestQualification"
                        value={highestQualification}
                        onChange={(e) =>
                          setHightestQualification(e.target.value)
                        }
                        required
                        placeholder="Enter Highest Qualification"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Post Graduation Marksheets
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faFilePdf} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="postGradMarks"
                        onChange={(e) => handleFileChange(e, setPostGradMarks)}
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Graduation/Diploma Marksheets
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faFilePdf} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="gradOrDiplomaMarks"
                        onChange={(e) =>
                          handleFileChange(e, setGradOrDiplomaMarks)
                        }
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      12th/Diploma Marksheets
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faFilePdf} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="twelthOrDiplomaMarks"
                        onChange={(e) =>
                          handleFileChange(e, setTwelthOrDiplomaMarks)
                        }
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Additional Certificates
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faFilePdf} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="certificates"
                        onChange={(e) => handleFileChange(e, setCertificates)}
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <h5 className="text-secondary mb-3 mt-4">Previous Employment</h5>
              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">Previous Company Name</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faBuilding} />
                      </span>
                      <Form.Control
                        type="text"
                        name="previousCompany"
                        value={previousCompany}
                        onChange={(e) => setPreviousCompany(e.target.value)}
                        placeholder="Enter Previous Company Name"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">Previous Designation</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faBriefcase} />
                      </span>
                      <Form.Control
                        type="text"
                        name="previousDesignation"
                        value={previousDesignation}
                        onChange={(e) => setPreviousDesignation(e.target.value)}
                        placeholder="Enter Previous Designation"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="12" className="mb-3">
                  <Form.Group>
                    <label className="label-style">Experience Letter</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faFile} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="experienceLetter"
                        onChange={(e) =>
                          handleFileChange(e, setExperienceLetter)
                        }
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Confirmation */}
              <Row>
                <Col md="12" className="mb-3">
                  <Form.Group>
                    <div className="form-check">
                      <Form.Check
                        type="checkbox"
                        name="confirmation"
                        id="confirmation"
                        label="I confirm that the information provided is accurate and up-to-date."
                        required
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Submit Button */}
              <Row>
                <Col md="12">
                  <div className="d-flex justify-content-end mt-4">
                    <Button
                      variant="outline-secondary"
                      className="mx-2"
                      onClick={handleCancleClick}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      className="mx-2"
                      onClick={handleSubmit}
                    >
                      Submit Application
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HRSEmployee;
