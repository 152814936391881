import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from "@tinymce/tinymce-react";
import { MessageCircle } from "iconic-react";
import { WhatsappLogo } from "phosphor-react";
import { Mail, Send, Schedule } from "@mui/icons-material";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Alert } from "@mui/material";

const NewCampaign = ({ navigation }) => {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");

  const [editorData, setEditorData] = useState("");

  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize with current date
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [custNames, setCustNames] = useState([]);
  const [scheduleVisible, setScheduleVisible] = useState(false);

  const [scheduledDate, setScheduledDate] = useState(new Date()); // Initialize with current date
  const [scheduledTime, setScheduledTime] = useState(new Date());

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [communicationMethods, setCommunicationMethods] = useState({
    whatsapp: true,
    sms: true,
    email: true,
  });

  const fetchAllCustomers = () => {
    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };
  useEffect(() => {
    fetchAllCustomers();
  }, []);

  const [formData, setFormData] = useState({
    uniqueId: decryptedUniqueId,
    customerType: "Business",
    status: "Active",
    someName: "Mr.",
    firstName: "",
    lastName: "",
    companyName: "",
    displayName:"",
    customerName: "",
    email: "",
    workphone: "",
    mobile: "",
    designation: "",
    department: "",
    website: "",
    currency: "INR- Indian Rupee",
    openingBalance: "0",
    paymentTerms: "",
    facebook: "",
    twitter: "",
    billAttention: "",
    billCountry: "India",
    billAddress1: "",
    billAddress2: "",
    billCity: "",
    billState: "Maharashtra",
    billZipcode: "",
    billPhone: "",
    billFax: "",
    shipAttention: "",
    shipCountry: "India",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "Maharashtra",
    shipZipcode: "",
    shipPhone: "",
    shipFax: "",
    gstin: "",
    pan: "",
    placeOfSupply: "[MH] - Maharashtra",
    taxable: "",
    reason: "N/A",
    activeStatus: "Active",
    gstTreatment: "",
    contactPerson: [
      {
        other: "",
        otherFirstname: "",
        otherLastname: "",
        otherEmail: "",
        otherWorkphone: "",
        otherMobile: "",
      },
    ],
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validationMessage, setValidationMessage] = useState("");

  const handleSaveClick = () => {
    let newValidationMessage = "";

    if (!formData.customerName) {
      newValidationMessage += "Please enter a customer display name.\n";
    } else if (
      formData.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
    ) {
      newValidationMessage += "Please enter a valid email address.\n";
    }

    setValidationMessage(newValidationMessage);
    if (newValidationMessage) {
      return; // Exit function if validation fails
    }

    fetch(BASE_URL + "/createCustomer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          swal({
            icon: "success",
            title: "Customer Created Successfully",
            text: "",
          });
          setShow(false);
          fetchAllCustomers();
        } else if (data.responseCode === "400") {
          swal({
            icon: "error",
            title: "This email already exists, please insert another email.",
            text: "",
          });
          setShow(true);
        } else {
          swal({
            icon: "error",
            title: "Failed to post customer data",
            text: "",
          });
          setShow(true);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        setShow(true);
      });
  };

  const handleChange = (event) => {
    // const { name, value } = event.target;
    // setFormData((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }));


    const { name, value } = event.target;
  
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        [name]: value,
      };
      
      // If customerName is being changed, also update displayName with the same value
      if (name === 'customerName') {
        newData.displayName = value;
      }
      
      return newData;
    });
  };

  const generateNamePairs = () => {
    const { someName, firstName, lastName } = formData;
    const pairs = [];

    if (firstName && lastName) {
      pairs.push(`${someName} ${firstName} ${lastName}`);
      pairs.push(`${firstName} ${lastName}`);
      pairs.push(`${lastName} , ${firstName}`);
    }

    return pairs;
  };

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleItemsClick = () => {
    handleNavigation("CampaignCard");
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleItemsClick();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit");
  };

  const [fileBlocks, setFileBlocks] = useState([
    { id: Date.now(), file: null, preview: null, name: null },
  ]);

  const handleAddFileBlock = () => {
    setFileBlocks([
      ...fileBlocks,
      { id: Date.now(), file: null, preview: null, name: null },
    ]);
  };

  const handleRemoveFileBlock = (id) => {
    setFileBlocks(fileBlocks.filter((block) => block.id !== id));
  };

  const handleFileChange = (id, file) => {
    if (!file) return;

    const fileType = file.type;
    const fileName = file.name;

    if (fileType.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileBlocks((blocks) =>
          blocks.map((block) =>
            block.id === id
              ? { ...block, file, preview: e.target.result, name: null }
              : block
          )
        );
      };
      reader.readAsDataURL(file);
    } else if (fileType === "application/pdf") {
      setFileBlocks((blocks) =>
        blocks.map((block) =>
          block.id === id
            ? { ...block, file, preview: null, name: fileName }
            : block
        )
      );
    } else {
      setFileBlocks((blocks) =>
        blocks.map((block) =>
          block.id === id
            ? { ...block, file, preview: null, name: "Unsupported file type" }
            : block
        )
      );
    }
  };

  const customerOptions = [
    { value: "all", label: "Select All" },
    ...custNames.map((customer) => ({
      value: customer.id,
      label: customer.customerName,
    })),
  ];

  const handleCustomerSelect = (option) => {
    if (option) {
      if (option.value === "all") {
        // Create array of all customers except the "Select All" option
        const allCustomers = custNames.map((customer) => ({
          id: customer.id,
          name: customer.customerName,
        }));
        // Set all customers as selected
        setSelectedCustomers(allCustomers);
      } else {
        const customer = {
          id: option.value,
          name: option.label,
        };
        if (!selectedCustomers.find((c) => c.id === customer.id)) {
          setSelectedCustomers([...selectedCustomers, customer]);
        }
      }
    }
  };

  const clearAllCustomers = () => {
    setSelectedCustomers([]);
  };

  const removeCustomer = (customerId) => {
    setSelectedCustomers(selectedCustomers.filter((c) => c.id !== customerId));
  };

  const handleCheckboxChange = (customerId) => {
    setSelectedCustomers((prev) => ({
      ...prev,
      [customerId]: !prev[customerId],
    }));
  };

  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F6F6F6",
      borderRadius: "7px",
      height: "46px",
      border: "1px solid #ced4da",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #ced4da",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      height: "19px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "white",
      "&:hover": {
        backgroundColor: state.isSelected ? "#007bff" : "#f8f9fa",
      },
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px", // Fixed height for dropdown
      overflowY: "auto", // Makes it scrollable
    }),
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="my-3">
            <div className="px-2 d-flex">
              <div className="cursor-pointer d-flex justify-content-center align-items-center back-button">
                <ArrowBackIosIcon
                  width={2}
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <h5
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                New Campaign
              </h5>
            </div>
          </div>

          {/* First Card */}
          <Card className="card-style">
            <Card.Body>
              <form onSubmit={handleSubmit}>
                <label
                  className="text-primary"
                  style={{ fontSize: 16, fontWeight: "600" }}
                >
                  NEW CAMPAIGN
                </label>
                <Row>
                  <Col md="6" className="mb-3">
                    <Col md="12" className="mb-3 mt-3">
                      <Form.Group>
                        <label className="label-style">
                          Customer name <span className="valid"> *</span>
                        </label>
                        <Select
                          className="mt-0"
                          options={customerOptions}
                          onChange={handleCustomerSelect}
                          placeholder="Select Customer"
                          styles={customStyles}
                          isClearable
                          value={null} // Keep empty to allow multiple selections
                        />
                        <button
                          type="button"
                          class="btn p-0 text-left"
                          onClick={handleShow}
                        >
                          <span
                            class="text-primary"
                            style={{ fontSize: "12px", fontWeight: "500" }}
                          >
                            New Customer
                          </span>
                        </button>

                        <Modal size="lg" show={show} onHide={handleClose}>
                          <div class="modal-header d-flex align-items-center pb-2 pt-2">
                            <h5 class="modal-title fs-5" id="newcustomerPopup">
                              New Customer
                            </h5>
                            <button
                              type="button"
                              className="btn pr-0"
                              onClick={handleClose}
                            >
                              <ion-icon
                                name="close-circle-outline"
                                size="large"
                                style={{ width: "28px" }}
                              ></ion-icon>
                            </button>
                          </div>
                          <Modal.Body className="p-0 pb-3">
                            {validationMessage && (
                              <Alert severity="error" className="mt-0">
                                {validationMessage
                                  .split("\n")
                                  .map((message, index) => (
                                    <div key={index}>{message}</div>
                                  ))}
                              </Alert>
                            )}{" "}
                            <div className="container">
                              <div className="row d-inline-flex">
                                <div className="modalinputchild">
                                  <div className="text-style"> Salutation </div>
                                  <select
                                    className="form-control"
                                    name="someName"
                                    value={formData.someName}
                                    onChange={handleChange}
                                    style={{
                                      borderRadius: "9px",
                                      backgroundColor: "#f5f5f5",
                                      width: "200px",
                                      height: "35px",
                                      fontSize: "13px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <option value="Mr.">Mr.</option>
                                    <option value="Mrs.">Mrs.</option>
                                    <option value="Ms.">Ms.</option>
                                    <option value="Miss.">Miss.</option>
                                    <option value="Dr.">Dr.</option>
                                  </select>
                                </div>
                                <div className="modalinputchild">
                                  <div className="text-style">First Name</div>
                                  <input
                                    className="form-control"
                                    placeholder="First Name"
                                    name="firstName"
                                    id="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    type="text"
                                  />
                                </div>
                                <div className="modalinputchild">
                                  <div className="text-style">Last Name</div>
                                  <input
                                    className="form-control"
                                    placeholder="Last Name"
                                    id="lastName"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="row d-inline-flex pt-2">
                                <div className="modalinputchild">
                                  <div className="text-style">
                                    {" "}
                                    Customer Email{" "}
                                  </div>
                                  <input
                                    className="form-control"
                                    placeholder="Customer Email"
                                    name="email"
                                    type="text"
                                    value={formData.email}
                                    onChange={handleChange}
                                  />
                                </div>

                                <div className="modalinputchild">
                                  <div className="text-style">
                                    {" "}
                                    Customer Display Name{" "}
                                    <span style={{ color: "red" }}> *</span>
                                  </div>
                                  <input
                                    className="form-control"
                                    placeholder="Customer Display Name"
                                    list="customerNames"
                                    name="customerName"
                                    value={formData.customerName}
                                    onChange={handleChange}
                                    style={{
                                      borderRadius: "7px",
                                      backgroundColor: "#f5f5f5",
                                      width: "200px",
                                      height: "40px",
                                      fontSize: "13px",
                                      marginTop: "10px",
                                    }}
                                  />
                                  <datalist id="customerNames">
                                    {generateNamePairs().map((pair, index) => (
                                      <option key={index} value={pair} />
                                    ))}
                                  </datalist>{" "}
                                </div>
                                <div className="modalinputchild">
                                  <div className="text-style">Company Name</div>
                                  <input
                                    className="form-control"
                                    placeholder="Add company name"
                                    name="companyName"
                                    type="text"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row d-inline-flex pt-2">
                                <div className="modalinputchild">
                                  <div className="text-style">
                                    Customer Phone
                                  </div>
                                  <input
                                    className="form-control"
                                    placeholder="Work Phone"
                                    name="workphone"
                                    type="text"
                                    value={formData.workphone}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="modalinputchild">
                                  <div className="text-style">Mobile</div>
                                  <input
                                    className="form-control"
                                    placeholder="Mobile"
                                    name="mobile"
                                    type="text"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer className="p-2">
                            <div className="modal-bottombtns">
                              <button
                                type="button"
                                class="modalbtn-1 btn mt-0"
                                onClick={handleClose}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                class="modalbtn-3 btn btn-primary"
                                onClick={handleSaveClick}
                              >
                                Create Customer
                              </button>
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </Form.Group>
                    </Col>
                    <Col
                      className="mb-3 d-flex"
                      md="12"
                      style={{ gap: "20px" }}
                    >
                      <Form.Group className="d-flex flex-column new-campaign-date w-100">
                        <label className="label-style">
                          Date <span className="valid"> *</span>
                        </label>
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          className="form-control"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="Select date"
                        />
                      </Form.Group>
                      <Form.Group className="d-flex flex-column new-campaign-time w-100">
                        <label className="label-style">
                          Time <span className="valid"> *</span>
                        </label>
                        <DatePicker
                          selected={selectedTime}
                          onChange={(time) => setSelectedTime(time)}
                          className="form-control"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1} // Adjust for interval (e.g., 15 minutes)
                          timeCaption="Time"
                          dateFormat="h:mm aa" // 12-hour format with AM/PM
                          placeholderText="Select time"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12" className="mb-3">
                      <Form.Group>
                        <label className="label-style">Title</label>
                        <Form.Control
                          className="border border-1"
                          placeholder="Title"
                          name="title"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12" className="mb-3">
                      <Form.Group>
                        <label className="label-style">Campaign Name</label>
                        <Form.Control
                          className="border border-1"
                          placeholder="Campaign Name"
                          name="title"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12" className="mb-3">
                      <Form.Group>
                        <label className="label-style">Campaign ID</label>
                        <Form.Control
                          className="border border-1"
                          placeholder="Campaign ID"
                          name="title"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                  </Col>
                  <Col md="6" className="mb-3">
                    {selectedCustomers.length > 0 && (
                      <div className="p-3 selected-customer-section mt-4">
                        <h6 className="mb-3">Selected Customers</h6>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary"
                          onClick={clearAllCustomers}
                        >
                          Clear All
                        </button>
                        <div
                          className="d-flex flex-column flex-wrap gap-2"
                          style={{
                            maxHeight: "300px", // Fixed height for selected customers
                            overflowY: "auto", // Makes it scrollable
                            paddingRight: "10px", // Add some padding for the scrollbar
                          }}
                        >
                          {selectedCustomers.map((customer) => (
                            <div
                              key={customer.id}
                              className="bg-light rounded p-2 d-flex align-items-center mb-1"
                            >
                              <span className="mx-2">{customer.name}</span>
                              <button
                                type="button"
                                className="btn btn-sm p-0 border-0 mx-2"
                                onClick={() => removeCustomer(customer.id)}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>

                <Row className="mt-4">
                  <h6 className="text-primary mb-4 mx-4">Upload Attachments</h6>
                  <Card className="p-3 mb-4 mx-3 attachments-card">
                    {fileBlocks.map((block, index) => (
                      <div key={block.id} className="mb-4">
                        <Form.Control
                          className="form-control file-input-container"
                          type="file"
                          accept=".jpg,.jpeg,.png,.pdf"
                          onChange={(e) =>
                            handleFileChange(block.id, e.target.files[0])
                          }
                        />

                        {/* Preview Section */}
                        {block.preview && (
                          <div className="mt-3">
                            <img
                              src={block.preview}
                              alt="Preview"
                              className="file-preview-image"
                            />
                          </div>
                        )}

                        {/* File Name Section for PDFs */}
                        {block.name && (
                          <div className="mt-2 text-secondary">
                            <strong>Selected File:</strong> {block.name}
                          </div>
                        )}

                        {/* Remove Button */}
                        {index > 0 && (
                          <Button
                            variant="outline-danger"
                            className="mt-2"
                            onClick={() => handleRemoveFileBlock(block.id)}
                          >
                            Remove
                          </Button>
                        )}

                        {/* Add Button */}
                        {index === fileBlocks.length - 1 && (
                          <Button
                            variant="outline-secondary"
                            className="mt-2"
                            onClick={handleAddFileBlock}
                          >
                            Add More
                          </Button>
                        )}
                      </div>
                    ))}
                  </Card>
                </Row>

                <Row>
                  <Col md="12" className="mb-3">
                    <Form.Group>
                      <label className="label-style">Description</label>
                      <ReactQuill
                        value={editorData}
                        onChange={setEditorData}
                        theme="snow" // Use "bubble" for a minimal UI
                        style={{ height: "300px", marginBottom: "30px" }}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
                            [{ font: [] }], // Font options
                            [{ size: [] }], // Font size
                            [{ color: [] }, { background: [] }], // Text color & background
                            ["bold", "italic", "underline", "strike"], // Basic text styles
                            [{ script: "sub" }, { script: "super" }], // Subscript & superscript
                            [{ align: [] }], // Text alignment
                            [{ list: "ordered" }, { list: "bullet" }], // Lists
                            [{ indent: "-1" }, { indent: "+1" }], // Indentation
                            ["blockquote", "code-block"], // Blockquote & code block
                            ["link", "image", "video"], // Media links
                            ["clean"], // Remove formatting
                          ],
                        }}
                        formats={[
                          "header",
                          "font",
                          "size",
                          "color",
                          "background",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "script",
                          "align",
                          "list",
                          "bullet",
                          "indent",
                          "blockquote",
                          "code-block",
                          "link",
                          "image",
                          "video",
                        ]}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-4 d-flex mt-4">
                  <Col md="6" className="pt-1">
                    <div className="border rounded p-3">
                      <h6 className="mb-3">Communication Methods</h6>
                      <div
                        className="d-flex gap-4 mt-4"
                        style={{ justifyContent: "center" }}
                      >
                        <Form.Check
                          type="checkbox"
                          id="whatsapp"
                          className="d-flex align-items-center gap-2 mx-4 custom-checkbox"
                          label={
                            <div className="d-flex align-items-center gap-2">
                              <WhatsappLogo
                                size={24}
                                className="text-success"
                              />
                              <span>WhatsApp</span>
                            </div>
                          }
                          checked={communicationMethods.whatsapp}
                          onChange={(e) =>
                            setCommunicationMethods({
                              ...communicationMethods,
                              whatsapp: e.target.checked,
                            })
                          }
                        />

                        <Form.Check
                          type="checkbox"
                          id="sms"
                          className="d-flex align-items-center gap-2 mx-4 custom-checkbox"
                          label={
                            <div className="d-flex align-items-center gap-2">
                              <MessageCircle
                                size={24}
                                className="text-primary"
                              />
                              <span>SMS</span>
                            </div>
                          }
                          checked={communicationMethods.sms}
                          onChange={(e) =>
                            setCommunicationMethods({
                              ...communicationMethods,
                              sms: e.target.checked,
                            })
                          }
                        />

                        <Form.Check
                          type="checkbox"
                          id="email"
                          className="d-flex align-items-center gap-2 mx-4 custom-checkbox"
                          label={
                            <div className="d-flex align-items-center gap-2">
                              <Mail size={24} className="text-danger" />
                              <span>Email</span>
                            </div>
                          }
                          checked={communicationMethods.email}
                          onChange={(e) =>
                            setCommunicationMethods({
                              ...communicationMethods,
                              email: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="border rounded px-3 py-2">
                      <h6 className="mb-3">Communication Methods</h6>
                      <Form.Group>
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: "20px", justifyContent: "center" }}
                        >
                          <Form.Check
                            type="radio"
                            id="sendNow"
                            name="whatsappSendOption"
                            className="custom-checkbox d-flex align-items-center"
                            onChange={() => setScheduleVisible(false)}
                            defaultChecked
                            label={
                              <label
                                htmlFor="sendNow"
                                className="d-flex align-items-center gap-2 mt-2"
                                style={{ cursor: "pointer", gap: "4px" }}
                              >
                                <Send
                                  fontSize="small"
                                  className="text-primary"
                                />
                                <span>Send Now</span>
                              </label>
                            }
                          />

                          <Form.Check
                            type="radio"
                            id="schedule"
                            name="whatsappSendOption"
                            className="mx-3 custom-checkbox d-flex align-items-center"
                            onChange={() => setScheduleVisible(true)}
                            label={
                              <label
                                htmlFor="schedule"
                                className="d-flex align-items-center gap-2 mt-2"
                                style={{ cursor: "pointer", gap: "4px" }}
                              >
                                <Schedule
                                  fontSize="small"
                                  className="text-secondary"
                                />
                                <span>Schedule</span>
                              </label>
                            }
                          />
                        </div>
                        {scheduleVisible && (
                          <div className="mt-3">
                            <h6 className="text-center">
                              Schedule Date and Time
                            </h6>
                            <div
                              className="d-flex mt-1"
                              style={{ gap: "20px", justifyContent: "center" }}
                            >
                              <DatePicker
                                selected={scheduledDate}
                                onChange={(date) => setScheduledDate(date)}
                                className="form-control"
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select date"
                                minDate={new Date()}
                              />
                              <DatePicker
                                selected={scheduledTime}
                                onChange={(time) => setScheduledTime(time)}
                                className="form-control"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={10}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                placeholderText="Select time"
                              />
                            </div>
                          </div>
                        )}
                      </Form.Group>
                    </div>
                  </Col>
                </Row>

                <div className="d-flex justify-content-end mt-2">
                  <button
                    type="button"
                    className="btn-1 border border-2 border-primary mt-0 me-2"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn-3 btn btn-primary mt-0">
                    Create
                  </button>
                </div>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NewCampaign;
