import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Button, Table } from "react-bootstrap";
import { BASE_URL } from "../CommonApi";
import { AES, enc } from "crypto-js";
import NoDataImage from "../../../asset/images/nodata.jpg";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import swal from "sweetalert";
import $ from "jquery";
import UpdatePassword from "./UpdatePassword";

const PasswordManager = ({ navigation }) => {
  const [passwordData, setPasswordData] = useState([]);
  const [passwordId, setPasswordId] = useState("");
  const [showCard, setShowCard] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const fetchData = () => {
    setLoading(true);
    $("#DataTables_Table_0").DataTable();

    fetch(`${BASE_URL}/findPassMangerByUniqueId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Current Password Data:", data);
        const formattedData = Array.isArray(data.data)
          ? data.data
          : [data.data];
        setPasswordData(formattedData || []);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching password data:", error);
        $("#DataTables_Table_0").DataTable();
      });
  };

  // Add useEffect to fetch data on component mount
  useEffect(() => {
    fetchData();
    console.log("password Data : ", passwordData);
  }, []);

  const handleDelete = (id) => {
    console.log("Password ID:", id);
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this password!"
    );

    if (userConfirmed) {
      setLoading(true);
      fetch(`${BASE_URL}/deletePassManager`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          id: id,
          uniqueId: decryptedUniqueId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.message === "Password deleted successfully") {
            setLoading(false);
            swal({
              icon: "success",
              title: "Password Deleted Successfully",
            });
            fetchData(); // Refresh the data after deletion
          } else {
            setLoading(false);
            swal({
              icon: "error",
              title: "Failed to Delete Password",
              text: data.message || "An error occurred",
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
          swal({
            icon: "error",
            title: "An Error Occurred While Deleting Password",
          });
        });
    }
  };

  const handleUpdate = (id) => {
    setPasswordId(id);
    setShowUpdate(true);
    setShowCard(false);
  };

  const handleCancelUpdate = () => {
    setShowCard(true);
    setShowUpdate(false);
    fetchData();
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container-fluid">
      {!showUpdate && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Password Manager
            </div>
            <div className="button-container d-flex">
              <Button
                className="btn btn-primary px-1 py-2 me-2 mr-1"
                onClick={() => navigation.navigate("AddPassword")}
              >
                + New Password
              </Button>
            </div>
          </div>
        </div>
      )}

      {showCard && (
        <div
          className="container-fluid"
          style={{ border: "none", borderRadius: "7px" }}
        >
          <div className="page-content ">
            <Container fluid>
              {/* <Card className="card-style p-3"> */}
              <Row>
                <Col>
                  <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    {passwordData && passwordData.length > 0 ? (
                      <div className="body">
                        <div
                          className="table-responsive1 dataTables_wrapper"
                          id="DataTables_Table_0_wrapper"
                        >
                          <Table
                            className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                            id="DataTables_Table_0"
                          >
                            <thead className="heading-text">
                              <tr role="row">
                                <th
                                  className="sorting_asc"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-sort="ascending"
                                >
                                  #
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Link
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  User ID
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Password
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Description
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Visibility
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text1">
                              {passwordData.map((item, index) => (
                                <tr key={item.id} role="row" className="odd">
                                  <td>{index + 1}</td>
                                  <td className="sorting_1">
                                    {item.link || "N/A"}
                                  </td>
                                  <td>{item.userId || "N/A"}</td>
                                  <td>{item.password || "N/A"}</td>
                                  <td>{item.description || "N/A"}</td>
                                  <td>{item.makeVisible || "N/A"}</td>
                                  <td>
                                    <button
                                      variant="warning"
                                      size="sm"
                                      className="btn icon-border btn-sm mx-1"
                                      onClick={() => handleUpdate(item.id)}
                                    >
                                      <i className="fa fa-edit"></i>
                                    </button>
                                    <button
                                      variant="danger"
                                      size="sm"
                                      className="btn icon-border btn-sm mx-1"
                                      onClick={() => handleDelete(item.id)}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    ) : (
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                      >
                                        #
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        Link
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        User ID
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        Password
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        Description
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        Visibility
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                                <h6 className="text-center">
                                  <img
                                    src={NoDataImage}
                                    style={{ width: "50%", height: "50%" }}
                                    alt="No Data Logo"
                                  />{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
              {/* </Card> */}
            </Container>
          </div>
        </div>
      )}

      {showUpdate && (
        <UpdatePassword
          handleCancel={handleCancelUpdate}
          passwordId={passwordId}
        />
      )}
    </div>
  );
};

export default PasswordManager;
