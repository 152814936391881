import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Table,
  Container,
} from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NoDataImage from "../../../asset/images/nodata.jpg";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import $ from "jquery";
import UpdateCategory from "./UpdateCategory";

function Category({ navigation }) {
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  const jwtToken = localStorage.getItem("jwtToken");

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const fetchData = () => {
    setLoading(true);
    $("#DataTables_Table_0").DataTable();
    fetch(`${BASE_URL}/getAllCategoryData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setCategoryData(data.data || []);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching Category data:", error);
        $("#DataTables_Table_0").DataTable();
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [showCard, setShowCard] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [categoryId, setCategoryId] = useState();

  const handleUpdate = (id) => {
    setCategoryId(id);
    setShowUpdate(true);
    setShowCard(false);
  };

  const handleCancelUpdate = () => {
    setShowCard(true);
    setShowUpdate(false);
    fetchData();
  };

  const handleNavigation = (key) => {
    console.log("nav kay === : ", key);
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleCancel = () => {
    console.log("going to items");
    handleNavigation("items");
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      console.log("confirmed.....");
      handleCancel();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this item!"
    );

    setLoading(true);
    if (userConfirmed) {
      fetch(`${BASE_URL}/deleteCategory/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.status === "success") {
            setLoading(false);
            swal({
              icon: "success",
              title: "Category Deleted Successfully",
            });
            fetchData();
          } else {
            setLoading(false);
            swal({
              icon: "error",
              title: "Failed to Delete Category",
              text: data.message || "An error occurred",
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
          swal({
            icon: "error",
            title: "An Error Occurred While Deleting Category",
          });
        });
    }
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      {!showUpdate && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center mx-1">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                }}
              >
                <ArrowBackIosIcon
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <div
                className="mx-2"
                style={{
                  color: "#3C7BD4",
                  fontSize: 20,
                  fontWeight: "600",
                  wordWrap: "break-word",
                  textAlign: "left",
                }}
              >
                Category
              </div>
            </div>
            <div className="button-container d-flex">
              <Button
                className="btn btn-primary px-1 py-2 me-2 mr-1"
                onClick={() => navigation.navigate("AddCategory")}
              >
                + New Category
              </Button>
            </div>
          </div>
        </div>
      )}

      {showCard && (
        <div
          className="container-fluid"
          style={{ border: "none", borderRadius: "7px" }}
        >
          <div className="page-content ">
            <Container fluid>
              {/* <Card className="card-style p-3"> */}
              <Row>
                <Col>
                  <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    {categoryData && categoryData.length > 0 ? (
                      <div className="body">
                        <div
                          className="table-responsive1 dataTables_wrapper"
                          id="DataTables_Table_0_wrapper"
                        >
                          <Table
                            className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                            id="DataTables_Table_0"
                          >
                            <thead className="heading-text">
                              <tr role="row">
                                <th
                                  className="sorting_asc"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-sort="ascending"
                                >
                                  #
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Category
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Category Description
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text1">
                              {categoryData.map((item, index) => (
                                <tr key={item.id} role="row" className="odd">
                                  <td>{index + 1}</td>
                                  <td className="sorting_1">
                                    {item.category || "N/A"}
                                  </td>
                                  <td>{item.description || "N/A"}</td>

                                  <td>
                                    <button
                                      variant="warning"
                                      size="sm"
                                      className="btn icon-border btn-sm mx-1"
                                      onClick={() => handleUpdate(item.id)}
                                    >
                                      <i className="fa fa-edit"></i>
                                    </button>
                                    <button
                                      variant="danger"
                                      size="sm"
                                      className="btn icon-border btn-sm mx-1"
                                      onClick={() => handleDelete(item.id)}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    ) : (
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                      >
                                        #
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        Category
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        Category Description
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                                <h6 className="text-center">
                                  <img
                                    src={NoDataImage}
                                    style={{ width: "50%", height: "50%" }}
                                    alt="No Data Logo"
                                  />{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
              {/* </Card> */}
            </Container>
          </div>
        </div>
      )}

      {showUpdate && (
        <UpdateCategory
          handleCancel={handleCancelUpdate}
          categoryId={categoryId}
        />
      )}
    </div>
  );
}

export default Category;
