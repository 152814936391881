import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";

const EmployeeDetails = ({ id, onCancel }) => {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");

  const [employeeData, setEmployeeData] = useState(null);
  const [documents, setDocuments] = useState({});


  const documentNameMapping = {
    addressProof: "Address Proof",
    panCard: "PAN Card",
    aadharCard: "Aadhar Card",
    bankPassbook: "Bank Passbook",
    postGraduateMarksheet: "Post Graduate Marksheet",
    graduationOrDiplomaMarksheet: "Graduation/Diploma Marksheet",
    twelfthOrDiplomaMarksheet: "HSC/Diploma Marksheet", // Corrected display name
    additionalCertificate: "Additional Certificate",
    experienceLetter: "Experience Letter",
  };


  const [empName, setEmpName] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState(null);
  const [contactNumber, setContactNumber] = useState("");
  const [alternateContact, setAlternateContact] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [salary, setSalary] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const [parentName, setParentName] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [permanentAddresss, setPermanentAddress] = useState("");
  const [highestQualification, setHightestQualification] = useState("");
  const [previousCompany, setPreviousCompany] = useState("");
  const [previousDesignation, setPreviousDesignation] = useState("");

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/getHrsDataById`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ id: String(id) }),
        });
        const data = await response.json();
        if (response.ok) {
          const Data = data.data;
          setEmpName(Data.name);
          setDepartment(Data.department);
          setDesignation(Data.designation);
          setDateOfJoining(Data.dateOfJoining);
          setContactNumber(Data.contactNumber);
          setAlternateContact(Data.alternateNumber);
          setEmail(Data.personalEmail);
          setDob(Data.dateOfBirth);
          setBloodGroup(Data.bloodGroup);
          setSalary(Data.salary);
          setSpouseName(Data.spouseName);
          setParentName(Data.parentName);
          setCurrentAddress(Data.currentAddress);
          setPermanentAddress(Data.permanentAddress);
          setPreviousCompany(Data.previousCompanyName);
          setPreviousDesignation(Data.previousCompanyDesignation);
          setHightestQualification(Data.highestQualification);

          const documentFiles = {
            addressProof: data.data.addressProofFile,
            panCard: data.data.panCardFile,
            aadharCard: data.data.adharCardFile,
            bankPassbook: data.data.bankPassbookFile,
            postGraduateMarksheet: data.data.postGraduateMarksheetFile,
            graduationOrDiplomaMarksheet:
              data.data.graduationOrDiplomaMarksheetFile,
            twelfthOrDiplomaMarksheet: data.data.twelfthOrDiplomaMarksheetFile,
            additionalCertificate: data.data.additionalCertificateFile,
            experienceLetter: data.data.experienceLetterFile,
          };
          setDocuments(documentFiles);
        } else {
          console.error("Failed to fetch employee data");
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchEmployeeData();
  }, [id]);

  const handleCancleClick = () => {
    onCancel();
  };

  const openDocument = (fileName) => {
    // Construct the full URL to the document
    const documentUrl = `https://qabooks.zarp.software/uploads/hrsEmployeeData/${fileName}`;
    window.open(documentUrl, "_blank");
  };
  return (
    <Container fluid className="py-4">
      <Row>
        <Col>
          <div className="my-3">
            <div className="px-2 d-flex">
              <div className="cursor-pointer d-flex justify-content-center align-items-center back-button">
                <ArrowBackIosIcon
                  width={2}
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <h5
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                Employee Details
              </h5>
            </div>
          </div>

          <Card className="shadow-sm card-style">
            <Card.Body>
              <Row>
                <Col md={6}>
                  <div
                    className="bg-light p-3 rounded mb-3"
                    style={{
                      // maxHeight: "370px",
                      height: "100%",
                    }}
                  >
                    <h5 className="text-primary mb-3">Employee Information</h5>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Employee ID:</strong> {id}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Employee Name:</strong> {empName}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Employee Department:</strong> {department}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Employee Designation:</strong> {designation}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Joining Date:</strong> {dateOfJoining}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Employee Department:</strong> {department}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Employee Email:</strong> {salary}
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div
                    className="bg-light p-3 rounded mb-3"
                    style={{
                      // maxHeight: "370px",
                      height: "100%",
                    }}
                  >
                    <h5 className="text-primary mb-3">Personal Information</h5>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Employee Email:</strong> {email}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Employee Contact:</strong> {contactNumber}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Employee Alternate Contact:</strong>{" "}
                      {alternateContact}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Employee Date of Birth:</strong> {dob}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Employee Designation:</strong> {bloodGroup}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Joining Date:</strong> {spouseName}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Employee Department:</strong> {parentName}
                    </p>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={6}>
                  <div
                    className="bg-light p-3 rounded mb-3"
                    style={{
                      height: "100%",
                    }}
                  >
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Permenent Address:</strong> {permanentAddresss}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Current Address:</strong> {currentAddress}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Highest Qualification:</strong>{" "}
                      {highestQualification}
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div
                    className="bg-light p-3 rounded mb-3"
                    style={{
                      height: "100%",
                    }}
                  >
                    <h5 className="text-primary mb-3">
                      Previous Company Information
                    </h5>

                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Previous Company Name:</strong> {previousCompany}
                    </p>
                    <p className="p-3 mb-2 border-bottom border-1 border-grey">
                      <strong>Previous Company Designation:</strong>{" "}
                      {previousDesignation}
                    </p>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <h5 className="text-primary mb-3">Employee Documents</h5>
                  <div className="bg-light p-3 rounded">
                    {/* <Row>
                      {Object.entries(documents).map(([docName, fileName]) => (
                        <Col md={4} key={docName} className="mb-2">
                          <button
                            onClick={() => openDocument(fileName)}
                            className="w-100 btn btn-primary"
                          >
                            {docName
                              .replace(/([A-Z])/g, " $1")
                              .replace(/^./, function (str) {
                                return str.toUpperCase();
                              })}
                          </button>
                        </Col>
                      ))}
                    </Row> */}
                    <Row>
                      {Object.entries(documents).map(([docName, fileName]) => (
                        <Col md={4} key={docName} className="mb-2">
                          <div
                            className="position-relative w-100"
                            title={
                              fileName
                                ? "Click to view document"
                                : "Document not available"
                            }
                          >
                            <button
                              onClick={() => fileName && openDocument(fileName)}
                              className={`w-100 btn ${
                                fileName ? "btn-primary" : "btn-danger"
                              }`}
                              style={{
                                cursor: fileName ? "pointer" : "not-allowed",
                              }}
                            >
                              {/* {docName
                                .replace(/([A-Z])/g, " $1")
                                .replace(/^./, (str) => str.toUpperCase())} */}
                                {documentNameMapping[docName] || docName}
                            </button>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EmployeeDetails;
