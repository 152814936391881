import React from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import swal from "sweetalert";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import {
  AccountBalance,
  CalendarMonth,
  CorporateFare,
  CreditCard,
  Person,
  ReceiptLong,
} from "@mui/icons-material";

const AddCheque = ({ navigation }) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize with current date

  const [formData, setFormData] = useState({
    uniqueId: decryptedUniqueId || "",
    chequeNo: "",
    partyBank: "",
    amount: "",
    partyName: "",
    firmNameBank: "",
    date: new Date().toISOString().split("T")[0],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleCancel = () => {
    handleNavigation("Cheque");
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleCancel();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const handleCreateCheque = (e) => {
    e.preventDefault();
    const requestBody = {
      uniqueId: formData.uniqueId,
      chequeNo: formData.chequeNo,
      partyBank: formData.partyBank,
      amount: formData.amount,
      partyName: formData.partyName,
      firmNameBank: formData.firmNameBank,
      date: formData.date,
    };
    console.log("Request Body: ", requestBody);
    setLoading(true);
    fetch(`${BASE_URL}/createCheque`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.message === "Cheque entry created successfully") {
          swal({
            icon: "success",
            title: "Cheque Created Successfully",
          });
        } else {
          swal({
            icon: "error",
            title: "Failed to Create Cheque",
          });
        }
        handleCancel();
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occurred While Creating Cheque",
        });
      });
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col>
          <div className="my-3">
            <div className="d-flex align-items-center ml-1 mt-4">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                }}
              >
                <ArrowBackIosIcon
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5 className="ml-1 mb-0" style={{ color: "#086bd5de" }}>
                  Cheque Register
                </h5>
              </div>
            </div>
          </div>

          <Card className="card-style p-4">
            <Row>
              <Col md="3">
                <Form.Group>
                  <label className="text-primary" style={{ fontWeight: "600" }}>
                    Cheque Details
                  </label>
                </Form.Group>
              </Col>
            </Row>

            <Form>
              <Row>
                {/* From Name */}
                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Party Name</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Person sx={{ fill: "#9A9A9A" }} />
                      <Form.Control
                        placeholder="Enter Party Name"
                        name="partyName"
                        value={formData.partyName}
                        onChange={handleInputChange}
                        style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                      />
                    </div>
                  </Form.Group>
                </Col>

                {/* Transport or Courier */}
                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Cheque Number</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <ReceiptLong sx={{ fill: "#9A9A9A" }} />
                      <Form.Control
                        placeholder="Enter Cheque Number"
                        name="chequeNo"
                        value={formData.chequeNo}
                        onChange={handleInputChange}
                        style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                      />
                    </div>
                  </Form.Group>
                </Col>

                {/* Tracking Number */}
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Amount</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <CreditCard sx={{ fill: "#9A9A9A" }} />
                      <Form.Control
                        placeholder="Enter Amount"
                        name="amount"
                        value={formData.amount}
                        onChange={handleInputChange}
                        style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Party Bank Name</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <AccountBalance sx={{ fill: "#9A9A9A" }} />
                      <Form.Control
                        name="partyBank"
                        placeholder="Enter  Party Bank Name"
                        value={formData.partyBank}
                        onChange={handleInputChange}
                        style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                {/* Received by */}
                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Firm Name</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <CorporateFare sx={{ fill: "#9A9A9A" }} />
                      <Form.Control
                        name="firmNameBank"
                        placeholder="Enter Firm Name"
                        value={formData.firmNameBank}
                        onChange={handleInputChange}
                        style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                      />
                    </div>
                  </Form.Group>
                </Col>

                {/* Checked by */}
                <Col md="6">
                  <Form.Group className="d-flex flex-column new-campaign-time w-100">
                    <label style={{ color: "grey" }}>Date</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <CalendarMonth sx={{ fill: "#9A9A9A" }} />
                      <div className="customDatePickerWidth">
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => {
                            setSelectedDate(date);
                            setFormData({
                              ...formData,
                              date: date.toISOString().split("T")[0],
                            });
                          }}
                          className="form-control date-control"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="Select date"
                        />
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>

            <hr className="mt-4" />

            <div className="text-right invoice-bottombtns">
              <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
                Cancel
              </button>
              {/* <button className="btn-1 btn mt-0">Save as Draft</button> */}
              <button
                className="btn-3 btn btn-primary"
                onClick={handleCreateCheque}
              >
                Create Cheque
              </button>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddCheque;
