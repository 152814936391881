import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import Select from "react-select";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function EnquiryUpdate({ navigation }) {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleEnquiryClick = () => {
    handleNavigation("Enquiry");
  };
  const [custNames, setCustNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(""); // State to store the selected customer's ID
  const [startDate, setStartDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const [enquiryId, setEnquiryId] = useState("");

  useEffect(() => {
    setStartDate(new Date());
  }, []);
  const handleDate = (date) => {
    setStartDate(date);
  };
  const fetchAllCustomers = () => {
    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };
  useEffect(() => {
    fetchAllCustomers();
  }, []);

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure you want to cancel?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleEnquiryClick();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const options = custNames.map((customer) => ({
    value: customer.id,
    label: customer.customerName,
  }));
  const handleCustomerChange = (value, id) => {
    const selectedValue = value;
    const selectedValueId = id;
    setSelectedCustomer(selectedValue);
    setSelectedCustomerId(selectedValueId);
    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );

    if (selectedCustomerObject) {
      const customerState = selectedCustomerObject.billState;
      const custEmail = selectedCustomerObject.email;
      //   setCustomerEmail(custEmail);
      //   setCustomerState(customerState);
    } else {
      console.log("Selected Customer: (Not found)");
    }
  };

  const [loading, setLoading] = useState(false);
  const [fileBlocks, setFileBlocks] = useState([
    { id: Date.now(), file: null, preview: null, name: null },
  ]);

  const handleAddFileBlock = () => {
    setFileBlocks([
      ...fileBlocks,
      { id: Date.now(), file: null, preview: null, name: null },
    ]);
  };

  const handleRemoveFileBlock = (id) => {
    setFileBlocks(fileBlocks.filter((block) => block.id !== id));
  };

  const handleFileChange = (id, file) => {
    if (!file) return;

    const fileType = file.type;
    const fileName = file.name;

    if (fileType.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileBlocks((blocks) =>
          blocks.map((block) =>
            block.id === id
              ? { ...block, file, preview: e.target.result, name: null }
              : block
          )
        );
      };
      reader.readAsDataURL(file);
    } else if (fileType === "application/pdf") {
      setFileBlocks((blocks) =>
        blocks.map((block) =>
          block.id === id
            ? { ...block, file, preview: null, name: fileName }
            : block
        )
      );
    } else {
      setFileBlocks((blocks) =>
        blocks.map((block) =>
          block.id === id
            ? { ...block, file, preview: null, name: "Unsupported file type" }
            : block
        )
      );
    }
  };
  const [validationMessage, setValidationMessage] = useState("");

  if (loading) {
    return (
      <div className="center-div">
        <div className="spinner"></div>
      </div>
    );
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);

      // Prepare FormData
      const formData = new FormData();
      const inquiryData = {
        customerId: selectedCustomerId,
        customerName: selectedCustomer,
        uniqueId: decryptedUniqueId,
        description,
        createdDate: startDate.toISOString().split("T")[0], // Format: YYYY-MM-DD
      };

      // Append JSON as a string
      formData.append("inquiry", JSON.stringify(inquiryData));

      // Append files
      fileBlocks.forEach((block, index) => {
        if (block.file) {
          formData.append(`files[${index}]`, block.file);
        }
      });

      // Send the data
      const response = await fetch(BASE_URL + "/addInquiry", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`, // JWT token
        },
        body: formData, // Attach FormData
      });

      const result = await response.json();

      if (response.ok) {
        swal({
          icon: "success",
          title: "Enquiry Inserted Successfully",
        });
        handleEnquiryClick();
      } else {
        swal({
          icon: "error",
          title: "Failed to insert item",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error submitting inquiry: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <Row>
          <Col>
            <div className="my-3">
              <div className="d-flex align-items-center ml-1 mt-4">
                <div
                  className="cursor-pointer d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    height: "35px",
                    width: "38px",
                  }}
                >
                  <ArrowBackIosIcon
                    width={2}
                    onClick={handleCancleClick}
                    className="arrow-child"
                  />
                </div>
                <div className="newcustomer-head ml-2">
                  <h5
                    className="ml-1 mb-0"
                    style={{ letterSpacing: "1px", color: "#086bd5de" }}
                  >
                    New Enquiry
                  </h5>
                </div>
              </div>
            </div>
            {validationMessage && (
              <Alert severity="error" className="mt-0">
                {validationMessage.split("\n").map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </Alert>
            )}{" "}
            <Card className="card-style p-4">
              <Row>
                <Col className="" md="3">
                  <Form.Group>
                    <label
                      className="text-primary"
                      style={{ fontSize: 16, fontWeight: "600" }}
                    >
                      CUSTOMER INFO
                    </label>
                  </Form.Group>
                </Col>
              </Row>
              <Form>
                <Row>
                  <Col className="" md="3">
                    <Form.Group>
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Customer Name <span className="valid"> *</span>
                      </label>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <Select
                        className="mt-0"
                        options={options}
                        value={options.find(
                          (option) => option.value === selectedCustomer
                        )}
                        onChange={(selectedOption) =>
                          handleCustomerChange(
                            selectedOption.label,
                            selectedOption.value
                          )
                        }
                        placeholder="Select Customer"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            height: "46px",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            height: "19px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Form.Group>
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Enquiry Date <span className="valid"> *</span>
                      </label>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <div className="customDatePickerWidth">
                        <DatePicker
                          className="form-control"
                          selected={startDate}
                          dateFormat="dd-MM-yyyy"
                          onChange={(date) => handleDate(date)}
                          showIcon
                          toggleCalendarOnIconClick
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Form.Group>
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Tracking No <span className="valid"> *</span>
                      </label>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Control
                      placeholder="Enter Tracking No."
                      type="text"
                      name="enquiryId"
                      value={enquiryId}
                      onChange={(e) => setEnquiryId(e.target.value)}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        color: "black",
                        height: "40px",
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="row pt-2">
                      <div className="col-lg-6">
                        <label
                          className="form-label"
                          style={{
                            fontSize: "14px",
                            color: "grey",
                            borderRadius: "7px",
                          }}
                        >
                          Discrption :
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12 pl-0 pr-0">
                      {/* <Editor
                        apiKey="4h8oo2lv4rl9gi28mb7hv90bwsf4k369lnf0gmxragfpofwj"
                        init={{
                          branding: false,
                          height: 500,
                          menubar: true,
                          plugins:
                            "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                          toolbar:
                            "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                          image_advtab: true,
                        }}
                        value={description}
                        onEditorChange={setDescription}
                      /> */}
                      <ReactQuill
                        value={description} // Replace with your state variable
                        onChange={setDescription} // Function to update state
                        theme="snow" // Use "bubble" for a minimal UI
                        style={{ height: "300px", marginBottom: "50px" }}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
                            [{ font: [] }], // Font options
                            [{ size: [] }], // Font size
                            [{ color: [] }, { background: [] }], // Text color & background
                            ["bold", "italic", "underline", "strike"], // Basic text styles
                            [{ script: "sub" }, { script: "super" }], // Subscript & superscript
                            [{ align: [] }], // Text alignment
                            [{ list: "ordered" }, { list: "bullet" }], // Lists
                            [{ indent: "-1" }, { indent: "+1" }], // Indentation
                            ["blockquote", "code-block"], // Blockquote & code block
                            ["link", "image", "video"], // Media links
                            ["clean"], // Remove formatting
                          ],
                        }}
                        formats={[
                          "header",
                          "font",
                          "size",
                          "color",
                          "background",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "script",
                          "align",
                          "list",
                          "bullet",
                          "indent",
                          "blockquote",
                          "code-block",
                          "link",
                          "image",
                          "video",
                        ]}
                      />
                    </div>
                  </Col>{" "}
                </Row>
                <Row className="mt-4">
                  <h5 className="text-primary mb-4">Upload Attachments</h5>
                  <Card
                    className="p-3 mb-4"
                    style={{
                      backgroundColor: "#f9f9f9",
                      border: "1px solid #ddd",
                    }}
                  >
                    {fileBlocks.map((block, index) => (
                      <div key={block.id} className="mb-4">
                        <Form.Control
                          type="file"
                          accept=".jpg,.jpeg,.png,.pdf"
                          onChange={(e) =>
                            handleFileChange(block.id, e.target.files[0])
                          }
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            padding: "10px",
                          }}
                        />

                        {/* Preview Section */}
                        {block.preview && (
                          <div className="mt-3">
                            <img
                              src={block.preview}
                              alt="Preview"
                              style={{
                                maxWidth: "50%",
                                height: "200px",
                                borderRadius: "5px",
                              }}
                            />
                          </div>
                        )}

                        {/* File Name Section for PDFs */}
                        {block.name && (
                          <div className="mt-2 text-secondary">
                            <strong>Selected File:</strong> {block.name}
                          </div>
                        )}

                        {/* Remove Button */}
                        {index > 0 && (
                          <Button
                            variant="outline-danger"
                            className="mt-2"
                            onClick={() => handleRemoveFileBlock(block.id)}
                          >
                            Remove
                          </Button>
                        )}

                        {/* Add Button */}
                        {index === fileBlocks.length - 1 && (
                          <Button
                            variant="outline-secondary"
                            className="mt-2"
                            onClick={handleAddFileBlock}
                          >
                            Add More
                          </Button>
                        )}
                      </div>
                    ))}
                  </Card>
                </Row>
              </Form>
              <div className="text-right invoice-bottombtns">
                <button
                  type="button"
                  className="btn-1 btn mt-0"
                  disabled={loading}
                  onClick={handleCancleClick}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="btn-3 btn btn-primary"
                  onClick={handleSubmit}
                >
                  {isLoading ? "Saving..." : "Create Enquiry"}
                </button>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EnquiryUpdate;
