import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import swal from "sweetalert";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";

const UpdateDocument = ({ navigation, handleCancel, documentId, recordId }) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const UPLOAD_BASE_URL =
    "https://qabooks.zarp.software/uploads/otherDocument/";

  const [fileRows, setFileRows] = useState([
    {
      id: 1,
      file: null,
      preview: null,
      fileName: "No file choosen",
      textValue: "",
    },
  ]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("document id : outer id : ", recordId);
    console.log("recordId : inner individual doc's id : ", documentId);
    if (documentId && recordId) {
      fetchDocumentDetails();
    } else {
      setLoading(false);
    }
  }, [documentId]);

  const fetchDocumentDetails = async () => {
    try {
      const response = await fetch(`${BASE_URL}/getOtherDocumentById`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          id: recordId,
          documentId: documentId,
          uniqueId: decryptedUniqueId,
        }),
      });

      const data = await response.json();

      if (data.message === "Document entries found" && data.data) {
        // Update the fileRows state with the fetched data
        if (
          data.data.fileName.endsWith(".jpg") ||
          data.data.fileName.endsWith(".jpeg") ||
          data.data.fileName.endsWith(".png")
        ) {
          setFileRows([
            {
              id: 1,
              file: null,
              preview: `${UPLOAD_BASE_URL}${data.data.fileName}`,
              fileName: data.data.fileName.split("_").slice(1).join("_"), // Remove prefix
              textValue: data.data.description || "",
            },
          ]);
        } else {
          setFileRows([
            {
              id: 1,
              file: null,
              preview: null,
              fileName: data.data.fileName.split("_").slice(1).join("_"), // Remove prefix
              textValue: data.data.description || "",
            },
          ]);
        }
        console.log(data.data.fileName);
      } else {
        console.error("Error fetching document data");
        swal({
          icon: "error",
          title: "Failed to fetch document details",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      swal({
        icon: "error",
        title: "An Error Occurred While Fetching Document Details",
      });
    } finally {
      setLoading(false);
    }
  };
  {
    /*
   const handleFileChange = (rowId, event) => {
    const file = event.target.files[0];
    setFileRows(prevRows =>
      prevRows.map(row =>
        row.id === rowId
          ? { ...row, file: file, fileName: file.name }
          : row
      )
    );
  };*/
  }
  const handleFileChange = (id, file) => {
    if (!file) return;

    const fileType = file.type;
    const fileName = file.name;
    if (fileType.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileRows((blocks) =>
          blocks.map((block) =>
            block.id === id
              ? {
                  ...block,
                  file: file,
                  preview: e.target.result,
                  fileName: null,
                  textValue: "",
                }
              : block
          )
        );
      };
      reader.readAsDataURL(file);
    } else if (fileType === "application/pdf") {
      setFileRows((blocks) =>
        blocks.map((block) =>
          block.id === id
            ? {
                ...block,
                file: file,
                preview: null,
                fileName: fileName,
                textValue: "",
              }
            : block
        )
      );
    } else {
      setFileRows((blocks) =>
        blocks.map((block) =>
          block.id === id
            ? {
                ...block,
                file,
                preview: null,
                fileName: "Unsupported file type",
                textValue: "",
              }
            : block
        )
      );
    }
  };

  const handleTextChange = (rowId, event) => {
    setFileRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId ? { ...row, textValue: event.target.value } : row
      )
    );
  };

  const handleAddMore = () => {
    const newId = Math.max(...fileRows.map((row) => row.id)) + 1;
    setFileRows([
      ...fileRows,
      { id: newId, file: null, fileName: "No file chosen", textValue: "" },
    ]);
  };

  const handleRemove = (rowId) => {
    if (fileRows.length > 1) {
      setFileRows(fileRows.filter((row) => row.id !== rowId));
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      const requestBody = {
        id: recordId,
        uniqueId: decryptedUniqueId,
        otherDocumentDteailId: documentId,
      };

      formData.append("request", JSON.stringify(requestBody));

      fileRows.forEach((row, index) => {
        if (row.file) {
          formData.append(`files`, row.file);
          formData.append(`descriptions`, row.textValue);
        }
      });

      const response = await fetch(`${BASE_URL}/updateSingleOtherDocument`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (data.message === "Document  updated successfully") {
        swal({
          icon: "success",
          title: "Documents Updated Successfully",
        });
        handleCancel();
      } else {
        throw new Error(data.message || "Failed to update documents");
      }
    } catch (error) {
      console.error("Error:", error);
      swal({
        icon: "error",
        title: "Failed to Update Documents",
        text: error.message,
      });
    }
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm(
      "Are you sure you want to leave this page?"
    );
    if (confirmCancel) {
      handleCancel();
    }
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="my-3">
        <div className="d-flex align-items-center ml-1 mt-4">
          <div
            className="cursor-pointer d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              height: "35px",
              width: "38px",
            }}
          >
            <ArrowBackIosIcon
              width={2}
              onClick={handleCancleClick}
              className="arrow-child"
            />
          </div>
          <div className="newcustomer-head ml-2">
            <h5
              className="ml-1 mb-0"
              style={{ letterSpacing: "1px", color: "#086bd5de" }}
            >
              Update Document
            </h5>
          </div>
        </div>
      </div>

      {/* <Card className="card-style p-4">
        {fileRows.map((row) => (
          <Row key={row.id} className="mb-3 align-items-center">
            <Col xs={12} md={6}>
              <div className="custom-file-upload d-flex">
                <Button
                  as="label"
                  htmlFor={`file-upload-${row.id}`}
                  variant="primary"
                  className="mb-0 mr-2"
                  style={{
                    backgroundColor: "#f6f6f6",
                    borderColor: "black",
                    cursor: "pointer",
                    padding: "6px 12px",
                    borderRadius: "4px",
                  }}
                >
                  Choose File
                </Button>
                <input
                  id={`file-upload-${row.id}`}
                  type="file"
                  className="d-none"
                  onChange={(e) => handleFileChange(row.id, e)}
                />
                <span
                  className="align-self-center ml-2"
                  style={{ color: "#5a6268" }}
                >
                  {row.fileName}
                </span>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <Form.Control
                type="text"
                placeholder="Description"
                value={row.textValue}
                onChange={(e) => handleTextChange(row.id, e)}
              />
            </Col>
            <Col xs={12} md={2}>
              <Button
                variant="danger"
                onClick={() => handleRemove(row.id)}
                className="w-100"
                disabled={fileRows.length === 1}
              >
                Remove
              </Button>
            </Col>
          </Row>
        ))}
<hr/>
        <div className="d-flex justify-content-between mt-3">
          { <Button
            variant="success"
            onClick={handleAddMore}
            style={{ backgroundColor: "#218838", borderColor: "#1e7e34" }}
          >
            Add More
          </Button> }

          <Button
            variant="primary"
            onClick={handleSubmit}
            style={{ backgroundColor: "#007bff", borderColor: "#0056b3" }}
          >
            Upload Documents
          </Button>
        </div>

        <p className="text-muted mt-2">
          You can upload multiple files with descriptions.
        </p>
      </Card>*/}

      <Card className="p-3 mb-4 mx-3 attachments-card">
        {fileRows.map((block, index) => (
          <div key={block.id} className="mb-4">
            <Row>
              <Col xs={12} md={6}>
                <Form.Control
                  className="form-control file-input-container"
                  type="file"
                  accept=".jpg,.jpeg,.png,.pdf"
                  onChange={(e) =>
                    handleFileChange(block.id, e.target.files[0])
                  }
                />
              </Col>
              <Col xs={12} md={4}>
                <Form.Control
                  type="text"
                  placeholder="Description"
                  value={block.textValue}
                  onChange={(e) => handleTextChange(block.id, e)}
                />
              </Col>
            </Row>
            {/* Preview Section */}
            {block.preview && (
              <div className="mt-3">
                <img
                  src={block.preview}
                  alt="preview"
                  className="file-preview-image"
                />
              </div>
            )}

            {/* File Name Section for PDFs */}
            {block.fileName && (
              <div className="mt-2 text-secondary">
                <strong>Selected File:</strong> {block.fileName}
              </div>
            )}

            {/* Remove Button */}
            {index > 0 && (
              <Button
                variant="outline-danger"
                className="mt-2"
                onClick={() => handleRemove(block.id)}
              >
                Remove
              </Button>
            )}

            {/* Add Button 
                                        {index === fileRows.length - 1 && (
                                          <Button
                                            variant="outline-secondary"
                                            className="mt-2"
                                            onClick={handleAddMore}
                                          >
                                            Add More
                                          </Button>
                                        )}*/}
          </div>
        ))}
        <Button
          variant="primary"
          onClick={handleSubmit}
          style={{ backgroundColor: "#007bff", borderColor: "#0056b3" }}
        >
          Upload Documents
        </Button>
      </Card>
    </div>
  );
};

export default UpdateDocument;
