import React from "react";
import { Card, Form, Row, Col, Button, Table } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import Select from "react-select";
import { FaTrash } from "react-icons/fa";

const AddProduction = ({ navigation }) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [materialsList, setMaterialsList] = useState([]);
  const [totalMaterialCost, setTotalMaterialCost] = useState(0);
  const [totalItemCost, setTotalItemCost] = useState(0);

  const [loading, setLoading] = useState(false);

  const [showSections, setShowSections] = useState(false);
  const [showMoreMaterials, setShowMoreMaterials] = useState([]);
  const [showNonInventoryCost, setShowNonInventoryCost] = useState([]);
  const [allProductList, setAllProductList] = useState([]);

  const [nonInventory, setNonInventory] = useState([]);
  const [rawMaterialList, setRawMaterialList] = useState([]);

  const [profitMargin, setProfitMargin] = useState(0);
  const [cgst, setCgst] = useState(18); // Default 18%
  const [sgst, setSgst] = useState(9); // Default 9%
  const [igst, setIgst] = useState(9); // Default 9%
  const [salePrice, setSalePrice] = useState(0);

  const hideArrowStyle = {
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
    backgroundImage: "none",
  };

  const costAndPricingStyle = {
    width: "30%",
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
    backgroundImage: "none",
    paddingRight: "8px",
    backgroundColor: "#f6f6f6",
  };

  const fetchMaterialData = () => {
    setLoading(true);
    fetch(`${BASE_URL}/getMaterial`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.status === "success" && data.responseMessage) {
          const options = data.responseMessage.map((item) => ({
            value: item.id, // Use materialId or id as the value
            label: item.materialName, // Use materialName as the label
          }));
          setMaterialsList(options);
        } else {
          setLoading(false);
          console.error("Error fetching indent data");
          setAllProductList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching indent data:", error);
      });
  };

  const fetchNonInventoryList = () => {
    setLoading(true);
    fetch(`${BASE_URL}/getData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        type: "nonInventory",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("fetched data : ", data);
        if (data.status === "success" && data.responseMessage) {
          const options = data.responseMessage.map((item) => ({
            value: item.id, // or use item.uniqueId if preferred
            label: item.name,
          }));
          setNonInventory(options);
          setLoading(false);
        } else {
          setLoading(false);
          console.error("Error fetching indent data");
          setNonInventory([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching Cheque data:", error);
      });
  };

  const fetchRawMaterialList = () => {
    setLoading(true);
    fetch(`${BASE_URL}/getRawMaterial`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        status: "rawMaterial",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("fetched raw data : ", data);
        if (data.status === "success" && data.responseMessage) {
          const options = data.responseMessage.map((item) => ({
            value: item.id, // or use item.uniqueId if preferred
            label: item.materialName,
          }));
          setRawMaterialList(options);
          setLoading(false);
        } else {
          setLoading(false);
          console.error("Error fetching indent data");
          setRawMaterialList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching Cheque data:", error);
      });
  };

  useEffect(() => {
    fetchMaterialData();
    fetchRawMaterialList();
    fetchNonInventoryList();
  }, []);

  const [showCheckStockButton, setShowCheckStockButton] = useState(false);
  const [showStockCheckData, setShowStockCheckData] = useState([]);

  const handleGoClick = (e) => {
    e.preventDefault();

    if (!formData.product) {
      swal({
        icon: "warning",
        title: "Product Not Added",
        text: "Please Select a Product.",
      });
      return;
    } else if (!formData.quantity) {
      swal({
        icon: "warning",
        title: "Quantity Not Added",
        text: "Please Select Quantity.",
      });
      return;
    }

    const apiUrl = `${BASE_URL}/getProduction/${formData.product.value}`;

    // Fetch the production data
    fetch(apiUrl, {
      method: "POST", // Assuming it's a GET request; change if necessary
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Set the state variables based on the response
        // Set raw materials consumption
        console.log("Product Response:", data); // Log the response for debugging

        const currentStock = parseFloat(data.responseMessage.currentStock); // Extract current stock
        const enteredQuantity = parseFloat(formData.quantity); // Get the entered quantity

        // Compare entered quantity with current stock
        if (enteredQuantity > currentStock) {
          swal({
            icon: "warning",
            title: "Insufficient Stock",
            text: `Current stock is only ${currentStock}. Please reduce the quantity.`,
          });
          setShowCheckStockButton(true);
        }

        const rawMaterials =
          data.responseMessage.rawMaterialConsumptionDtos.map(
            (item, index) => ({
              id: index + 1, // Assuming you want to use index as ID
              name: item.name, // Assuming materialName is available
              unitPrice: parseFloat(item.unitPrice), // Assuming purchasePrice is available
              consumption: parseFloat(item.consumption), // Assuming consumption is available
              totalCost:parseFloat(item.totalCost)
            })
          );
        setShowMoreMaterials(rawMaterials);
        setShowStockCheckData(rawMaterials);

        // Set non-inventory costs
        const nonInventoryCosts = data.responseMessage.nonInventoryCostDtos.map(
          (item, index) => ({
            id: index + 1, // Assuming you want to use index as ID
            name: item.name, // Assuming name is available
            cost: parseFloat(item.nonInventoryCost), // Assuming cost is available
            account: item.account || "", // Assuming account is available
          })
        );
        setShowNonInventoryCost(nonInventoryCosts);

        // Set production stages
        const productionStages = data.responseMessage.productionStagesDtos.map(
          (item, index) => ({
            id: index + 1, // Assuming you want to use index as ID
            stage: item.name, // Assuming stageName is available
            requiredTime: {
              months: parseInt(item.months) || 0,
              days: parseInt(item.days) || 0,
              hours: parseInt(item.hours) || 0,
            },
          })
        );
        setShowProductionStage(productionStages);

        setShowSections(true); // Show sections after fetching data
      })
      .catch((error) => {
        console.error("Error fetching production data:", error);
        swal({
          icon: "error",
          title: "Error",
          text: "Failed to fetch production data.",
        });
      });
  };

  useEffect(() => {
    const totalMaterialCost = showMoreMaterials.reduce(
      (sum, item) => sum + item.unitPrice * item.consumption,
      0
    );
    setTotalMaterialCost(totalMaterialCost);
  }, [showMoreMaterials]);

  useEffect(() => {
    const totalItemCost = showNonInventoryCost.reduce(
      (sum, item) => sum + item.cost,
      0
    );
    setTotalItemCost(totalItemCost);
  }, [showNonInventoryCost]);

  const [formData, setFormData] = useState({
    uniqueId: decryptedUniqueId || "",
    refNo: "",
    productionType: null,
    productionStatus: null,
    product: null,
    quantity: null,
    startDate: new Date(),
    completionDate: new Date(),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleCancel = () => {
    handleNavigation("Production");
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleCancel();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };
  useEffect(() => {
    const totalCost = totalMaterialCost + totalItemCost;
    const profitAmount = totalCost * (profitMargin / 100);
    const preTaxPrice = totalCost + profitAmount;
    const taxAmount = preTaxPrice * ((cgst + sgst + igst) / 100);
    const calculatedSalePrice = preTaxPrice + taxAmount;

    setSalePrice(calculatedSalePrice.toFixed(2));
  }, [totalMaterialCost, totalItemCost, profitMargin, cgst, sgst, igst]);

  // Other states...
  const [showProductionStage, setShowProductionStage] = useState([]);

  // ADDED: Handle editing unit price
  const handleUnitPriceChange = (index, value) => {
    const updatedList = [...showMoreMaterials];
    updatedList[index].unitPrice = parseFloat(value) || 0;
    setShowMoreMaterials(updatedList);
  };

  // ADDED: Handle editing consumption
  const handleConsumptionChange = (index, value) => {
    const updatedList = [...showMoreMaterials];
    updatedList[index].consumption = parseFloat(value) || 0;
    setShowMoreMaterials(updatedList);
  };

  // Update handleRemoveMaterial function
  const handleRemoveMaterial = (index) => {
    const updatedList = [...showMoreMaterials];
    updatedList.splice(index, 1);
    // Reindex the list
    const reindexedList = updatedList.map((item, idx) => ({
      ...item,
      id: idx + 1,
    }));
    setShowMoreMaterials(reindexedList);
  };

  const [editableRowIndex, setEditableRowIndex] = useState(null);

  const handleStartDateChange = (date) => {
    setFormData((prev) => ({
      ...prev,
      startDate: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setFormData((prev) => ({
      ...prev,
      completionDate: date,
    }));
  };

  const productionTypes = [
    { value: "instant production entity", label: "Instant Production Entity" },
    { value: "production by scheduling", label: "Production By Scheduling" },
    { value: "from customer order", label: "From Customer Order" },
  ];

  const productionStatus = [
    { value: "draft", label: "Draft" },
    { value: "in_progress", label: "In Progress" },
    { value: "done", label: "Done" },
  ];

  const accountOptions = [
    { value: "bkash", label: "Bkash" },
    { value: "brack bank", label: "Brack Bank" },
    { value: "paypal", label: "Paypal" },
    { value: "stripe", label: "Stripe" },
    { value: "cash", label: "Cash" },
  ];

  const handleSelectChange = (selectedOption, fieldName) => {
    setFormData({ ...formData, [fieldName]: selectedOption });
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const [nonInventoryItems, setNonInventoryItems] = useState([]);

  const handleAddMoreMaterial = () => {
    const newMaterial = {
      id: showMoreMaterials.length + 1,
      name: "", // Initially empty
      unitPrice: 0,
      consumption: 0,
    };
    setShowMoreMaterials([...showMoreMaterials, newMaterial]);
    setEditableRowIndex(showMoreMaterials.length); // Set the new row as editable
  };

  const handleAddMoreNonInventoryItem = () => {
    const newItem = {
      id: showNonInventoryCost.length + 1,
      name: "",
      cost: 0,
      account: "",
    };
    setShowNonInventoryCost([...showNonInventoryCost, newItem]);
  };

  const handleNonInventoryCostChange = (index, value) => {
    const updatedList = [...showNonInventoryCost];
    updatedList[index].cost = parseFloat(value) || 0;
    setShowNonInventoryCost(updatedList);
  };

  const handleRemoveNonInventoryItem = (index) => {
    const updatedList = [...showNonInventoryCost];
    updatedList.splice(index, 1);
    setShowNonInventoryCost(updatedList);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F6F6F6",
      borderRadius: "7px",
      height: "46px",
      border: "1px solid #ced4da",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #ced4da",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown menu appears above other elements
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    singleValue: (provided) => ({
      ...provided,
      height: "19px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d",
    }),
  };

  useEffect(() => {
    calculateTotalItemCost();
  }, [nonInventoryItems]);

  // Handle cost change for an item
  const handleCostChange = (index, value) => {
    const updatedList = [...nonInventoryItems];
    updatedList[index].cost = parseFloat(value) || 0;
    setNonInventoryItems(updatedList);
  };

  // Remove an item from the list
  const handleRemoveItem = (index) => {
    const updatedList = [...nonInventoryItems];
    updatedList.splice(index, 1);

    // Update serial numbers
    const reindexedList = updatedList.map((item, idx) => ({
      ...item,
      sn: idx + 1,
    }));

    setNonInventoryItems(reindexedList);
  };

  const [showStockModal, setShowStockModal] = useState(false);

  const handleCheckStock = () => {
    setShowStockModal(true);
  };

  const handleCloseStockModal = () => {
    setShowStockModal(false);
  };

  const handlePurchaseStock = () => {
    console.log("stock purchase clicked");
  };

  // Calculate total cost of all items
  const calculateTotalItemCost = () => {
    const total = nonInventoryItems.reduce((sum, item) => sum + item.cost, 0);
    setTotalItemCost(total);
  };

  const handleCheckboxChange = (index) => {
    const updatedStages = showProductionStage.map((stage, idx) => ({
      ...stage,
      selected: idx === index, // Only the clicked checkbox will be selected
    }));
    setShowProductionStage(updatedStages);
  };

  const handleCreateProduction = async () => {
    try {
      const selectedStages = showProductionStage.filter(
        (stage) => stage.selected
      );
      // Construct the payload
      const payload = {
        referenceId: formData.refNo,
        productionType: formData.productionType?.value || "",
        status: formData.productionStatus?.value || "",
        startDate: formData.startDate.toISOString().split("T")[0], // Format as YYYY-MM-DD
        completedDate: formData.completionDate.toISOString().split("T")[0], // Format as YYYY-MM-DD
        productionId: formData.product?.value || "",
        productionName: formData.product?.label || "",
        quantity: formData.quantity || 0,
        uniqueId: decryptedUniqueId,
        totalCost: (totalMaterialCost + totalItemCost).toFixed(2),
        profitMargin: profitMargin,
        cgst: cgst,
        sgst: sgst,
        igst: igst,
        salePrice: salePrice,
        productionStagesDtos: selectedStages.map((stage, index) => ({
          id: stage.id,
          stageName: stage.stage,
          months: stage.requiredTime.months,
          days: stage.requiredTime.days,
          hours: stage.requiredTime.hours,
          // uniqueId: `UNIQUE_STAGE${index + 1}`,
        })),
        rawMaterialConsumptionDtos: showMoreMaterials.map((item, index) => ({
          id: index + 1,
          rawMaterialId: item.rawMaterialId || "", // You need to map this from your data
          materialId: item.materialId || "", // You need to map this from your data
          unitPrice: item.unitPrice,
          consumption: item.consumption,
          totalCost: (item.unitPrice * item.consumption).toFixed(2),
          totalRawCost: (item.unitPrice * item.consumption).toFixed(2),
          name: item.name,
          // uniqueId: `UNIQUE_RM${index + 1}`,
          findProductionId: formData.product?.value || "",
        })),
        nonInventoryCostDtos: showNonInventoryCost.map((item, index) => ({
          id: index + 1,
          materialId: item.materialId || "", // You need to map this from your data
          nonInventoryId: item.nonInventoryId || "", // You need to map this from your data
          nonInventoryCost: item.cost,
          totalNonInventoryCost: item.cost,
          // uniqueId: `UNIQUE_NI${index + 1}`,
          name: item.name,
          findProductionId: formData.product?.value || "",
        })),
      };

      // Make the API call
      const response = await fetch(`${BASE_URL}/insertFinalProduction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.status === "success") {
        swal({
          icon: "success",
          title: "Production Created",
          text: "Production has been successfully created.",
        });
        // Optionally, navigate to another page or reset the form
      } else {
        swal({
          icon: "error",
          title: "Error",
          text: data.responseMessage || "Failed to create production.",
        });
      }
    } catch (error) {
      console.error("Error creating production:", error);
      swal({
        icon: "error",
        title: "Error",
        text: "An error occurred while creating production.",
      });
    }
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col>
          <div className="my-3">
            <div className="d-flex align-items-center ml-1 mt-4">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                }}
              >
                <ArrowBackIosIcon
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5 className="ml-1 mb-0" style={{ color: "#086bd5de" }}>
                  Add Production
                </h5>
              </div>
            </div>
          </div>

          <Card className="card-style p-4">
            <Row>
              <Col md="12">
                <h5 style={{ color: "#086bd5de" }}>Production Details</h5>
              </Col>
            </Row>

            <Form>
              <Row>
                {/* From Name */}
                <Col md="4">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Reference No</label>
                    <Form.Control
                      placeholder="Enter Reference No"
                      name="refNo"
                      value={formData.refNo}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>

                {/* Transport or Courier */}
                <Col md="4">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Production Type</label>
                    <Select
                      options={productionTypes}
                      value={formData.productionType}
                      isClearable
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "productionType")
                      }
                      placeholder="Select Production Type"
                      styles={customStyles}
                    />
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Production Status</label>
                    <Select
                      options={productionStatus}
                      value={formData.productionStatus}
                      isClearable
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "productionStatus")
                      }
                      placeholder="Select Production Status"
                      styles={customStyles}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Form.Group>
                    <label>Start Date</label>
                    <DatePicker
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                      selected={formData.startDate} // ✅ Bind to state
                      onChange={handleStartDateChange} // ✅ Update state on change
                    />
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group>
                    <label>Completion Date</label>
                    <DatePicker
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                      selected={formData.completionDate} // ✅ Bind to state
                      onChange={handleEndDateChange} // ✅ Update state on change
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Product</label>
                    <Select
                      options={materialsList}
                      value={formData.product}
                      isClearable
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "product")
                      }
                      placeholder="Product"
                      styles={customStyles}
                      menuPortalTarget={document.body}
                    />
                  </Form.Group>
                </Col>
                <Col md="2">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Quantity</label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Quantity"
                      name="quantity"
                      value={formData.quantity}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>

                {/* Go Button */}
                <Col md="2">
                  <button
                    type="button"
                    className="btn-3 btn btn-primary"
                    style={{ marginTop: "1.5rem" }}
                    onClick={handleGoClick}
                  >
                    Go →
                  </button>
                </Col>
                <Col md="2"></Col>
              </Row>

              <hr className="mt-4" />
              {showSections && (
                <>
                  <Row>
                    <Col md="12">
                      <h5 style={{ color: "#086bd5de" }}>
                        Raw Material Consumption and Cost (BoM)
                      </h5>
                    </Col>
                  </Row>

                  <Table bordered hover responsive>
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>Raw Material (Code)</th>
                        <th>Unit Price</th>
                        <th>Consumption</th>
                        <th>Total Cost</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {showMoreMaterials.map((item, index) => (
                        <tr key={index}>
                          <td>{item.id}</td>
                          <td>
                            {editableRowIndex === index ? (
                              <Select
                                options={rawMaterialList} // Assuming you have a materials array
                                value={{ label: item.name, value: item.id }} // Set the selected value
                                onChange={(selected) => {
                                  const updatedMaterials = [
                                    ...showMoreMaterials,
                                  ];
                                  updatedMaterials[index].name = selected.label;
                                  updatedMaterials[index].code = selected.code; // Assuming you have code in your materials
                                  setShowMoreMaterials(updatedMaterials);
                                }}
                                placeholder="Select Raw Material"
                                styles={customStyles}
                                menuPortalTarget={document.body}
                              />
                            ) : (
                              item.name
                            )}
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              value={item.unitPrice}
                              onChange={(e) =>
                                handleUnitPriceChange(index, e.target.value)
                              }
                              // disabled={editableRowIndex !== index} // Disable if not editable
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              value={item.consumption}
                              onChange={(e) =>
                                handleConsumptionChange(index, e.target.value)
                              }
                              // disabled={editableRowIndex !== index} // Disable if not editable
                            />
                          </td>
                          <td>
                            {(item.unitPrice * item.consumption).toFixed(2)}
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleRemoveMaterial(index)}
                            >
                              <FaTrash />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Button onClick={handleAddMoreMaterial}>Add More</Button>

                  <Row className="mt-2 mb-4">
                    <Col md="9" className="text-right">
                      <strong>Total Material Cost:</strong>
                    </Col>
                    <Col md="2">
                      {showMoreMaterials
                        .reduce(
                          (sum, item) =>
                            sum + item.unitPrice * item.consumption,
                          0
                        )
                        .toFixed(2)}
                    </Col>
                    <Col md="1"></Col>
                  </Row>
                  <hr className="mt-4" />
                </>
              )}

              {showSections && (
                <>
                  <Row>
                    <Col md="12">
                      <h5 style={{ color: "#086bd5de" }}>
                        Non-Inventory Costs
                      </h5>
                    </Col>
                  </Row>
                  <Table bordered hover responsive>
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>Non-Inventory Item</th>
                        <th>Cost</th>
                        <th>Account</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {showNonInventoryCost.map((item, index) => (
                        <tr key={index}>
                          <td>{item.id}</td>
                          <td>
                            {item.name ? (
                              item.name
                            ) : (
                              <Select
                                options={nonInventory}
                                value={nonInventory.find(
                                  (option) => option.label === item.name
                                )}
                                onChange={(selected) => {
                                  const updatedCosts = [
                                    ...showNonInventoryCost,
                                  ];
                                  updatedCosts[index].name = selected.label;
                                  setShowNonInventoryCost(updatedCosts);
                                }}
                                placeholder="Select Item"
                                styles={customStyles}
                                menuPortalTarget={document.body}
                              />
                            )}
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              value={item.cost}
                              onChange={(e) => {
                                const updatedCosts = [...showNonInventoryCost];
                                updatedCosts[index].cost =
                                  parseFloat(e.target.value) || 0;
                                setShowNonInventoryCost(updatedCosts);
                              }}
                            />
                          </td>
                          <td>
                            {item.account ? (
                              item.account
                            ) : (
                              <Select
                                options={accountOptions} // Use the dummy account options
                                value={accountOptions.find(
                                  (option) => option.value === item.account
                                )}
                                onChange={(selected) => {
                                  const updatedCosts = [
                                    ...showNonInventoryCost,
                                  ];
                                  updatedCosts[index].account = selected.value; // Update the account value
                                  setShowNonInventoryCost(updatedCosts);
                                }}
                                placeholder="Select Account"
                                styles={customStyles}
                                menuPortalTarget={document.body} // Render the dropdown outside the parent container
                              />
                            )}
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => {
                                const updatedCosts =
                                  showNonInventoryCost.filter(
                                    (cost) => cost.id !== item.id
                                  );
                                // Reindex
                                const reindexedCosts = updatedCosts.map(
                                  (cost, idx) => ({
                                    ...cost,
                                    id: idx + 1,
                                  })
                                );
                                setShowNonInventoryCost(reindexedCosts);
                              }}
                            >
                              <FaTrash />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Button
                    onClick={() => {
                      const newItem = {
                        id: showNonInventoryCost.length + 1,
                        name: "",
                        cost: 0,
                        account: "",
                      };
                      setShowNonInventoryCost([
                        ...showNonInventoryCost,
                        newItem,
                      ]);
                    }}
                  >
                    Add More
                  </Button>

                  <Row className="mt-2 mb-4">
                    <Col md="9" className="text-right">
                      <strong>Total Non-Inventory Cost:</strong>
                    </Col>
                    <Col md="2">
                      {showNonInventoryCost
                        .reduce((sum, item) => sum + item.cost, 0)
                        .toFixed(2)}
                    </Col>
                    <Col md="1"></Col>
                  </Row>
                  <hr className="mt-4" />
                </>
              )}

              <Row>
                <Col md="12">
                  <h5 style={{ color: "#086bd5de" }}>Cost & Pricing Details</h5>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>
                      Total Cost <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <Form.Control
                        type="number"
                        min="0"
                        step="0.01"
                        value={(totalMaterialCost + totalItemCost).toFixed(2)}
                        readOnly
                        style={{ width: "70%" }}
                      />
                      <Form.Control
                        as="select"
                        value="INR"
                        disabled
                        style={costAndPricingStyle}
                      >
                        <option>INR</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Profit Margin (%)</label>
                    <div className="d-flex">
                      <Form.Control
                        type="number"
                        min="0"
                        step="0.01"
                        placeholder="Profit Margin"
                        value={profitMargin}
                        onChange={(e) =>
                          setProfitMargin(parseFloat(e.target.value) || 0)
                        }
                        style={{ width: "70%" }}
                      />
                      <Form.Control
                        as="select"
                        value="%"
                        disabled
                        style={costAndPricingStyle}
                      >
                        <option>%</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="4">
                  <Form.Group>
                    <label style={{ color: "grey" }}>CGST</label>
                    <div className="d-flex">
                      <Form.Control
                        type="number"
                        min="0"
                        step="0.01"
                        placeholder="18.00"
                        value={cgst}
                        onChange={(e) =>
                          setCgst(parseFloat(e.target.value) || 0)
                        }
                        style={{ width: "70%" }}
                      />
                      <Form.Control
                        as="select"
                        value="%"
                        disabled
                        style={costAndPricingStyle}
                      >
                        <option>%</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <label style={{ color: "grey" }}>SGST</label>
                    <div className="d-flex">
                      <Form.Control
                        type="number"
                        min="0"
                        step="0.01"
                        placeholder="9.00"
                        value={sgst}
                        onChange={(e) =>
                          setSgst(parseFloat(e.target.value) || 0)
                        }
                        style={{ width: "70%" }}
                      />
                      <Form.Control
                        as="select"
                        value="%"
                        disabled
                        style={costAndPricingStyle}
                      >
                        <option>%</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <label style={{ color: "grey" }}>IGST</label>
                    <div className="d-flex">
                      <Form.Control
                        type="number"
                        min="0"
                        step="0.01"
                        placeholder="9.00"
                        value={igst}
                        onChange={(e) =>
                          setIgst(parseFloat(e.target.value) || 0)
                        }
                        style={{ width: "70%" }}
                      />
                      <Form.Control
                        as="select"
                        value="%"
                        disabled
                        style={costAndPricingStyle}
                      >
                        <option>%</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="6">
                  <Form.Group>
                    <label style={{ color: "grey" }}>
                      Sale Price <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <Form.Control
                        type="number"
                        min="0"
                        step="0.01"
                        value={salePrice}
                        onChange={(e) => setSalePrice(e.target.value)}
                        style={{ width: "70%" }}
                      />
                      <Form.Control
                        as="select"
                        value="INR"
                        disabled
                        style={costAndPricingStyle}
                      >
                        <option>INR</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <hr className="mt-4" />
              {showSections && (
                <>
                  <Row>
                    <Col md="12">
                      <h5 style={{ color: "#086bd5de" }}>Production Stage</h5>
                    </Col>
                  </Row>

                  <Table bordered hover responsive>
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>check</th>
                        <th>Stage</th>
                        <th colSpan="2">Months</th>
                        <th colSpan="2">Days</th>
                        <th colSpan="2">Hours</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {showProductionStage.map((stage, index) => (
                        <tr key={stage.id}>
                          <td>{stage.id}</td>
                          <td className="text-center">
                            <Form.Check
                              type="checkbox"
                              onChange={() => handleCheckboxChange(index)} // Use the new handler
                              checked={stage.selected || false}
                            />
                          </td>
                          <td>{stage.stage}</td>
                          <td>
                            <Form.Control
                              type="number"
                              min="0"
                              value={
                                stage.requiredTime.months
                              } /* Access through requiredTime object */
                              onChange={(e) => {
                                const updatedStages = [...showProductionStage];
                                updatedStages[index].requiredTime.months =
                                  parseInt(e.target.value) || 0;
                                setShowProductionStage(updatedStages);
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              as="select"
                              defaultValue="Months"
                              disabled
                              style={hideArrowStyle}
                            >
                              <option>Months</option>
                            </Form.Control>
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              min="0"
                              value={
                                stage.requiredTime.days
                              } /* Access through requiredTime object */
                              onChange={(e) => {
                                const updatedStages = [...showProductionStage];
                                updatedStages[index].requiredTime.days =
                                  parseInt(e.target.value) || 0;
                                setShowProductionStage(updatedStages);
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              as="select"
                              defaultValue="Days"
                              disabled
                              style={hideArrowStyle}
                            >
                              <option>Days</option>
                            </Form.Control>
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              min="0"
                              value={
                                stage.requiredTime.hours
                              } /* Access through requiredTime object */
                              onChange={(e) => {
                                const updatedStages = [...showProductionStage];
                                updatedStages[index].requiredTime.hours =
                                  parseInt(e.target.value) || 0;
                                setShowProductionStage(updatedStages);
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              as="select"
                              defaultValue="Hours"
                              disabled
                              style={hideArrowStyle}
                            >
                              <option>Hours</option>
                            </Form.Control>
                          </td>
                          <td className="text-center">
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => {
                                const updatedStages =
                                  showProductionStage.filter(
                                    (item) => item.id !== stage.id
                                  );
                                // Reindex the remaining stages
                                const reindexedStages = updatedStages.map(
                                  (item, idx) => ({
                                    ...item,
                                    id: idx + 1,
                                  })
                                );
                                setShowProductionStage(reindexedStages);
                              }}
                            >
                              <FaTrash />
                            </Button>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="2">Total</td>
                        <td></td> {/* Empty cell for the stage column */}
                        <td>
                          <Form.Control
                            type="number"
                            value={showProductionStage.reduce(
                              (sum, stage) =>
                                sum + (stage.requiredTime?.months || 0),
                              0
                            )}
                            readOnly
                          />
                        </td>
                        <td>
                          <Form.Control
                            as="select"
                            defaultValue="Months"
                            disabled
                            style={hideArrowStyle}
                          >
                            <option>Months</option>
                          </Form.Control>
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={showProductionStage.reduce(
                              (sum, stage) =>
                                sum + (stage.requiredTime?.days || 0),
                              0
                            )}
                            readOnly
                          />
                        </td>
                        <td>
                          <Form.Control
                            as="select"
                            defaultValue="Days"
                            disabled
                            style={hideArrowStyle}
                          >
                            <option>Days</option>
                          </Form.Control>
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={showProductionStage.reduce(
                              (sum, stage) =>
                                sum + (stage.requiredTime?.hours || 0),
                              0
                            )}
                            readOnly
                          />
                        </td>
                        <td>
                          <Form.Control
                            as="select"
                            defaultValue="Hours"
                            disabled
                            style={hideArrowStyle}
                          >
                            <option>Hours</option>
                          </Form.Control>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )}
            </Form>

            <div className="text-left invoice-bottombtns">
              {showCheckStockButton ? (
                <button className="btn-1 btn mt-0" onClick={handleCheckStock}>
                  Check Stock
                </button>
              ) : null}
            </div>

            <div className="text-right invoice-bottombtns">
              <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
                Cancel
              </button>
              {/* <button className="btn-1 btn mt-0">Save as Draft</button> */}
              {!showCheckStockButton && (
                <button
                  className="btn-3 btn btn-primary"
                  onClick={handleCreateProduction}
                >
                  Create Production
                </button>
              )}
            </div>
          </Card>
        </Col>
      </Row>

      <Modal show={showStockModal} onHide={handleCloseStockModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Current Stock Check</Modal.Title>
        </Modal.Header>
        <Modal.Body className="" style={{ padding: "1rem !important" }}>
          <Table hover responsive>
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Unit Price</th>
                <th>Total Cost</th>
              </tr>
            </thead>
            <tbody>
              {showStockCheckData &&
                showStockCheckData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.unitPrice}</td>
                    <td>{item.totalCost}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePurchaseStock}>
            <i className="fas fa-shopping-cart mr-2"></i>
            Purchase
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddProduction;
