import React, { useEffect, useState, useRef } from "react";
import "./Setting.css";
import SettingsIcon from "@mui/icons-material/Settings";
import { BASE_URL } from "../CommonApi";
import { AES, enc } from "crypto-js";
import { ToastContainer, toast } from "react-toastify";
import { FaWhatsapp } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoice,
  faUser,
  faBuilding,
  faCog,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import Alert from "@mui/material/Alert";

function Setting({ navigation }) {
  const [toggleDis, setToggleDis] = useState(false);
  const [toggleSize, setToggleSize] = useState(false);
  const [toggleHSN, setToggleHSN] = useState(false);
  const [toggleBatch, setToggleBatch] = useState(false);
  const [toggleNetRate, setToggleNetRate] = useState(false);
  const [togglePack, setTogglePack] = useState(false);
  const [toggleWhatsappApi, setToggleWhatsappAPI] = useState(false);
  const [toggleSelfWhatsapp, setToggleSelfWhatsapp] = useState(false);
  const [showSalesModal, setShowSalesModal] = useState(false);

  const [isChecked1, setIsChecked1] = useState(false);
  const [isQtyChecked, setIsQtyChecked] = useState(false);
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");

  const handleSalesClose = () => setShowSalesModal(false);
  const handleSalesShow = () => {
    handleNavigation("TermsAndConditionCard");
  };
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleProfileClick = () => {
    handleNavigation("profile");
  };

  const handleTaxClick = () => {
    handleNavigation("taxes");
  };

  const notifySuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // Function to show blue toast
  const notifyBlue = (message) => {
    toast.info(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // Function to show error toast
  const notifyError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleSwitchQuantityCheckToggle = () => {
    const newQtyCheckedState = !isQtyChecked;
    setIsQtyChecked(newQtyCheckedState); // Update state first
  
    // Prepare API request data
    const bodyData = {
      uniqueId: decryptedUniqueId,
      quantityCheck: newQtyCheckedState ? "YES" : "NO",
    };
  
    // Make API call
    fetch(BASE_URL + `/updateQuantityCheck`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          newQtyCheckedState
            ? notifySuccess("Quantity Display Enabled")
            : notifyBlue("Quantity Display Disabled");
        } else {
          notifyError("Update Failed: " + data.responseMessage);
          // Revert state if API call fails
          setIsQtyChecked(!newQtyCheckedState);
        }
      })
      .catch((error) => {
        console.error("Error updating quantity setting:", error);
        notifyError("Failed to Update Quantity Setting " + error.message);
        // Revert state if API call fails
        setIsQtyChecked(!newQtyCheckedState);
      });
  };

  const handleSwitchNoteCalcToggle = () => {
    const newChecked1State = !isChecked1;
    setIsChecked1(newChecked1State);
    const bodyData = {
      uniqueId: decryptedUniqueId,
      deliveryChallanCalculateCheck: newChecked1State ? "YES" : "NO",
    };

    fetch(BASE_URL + `/updateDeliveryChallanCheck`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          newChecked1State
            ? notifySuccess("Delivery Note Calculations Enabled")
            : notifyBlue("Delivery Note Calculations Disabled");
        } else {
          notifyError("Update Failed: " + data.responseMessage);
          // Revert state if update fails
          setIsChecked1(!newChecked1State);
        }
      })
      .catch((error) => {
        console.error("Error updating tax:", error);
        notifyError("Failed to Update GST Setting " + error.message);
      });
  };

  const handleSwitchDisToggle = (event) => {
    const isChecked = event.target.checked;
    setToggleDis(isChecked);

    const bodyData = {
      uniqueId: decryptedUniqueId,
      discountCheck: isChecked ? "YES" : "NO",
    };

    fetch(BASE_URL + `/updateDiscountCheck`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (isChecked) {
            notifySuccess(
              "Discount Column Setting Turned On Successfully: " +
                data.responseMessage
            );
          } else {
            // Show blue toast
            notifyBlue(
              "Discount Column Setting Turned Off Successfully: " +
                data.responseMessage
            );
          }
        } else {
          console.error("Failed to update tax:", data.responseMessage);
          notifyError("Failed to Update GST Setting: " + data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error updating tax:", error);
        notifyError("Failed to Update GST Setting " + error.message);
      });
  };

  const handleSwitchPackToggle = (event) => {
    const isChecked = event.target.checked;
    setTogglePack(isChecked);

    const bodyData = {
      uniqueId: decryptedUniqueId,
      packCheck: isChecked ? "YES" : "NO",
    };

    fetch(BASE_URL + `/updatePackCheck`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (isChecked) {
            notifySuccess(
              "Pack Column Setting Turned On Successfully: " +
                data.responseMessage
            );
          } else {
            // Show blue toast
            notifyBlue(
              "Pack Column Setting Turned Off Successfully: " +
                data.responseMessage
            );
          }
        } else {
          console.error("Failed to update tax:", data.responseMessage);
          notifyError("Failed to Update GST Setting: " + data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error updating tax:", error);
        notifyError("Failed to Update GST Setting " + error.message);
      });
  };
  const handleSwitchSizeToggle = (event) => {
    const isChecked = event.target.checked;
    setToggleSize(isChecked);
    const bodyData = {
      uniqueId: decryptedUniqueId,
      sizeCheck: isChecked ? "YES" : "NO",
    };

    fetch(BASE_URL + `/updateSizeCheck`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (isChecked) {
            notifySuccess(
              "Size Column Setting Turned On Successfully: " +
                data.responseMessage
            );
          } else {
            // Show blue toast
            notifyBlue(
              "Size Column Setting Turned Off Successfully: " +
                data.responseMessage
            );
          }
        } else {
          console.error("Failed to update tax:", data.responseMessage);
          notifyError("Failed to Update GST Setting: " + data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error updating tax:", error);
        notifyError("Failed to Update GST Setting " + error.message);
      });
  };

  const handleSwitchHSNToggle = (event) => {
    const isChecked = event.target.checked;
    setToggleHSN(isChecked);
    const bodyData = {
      uniqueId: decryptedUniqueId,
      hsnCheck: isChecked ? "YES" : "NO",
    };
    fetch(BASE_URL + `/updateHsnCheck`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (isChecked) {
            notifySuccess(
              "HSN Column Setting Turned On Successfully: " +
                data.responseMessage
            );
          } else {
            notifyBlue(
              "HSN Column Setting Turned Off Successfully: " +
                data.responseMessage
            );
          }
        } else {
          console.error("Failed to update tax:", data.responseMessage);
          notifyError("Failed to Update GST Setting: " + data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error updating tax:", error);
        notifyError("Failed to Update GST Setting " + error.message);
      });
  };

  const handleSwitchBatchToggle = (event) => {
    const isChecked = event.target.checked;
    setToggleBatch(isChecked);
    const bodyData = {
      uniqueId: decryptedUniqueId,
      batchIdCheck: isChecked ? "YES" : "NO",
    };
    fetch(BASE_URL + `/updateBatchIdCheck`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (isChecked) {
            notifySuccess(
              "Batch ID Column Setting Turned On Successfully: " +
                data.responseMessage
            );
          } else {
            notifyBlue(
              "Batch ID Column Setting Turned Off Successfully: " +
                data.responseMessage
            );
          }
        } else {
          console.error("Failed to update tax:", data.responseMessage);
          notifyError("Failed to Update GST Setting: " + data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error updating tax:", error);
        notifyError("Failed to Update GST Setting " + error.message);
      });
  };

  const handleSwitchNetRateToggle = (event) => {
    const isChecked = event.target.checked;
    setToggleNetRate(isChecked);
    const bodyData = {
      uniqueId: decryptedUniqueId,
      netRateCheck: isChecked ? "YES" : "NO",
    };
    fetch(BASE_URL + `/updateNetRateCheck`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (isChecked) {
            notifySuccess(
              "Net Rate Column Setting Turned On Successfully: " +
                data.responseMessage
            );
          } else {
            notifyBlue(
              "Net Rate Column Setting Turned Off Successfully: " +
                data.responseMessage
            );
          }
        } else {
          console.error("Failed to update tax:", data.responseMessage);
          notifyError("Failed to Update GST Setting: " + data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error updating tax:", error);
        notifyError("Failed to Update GST Setting " + error.message);
      });
  };

  const handleSwitchWhatsAppApiToggle = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setToggleWhatsappAPI(true);
      setToggleSelfWhatsapp(false);
    } else {
      setToggleWhatsappAPI(false);
    }

    const bodyData = {
      uniqueId: decryptedUniqueId,
      whatsappApiCheck: isChecked ? "YES" : "NO",
      selfWhatsappCheck: "NO", // Force self WhatsApp off when API is turned on
    };

    // fetch(BASE_URL + `/updateWhatsAppSettings`, {
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${jwtToken}`,
    //   },
    //   body: JSON.stringify(bodyData),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.status === "success") {
    //       if (isChecked) {
    //         notifySuccess("WhatsApp API Setting Turned On Successfully");
    //       } else {
    //         notifyBlue("WhatsApp API Setting Turned Off Successfully");
    //       }
    //     } else {
    //       console.error("Failed to update WhatsApp settings:", data.responseMessage);
    //       notifyError("Failed to Update WhatsApp Settings: " + data.responseMessage);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error updating WhatsApp settings:", error);
    //     notifyError("Failed to Update WhatsApp Settings: " + error.message);
    //   });
  };

  const handleSwitchSelfWhatsAppToggle = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // If turning on Self WhatsApp, turn off WhatsApp API
      setToggleSelfWhatsapp(true);
      setToggleWhatsappAPI(false);
    } else {
      // Allow turning off Self WhatsApp regardless of other toggle
      setToggleSelfWhatsapp(false);
    }

    const bodyData = {
      uniqueId: decryptedUniqueId,
      selfWhatsappCheck: isChecked ? "YES" : "NO",
      whatsappApiCheck: "NO", // Force WhatsApp API off when self WhatsApp is turned on
    };

    // fetch(BASE_URL + `/updateWhatsAppSettings`, {
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${jwtToken}`,
    //   },
    //   body: JSON.stringify(bodyData),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.status === "success") {
    //       if (isChecked) {
    //         notifySuccess("Self WhatsApp Setting Turned On Successfully");
    //       } else {
    //         notifyBlue("Self WhatsApp Setting Turned Off Successfully");
    //       }
    //     } else {
    //       console.error("Failed to update WhatsApp settings:", data.responseMessage);
    //       notifyError("Failed to Update WhatsApp Settings: " + data.responseMessage);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error updating WhatsApp settings:", error);
    //     notifyError("Failed to Update WhatsApp Settings: " + error.message);
    //   });
  };

  const fetchDiscountSetting = () => {
    fetch(BASE_URL + `/fetchDiscountCheck`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const businessRegistered = data.responseMessage.discountCheck;
          const sizeCheck = data.responseMessage.sizeCheck;
          const hsnCheck = data.responseMessage.hsnCheck;
          setToggleDis(businessRegistered === "YES");
          setToggleSize(sizeCheck === "YES");
          setToggleHSN(hsnCheck === "YES");
          setToggleBatch(data.responseMessage.batchIdCheck === "YES");
          setToggleNetRate(data.responseMessage.netRateCheck === "YES");
          setTogglePack(data.responseMessage.packCheck === "YES");
          setIsChecked1(
            data.responseMessage.deliveryChallanCalculateCheck === "YES"
          );
          setIsQtyChecked(data.responseMessage.quantityCheck === "YES");
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  useEffect(() => {
    fetchDiscountSetting();
  }, []);
  const textareaRef = useRef(null);
  const [termsAndCondition, setTermsAndCondition] = useState("");
  const [validationMessage2, setValidationMessage2] = useState("");
  useEffect(() => {
    fetchItemData();
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, [termsAndCondition]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleTermsAndConditionChange = (event) => {
    setTermsAndCondition(event.target.value);
  };
  const fetchItemData = () => {
    const postApiUrl = BASE_URL + `/fetchTermsAndConditions`;
    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseMessage;
        // setDropdownData(responseData.map((item) => item));
        setTermsAndCondition(responseData.termsAndCondition);
        setTimeout(() => adjustTextareaHeight(), 0);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const handleTermsAndCondition = (event) => {
    event.preventDefault();
    let newValidationMessage = "";

    if (!termsAndCondition) {
      newValidationMessage += "Please enter a terms and condition.\n";
    }

    setValidationMessage2(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0);
      // alert(newValidationMessage.trim()); // Trim trailing newline for better formatting
      return; // Exit function if validation fails
    }

    const data = {
      uniqueId: decryptedUniqueId,
      termsAndCondition: termsAndCondition,
    };
    fetch(BASE_URL + "/updateTermsAndCondition", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Terms And Condition Created ",
          });
          setShowSalesModal(false);
          fetchItemData();
        } else {
          swal({
            icon: "error",
            title: "Failed To Create Terms And Condition! ",
          });
          setShowSalesModal(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occured While Creating Terms And Condition",
        });
        setShowSalesModal(true);
      });
  };

  return (
    <>
      <div className="">
        <div className="my-3 ml-2">
          <div className="px-2 d-flex">
            <SettingsIcon
              className="icon mt-2 text-primary"
              size={26}
              onClick={""}
            />
            <h4
              className="mt-1 mx-2 text-primary"
              style={{ letterSpacing: "1px" }}
            >
              Settings
            </h4>
          </div>
        </div>
        <div>
          <div class="card-group container1">
            <div class="card1">
              <div class="card-body">
                <h5 class="card-title">
                  <FontAwesomeIcon
                    icon={faBuilding} // Organization icon
                    style={{ width: "18px", marginRight: "8px" }}
                  />
                  Organization
                </h5>
                <div className="cardchild ">
                  <p onClick={handleProfileClick} className="hover-effect">
                    <FontAwesomeIcon
                      className="mr-1"
                      icon={faUser}
                      style={{ width: "16px" }}
                    />{" "}
                    <span
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      Profile
                    </span>
                  </p>
                  {/* <p onClick={handleTemplateClick} className="hover-effect">
                    <FontAwesomeIcon
                      className="mr-1"
                      icon={faFile}
                      style={{ width: "16px" }}
                    />{" "}
                    <span
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      Templates
                    </span>
                  </p> */}
                </div>
              </div>
              <div class="card-body">
                <h5 class="card-title">
                  <FaWhatsapp
                    style={{ width: "18px", marginRight: "8px", color: "#000" }}
                  />
                  Whats App Settings
                </h5>
                <div className="cardchild ">
                  <div className="body mt-0">
                    <ul className="list-group">
                      <li
                        className=" mt-1 d-flex justify-content-between list-group-item align-items-center"
                        style={{ border: "none" }}
                      >
                        <h6
                          className={`mb-0 ${
                            toggleWhatsappApi ? "text-success" : "text-danger"
                          }`}
                        >
                          {toggleWhatsappApi
                            ? "WhatsApp API On"
                            : "WhatsApp API Off"}
                        </h6>
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            onChange={handleSwitchWhatsAppApiToggle}
                            checked={toggleWhatsappApi}
                          />
                          <span className="toggle-switch-slider"></span>
                        </label>
                      </li>
                      <li
                        className=" mt-1 d-flex justify-content-between list-group-item align-items-center"
                        style={{ border: "none" }}
                      >
                        <h6
                          className={`mb-0 ${
                            toggleSelfWhatsapp ? "text-success" : "text-danger"
                          }`}
                        >
                          {toggleSelfWhatsapp
                            ? "Self WhatsApp On"
                            : "Self WhatsApp Off"}
                        </h6>
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            onChange={handleSwitchSelfWhatsAppToggle}
                            checked={toggleSelfWhatsapp}
                          />
                          <span className="toggle-switch-slider"></span>
                        </label>
                      </li>
                    </ul>
                    {/* <div>
                      <ToastContainer
                        //className="toast toast-info"
                        hideProgressBar={true}
                      ></ToastContainer>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="card-body">
                <h5 class="card-title">
                  <FontAwesomeIcon
                    icon={faFileInvoice} // Taxes & Compliance icon
                    style={{ width: "16px", marginRight: "8px" }}
                  />
                  Taxes & Compliance
                </h5>
                <div className="cardchild">
                  <p onClick={handleTaxClick} className="hover-effect">
                    <FontAwesomeIcon
                      className="mr-1"
                      icon={faFileInvoice}
                      style={{ width: "18px" }}
                    />{" "}
                    <span
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      Taxes / GST
                    </span>
                  </p>
                </div>
                <h5 class="card-title">
                  <FontAwesomeIcon
                    icon={faCog} // Others icon
                    style={{ width: "16px", marginRight: "8px" }}
                  />
                  Others
                </h5>
                <div className="cardchild">
                  <p onClick={handleSalesShow} className="hover-effect">
                    <FontAwesomeIcon
                      className="mr-0"
                      icon={faFileContract}
                      style={{ width: "18px" }}
                    />{" "}
                    <span
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      Add Terms and Condition
                    </span>
                  </p>
                </div>
                <Modal show={showSalesModal} onHide={handleSalesClose}>
                  <div class="modal-header d-flex align-items-center pb-2 pt-2">
                    <h5 class="modal-title fs-5" id="salespersonHead">
                      Add Terms And Condition
                    </h5>
                    <button
                      type="button"
                      className="btn pr-0"
                      onClick={handleSalesClose}
                    >
                      <ion-icon
                        name="close-circle-outline"
                        size="large"
                        style={{ width: "28px" }}
                      ></ion-icon>
                    </button>
                  </div>
                  <Modal.Body>
                    {validationMessage2 && (
                      <Alert severity="error" className="mt-0">
                        {validationMessage2
                          .split("\n")
                          .map((message, index) => (
                            <div key={index}>{message}</div>
                          ))}
                      </Alert>
                    )}{" "}
                    <div className="row pt-2">
                      <div className="modalsalesinput">
                        <label className="form-label">
                          <span style={{ color: "red" }}>
                            Terms And Condition *
                          </span>
                        </label>
                        <div className="col-md-12">
                          <textarea
                            className="invoicetextarea"
                            placeholder="Terms and Condition..."
                            name="termsAndCondition"
                            value={termsAndCondition}
                            ref={textareaRef}
                            onChange={handleTermsAndConditionChange}
                            rows={8}
                            style={{
                              backgroundColor: "#F6F6F6",
                              borderRadius: "9px",
                              padding: "12px",
                              height: "auto",
                            }}
                            onInput={adjustTextareaHeight}
                          />
                          <i className="text-muted">
                            This terms will be displayed on the invoice
                          </i>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="p-2">
                    <div className="modal-bottombtns">
                      <button
                        type="button"
                        onClick={handleTermsAndCondition}
                        class="modalbtn-3 btn btn-primary"
                        data-bs-dismiss="modal"
                      >
                        Create Terms
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
            <div class="card1">
              <div class="card-body">
                <div className="cardchild">
                  <div className="body mt-0">
                    <ul className="list-group">
                      <li
                        className=" mt-1 d-flex justify-content-between list-group-item align-items-center"
                        style={{ border: "none" }}
                      >
                        <h6
                          className={`mb-0 ${
                            toggleDis ? "text-success" : "text-danger"
                          }`}
                        >
                          {toggleDis ? "Discount On" : "Discount Off"}
                        </h6>
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            onChange={handleSwitchDisToggle}
                            checked={toggleDis}
                          />
                          <span className="toggle-switch-slider"></span>
                        </label>
                      </li>
                      <li
                        className=" mt-1 d-flex justify-content-between list-group-item align-items-center"
                        style={{ border: "none" }}
                      >
                        <h6
                          className={`mb-0 ${
                            toggleSize ? "text-success" : "text-danger"
                          }`}
                        >
                          {toggleSize ? "Size On" : "Size Off"}
                        </h6>
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            onChange={handleSwitchSizeToggle}
                            checked={toggleSize}
                          />
                          <span className="toggle-switch-slider"></span>
                        </label>
                      </li>

                      <li
                        className=" mt-1 d-flex justify-content-between list-group-item align-items-center"
                        style={{ border: "none" }}
                      >
                        <h6
                          className={`mb-0 ${
                            toggleHSN ? "text-success" : "text-danger"
                          }`}
                        >
                          {toggleHSN ? "HSN On" : "HSN Off"}
                        </h6>
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            onChange={handleSwitchHSNToggle}
                            checked={toggleHSN}
                          />
                          <span className="toggle-switch-slider"></span>
                        </label>
                      </li>

                      <li
                        className=" mt-1 d-flex justify-content-between list-group-item align-items-center"
                        style={{ border: "none" }}
                      >
                        <h6
                          className={`mb-0 ${
                            toggleBatch ? "text-success" : "text-danger"
                          }`}
                        >
                          {toggleBatch ? "Batch ID On" : "Batch ID Off"}
                        </h6>
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            onChange={handleSwitchBatchToggle}
                            checked={toggleBatch}
                          />
                          <span className="toggle-switch-slider"></span>
                        </label>
                      </li>
                      <li
                        className=" mt-1 d-flex justify-content-between list-group-item align-items-center"
                        style={{ border: "none" }}
                      >
                        <h6
                          className={`mb-0 ${
                            toggleNetRate ? "text-success" : "text-danger"
                          }`}
                        >
                          {toggleNetRate ? "Net Rate On" : "Net Rate Off"}
                        </h6>
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            onChange={handleSwitchNetRateToggle}
                            checked={toggleNetRate}
                          />
                          <span className="toggle-switch-slider"></span>
                        </label>
                      </li>
                    </ul>
                    <div>
                      <ToastContainer
                        //className="toast toast-info"
                        hideProgressBar={true}
                      ></ToastContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card1" style={{ width: "640px" }}>
              <div class="card-body">
                <div className="cardchild">
                  <div className="body mt-0">
                    <div
                      className="onoffbg d-flex justify-content-between"
                      style={{
                        width: "100%",
                        backgroundColor: "#f8f9fa",
                        borderRadius: "10px",
                        border: "1px solid #e9ecef",
                      }}
                    >
                      <div>
                        <label className="form-label">
                          <span
                            className="text-dashed-underline"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#495057",
                            }}
                          >
                            Do you want calculations in delivery note?
                          </span>
                        </label>
                      </div>
                      <div className="d-flex">
                        <span
                          className="mr-2"
                          style={{
                            fontSize: "14px",
                            color: isChecked1 ? "#28a745" : "#dc3545",
                            fontWeight: "500",
                          }}
                        >
                          {isChecked1 ? "YES" : "NO"}
                        </span>
                        <div
                          className={`onoffswitch ${isChecked1 ? "on" : "off"}`}
                          onClick={handleSwitchNoteCalcToggle}
                          style={{
                            width: "50px",
                            height: "26px",
                            borderRadius: "13px",
                            backgroundColor: isChecked1 ? "#28a745" : "#dc3545",
                            position: "relative",
                            cursor: "pointer",
                            // transition: "background-color 0.3s ease",
                          }}
                        >
                          <div
                            className="toggle"
                            style={{
                              width: "22px",
                              height: "22px",
                              borderRadius: "11px",
                              backgroundColor: "#fff",
                              position: "absolute",
                              top: "2px",
                              left: isChecked1 ? "-3px" : "2px",
                              // transition: "left 0.3s ease",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div className="cardchild">
                  <div className="body mt-0">
                    <div
                      className="onoffbg d-flex justify-content-between"
                      style={{
                        width: "100%",
                        backgroundColor: "#f8f9fa",
                        borderRadius: "10px",
                        border: "1px solid #e9ecef",
                      }}
                    >
                      <div>
                        <label className="form-label">
                          <span
                            className="text-dashed-underline"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#495057",
                            }}
                          >
                            Do you want show quantity?
                          </span>
                        </label>
                      </div>
                      <div className="d-flex">
                        <span
                          className="mr-2"
                          style={{
                            fontSize: "14px",
                            color: isQtyChecked ? "#28a745" : "#dc3545",
                            fontWeight: "500",
                          }}
                        >
                          {isQtyChecked ? "YES" : "NO"}
                        </span>
                        <div
                          className={`onoffswitch ${isQtyChecked ? "on" : "off"}`}
                          onClick={handleSwitchQuantityCheckToggle}
                          style={{
                            width: "50px",
                            height: "26px",
                            borderRadius: "13px",
                            backgroundColor: isQtyChecked ? "#28a745" : "#dc3545",
                            position: "relative",
                            cursor: "pointer",
                            // transition: "background-color 0.3s ease",
                          }}
                        >
                          <div
                            className="toggle"
                            style={{
                              width: "22px",
                              height: "22px",
                              borderRadius: "11px",
                              backgroundColor: "#fff",
                              position: "absolute",
                              top: "2px",
                              left: isQtyChecked ? "-3px" : "2px",
                              // transition: "left 0.3s ease",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="card-body">
                <div className="cardchild">
                  <div className="body mt-0">
                    <ul className="list-group">
                      <li
                        className=" mt-1 d-flex justify-content-between list-group-item align-items-center"
                        style={{ border: "none" }}
                      >
                        <h6
                          className={`mb-0 ${
                            togglePack ? "text-success" : "text-danger"
                          }`}
                        >
                          {togglePack ? "Pack On" : "Pack Off"}
                        </h6>
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            onChange={handleSwitchPackToggle}
                            checked={togglePack}
                          />
                          <span className="toggle-switch-slider"></span>
                        </label>
                      </li>
                    </ul>
                    <div>
                      <ToastContainer hideProgressBar={true}></ToastContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Setting;
