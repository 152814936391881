import React, { useState, useEffect } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import NoDataImage from "../../../asset/images/nodata.jpg";
import PaymentRecivedDetails from "../SalesNew/PaymentRecivedDetails";

function PaymentRecived({ navigation }) {
  const [showDetails, setShowDetails] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handlePaymentClick = () => {
    handleNavigation("PaymentRecivedNew");
  };

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");
  const [loading, setLoading] = useState(true);
  const [postData, setPostData] = useState({
    uniqueId: decryptedUniqueId,
  });
  const [paymentData, setPaymentData] = useState("");
  const [paymentCount, setPaymentCount] = useState("");
  const [id, setId] = useState("");

  const fetchDataAndInitializeDataTable = () => {
    $("#DataTables_Table_0").DataTable();
    const apiUrl = BASE_URL + "/fetchPaymentReceived2";
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("POST request failed");
        }
      })
      .then((responseData) => {
        const mergedData = mergeArrays(responseData.responseMessage);
        setPaymentData(mergedData);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);

        $("#DataTables_Table_0").DataTable();
      });
  };

  const mergeArrays = (data) => {
    const mergedData = [];
    const uniquePaymentCounts = new Set();
    data.forEach((innerArray) => {
      innerArray.forEach((item) => {
        const existingRow = mergedData.find(
          (row) => row.paymentCount === item.paymentCount
        );
        if (!existingRow) {
          uniquePaymentCounts.add(item.paymentCount);
          mergedData.push({ ...item, invoices: [item.invoiceNumber] });
        } else {
          existingRow.invoices.push(item.invoiceNumber);
        }
      });
    });
    return mergedData;
  };
  useEffect(() => {
    fetchDataAndInitializeDataTable();
  }, []);

  const handleOpen = (paymentCount, customerId) => {
    setId(customerId);
    setPaymentCount(paymentCount);
    setShowCard(false);
    setShowDetails(true);
  };

  const handleCancelClick = () => {
    setShowCard(true);
    setShowDetails(false);
    fetchDataAndInitializeDataTable();
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      {!showDetails && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              All Payment Recieved
            </div>
            <div className="d-flex">
              <div className="">
                <button
                  className="btn btn-primary px-1 py-2 new-purchase"
                  onClick={handlePaymentClick}
                >
                  + New Payment
                </button>
              </div>
              <div className="ml-2 px-1 py-2 icon-dot">
                <i
                  className="fa fa-ellipsis-v "
                  style={{ fontSize: 18, color: "gray" }}
                ></i>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCard && (
        <div className="container-fluid">
          <Container fluid={true}>
            <Row>
              <Col>
                <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                  {paymentData && paymentData.length > 0 ? (
                    <>
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Payment#
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Reference
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        Customer name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Invoices
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Mode
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Amount
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="text1">
                                    {paymentData.map((data) => (
                                      <tr
                                        role="row"
                                        className="odd"
                                        key={data.id}
                                      >
                                        <td>{data.paymentDate}</td>
                                        <td>
                                          <a
                                            href="#"
                                            onClick={() =>
                                              handleOpen(
                                                data.paymentCount,
                                                data.customerId
                                              )
                                            }
                                          >
                                            {data.paymentCount}
                                          </a>{" "}
                                        </td>
                                        <td>{data.reference}</td>
                                        <td>{data.customerName}</td>
                                        <td>
                                          {" "}
                                          {data.invoices.map(
                                            (invoice, index) => (
                                              <React.Fragment key={invoice}>
                                                {index > 0 && <br />}
                                                {""}
                                                {invoice}
                                              </React.Fragment>
                                            )
                                          )}
                                        </td>
                                        <td>{data.paymentMode}</td>
                                        <td>₹{data.payment}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="body">
                      <div className="table-responsive1">
                        <div
                          id="DataTables_Table_0_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 no-footer"
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                id="DataTables_Table_0"
                                role="grid"
                                aria-describedby="DataTables_Table_0_info"
                              >
                                <thead className="heading-text">
                                  <tr role="row">
                                    <th
                                      className="sorting_asc"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-sort="ascending"
                                      aria-label="Date: activate to sort column descending"
                                    >
                                      Date
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Type: activate to sort column ascending"
                                    >
                                      Payment #
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Amount BTC: activate to sort column ascending"
                                    >
                                      Reference
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="BTC Remaining: activate to sort column ascending"
                                    >
                                      Customer name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      Invoice
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      Mode
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      Amount
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                              <h6 className="text-center">
                                <img
                                  src={NoDataImage}
                                  style={{ width: "50%", height: "50%" }}
                                  alt="No Data Logo"
                                />{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      {showDetails && (
        <PaymentRecivedDetails
          handleCancel={handleCancelClick}
          custId={id}
          paymentCount={paymentCount}
        />
      )}
    </div>
  );
}
export default PaymentRecived;
