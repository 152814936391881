import React from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import swal from "sweetalert";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";

function ConvertInWard(props) {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize with current date
  const [selectedTime, setSelectedTime] = useState(new Date());
  const { vendorName, billNumber } = props;

  const [formData, setFormData] = useState({
    trackingNo: "",
    uniqueId: decryptedUniqueId || "",
    fromName: vendorName,
    transportOrCourier: "",
    materialDescription: "",
    receivedBy: "",
    checkedBy: "",
    placeLocation: "",
    invoiceNumber: billNumber,
    date: new Date().toISOString().split("T")[0],
    time: new Date().toLocaleTimeString(),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCreateInward = (e) => {
    e.preventDefault();
    const requestBody = {
      trackingNo: formData.trackingNo,
      uniqueId: formData.uniqueId,
      fromName: vendorName,
      transportOrCourier: formData.transportOrCourier,
      materialDescription: formData.materialDescription,
      packedBy: formData.packedBy,
      checkedBy: formData.checkedBy,
      placeLocation: formData.placeLocation,
      invoiceNumber: billNumber,
      date: formData.date,
      time: formData.time,
    };

    const formDataToSend = new FormData();
    formDataToSend.append("request", JSON.stringify(requestBody));
    setLoading(true);

    fetch(`${BASE_URL}/createInward`, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: formDataToSend,
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.message === "Inward entry created successfully") {
          swal({
            icon: "success",
            title: "Inward Created Successfully",
          });
        } else {
          swal({
            icon: "error",
            title: "Failed to Create Inward",
          });
        }
        props.handleCancel();
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occurred While Creating Inward",
        });
      });
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      props.handleCancel();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col>
          <div className="my-3">
            <div className="d-flex align-items-center ml-1 mt-4">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                }}
              >
                <ArrowBackIosIcon
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5 className="ml-1 mb-0" style={{ color: "#086bd5de" }}>
                  New Inward
                </h5>
              </div>
            </div>
          </div>

          <Card className="card-style p-4">
            <Row>
              <Col md="3">
                <Form.Group>
                  <label className="text-primary" style={{ fontWeight: "600" }}>
                    INWARD DETAILS
                  </label>
                </Form.Group>
              </Col>
            </Row>

            <Form>
              <Row>
                {/* From Name */}
                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>From Name</label>
                    <Form.Control
                      placeholder="Enter Name"
                      name="fromName"
                      value={vendorName}
                      readOnly
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>

                {/* Transport or Courier */}
                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>
                      Transport or Courier
                    </label>
                    <Form.Control
                      placeholder="Enter Transport/Courier"
                      name="transportOrCourier"
                      value={formData.transportOrCourier}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>

                {/* Tracking Number */}
                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Tracking Number</label>
                    <Form.Control
                      placeholder="Enter Tracking Number"
                      name="trackingNo"
                      value={formData.trackingNo}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                {/* Material Description */}
                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>
                      Material Description
                    </label>
                    <Form.Control
                      name="materialDescription"
                      placeholder="Enter Material Description"
                      value={formData.materialDescription}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>

                {/* Received by */}
                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Received by</label>
                    <Form.Control
                      name="receivedBy"
                      placeholder="Enter Receiver's Name"
                      value={formData.receivedBy}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>

                {/* Checked by */}
                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Checked by</label>
                    <Form.Control
                      name="checkedBy"
                      placeholder="Enter Checker's Name"
                      value={formData.checkedBy}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                {/* Place / Location */}
                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>Place / Location</label>
                    <Form.Control
                      name="placeLocation"
                      placeholder="Enter Location"
                      value={formData.placeLocation}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>

                {/* Date & Time */}
                <Col md="3">
                  <Form.Group className="d-flex flex-column new-campaign-time w-100">
                    <label style={{ color: "grey" }}>Date</label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date);
                        setFormData({
                          ...formData,
                          date: date.toISOString().split("T")[0],
                        });
                      }}
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select date"
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group className="d-flex flex-column new-campaign-time w-100">
                    <label style={{ color: "grey" }}>Time</label>
                    <DatePicker
                      selected={selectedTime}
                      onChange={(time) => {
                        setSelectedTime(time);
                        setFormData({
                          ...formData,
                          time: time.toLocaleTimeString(),
                        });
                      }}
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1} // Adjust for interval (e.g., 15 minutes)
                      timeCaption="Time"
                      dateFormat="h:mm aa" // 12-hour format with AM/PM
                      placeholderText="Select time"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                {/* Place / Location */}
                <Col md="3">
                  <Form.Group>
                    <label style={{ color: "grey" }}>
                      Invoice / Document Number
                    </label>
                    <Form.Control
                      name="invoiceNumber"
                      placeholder="Enter Invoice / Document Number"
                      value={billNumber}
                      readOnly
                      style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>

            <hr className="mt-4" />

            <div className="text-right invoice-bottombtns">
              <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
                Cancel
              </button>
              <button className="btn-1 btn mt-0">Save as Draft</button>
              <button
                className="btn-3 btn btn-primary"
                onClick={handleCreateInward}
              >
                Create InWard
              </button>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ConvertInWard;
