import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import Select from "react-select";

import { BASE_URL } from "../CommonApi";

function NewIndent({ navigation }) {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState([]);
  const [userEmail, setUserEmail] = useState([]);
  const [userState, setUserState] = useState([]);

  const decryptedUniqueId = encryptedUniqueId
    ? AES.decrypt(encryptedUniqueId, "1234567812345678").toString(enc.Utf8)
    : "";

  const [IntedentData, setIntedentData] = useState({
    uniqueId: decryptedUniqueId || "",
    name: "",
    consumable: "",
    nonConsumable: "",
    suggestNameOfFirm1: "",
    suggestNameOfFirm2: "",
    suggestNameOfFirm3: "",
    finalTotal: "0.00",
    rate: "",
    make: "",
    totalCost: "",
    vendorName: "",
    indentor: "",
    director: "Jaypal Mahindrakar",
    accountOfficer: "",
    purchase: "",
    date: new Date(),
  });

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");

  const [vendorNames, setVendorNames] = useState([]);
  const [allItemNames, setAllItemNames] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);

  const fetchAllVendors = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchVendors`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const vendors = data.responseMessage.map((names) => ({
            value: names.vendorDisplayName,
            label: names.vendorDisplayName,
          }));
          setVendorNames(vendors);
        } else {
          console.error("Failed to fetch vendor names:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching vendor names:", error);
      });
  };

  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userState = data.responseMessage;
          setUserDetail(data.responseMessage);
          setUserEmail(data.responseMessage.email);
          setUserState(userState);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  const fetchItemData = (selectedItemId) => {
    const postApiUrl = BASE_URL + `/getAllItemData`;
    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, vendorId: "" }),
    })
      .then((response) => response.json())
      .then((data) => {
        setAllItemNames(data.responseMessage);

        const options = data.responseMessage.map((item) => ({
          value: item.id,
          label: item.name,
          itemData: item,
        }));

        setItemOptions(options);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  // const fetchItemRate = (itemId, index) => {
  //   const getApiUrl = BASE_URL + `/getItemData/${itemId}`;

  //   fetch(getApiUrl, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${jwtToken}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data.data.taxableAmount;
  //       const hsnSac = data.data.hsnCode;
  //       const description1 = data.data.description;
  //       const itemName = data.data.name;
  //       const tax = data.data.intraStateTaxRate;
  //       const updatedRow = [...row];
  //       updatedRow[index].rate = rate;
  //       updatedRow[index].hsnSac = hsnSac;
  //       updatedRow[index].description1 = description1;
  //       updatedRow[index].itemDetails = itemName;
  //       updatedRow[index].tax = tax;
  //       setRow(updatedRow);
  //       setSearchResults([]);
  //     })
  //     .catch((error) => {
  //       console.error("Error making POST request:", error);
  //     });
  // };

  const fetchItemDetails = (itemId, index) => {
    const getApiUrl = BASE_URL + `/getItemData/${itemId}`;

    fetch(getApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data) {
          const rate = data.data.taxableAmount;
          const make = data.data.make;

          const updatedRows = [...rows];
          updatedRows[index].rate = parseFloat(rate) || 0;
          updatedRows[index].make = make || "";

          updatedRows[index].totalCost = (
            updatedRows[index].quantityInStock * updatedRows[index].rate
          ).toFixed(2);

          setRows(updatedRows);

          const newFinalTotal = calculateFinalTotal(updatedRows);
          setIntedentData((prev) => ({
            ...prev,
            finalTotal: newFinalTotal,
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };

  const [rows, setRows] = useState([
    {
      id: Date.now(),
      itemDetail: "",
      selectedItem: null,
      quantityInStock: 1,
      rate: 0.0,
      make: "",
      totalCost: 0.0,
      vendorName: "",
    },
  ]);

  // Modified handleAdd to recalculate total
  const handleAdd = () => {
    const updatedRows = [
      ...rows,
      {
        id: Date.now(),
        itemDetail: "",
        selectedItem: null,
        quantityInStock: 1,
        rate: 0.0,
        make: "",
        totalCost: 0.0,
        vendorName: "",
      },
    ];
    setRows(updatedRows);

    const newFinalTotal = calculateFinalTotal(updatedRows);
    setIntedentData((prev) => ({
      ...prev,
      finalTotal: newFinalTotal,
    }));
  };

  const handleDateChange = (date) => {
    setIntedentData((prev) => ({
      ...prev,
      date: date,
    }));
  };

  const handleIndentChange = (e) => {
    setIntedentData({
      ...IntedentData,
      [e.target.name]: e.target.value,
    });
  };

  const calculateFinalTotal = (updatedRows) => {
    const total = updatedRows.reduce((sum, row) => {
      return sum + parseFloat(row.totalCost || 0);
    }, 0);
    return total.toFixed(2);
  };

  // Modified handleRowChange to update finalTotal
  const handleRowChange = (index, field, value) => {
    // const updatedRows = [...rows];
    // updatedRows[index][field] = value;
    // updatedRows[index].totalCost = (
    //   updatedRows[index].quantityInStock * updatedRows[index].rate
    // ).toFixed(2);

    // const newFinalTotal = calculateFinalTotal(updatedRows);

    // setRows(updatedRows);
    // setIntedentData((prev) => ({
    //   ...prev,
    //   finalTotal: newFinalTotal,
    // }));

    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    updatedRows[index].totalCost = (
      updatedRows[index].quantityInStock * updatedRows[index].rate
    ).toFixed(2);

    const newFinalTotal = calculateFinalTotal(updatedRows);

    setRows(updatedRows);
    setIntedentData((prev) => ({
      ...prev,
      finalTotal: newFinalTotal,
    }));
  };

  const handleItemSelect = (selectedOption, index) => {
    const updatedRows = [...rows];
    updatedRows[index].selectedItem = selectedOption;
    updatedRows[index].itemDetail = selectedOption ? selectedOption.label : "";

    setRows(updatedRows);

    // Fetch item details when an item is selected
    if (selectedOption) {
      fetchItemDetails(selectedOption.value, index);
    }
  };

  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);

    const newFinalTotal = calculateFinalTotal(updatedRows);
    setIntedentData((prev) => ({
      ...prev,
      finalTotal: newFinalTotal,
    }));
  };

  const handleIndentSubmit = (event) => {
    event.preventDefault();

    if (!IntedentData.name) {
      swal({
        icon: "error",
        title: "Please fill in all required fields",
      });
      return;
    }

    setLoading(true);
    const formattedRows = rows.map((row) => ({
      id: row.id,
      itemDetail: row.itemDetail,
      item: row.selectedItem ? row.selectedItem.value : "",
      quantityInStock: row.quantityInStock,
      rate: row.rate,
      make: row.make,
      totalCost: row.totalCost,
      vendorName: row.vendorName,
    }));

    fetch(`${BASE_URL}/createIndent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ ...IntedentData, items: formattedRows }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Indent Created Successfully",
          });
        } else {
          swal({
            icon: "error",
            title: "Failed to Create Indent",
          });
        }
        handleCancel();
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occurred While Creating Indent",
        });
      });
  };

  useEffect(() => {
    fetchData();
    fetchAllVendors();
    fetchItemData();
  }, []);

  useEffect(() => {
    if (userDetail.name) {
      setIntedentData((prev) => ({
        ...prev,
        indentor: userDetail.name,
      }));
    }
  }, [userDetail]);

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleCancel = () => {
    handleNavigation("Indent");
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleCancel();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col>
          <div className="my-3 d-flex align-items-center ml-1 mt-4">
            <div
              className="cursor-pointer d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                height: "35px",
                width: "38px",
              }}
            >
              <ArrowBackIosIcon
                className="arrow-child"
                onClick={handleCancleClick}
              />
            </div>
            <h5 className="ml-2 mb-0 text-primary">New Indent</h5>
          </div>

          <Card className="card-style p-4">
            <Form onSubmit={handleIndentSubmit}>
              <Row>
                <Col md="3">
                  <Form.Group>
                    <label className="text-primary font-weight-bold">
                      INDENT INFO
                    </label>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="3">
                  <Form.Group>
                    <label>Name of the Indentor</label>
                    <Form.Control
                      name="name"
                      placeholder="Enter Name"
                      value={IntedentData.name}
                      onChange={handleIndentChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Indent Date</label>
                    <DatePicker
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                      selected={IntedentData.date} // ✅ Bind to state
                      onChange={handleDateChange} // ✅ Update state on change
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="3">
                  <Form.Group>
                    <label>Consumable</label>
                    <Form.Control
                      name="consumable"
                      placeholder="Enter Consumable"
                      value={IntedentData.consumable}
                      onChange={handleIndentChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Non-Consumable</label>
                    <Form.Control
                      name="nonConsumable"
                      placeholder="Enter Non-Consumable"
                      value={IntedentData.nonConsumable}
                      onChange={handleIndentChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md="3">
                  <Form.Group>
                    <label className="text-primary font-weight-bold">
                      ITEM INFO
                    </label>
                  </Form.Group>
                </Col>
              </Row>

              <Card>
                <table className="table table-bordered mb-0">
                  <thead className="text-center">
                    <tr>
                      <th style={{ width: "30%" }}>Item Details</th>
                      <th style={{ width: "10%" }}>QUANTITY</th>
                      <th style={{ width: "10%" }}>RATE</th>
                      <th style={{ width: "15%" }}>MAKE</th>
                      <th style={{ width: "10%" }}>Total Cost</th>
                      <th style={{ width: "20%" }}>Vendor</th>
                      <th style={{ width: "5%" }}>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={row.id}>
                        <td>
                          <Select
                            options={itemOptions}
                            value={row.selectedItem}
                            onChange={(selectedOption) =>
                              handleItemSelect(selectedOption, index)
                            }
                            placeholder="Search for an item"
                            isClearable
                            isSearchable
                            
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={row.quantityInStock} // ✅ Ensure this is set correctly
                            onChange={(e) =>
                              handleRowChange(
                                index,
                                "quantityInStock",
                                Number(e.target.value)
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={row.rate}
                            onChange={(e) =>
                              handleRowChange(
                                index,
                                "rate",
                                Number(e.target.value)
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            placeholder="Make"
                            value={row.make}
                            onChange={(e) =>
                              handleRowChange(index, "make", e.target.value)
                            }
                          />
                        </td>
                        <td>{row.totalCost}</td>
                        <td>
                          <Select
                            options={vendorNames}
                            value={vendorNames.find(
                              (option) => option.value === row.vendorName
                            )}
                            onChange={(selectedOption) =>
                              handleRowChange(
                                index,
                                "vendorName",
                                selectedOption.value
                              )
                            }
                            placeholder="Select Vendor"
                          />
                        </td>
                        <td className="text-center">
                          <Button
                            variant="danger"
                            onClick={() => handleRemoveRow(index)}
                          >
                            <i className="fa fa-trash"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="4" className="text-right font-weight-bold">
                        Final Total:
                      </td>
                      <td colSpan="3" className="font-weight-bold">
                        ₹ {IntedentData.finalTotal}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>

              <Button className="mt-3" onClick={handleAdd}>
                + Add New Row
              </Button>

              <hr className="mt-4" />
              <Row className="px-4 py-3">
                <h5>Justification For Procurement of the above Items</h5>
                <hr className="mt-4" />
              </Row>

              {/* <Row className="px-5 py-3">
                <h6>Suggested Name Of The Firm</h6>
                <Col md="3">
                  <Form.Group>
                    <Form.Label>M/S</Form.Label>
                    <Form.Control
                      name="suggestNameOfFirm1"
                      placeholder="Enter firm name"
                      value={IntedentData.suggestNameOfFirm1} // ✅ Corrected state name
                      onChange={handleIndentChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <Form.Label>M/S</Form.Label>
                    <Form.Control
                      name="suggestNameOfFirm2" // ✅ Corrected input name
                      placeholder="Enter firm name"
                      value={IntedentData.suggestNameOfFirm2}
                      onChange={handleIndentChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <Form.Label>M/S</Form.Label>
                    <Form.Control
                      name="suggestNameOfFirm3" // ✅ Corrected input name
                      placeholder="Enter firm name"
                      value={IntedentData.suggestNameOfFirm3}
                      onChange={handleIndentChange}
                    />
                  </Form.Group>
                </Col>
              </Row> */}

              <Row className="px-5 py-3">
                <Col md="3">
                  <Form.Group>
                    <Form.Label>Indentor</Form.Label>
                    <Form.Control
                      name="indentor"
                      placeholder=""
                      value={IntedentData.indentor}
                      onChange={handleIndentChange}
                      readOnly
                    />
                  </Form.Group>
                </Col>

                <Col md="3">
                  <Form.Group>
                    <Form.Label>Purchase/Store</Form.Label>
                    <Form.Control
                      name="purchase"
                      placeholder=""
                      value={IntedentData.purchase}
                      onChange={handleIndentChange}
                    />
                  </Form.Group>
                </Col>

                <Col md="3">
                  <Form.Group>
                    <Form.Label>Account Officer</Form.Label>
                    <Form.Control
                      name="accountOfficer"
                      placeholder=""
                      value={IntedentData.accountOfficer}
                      onChange={handleIndentChange}
                    />
                  </Form.Group>
                </Col>

                <Col md="3">
                  <Form.Group>
                    <Form.Label>Director</Form.Label>
                    <Form.Control
                      name="director"
                      placeholder=""
                      value={IntedentData.director}
                      // onChange={handleIndentChange}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button type="submit" className="mt-3 btn-primary">
                Submit Indent
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default NewIndent;
