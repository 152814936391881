import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";

const UpdateOutWard = ({ navigation, handleCancel, trackingNo }) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());

  const [toName, setToName] = useState("");
  const [transportOrCourier, setTransportOrCourier] = useState("");
  const [materialDescription, setMaterialDescription] = useState("");
  const [packedBy, setPackedBy] = useState("");
  const [checkedBy, setCheckedBy] = useState("");
  const [placeLocation, setPlaceLocation] = useState("");
  const [status, setStatus] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");

  useEffect(() => {
    // Fetch outward data by tracking number
    if (trackingNo) {
      setLoading(false);
      fetch(`${BASE_URL}/getOutwardByTrackingNo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          trackingNo: trackingNo,
          uniqueId: decryptedUniqueId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data to be updated : ", data);
          if (data.message === "Outward entry found" && data.data) {
            setLoading(false);
            const outwardData = data.data;
            setToName(outwardData.toName || "");
            setTransportOrCourier(outwardData.transportOrCourier || "");
            setMaterialDescription(outwardData.materialDescription || "");
            setPackedBy(outwardData.packedBy || "");
            setCheckedBy(outwardData.checkedBy || "");
            setPlaceLocation(outwardData.placeLocation || "");
            setStatus(outwardData.status || "");
            setInvoiceNumber(outwardData.invoiceNumber || "");

            // Set date and time for DatePicker components
            if (outwardData.date) {
              setSelectedDate(new Date(outwardData.date));
            }
            if (outwardData.time) {
              const [hours, minutes, seconds] = outwardData.time.split(":");
              const timeDate = new Date();
              timeDate.setHours(parseInt(hours, 10));
              timeDate.setMinutes(parseInt(minutes, 10));
              timeDate.setSeconds(parseInt(seconds, 10));
              setSelectedTime(timeDate);
            }
          } else {
            setLoading(false);
            console.error("Error fetching outward data");
            swal({
              icon: "error",
              title: "Failed to fetch outward details",
            });
          }
          // setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
          swal({
            icon: "error",
            title: "An Error Occurred While Fetching Outward Details",
          });
        });
    } else {
      setLoading(false);
    }
  }, [trackingNo]);

  const handleUpdateOutward = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    const outwardData = {
      toName: toName,
      transportOrCourier: transportOrCourier,
      materialDescription: materialDescription,
      checkedBy: checkedBy,
      packedBy: packedBy,
      placeLocation: placeLocation,
      status: status,
      date: selectedDate.toISOString().split("T")[0],
      time: selectedTime.toTimeString().split(" ")[0],
      invoiceNumber: invoiceNumber,
      trackingNo: trackingNo,
      uniqueId: decryptedUniqueId,
    };

    formData.append("request", JSON.stringify(outwardData));

    setLoading(true);

    fetch(`${BASE_URL}/updateOutward`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Update Outward Response:", data);
        // handleCancel();
        if (data.message === "Outward entry updated successfully") {
          setLoading(false);
          swal({
            icon: "success",
            title: "Outward Updated Successfully",
          }).then(() => {
            handleCancel();
          });
        } else {
          setLoading(false);
          swal({
            icon: "error",
            title: "Failed to Update Outward",
            text: data.message || "An error occurred",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occurred While Updating Outward",
        });
      });
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm(
      "Are you sure you want to leave this page?"
    );
    if (confirmCancel) {
      handleCancel();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="my-3">
        <div className="d-flex align-items-center ml-1 mt-4">
          <div
            className="cursor-pointer d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              height: "35px",
              width: "38px",
            }}
          >
            <ArrowBackIosIcon
              width={2}
              onClick={handleCancleClick}
              className="arrow-child"
            />
          </div>
          <div className="newcustomer-head ml-2">
            <h5
              className="ml-1 mb-0"
              style={{ letterSpacing: "1px", color: "#086bd5de" }}
            >
              Update OutWard
            </h5>
          </div>
        </div>
      </div>
      <Container fluid>
        <Card className="card-style p-4">
          <Row>
            <Col md="3">
              <Form.Group>
                <label className="text-primary" style={{ fontWeight: "600" }}>
                  OUTWARD DETAILS
                </label>
              </Form.Group>
            </Col>
          </Row>

          <Form>
            <Row>
              <Col md="3">
                <Form.Group>
                  <label style={{ color: "grey" }}>To Name</label>
                  <Form.Control
                    name="toName"
                    placeholder="Enter Name"
                    value={toName}
                    onChange={(e) => setToName(e.target.value)}
                    style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                  />
                </Form.Group>
              </Col>

              <Col md="3">
                <Form.Group>
                  <label style={{ color: "grey" }}>Transport or Courier</label>
                  <Form.Control
                    name="transportOrCourier"
                    placeholder="Enter Transport/Courier"
                    value={transportOrCourier}
                    onChange={(e) => setTransportOrCourier(e.target.value)}
                    style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                  />
                </Form.Group>
              </Col>

              <Col md="3">
                <Form.Group>
                  <label style={{ color: "grey" }}>Tracking Number</label>
                  <Form.Control
                    name="trackingNo"
                    placeholder="Enter Tracking Number"
                    value={trackingNo}
                    style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <Form.Group>
                  <label style={{ color: "grey" }}>Material Description</label>
                  <Form.Control
                    name="materialDescription"
                    placeholder="Enter Material Description"
                    value={materialDescription}
                    onChange={(e) => setMaterialDescription(e.target.value)}
                    style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                  />
                </Form.Group>
              </Col>

              <Col md="3">
                <Form.Group>
                  <label style={{ color: "grey" }}>Packed by</label>
                  <Form.Control
                    name="packedBy"
                    placeholder="Enter Packer's Name"
                    value={packedBy}
                    onChange={(e) => setPackedBy(e.target.value)}
                    style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                  />
                </Form.Group>
              </Col>

              <Col md="3">
                <Form.Group>
                  <label style={{ color: "grey" }}>Checked by</label>
                  <Form.Control
                    name="checkedBy"
                    placeholder="Enter Checker's Name"
                    value={checkedBy}
                    onChange={(e) => setCheckedBy(e.target.value)}
                    style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <Form.Group>
                  <label style={{ color: "grey" }}>Place / Location</label>
                  <Form.Control
                    name="placeLocation"
                    placeholder="Enter Location"
                    value={placeLocation}
                    onChange={(e) => setPlaceLocation(e.target.value)}
                    style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                  />
                </Form.Group>
              </Col>

              <Col md="3">
                <Form.Group className="d-flex flex-column new-campaign-time w-100">
                  <label style={{ color: "grey" }}>Date</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select date"
                  />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group className="d-flex flex-column new-campaign-time w-100">
                  <label style={{ color: "grey" }}>Time</label>
                  <DatePicker
                    selected={selectedTime}
                    onChange={(time) => setSelectedTime(time)}
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="Select time"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <Form.Group>
                  <label style={{ color: "grey" }}>Status</label>
                  <Form.Control
                    as="select"
                    className="form-control"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="">Select Status</option>
                    <option value="Dispatch">Dispatch</option>
                    <option value="Transit">Transit</option>
                    <option value="Deliver">Deliver</option>
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md="3">
                <Form.Group>
                  <label style={{ color: "grey" }}>
                    Invoice / Document Number
                  </label>
                  <Form.Control
                    name="invoiceNumber"
                    placeholder="Enter Invoice / Document Number"
                    value={invoiceNumber}
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                    style={{ backgroundColor: "#F6F6F6", height: "40px" }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <hr className="mt-4" />

            <div className="text-right invoice-bottombtns">
              <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
                Cancel
              </button>
              <button
                className="btn-3 btn btn-primary"
                onClick={handleUpdateOutward}
              >
                Update OutWard
              </button>
            </div>
          </Form>
        </Card>
      </Container>
    </div>
  );
};

export default UpdateOutWard;
