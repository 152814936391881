import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import Select from "react-select";

const UpdateIndent = ({ navigation, indentNum, handleCancel }) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [loading, setLoading] = useState(false);

  const [indentData, setIndentData] = useState({
    id: "",
    uniqueId: "",
    name: "",
    consumable: "",
    nonConsumable: "",
    date: new Date(),
    suggestNameOfFirm1: "",
    suggestNameOfFirm2: "",
    suggestNameOfFirm3: "",
    finalTotal: 0.0,
    indentNumber: "",
    indentor: "",
    purchase: "",
    accountOfficer: "",
    director: "",
    items: [],
  });

  const [rows, setRows] = useState([
    {
      id: Date.now(),
      itemId: "",
      itemDetail: "",
      item: "",
      quantityInStock: 1,
      rate: 0.0,
      make: "",
      totalCost: 0.0,
      vendorName: "",
    },
  ]);

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      handleCancel();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  const handleAdd = () => {
    const updatedRows = [
      ...rows,
      {
        id: Date.now(),
        itemId: "",
        itemDetail: "",
        item: "",
        quantityInStock: 1,
        rate: 0.0,
        make: "",
        totalCost: 0.0,
        vendorName: "",
      },
    ];
    setRows(updatedRows);

    const newFinalTotal = calculateFinalTotal(updatedRows);
    setIndentData((prev) => ({
      ...prev,
      finalTotal: newFinalTotal,
    }));
  };

  const calculateFinalTotal = (updatedRows) => {
    const total = updatedRows.reduce((sum, row) => {
      return sum + parseFloat(row.totalCost || 0);
    }, 0);
    return total.toFixed(2);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    // Calculate total cost for the row
    if (field === "rate" || field === "quantityInStock") {
      updatedRows[index].totalCost = (
        updatedRows[index].quantityInStock * updatedRows[index].rate
      ).toFixed(2);
    }

    const newFinalTotal = calculateFinalTotal(updatedRows);

    setRows(updatedRows);
    setIndentData((prev) => ({
      ...prev,
      finalTotal: newFinalTotal,
    }));
  };

  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);

    const newFinalTotal = calculateFinalTotal(updatedRows);
    setIndentData((prev) => ({
      ...prev,
      finalTotal: newFinalTotal,
    }));
  };

  const [vendorNames, setVendorNames] = useState([]);
  const [allItemNames, setAllItemNames] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);

  const fetchAllVendors = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchVendors`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const vendors = data.responseMessage.map((names) => ({
            value: names.vendorDisplayName,
            label: names.vendorDisplayName,
          }));
          setVendorNames(vendors);
        } else {
          console.error("Failed to fetch vendor names:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching vendor names:", error);
      });
  };

  const fetchIndentData = () => {
    setLoading(true);
    fetch(`${BASE_URL}/findByIndentNumber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        indentNumber: indentNum,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.responseCode === "200") {
          // Prepare items with proper IDs for select components
          const processedItems = data.data.items.map(item => {
            return {
              ...item,
              id: item.id || Date.now(),
              itemId: item.itemId || "", // Make sure itemId exists
              rate: parseFloat(item.rate || 0),
              quantityInStock: parseInt(item.quantityInStock || 1),
              totalCost: parseFloat(item.totalCost || 0).toFixed(2)
            };
          });

          setIndentData({
            ...data.data,
            date: new Date(data.data.date),
          });
          
          setRows(processedItems);

          // Fetch item data if not already loaded
          if (itemOptions.length === 0) {
            fetchItemData();
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const fetchItemData = () => {
    const postApiUrl = BASE_URL + `/getAllItemData`;
    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, vendorId: "" }),
    })
      .then((response) => response.json())
      .then((data) => {
        setAllItemNames(data.responseMessage);

        const options = data.responseMessage.map((item) => ({
          value: item.id,
          label: item.name,
          itemData: item,
        }));

        setItemOptions(options);
        
        // After fetching item options, match them with rows
        matchItemsWithOptions(options);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  // New function to match item names with options after data is loaded
  const matchItemsWithOptions = (options) => {
    if (rows.length > 0 && options.length > 0) {
      const updatedRows = rows.map(row => {
        // Try to find matching item by name or ID
        const matchingOption = options.find(
          option => option.label === row.item || option.label === row.itemDetail || option.value === row.itemId
        );
        
        if (matchingOption) {
          return {
            ...row,
            itemId: matchingOption.value,
            item: matchingOption.label,
            itemDetail: matchingOption.label
          };
        }
        return row;
      });
      
      setRows(updatedRows);
    }
  };

  const handleItemSelect = (selectedOption, index) => {
    const itemId = selectedOption ? selectedOption.value : "";
    const itemName = selectedOption ? selectedOption.label : "";
    
    // Update the row with the selected item name
    const updatedRows = [...rows];
    updatedRows[index].itemId = itemId;
    updatedRows[index].item = itemName;
    updatedRows[index].itemDetail = itemName; // Also update itemDetail for consistency
    
    setRows(updatedRows);
    
    // Fetch item details if an item is selected
    if (itemId) {
      fetchItemDetails(itemId, index);
    }
  };

  const fetchItemDetails = (itemId, index) => {
    const getApiUrl = BASE_URL + `/getItemData/${itemId}`;

    fetch(getApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data) {
          const rate = data.data.taxableAmount;
          const make = data.data.make;

          const updatedRows = [...rows];
          updatedRows[index].rate = parseFloat(rate) || 0;
          updatedRows[index].make = make || "";

          updatedRows[index].totalCost = (
            updatedRows[index].quantityInStock * updatedRows[index].rate
          ).toFixed(2);

          setRows(updatedRows);

          const newFinalTotal = calculateFinalTotal(updatedRows);
          setIndentData((prev) => ({
            ...prev,
            finalTotal: newFinalTotal,
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };

  useEffect(() => {
    fetchAllVendors();
    fetchItemData();
    fetchIndentData();
  }, []);

  // When item options load, match with existing rows
  useEffect(() => {
    if (itemOptions.length > 0 && rows.length > 0) {
      matchItemsWithOptions(itemOptions);
    }
  }, [itemOptions]);

  const handleIndentUpdate = (e) => {
    e.preventDefault();

    // Prepare the request body
    const requestBody = {
      id: indentData.id,
      uniqueId: decryptedUniqueId,
      name: indentData.name,
      indentNumber: indentData.indentNumber,
      consumable: indentData.consumable,
      nonConsumable: indentData.nonConsumable,
      date: indentData.date.toISOString().split("T")[0], // Format date as "YYYY-MM-DD"
      suggestNameOfFirm: [
        indentData.suggestNameOfFirm1,
        indentData.suggestNameOfFirm2,
        indentData.suggestNameOfFirm3,
      ].filter((firm) => firm), // Remove empty values
      finalTotal: indentData.finalTotal,
      indentor: indentData.indentor,
      purchase: indentData.purchase,
      accountOfficer: indentData.accountOfficer,
      director: indentData.director,
      items: rows.map((row) => ({
        id: row.id,
        itemId: row.itemId,
        itemDetail: row.itemDetail,
        item: row.item,
        quantityRequired: row.quantityInStock,
        quantityInStock: row.quantityInStock,
        rate: row.rate,
        make: row.make,
        vendorName: row.vendorName,
        totalCost: row.totalCost,
      })),
    };

    // Log the request body for debugging
    console.log("Request Body:", requestBody);

    setLoading(true);
    // Send the update request
    fetch(`${BASE_URL}/updateIndent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setLoading(false);
          swal("Success", "Indent updated successfully!", "success");
          handleCancel();
        } else {
          setLoading(false);
          swal("Error", "Failed to update indent.", "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error updating indent:", error);
        swal("Error", "An error occurred while updating indent.", "error");
      });
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="my-3">
        <div className="d-flex align-items-center ml-1 mt-4">
          <div
            className="cursor-pointer d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              height: "35px",
              width: "38px",
            }}
          >
            <ArrowBackIosIcon
              width={2}
              onClick={handleCancleClick}
              className="arrow-child"
            />
          </div>
          <div className="newcustomer-head ml-2">
            <h5
              className="ml-1 mb-0"
              style={{ letterSpacing: "1px", color: "#086bd5de" }}
            >
              Update Indent
            </h5>
          </div>
        </div>
      </div>
      <Container fluid>
        <Card className="card-style p-4">
          <Form onSubmit={handleIndentUpdate}>
            <Row>
              <Col md="3">
                <Form.Group>
                  <label className="text-primary font-weight-bold">
                    INDENT INFO
                  </label>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <Form.Group>
                  <label>Name of the Indentor</label>
                  <Form.Control
                    name="name"
                    placeholder="Enter Name"
                    value={indentData.name}
                    onChange={(e) =>
                      setIndentData({ ...indentData, name: e.target.value })
                    }
                    required
                  />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>Indent Date</label>
                  <DatePicker
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    selected={indentData.date}
                    onChange={(date) => setIndentData({ ...indentData, date })}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <Form.Group>
                  <label>Consumable</label>
                  <Form.Control
                    name="consumable"
                    placeholder="Enter Consumable"
                    value={indentData.consumable}
                    onChange={(e) =>
                      setIndentData({
                        ...indentData,
                        consumable: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>Non-Consumable</label>
                  <Form.Control
                    name="nonConsumable"
                    placeholder="Enter Non-Consumable"
                    value={indentData.nonConsumable}
                    onChange={(e) =>
                      setIndentData({
                        ...indentData,
                        nonConsumable: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col md="3">
                <Form.Group>
                  <label className="text-primary font-weight-bold">
                    ITEM INFO
                  </label>
                </Form.Group>
              </Col>
            </Row>

            <Card>
              <table className="table table-bordered mb-0">
                <thead className="text-center">
                  <tr>
                    <th style={{ width: "30%" }}>Item Details</th>
                    <th style={{ width: "10%" }}>QUANTITY</th>
                    <th style={{ width: "10%" }}>RATE</th>
                    <th style={{ width: "15%" }}>MAKE</th>
                    <th style={{ width: "10%" }}>Total Cost</th>
                    <th style={{ width: "20%" }}>Vendor</th>
                    <th style={{ width: "5%" }}>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={row.id}>
                      <td>
                        <Select
                          options={itemOptions}
                          value={itemOptions.find(
                            option => option.value === row.itemId || option.label === row.item || option.label === row.itemDetail
                          )}
                          onChange={(selectedOption) =>
                            handleItemSelect(selectedOption, index)
                          }
                          placeholder="Select Item"
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={row.quantityInStock}
                          onChange={(e) =>
                            handleRowChange(
                              index,
                              "quantityInStock",
                              Number(e.target.value)
                            )
                          }
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={row.rate}
                          onChange={(e) =>
                            handleRowChange(
                              index,
                              "rate",
                              Number(e.target.value)
                            )
                          }
                        />
                      </td>
                      <td>
                        <Form.Control
                          placeholder="Make"
                          value={row.make}
                          onChange={(e) =>
                            handleRowChange(index, "make", e.target.value)
                          }
                        />
                      </td>
                      <td>{row.totalCost}</td>
                      <td>
                        <Select
                          options={vendorNames}
                          value={vendorNames.find(
                            option => option.value === row.vendorName
                          )}
                          onChange={(selectedOption) =>
                            handleRowChange(
                              index,
                              "vendorName",
                              selectedOption.value
                            )
                          }
                          placeholder="Select Vendor"
                        />
                      </td>
                      <td className="text-center">
                        {rows.length > 1 && (
                          <Button
                            variant="danger"
                            onClick={() => handleRemoveRow(index)}
                          >
                            <i className="fa fa-trash"></i>
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="4" className="text-right font-weight-bold">
                      Final Total:
                    </td>
                    <td colSpan="3" className="font-weight-bold">
                      ₹ {indentData.finalTotal}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>

            <Button className="mt-3" onClick={handleAdd}>
              + Add New Row
            </Button>

            <hr className="mt-4" />
            <Row className="px-4 py-3">
              <h5>Justification For Procurement of the above Items</h5>
              <hr className="mt-4" />
            </Row>

            <Row className="px-5 py-3">
              <h6>Suggested Name Of The Firm</h6>
              <Col md="3">
                <Form.Group>
                  <Form.Label>M/S</Form.Label>
                  <Form.Control
                    name="suggestNameOfFirm1"
                    placeholder="Enter firm name"
                    value={indentData.suggestNameOfFirm1}
                    onChange={(e) =>
                      setIndentData({
                        ...indentData,
                        suggestNameOfFirm1: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <Form.Label>M/S</Form.Label>
                  <Form.Control
                    name="suggestNameOfFirm2"
                    placeholder="Enter firm name"
                    value={indentData.suggestNameOfFirm2}
                    onChange={(e) =>
                      setIndentData({
                        ...indentData,
                        suggestNameOfFirm2: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <Form.Label>M/S</Form.Label>
                  <Form.Control
                    name="suggestNameOfFirm3"
                    placeholder="Enter firm name"
                    value={indentData.suggestNameOfFirm3}
                    onChange={(e) =>
                      setIndentData({
                        ...indentData,
                        suggestNameOfFirm3: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="px-5 py-3">
              <Col md="3">
                <Form.Group>
                  <Form.Label>Indentor</Form.Label>
                  <Form.Control
                    name="indentor"
                    placeholder=""
                    value={indentData.indentor}
                    onChange={(e) =>
                      setIndentData({ ...indentData, indentor: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>

              <Col md="3">
                <Form.Group>
                  <Form.Label>Purchase/Store</Form.Label>
                  <Form.Control
                    name="purchase"
                    placeholder=""
                    value={indentData.purchase}
                    onChange={(e) =>
                      setIndentData({ ...indentData, purchase: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>

              <Col md="3">
                <Form.Group>
                  <Form.Label>Account Officer</Form.Label>
                  <Form.Control
                    name="accountOfficer"
                    placeholder=""
                    value={indentData.accountOfficer}
                    onChange={(e) =>
                      setIndentData({
                        ...indentData,
                        accountOfficer: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>

              <Col md="3">
                <Form.Group>
                  <Form.Label>Director</Form.Label>
                  <Form.Control
                    name="director"
                    placeholder=""
                    value={indentData.director}
                    onChange={(e) =>
                      setIndentData({ ...indentData, director: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button type="submit" className="mt-3 btn-primary">
              Submit Indent
            </Button>
          </Form>
        </Card>
      </Container>
    </div>
  );
};

export default UpdateIndent;